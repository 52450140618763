<ol class="breadcrumb" *ngIf="!preview">
    <li class="breadcrumb-item d-sm-down-none">
        <button class="btn btn-link" (click)="onSave()" [disabled]="!(access.save || (model.Status == 'PENDING' && (access.process || access.admin)))">
            <i class="fas fa-save"></i> <span class="ml-2">Save</span>
        </button>
    </li>
    <li class="breadcrumb-item d-sm-down-none">
        <button class="btn btn-link" (click)="onBack()">
            <i class="fas fa-undo-alt"></i> <span class="ml-2">Back</span>
        </button>
    </li>
    <li class="breadcrumb-menu">
        <div class="btn-group" dropdown placement="bottom right">
            <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
                <i class="fas fa-bullseye"></i> <span class="ml-2 mr-1">Actions</span> <span class="caret"></span>
            </button>

            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                <li role="menuitem">
                    <a class="dropdown-item" (click)="onSubmit()" *ngIf="access.submit" href="javascript:;">
                        <i class="fas fa-check-circle"></i> Submit
                    </a>
                    <a class="dropdown-item" (click)="onExportExcel()" href="javascript:;">
                        <i class="fas fa-file-export"></i> Export
                    </a>
                    <a class="dropdown-item" (click)="onDelete()" *ngIf="access.delete" href="javascript:;">
                        <i class="fas fa-times-circle"></i> Delete
                    </a>

                    <workflow [model]="model" (onConfirm)="onLoad($event)"></workflow>
                </li>
            </ul>
        </div>
    </li>
</ol>

<div class="container-fluid" [class.preview-mode]="preview" [class.preview-mode-scroll]="preview">
    <fluids-request-new-tank-detail *ngIf="loaded && model?.Type.Type == 'NewTank'" [access]="access" [model]="model" [lookupValues]="lookupValues" [types]="types"></fluids-request-new-tank-detail>
    <fluids-request-move-tank-detail *ngIf="loaded && model?.Type.Type == 'MoveTank'" [access]="access" [model]="model" [lookupValues]="lookupValues" [types]="types"></fluids-request-move-tank-detail>
    <fluids-request-shutdown-tank-detail *ngIf="loaded && model?.Type.Type == 'ShutdownTank'" [access]="access" [model]="model" [lookupValues]="lookupValues" [types]="types"></fluids-request-shutdown-tank-detail>
    <fluids-request-emergency-fill-tank-detail *ngIf="loaded && model?.Type.Type == 'EmergencyFill'" [access]="access" [model]="model" [lookupValues]="lookupValues" [types]="types"></fluids-request-emergency-fill-tank-detail>
    <fluids-request-oil-only-detail *ngIf="loaded && model?.Type.Type == 'OilOnly'" [access]="access" [model]="model" [lookupValues]="lookupValues" [types]="types"></fluids-request-oil-only-detail>
    <fluids-request-swap-unit-detail *ngIf="loaded && model?.Type.Type == 'SwapUnit'" [access]="access" [model]="model" [lookupValues]="lookupValues" [types]="types"></fluids-request-swap-unit-detail>
    <fluids-request-fill-tanks-detail *ngIf="loaded && model?.Type.Type == 'FillTanks'" [access]="access" [model]="model" [lookupValues]="lookupValues" [types]="types"></fluids-request-fill-tanks-detail>
</div>