import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    ExpenseReportModel,
    ExpenseReportItemModel,
    MacolaBatchModel,
    MacolaCategoryModel,
    ReportModel,
    SearchCriteriaModel,
    SearchResultModel
} from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class ExpenseReportService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.EXPENSE}/api/expensereport`;

    data: any = {};

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get<ExpenseReportModel>(this.url + `?modelPk=${modelPk}`)
            .pipe(
                map(res => new ExpenseReportModel(res))
            );
    }

    getBatch(batchPk: number) {
        return this.http.get<ExpenseReportModel[]>(this.url + `/getBatch?batchPk=${batchPk}`)
            .pipe(map(res => res ? res.map(x => new ExpenseReportModel(x)) : new Array() as ExpenseReportModel[]));
    }

    searchBatches(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<MacolaBatchModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new MacolaBatchModel(x));
                }
                return res;
            }));
    }

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<ExpenseReportModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new ExpenseReportModel(x));
                }
                return res;
            }));
    }

    create(model: ExpenseReportModel) {
        return this.http.post<ExpenseReportModel>(this.url + '/create', JSON.stringify(model))
            .pipe(
                map(res => new ExpenseReportModel(res))
            );
    }

    update(model: ExpenseReportModel) {
        return this.http.put<ExpenseReportModel>(this.url + '/update', JSON.stringify(model))
            .pipe(
                map(res => new ExpenseReportModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(this.url + `/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    createItem(itemModel: ExpenseReportItemModel) {
        return this.http.post<ExpenseReportItemModel>(this.url + '/createitem', JSON.stringify(itemModel))
            .pipe(
                map(res => new ExpenseReportItemModel(res))
            );
    }

    updateItem(itemModel: ExpenseReportItemModel) {
        return this.http.put<ExpenseReportItemModel>(this.url + '/updateitem', JSON.stringify(itemModel))
            .pipe(
                map(res => new ExpenseReportItemModel(res))
            );
    }

    deleteItem(itemPk: number) {
        return this.http.delete<Boolean>(this.url + `/deleteItem?itemPk=${itemPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    printBatch(batchDate: string, batchId: string, ersInBatch: string) {
        return this.http.get<ReportModel>(this.url + `/printbatch?batchDate=${batchDate}&batchId=${batchId}&ersInBatch=${ersInBatch}`)
            .pipe(
                map(res => new ReportModel(res))
            );
    }

    print(modelPk: number) {
        return this.http.get<ReportModel>(this.url + `/print?modelPk=${modelPk}`)
            .pipe(
                map(res => new ReportModel(res))
            );
    }

    email(modelPk: number, recipient: string) {
        return this.http.post<Boolean>(this.url + `/email?modelPk=${modelPk}&recipient=${recipient}`, {}, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    process(batchModel: MacolaBatchModel) {
        return this.http.post<string>(this.url + '/process', JSON.stringify(batchModel));
    }

    getCategories() {
        const key = 'Categories';

        if (this.data[key]) {
            return of(this.data[key] as MacolaCategoryModel[]);
        }

        return this.http.get<MacolaCategoryModel[]>(this.url + '/getcategories')
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new MacolaCategoryModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as MacolaCategoryModel[];
            }));
    }

    processPreview() {
        return this.http.post(`${this.url}/process-preview`, {}, { responseType: 'arraybuffer' }).pipe(map(res => res));
    }

    getAssigned() {
        return this.http.get<ExpenseReportItemModel[]>(this.url + `/get-assigned`)
            .pipe(map(res => res ? res.map(x => new ExpenseReportModel(x)) : new Array() as ExpenseReportModel[]));
    }

    assignTo(modelPk: number, isOverride: boolean = false) {
        return this.http.post(`${this.url}/assign-to?modelPk=${modelPk}&isOverride=${isOverride}`, {}, { responseType: 'text'}).pipe(map(res => res));
    }
}
