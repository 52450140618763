import { environment } from '../../environments/environment';

import { LogLevel, BrowserCacheLocation } from '@azure/msal-browser';

export const Globals = {
    BASE_API_URL: environment.apiAddress,
    API_PORTS: {
        APPLICATION: 44387,
        ASSET: 44308,
        ASSET_ACTIVITY: 44309,
        COMMON: 44314,
        EXPENSE: 44307,
        FLUID_REQUEST: 44312,
        LABOR: 44302,
        LEGACY: 44301,
        PURCHASE_ORDER: 44324,
        RUNTIME: 44303,
        SALES: 44377,
        SAFETY: 44344,
        SERVICE: 44304,
        SUPPLY_CHAIN: 44306,
        OIL: 44383,
        OPERATOR: 44305,
        WORKFLOW: 44311
    },
    MSAL_CONFIG: {
        auth: {
            clientId: '86bf29e7-7cfe-41a0-9166-c3263acc5c27',
            authority: 'https://login.microsoftonline.com/c34fa794-0556-4b35-bd37-366dc37de517',
            redirectUri: '/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false
        },
        system: {
            loggerOptions: {
                loggerCallback(logLevel: LogLevel, message: string) {
                    console.log(message);
                },
                logLevel: LogLevel.Warning,
                piiLoggingEnabled: false
            }
        }
    },
    MSAL_PROTECTED_RESOURCES: {
        api: ["api://8d62da3b-5c3b-4308-9aff-2172dfc941da/Access.As.User"]
    }
};
