import * as moment from 'moment';

export class ProducerPriceIndexModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Year: number;
    January: number;
    Febuary: number;
    March: number;
    April: number;
    May: number;
    June: number;
    July: number;
    August: number;
    September: number;
    October: number;
    November: number;
    December: number;
    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        this.Pk = obj.Pk;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.Year = obj.Year;
        this.January = obj.January;
        this.Febuary = obj.Febuary;
        this.March = obj.March;
        this.April = obj.April;
        this.May = obj.May;
        this.June = obj.June;
        this.July = obj.July;
        this.August = obj.August;
        this.September = obj.September;
        this.October = obj.October;
        this.November = obj.November;
        this.December = obj.December;
    }
}
