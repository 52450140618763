import { WorkflowModuleModel } from '../../shared/models/workflow.model';

import * as moment from 'moment';

export class OilSampleModel {
    Pk: number = 0;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Date: Date;
    Status: string = 'New';
    Units: OilSampleUnitModel[] = new Array();
    CanApprove: boolean;
    OperatorId: string;
    OperatorName: string;
    WorkflowId: string = 'OS';
    Workflows: WorkflowModuleModel[] = new Array();

    constructor(obj?: any) {
        if (obj != null) {
            this.Pk = obj.Pk;
            this.CreatedBy = obj.CreatedBy;
            this.CreationDate = moment(obj.CreationDate).toDate();
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
            this.LastUpdateBy = obj.LastUpdateBy;

            if (obj.Date) {
                this.Date = moment(obj.Date).startOf('day').toDate();
            }

            this.Status = obj.Status;
            this.Units = this.buildOilSampleUnits(obj.Units);
            this.CanApprove = obj.CanApprove;
            this.WorkflowId = obj.WorkflowId;
            this.OperatorId = obj.OperatorId;
            this.OperatorName = obj.OperatorName;

            if (obj.Workflows) {
                for (var i of obj.Workflows) {
                    this.Workflows.push(new WorkflowModuleModel(i));
                }
            }
        }
    }

    private buildOilSampleUnits(units: any[]) {
        if (units == null)
            return new Array();

        let unitList: OilSampleUnitModel[] = new Array();
        for (var i = 0; i < units.length; i++) {
            unitList.push(new OilSampleUnitModel(units[i]));
        }

        return unitList;
    }
}

export class OilSampleUnitModel {
    Pk: number = 0;
    OilSamplePk: number;
    CreatedBy: number;
    CreatedId: string;
    CreatedName: string;
    CreationDate: Date;
    AssetId: string;
    AssetName: string;
    AreaId: string;
    WOID: string;
    Customer: string;
    Location: string;
    ShowUnitLabel: boolean;
    OilSample: OilSampleModel = new OilSampleModel();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.OilSamplePk = obj.OilSamplePk;
        this.CreatedBy = obj.CreatedBy;
        this.CreatedId = obj.CreatedId;
        this.CreatedName = obj.CreatedName;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.AreaId = obj.AreaId;
        this.WOID = obj.WOID;
        this.Customer = obj.Customer;
        this.Location = obj.Location;
        this.ShowUnitLabel = this.AssetId != '';
        this.OilSample = new OilSampleModel(obj.OilSample);
    }
}
