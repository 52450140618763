<ol class="breadcrumb" *ngIf="!preview">
    <li class="breadcrumb-item d-sm-down-none">
        <button class="btn btn-link" (click)="onUpdate()" [disabled]="canSave()">
            <i class="fas fa-save"></i> <span class="ml-2">Save</span>
        </button>
    </li>
    <li class="breadcrumb-item d-sm-down-none">
        <button class="btn btn-link" (click)="onBack()">
            <i class="fas fa-undo-alt"></i> <span class="ml-2">Back</span>
        </button>
    </li>
    <li class="breadcrumb-menu" *ngIf="access.delete || access.submit || access.rollback || (model.Workflows && model.Workflows.length)">
        <div class="btn-group" dropdown placement="bottom right">
            <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
                <i class="fas fa-bullseye"></i> <span class="ml-2 mr-1">Actions</span> <span class="caret"></span>
            </button>

            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                <li role="menuitem">
                    <a class="dropdown-item" (click)="onSubmit()" *ngIf="access.submit" href="javascript:;">
                        <i class="fas fa-check-circle"></i> Submit
                    </a>
                    <a class="dropdown-item" (click)="onDelete()" *ngIf="access.delete" href="javascript:;">
                        <i class="fas fa-times-circle"></i> Delete
                    </a>
                    <a class="dropdown-item" [class.disabled]="!access.print" (click)="access.print && onPrint()" href="javascript:;">
                        <i class="fas fa-print"></i> Print
                    </a>

                    <workflow [model]="model" [showRollbackButton]="access.rollback" (onConfirm)="onLoad($event)"></workflow>
                </li>
            </ul>
        </div>
    </li>
</ol>

<div class="container-fluid" [class.preview-mode]="preview" [class.preview-mode-scroll]="preview">
    <div class="row" id="asset-activity-report-detail">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fas fa-list"></i>
                    <strong>Asset Activity Report #{{model.Pk}}</strong>
                </div>

                <div class="card-body">
                    <asset-activity-dataflow #dataflow mode="tab" [model]="model" (onSave)="onConfirmUpdate()"></asset-activity-dataflow>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="app-footer d-md-none d-lg-none">
    <div>
        <button class="btn btn-success" (click)="onUpdate()" [disabled]="canSave()">
            <i class="fas fa-save"></i> <span class="ml-2">Save</span>
        </button>

        <button class="btn btn-secondary float-right" (click)="onBack()">
            <i class="fas fa-undo-alt"></i> <span class="ml-2">Back</span>
        </button>
    </div>
</footer>
