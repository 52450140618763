export class LubeValueFieldsModel {
    Pk: number = 0;
    OperatorReportPk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    OilPressureOperating: number;
    OilPressureKills: number;
    OilTempOperating: number;
    OilTempKills: number;
    FilterDiffOperating: number;
    FilterDiffKills: number;
    LubeRateRBOperating: number;
    LubeRateRBKills: number;
    LubeRateLBOperating: number;
    LubeRateLBKills: number;
    ActualOperating: number;
    ActualKills: number;
    DesireOperating: number;
    DesireKills: number;
    MainBearing1TempOperating: number;
    MainBearing1TempKills: number;
    MainBearing2TempOperating: number;
    MainBearing2TempKills: number;
    MainBearing3TempOperating: number;
    MainBearing3TempKills: number;
    MainBearing4TempOperating: number;
    MainBearing4TempKills: number;
    MainBearing5TempOperating: number;
    MainBearing5TempKills: number;
    MainBearing6TempOperating: number;
    MainBearing6TempKills: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.OperatorReportPk = obj.OperatorReportPk;
        this.CreationDate = obj.CreationDate;
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = obj.LastUpdateDate;
        this.LastUpdateBy = obj.LastUpdateBy;
        this.OilPressureOperating = this.numberToUndefined(obj.OilPressureOperating);
        this.OilPressureKills = this.numberToUndefined(obj.OilPressureKills);
        this.OilTempOperating = this.numberToUndefined(obj.OilTempOperating);
        this.OilTempKills = this.numberToUndefined(obj.OilTempKills);
        this.FilterDiffOperating = this.numberToUndefined(obj.FilterDiffOperating);
        this.FilterDiffKills = this.numberToUndefined(obj.FilterDiffKills);
        this.LubeRateRBOperating = this.numberToUndefined(obj.LubeRateRBOperating);
        this.LubeRateRBKills = this.numberToUndefined(obj.LubeRateRBKills);
        this.LubeRateLBOperating = this.numberToUndefined(obj.LubeRateLBOperating);
        this.LubeRateLBKills = this.numberToUndefined(obj.LubeRateLBKills);
        this.ActualOperating = this.numberToUndefined(obj.ActualOperating);
        this.ActualKills = this.numberToUndefined(obj.ActualKills);
        this.DesireOperating = this.numberToUndefined(obj.DesireOperating);
        this.DesireKills = this.numberToUndefined(obj.DesireKills);
        this.MainBearing1TempOperating = this.numberToUndefined(obj.MainBearing1TempOperating);
        this.MainBearing1TempKills = this.numberToUndefined(obj.MainBearing1TempKills);
        this.MainBearing2TempOperating = this.numberToUndefined(obj.MainBearing2TempOperating);
        this.MainBearing2TempKills = this.numberToUndefined(obj.MainBearing2TempKills);
        this.MainBearing3TempOperating = this.numberToUndefined(obj.MainBearing3TempOperating);
        this.MainBearing3TempKills = this.numberToUndefined(obj.MainBearing3TempKills);
        this.MainBearing4TempOperating = this.numberToUndefined(obj.MainBearing4TempOperating);
        this.MainBearing4TempKills = this.numberToUndefined(obj.MainBearing4TempKills);
        this.MainBearing5TempOperating = this.numberToUndefined(obj.MainBearing5TempOperating);
        this.MainBearing5TempKills = this.numberToUndefined(obj.MainBearing5TempKills);
        this.MainBearing6TempOperating = this.numberToUndefined(obj.MainBearing6TempOperating);
        this.MainBearing6TempKills = this.numberToUndefined(obj.MainBearing6TempKills);
    }

    differentToObject(objectToCompare: LubeValueFieldsModel) {
        var object1ToCompare: LubeValueFieldsModel = new LubeValueFieldsModel(this);
        var object2ToCompare: LubeValueFieldsModel = new LubeValueFieldsModel(objectToCompare);

        if (object1ToCompare.OilPressureOperating != object2ToCompare.OilPressureOperating ||
            object1ToCompare.OilPressureKills != object2ToCompare.OilPressureKills ||
            object1ToCompare.OilTempOperating != object2ToCompare.OilTempOperating ||
            object1ToCompare.OilTempKills != object2ToCompare.OilTempKills ||
            object1ToCompare.FilterDiffOperating != object2ToCompare.FilterDiffOperating ||
            object1ToCompare.FilterDiffKills != object2ToCompare.FilterDiffKills ||
            object1ToCompare.LubeRateRBOperating != object2ToCompare.LubeRateRBOperating ||
            object1ToCompare.LubeRateRBKills != object2ToCompare.LubeRateRBKills ||
            object1ToCompare.LubeRateLBOperating != object2ToCompare.LubeRateLBOperating ||
            object1ToCompare.LubeRateLBKills != object2ToCompare.LubeRateLBKills ||
            object1ToCompare.ActualOperating != object2ToCompare.ActualOperating ||
            object1ToCompare.ActualKills != object2ToCompare.ActualKills ||
            object1ToCompare.DesireOperating != object2ToCompare.DesireOperating ||
            object1ToCompare.DesireKills != object2ToCompare.DesireKills ||
            object1ToCompare.MainBearing1TempOperating != object2ToCompare.MainBearing1TempOperating ||
            object1ToCompare.MainBearing1TempKills != object2ToCompare.MainBearing1TempKills ||
            object1ToCompare.MainBearing2TempOperating != object2ToCompare.MainBearing2TempOperating ||
            object1ToCompare.MainBearing2TempKills != object2ToCompare.MainBearing2TempKills ||
            object1ToCompare.MainBearing3TempOperating != object2ToCompare.MainBearing3TempOperating ||
            object1ToCompare.MainBearing3TempKills != object2ToCompare.MainBearing3TempKills ||
            object1ToCompare.MainBearing4TempOperating != object2ToCompare.MainBearing4TempOperating ||
            object1ToCompare.MainBearing4TempKills != object2ToCompare.MainBearing4TempKills ||
            object1ToCompare.MainBearing5TempOperating != object2ToCompare.MainBearing5TempOperating ||
            object1ToCompare.MainBearing5TempKills != object2ToCompare.MainBearing5TempKills ||
            object1ToCompare.MainBearing6TempOperating != object2ToCompare.MainBearing6TempOperating ||
            object1ToCompare.MainBearing6TempKills != object2ToCompare.MainBearing6TempKills) {
            return true;
        }

        return false;
    }

    private numberToUndefined(value: number) {
        return (value > 0 ? value : undefined);
    }
}
