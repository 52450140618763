<div class="row" id="fluids-request-shutdown-tank-detail">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <i class="fas fa-calculator"></i>
                <strong>Fluids Request #{{model.Pk}}</strong>
            </div>

            <div class="card-body">
                <form #fluidRequestNewTankForm="ngForm" novalidate autocomplete="off">
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label>Status:</label>
                            <div>{{model.Status | titlecase}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label>Type:</label>
                            <div>{{model.Type?.Name}}</div>
                        </div>

                        <div class="form-group col-sm-6 col-md-4" *ngIf="model.Workflows.length">
                            <label>Submitted on:</label>
                            <div>{{model.Workflows[0].CreationDate | date: 'MM/dd/yyyy h:mm a'}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label>Unit:</label>
                            <div>{{model.AssetId}}</div>
                        </div>

                        <div class="form-group col-sm-6 col-md-4">
                            <label>Area:</label>
                            <div>{{model.AreaId}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-12">
                            <label for="IsUrgent">
                                Is this an urgent request?
                            </label>
                            <div>
                                <label class="switch switch-text switch-success switch-lg">
                                    <input [(ngModel)]="model.IsUrgent" name="IsUrgent" id="IsUrgent" class="switch-input" type="checkbox" [disabled]="!access.save">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label for="VendorId">Vendor:</label>
                            <select #vendorRef="ngModel" name="VendorId" id="VendorId" class="form-control" [(ngModel)]="model.VendorId" required [disabled]="!access.save" (ngModelChange)="onVendorUpdate()">
                                <option *ngIf="!model.VendorId"></option>
                                <option *ngFor="let item of lookupValues['Vendor']" [value]="item.Id">{{item.Name}}</option>
                            </select>

                            <validation-message [isValid]="vendorRef.invalid && (vendorRef.dirty || vendorRef.touched)">
                                Please select a Vendor
                            </validation-message>
                        </div>

                        <div class="form-group col-sm-6 col-md-4">
                            <label for="DeliveryDate">Date Needed:</label>
                            <p-calendar #deliveryDateRef="ngModel" name="DeliveryDate" id="DeliveryDate" [(ngModel)]="model.DeliveryDate" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true" [disabled]="!access.save" showTime="true" hourFormat="12" [hideOnDateTimeSelect]="false" [stepMinute]="5">
                                <p-footer>
                                    <div class="p-datepicker-buttonbar text-right">
                                        <button class="btn btn-secondary" (click)="common.closeCalendar(deliveryDateRef)">
                                            <i class="fas fa-times-circle"></i> <span class="ml-2">Close</span>
                                        </button>
                                    </div>
                                </p-footer>
                            </p-calendar>

                            <validation-message [isValid]="deliveryDateRef.invalid && (deliveryDateRef.dirty || deliveryDateRef.touched)">
                                Please enter the Date Needed
                            </validation-message>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label for="Product">Product:</label>
                            <select #productRef="ngModel" [(ngModel)]="model.Items[0].Types[0].ProductId" name="Product" id="Product" class="form-control" required [disabled]="!access.save" (ngModelChange)="onProductUpdate(model.Items[0].Types[0])">
                                <option *ngIf="!model.Items[0].Types[0].ProductId"></option>
                                <option *ngFor="let item of lookupValues['OilProducts']" [value]="item.PartId">{{item.PartName}}</option>
                            </select>

                            <validation-message [isValid]="productRef.invalid && (productRef.dirty || productRef.touched)">
                                Please select a Product
                            </validation-message>
                        </div>

                        <div class="form-group col-sm-6 col-md-4">
                            <label for="Quantity'">Order Quantity:</label>

                            <div class="input-group">
                                <input #quantityRef="ngModel" [(ngModel)]="model.Items[0].Types[0].Quantity" type="number" name="Quantity" id="Quantity" class="form-control" required numbersOnly [allowDecimals]="false" [disabled]="!access.save">
                                <div class="input-group-append">
                                    <span class="input-group-text">{{getUnitOfMeasurement(model.Items[0].Types[0].ProductId)}}</span>
                                </div>
                            </div>

                            <validation-message [isValid]="quantityRef.invalid && (quantityRef.dirty || quantityRef.touched)">
                                Please specify the Order Quantity
                            </validation-message>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-12 col-md-8">
                            <label for="Directions">Directions:</label>
                            <textarea #directionsRef="ngModel" rows="3" [(ngModel)]="model.Directions" name="Directions" id="Directions" class="form-control" [disabled]="!access.save"></textarea>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-12 col-md-8">
                            <label for="Comments">Comments:</label>
                            <textarea #commentsRef="ngModel" rows="3" [(ngModel)]="model.Comments" name="Comments" id="Comments" class="form-control" required [disabled]="!access.save"></textarea>

                            <validation-message [isValid]="commentsRef.invalid && (commentsRef.dirty || commentsRef.touched)">
                                Please enter Comments
                            </validation-message>
                        </div>
                    </div>

                    <ng-container *ngIf="model.Status == 'PENDING' && (access.process || access.admin)">
                        <hr>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label for="InvoiceNumber">Invoice Number:</label>
                                <input #invoiceNumberRef="ngModel" [(ngModel)]="model.Items[0].Types[0].InvoiceNumber" type="text" name="InvoiceNumber" id="InvoiceNumber" class="form-control" required>

                                <validation-message [isValid]="invoiceNumberRef.invalid && (invoiceNumberRef.dirty || invoiceNumberRef.touched)">
                                    Please enter the Invoice Number
                                </validation-message>
                            </div>

                            <div class="form-group col-sm-6 col-md-4">
                                <label for="InvoiceDate">Invoice Date:</label>
                                <p-calendar #invoiceDateRef="ngModel" name="InvoiceDate" id="InvoiceDate" [(ngModel)]="model.Items[0].Types[0].InvoiceDate" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true">
                                </p-calendar>

                                <validation-message [isValid]="invoiceDateRef.invalid && (invoiceDateRef.dirty || invoiceDateRef.touched)">
                                    Please enter the Invoice Date
                                </validation-message>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label for="InvoiceQuantity">Invoice Quantity:</label>
                                <input #invoiceQuantityRef="ngModel" [(ngModel)]="model.Items[0].Types[0].InvoiceQuantity" type="number" name="InvoiceQuantity" id="InvoiceQuantity" class="form-control" required numbersOnly [allowDecimals]="false">

                                <validation-message [isValid]="invoiceQuantityRef.invalid && (invoiceQuantityRef.dirty || invoiceQuantityRef.touched)">
                                    Please enter the Invoice Quantity
                                </validation-message>
                            </div>

                            <div class="form-group col-sm-6 col-md-4">
                                <label for="InvoicePrice">Invoice Price:</label>
                                <input #invoicePriceRef="ngModel" [(ngModel)]="model.Items[0].Types[0].InvoicePrice" type="text" name="InvoicePrice" id="InvoicePrice" class="form-control" required>

                                <validation-message [isValid]="invoicePriceRef.invalid && (invoicePriceRef.dirty || invoicePriceRef.touched)">
                                    Please enter the Invoice Price
                                </validation-message>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label for="InvoiceAmount">Invoice Amount:</label>
                                <input #invoiceAmountRef="ngModel" [(ngModel)]="model.Items[0].Types[0].InvoiceAmount" type="number" name="InvoiceAmount" id="InvoiceAmount" class="form-control" required numbersOnly>

                                <validation-message [isValid]="invoiceAmountRef.invalid && (invoiceAmountRef.dirty || invoiceAmountRef.touched)">
                                    Please enter the Invoice Amount
                                </validation-message>
                            </div>

                            <div class="form-group col-sm-6 col-md-4">
                                <label for="InvoiceTax">Invoice Tax:</label>
                                <input #invoiceTaxRef="ngModel" [(ngModel)]="model.Items[0].Types[0].InvoiceTax" type="text" name="InvoiceTax" id="InvoiceTax" class="form-control" required>

                                <validation-message [isValid]="invoiceTaxRef.invalid && (invoiceTaxRef.dirty || invoiceTaxRef.touched)">
                                    Please enter the Invoice Tax
                                </validation-message>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label for="InvoiceTotal">Invoice Total:</label>
                                <input #invoiceTotalRef="ngModel" [(ngModel)]="model.Items[0].Types[0].InvoiceTotal" type="number" name="InvoiceTotal" id="InvoiceTotal" class="form-control" required numbersOnly>

                                <validation-message [isValid]="invoiceTotalRef.invalid && (invoiceTotalRef.dirty || invoiceTotalRef.touched)">
                                    Please enter the Invoice Total
                                </validation-message>
                            </div>
                        </div>
                    </ng-container>
                </form>
            </div>
        </div>
    </div>
</div>