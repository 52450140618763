<div class="modal fade" role="dialog">
    <div [ngClass]="['modal-dialog', class]">
        <div class="modal-content">
            <div class="modal-header" #header>
                <h4 class="modal-title">
                    <ng-content select="header"></ng-content>
                </h4>
                <button *ngIf="showHeaderCloseButton" type="button" class="close pull-right" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <ng-content></ng-content>
            </div>

            <div #footer>
                <ng-content select="modal-footer"></ng-content>
            </div>

            <modal-footer *ngIf="!hasFooter">
                <button class="btn btn-secondary" (click)="hide()">
                    <i class="fas fa-times-circle mr-1"></i> Close
                </button>
            </modal-footer>
        </div>
    </div>
</div>

<div class="modal-backdrop fade"></div>
