import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Common } from '../services';

import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private common: Common,
        private router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((res: HttpErrorResponse) => {
            console.log(req, res);

            if (res.status == 401) {
                this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
            }

            let error = "Unknown Server Error";

            if (res.error instanceof ArrayBuffer) {
                const decoded = String.fromCharCode.apply(null, new Uint8Array(res.error));

                let message;

                try {
                    message = JSON.parse(decoded);
                }
                catch {
                    message = decoded;
                }

                error = this.common.parseErrors(message);
            }
            else if (res.error instanceof ProgressEvent) {
                error = this.common.parseErrors(res.message);
            }
            else if (res) {
                if (res.error) {
                    error = this.common.parseErrors(res.error);
                }
                else if (res.message) {
                    error = this.common.parseErrors(res.message);
                }
            }

            return this.common.httpErrorHandler(error);
        }));
    }
}
