import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SalesOrderDetailComponent } from '../components/sales-order/sales-order-detail.component';
import { InvoicingInstructionDetailComponent } from '../components/invoicing-instruction/invoicing-instruction-detail.component';
import { AssetActivityReportDetailComponent } from '../components/asset-activity-report/asset-activity-report-detail.component';
import { FluidsRequestDetailComponent } from '../components/fluids-request/fluids-request-detail.component';
import { FluidsRequestEmergencyFillTankDetailComponent } from '../components/fluids-request/fluids-request-emergency-fill-tank-detail.component';
import { FluidsRequestFillTanksDetailComponent } from '../components/fluids-request/fluids-request-fill-tanks-detail.component';
import { FluidsRequestHistoryComponent } from '../components/fluids-request/fluids-request-history.component';
import { FluidsRequestMoveTankDetailComponent } from '../components/fluids-request/fluids-request-move-tank-detail.component';
import { FluidsRequestNewTankDetailComponent } from '../components/fluids-request/fluids-request-new-tank-detail.component';
import { FluidsRequestOilOnlyDetailComponent } from '../components/fluids-request/fluids-request-oil-only-detail.component';
import { FluidsRequestShutdownTankDetailComponent } from '../components/fluids-request/fluids-request-shutdown-tank-detail.component';
import { FluidsRequestSwapUnitDetailComponent } from '../components/fluids-request/fluids-request-swap-unit-detail.component';

import * as components from './components';
import * as directives from './directives';
import * as pipes from './pipes';

const APP_COMPONENTS = [
    // Manual includes here so that they are accessible to outside
    // modules without moving them into the shared components
    InvoicingInstructionDetailComponent,
    SalesOrderDetailComponent,
    AssetActivityReportDetailComponent,
    FluidsRequestDetailComponent,
    FluidsRequestFillTanksDetailComponent,
    FluidsRequestHistoryComponent,
    FluidsRequestMoveTankDetailComponent,
    FluidsRequestNewTankDetailComponent,
    FluidsRequestShutdownTankDetailComponent,
    FluidsRequestEmergencyFillTankDetailComponent,
    FluidsRequestOilOnlyDetailComponent,
    FluidsRequestSwapUnitDetailComponent,
    components.AssetActivityDataflowComponent,
    components.DataflowComponent,
    components.FluidsRequestDataflowComponent,
    components.HelpLinksComponent,
    components.LoaderComponent,
    components.MassSalesOrderRenewalDataflowComponent,
    components.ModalComponent,
    components.ModalFooterComponent,
    components.PaginationContainerComponent,
    components.SearchContainerComponent,
    components.SortHeaderComponent,
    components.SparkFileComponent,
    components.SparkFileListComponent,
    components.TextPreviewComponent,
    components.ValidationMessageComponent,
    components.WorkflowComponent,
    components.SafetyCorrectiveActionComponent,
    components.SparkFileExpenseReportComponent,
    components.SparkCarouselComponent,
    components.SparkCarouselItemComponent,
    components.WorkflowHistoryComponent
];

const APP_DIRECTIVES = [
    directives.AutoGrowDirective,
    directives.DebounceDirective,
    directives.MaxDirective,
    directives.MinDirective,
    directives.MinimumCategoryAmountDirective,
    directives.MobileSidebarToggleDirective,
    directives.NumbersOnly,
    directives.SidebarToggleDirective,
    directives.SortDirective,
    directives.ValidNumberDirective,
    directives.StatusColorDirective,
    directives.PhoneNumberMask
];

const APP_PIPES = [
    pipes.FilterPipe,
    pipes.SplitTitleCase
];

import { CalendarModule } from 'primeng/calendar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SortableModule } from 'ngx-bootstrap/sortable';

import { ToastrModule } from 'ngx-toastr';

import { MsalModule } from '@azure/msal-angular';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CalendarModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToastrModule.forRoot({
            extendedTimeOut: 0,
            tapToDismiss: true,
            timeOut: 3000
        }),
        SortableModule.forRoot(),
        MsalModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        CalendarModule,
        BsDropdownModule,
        ModalModule,
        ProgressbarModule,
        TabsModule,
        TooltipModule,
        TypeaheadModule,
        ToastrModule,
        SortableModule,
        ...APP_COMPONENTS,
        ...APP_DIRECTIVES,
        ...APP_PIPES
    ],
    declarations: [
        ...APP_COMPONENTS,
        ...APP_DIRECTIVES,
        ...APP_PIPES
    ]
})
export class SharedModule {}
