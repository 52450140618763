import { ApplicationRoleModel, ApplicationUserModel } from './application.model';
import { SparkModuleTypeModel } from './spark-module-type.model';

import * as moment from 'moment';

export class WorkflowModel {
    Pk: number;
    Name: string;
    Id: string;
    Active: boolean;
    IsDeniedSilent: boolean;
    IsResubmittable: boolean;
    UseQueue: boolean;
    SparkModuleTypePk: number;
    CreationDate: Date;
    CreatedBy: string;
    LastUpdateDate: Date;
    LastUpdateBy: string;
    WorkflowDetails: WorkflowDetailModel[] = new Array();
    SparkModuleType: SparkModuleTypeModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Name = obj.Name;
        this.Id = obj.Id;
        this.Active = obj.Active;
        this.IsDeniedSilent = obj.IsDeniedSilent;
        this.IsResubmittable = obj.IsResubmittable;
        this.UseQueue = obj.UseQueue;
        this.SparkModuleTypePk = obj.SparkModuleTypePk;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;

        if (obj.WorkflowDetails) {
            this.WorkflowDetails = obj.WorkflowDetails.map(x => new WorkflowDetailModel(x));
        }

        if (obj.SparkModuleType) {
            this.SparkModuleType = new SparkModuleTypeModel(obj.SparkModuleType);
        }
    }
}

export class WorkflowDetailModel {
    Pk: number;
    WorkflowPk: number;
    WorkflowDetailTypePk: number;
    Recipient: string;
    ApplicationRolePk: number;
    Step: number;
    GoBackStep: number;
    RollbackStep: boolean;
    Type: WorkflowDetailTypeModel = new WorkflowDetailTypeModel();
    Silent: boolean;
    SkipOnApproval: boolean;
    ApplicationRole: ApplicationRoleModel = new ApplicationRoleModel();
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.WorkflowPk = obj.WorkflowPk;
        this.WorkflowDetailTypePk = obj.WorkflowDetailTypePk;
        this.Recipient = obj.Recipient;
        this.ApplicationRolePk = obj.ApplicationRolePk;
        this.Step = obj.Step;
        this.GoBackStep = obj.GoBackStep;
        this.RollbackStep = obj.RollbackStep;
        this.Type = new WorkflowDetailTypeModel(obj.Type);
        this.Silent = obj.Silent;
        this.SkipOnApproval = obj.SkipOnApproval;
        this.ApplicationRole = new ApplicationRoleModel(obj.ApplicationRole);
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
    }
}

export class WorkflowModuleModel {
    Pk: number;
    ModulePk: number;
    Status: string;
    Active: boolean;
    CreationDate: Date;
    CreatedBy: string;
    CreatedById: string;
    LastUpdateDate: Date;
    LastUpdateBy: string;
    LastUpdateById: string;
    Workflow: WorkflowModel = new WorkflowModel();
    Details: WorkflowModuleDetailModel[] = new Array();
    Creator: ApplicationUserModel;
    Updater: ApplicationUserModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ModulePk = obj.ModulePk;
        this.Status = obj.Status;
        this.Active = obj.Active;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.CreatedById = obj.CreatedById;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.LastUpdateById = obj.LastUpdateById;
        this.Workflow = new WorkflowModel(obj.Workflow);

        if (obj.Details) {
            this.Details = obj.Details.map(x => new WorkflowModuleDetailModel(x));
        }

        if (obj.Creator) {
            this.Creator = new ApplicationUserModel(obj.Creator);
        }

        if (obj.Updater) {
            this.Updater = new ApplicationUserModel(obj.Updater);
        }
    }
}

export class WorkflowModuleDetailModel {
    Pk: number;
    WorkflowModulePk: number;
    WorkflowDetailPk: number;
    Comments: string = '';
    UsersNotified: string;
    Status: string;
    CreationDate: Date;
    CreatedBy: string;
    CreatedById: string;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    LastUpdateById: string;
    WorkflowDetail: WorkflowDetailModel = new WorkflowDetailModel();
    Creator: ApplicationUserModel;
    Updater: ApplicationUserModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.WorkflowModulePk = obj.WorkflowModulePk;
        this.WorkflowDetailPk = obj.WorkflowDetailPk;
        this.Comments = obj.Comments;
        this.UsersNotified = obj.UsersNotified;
        this.Status = obj.Status;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.CreatedById = obj.CreatedById;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.LastUpdateById = obj.LastUpdateById;
        this.WorkflowDetail = new WorkflowDetailModel(obj.WorkflowDetail);

        if (obj.Creator) {
            this.Creator = new ApplicationUserModel(obj.Creator);
        }

        if (obj.Updater) {
            this.Updater = new ApplicationUserModel(obj.Updater);
        }
    }

    getStatus() {
        return this.Status ? this.Status.toLowerCase() : '';
    }
}

export class WorkflowDetailTypeModel {
    Pk: number;
    ButtonText: string = 'Undefined';
    PendingText: string;
    FinalText: string;
    CreationDate: Date;
    CreatedBy: string;
    LastUpdateDate: Date;
    LastUpdateBy: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ButtonText = obj.ButtonText;
        this.PendingText = obj.PendingText;
        this.FinalText = obj.FinalText;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
    }
}
