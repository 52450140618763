import { Injectable } from '@angular/core';
import { UserModel } from '../../shared/models';

@Injectable({
    providedIn: 'root'
})
export class AppGlobalService {
    public kodiakToken: string = "";
    public sparkUser: UserModel = new UserModel();
    public userObject: string = null;
}
