import { SparkFileModel } from './spark-file.model';
import { WorkflowModuleModel } from './workflow.model';

import * as moment from 'moment';

export class AssetActivityReportModel {
    Pk: number;
    Type: string;
    Status: string = 'New';
    AssetId: string;
    AssetName: string;
    AreaId: string;
    State: string;
    County: string;
    EngineSerialNumber: string;
    ActivityDate: Date;
    Notes: string;
    CustomerId: string;
    CustomerName: string;
    FromLocationId: string;
    FromLocationName: string;
    ToLocationId: string;
    ToLocationName: string;
    HasNewOperator: boolean;
    OperatorId: string;
    OperatorName: string;
    WarrantySubmitted: boolean;
    WarrantyRecipientId: string;
    WarrantyRecipientName: string;
    PartsNearLocation: boolean;
    NearestConexId: string;
    NearestConexName: string;
    StartedSameDay: boolean;
    AreaManagerId: string;
    AreaManagerName: string;
    Horsepower: number;
    CompressorSerialNumber: string;
    CoolerSerialNumber: string;
    HasSetCrew: boolean;
    IsReadyForNextJob: boolean;
    WorkNeededNotes: boolean;
    WOID: string;
    Files: SparkFileModel[] = new Array();
    CanApprove: boolean;
    WorkflowId: string = 'AAR_';
    Workflows: WorkflowModuleModel[] = new Array();
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Type = obj.Type;
        this.Status = obj.Status;
        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.AreaId = obj.AreaId;
        this.State = obj.State;
        this.County = obj.County;
        this.EngineSerialNumber = obj.EngineSerialNumber;
        this.ActivityDate = moment(obj.ActivityDate).toDate();
        this.Notes = obj.Notes;
        this.CustomerId = obj.CustomerId;
        this.CustomerName = obj.CustomerName;
        this.FromLocationId = obj.FromLocationId;
        this.FromLocationName = obj.FromLocationName;
        this.ToLocationId = obj.ToLocationId;
        this.ToLocationName = obj.ToLocationName;
        this.HasNewOperator = obj.HasNewOperator;
        this.OperatorId = obj.OperatorId;
        this.OperatorName = obj.OperatorName;
        this.WarrantySubmitted = obj.WarrantySubmitted;
        this.WarrantyRecipientId = obj.WarrantyRecipientId;
        this.WarrantyRecipientName = obj.WarrantyRecipientName;
        this.PartsNearLocation = obj.PartsNearLocation;
        this.NearestConexId = obj.NearestConexId;
        this.NearestConexName = obj.NearestConexName;
        this.StartedSameDay = obj.StartedSameDay;
        this.AreaManagerId = obj.AreaManagerId;
        this.AreaManagerName = obj.AreaManagerName;
        this.Horsepower = obj.Horsepower;
        this.CompressorSerialNumber = obj.CompressorSerialNumber;
        this.CoolerSerialNumber = obj.CoolerSerialNumber;
        this.HasSetCrew = obj.HasSetCrew;
        this.IsReadyForNextJob = obj.IsReadyForNextJob;
        this.WorkNeededNotes = obj.WorkNeededNotes;
        this.WOID = obj.WOID;
        this.CanApprove = obj.CanApprove;

        if (obj.Type) {
            this.WorkflowId += obj.Type;
        }

        if (obj.Files) {
            this.Files = obj.Files.map(x => new SparkFileModel(x));
        }

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }

    getType() {
        // Unit Standby types were removed, and Unit Idle was 'renamed' to Unit Standby
        // but the internal code was not changed for unknown reasons
        return this.Type.replace('Idle', 'Standby');
    }
}
