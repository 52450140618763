export class LookupModel {
    Pk: number;
    Name: string;
    Description: string;
    NameAndDescription: string;
    Id: string;
    Type: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Name = (obj.Name || '').trim();
        this.Description = (obj.Description || '').trim();
        this.NameAndDescription = obj.NameAndDescription ? obj.NameAndDescription.trim() : this.Description + " (" + this.Name + ")";
        this.Id = obj.Id;
        this.Type = obj.Type;
    }
}

export class LookupType {
    Pk: number;
    Name: string;
    Description: string;
}

export enum StateData {
    County = 0,
    City
};