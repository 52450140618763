export class CylinderValueModel {
    Key: number;
    Pk: number = 0;
    OperatorReportPk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    CylinderNum: number;
    CylinderTemps: string;
    SparkVoltage: string;
    BurnTimes: string;
    ShowDelete: boolean = false;

    constructor(obj: any, key: number) {
        this.Key = key;

        if (obj != null) {
            this.Pk = obj.Pk;
            this.OperatorReportPk = obj.OperatorReportPk;
            this.CreationDate = obj.CreationDate;
            this.CreatedBy = obj.CreatedBy;
            this.LastUpdateDate = obj.LastUpdateDate;
            this.LastUpdateBy = obj.LastUpdateBy;
            this.CylinderNum = obj.CylinderNum;
            this.CylinderTemps = this.numberToEmpty(obj.CylinderTemps);
            this.SparkVoltage = this.numberToEmpty(obj.SparkVoltage);
            this.BurnTimes = this.numberToEmpty(obj.BurnTimes);
            this.ShowDelete = obj.ShowDelete != undefined ? obj.ShowDelete : false;
        }
    }

    differentToObject(objectToCompare: CylinderValueModel) {
        if (this.CylinderNum != objectToCompare.CylinderNum ||
            this.CylinderTemps != objectToCompare.CylinderTemps ||
            this.SparkVoltage != objectToCompare.SparkVoltage ||
            this.BurnTimes != objectToCompare.BurnTimes) {
            return true;
        }

        return false;
    }

    private numberToEmpty(value: number) {
        return (value > 0 ? value.toString() : '');
    }
}
