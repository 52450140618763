import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { SparkFileModel } from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class SparkFileService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/spark-file`;

    constructor(
        private common: Common,
        private http: HttpClient
    ) {}

    get(modelPk: number) {
        return this.http.get<SparkFileModel>(`${this.url}/get?modelPk=${modelPk}`)
            .pipe(
                map(res => new SparkFileModel(res))
            );
    }

    getByModule(modulePk: number, moduleType: string) {
        return this.http.get<SparkFileModel[]>(`${this.url}/search?modulePk=${modulePk}&moduleType=${moduleType}`)
            .pipe(
                map(res => res ? res.map(x => new SparkFileModel(x)) : new Array())
            );
    }

    // todo: need module info?
    create(model: File, moduleTypePk: number, modulePk: number = 0, documentTypeName: string = '') {
        const formData = new FormData();
        formData.append('file', model, model.name);

        return this.http.post<any>(`${this.url}/create?modulePk=${modulePk}&moduleTypePk=${moduleTypePk}&documentTypeName=${documentTypeName}`, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    delete(modelPk: number, fileName: string) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}&fileName=${fileName}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    download(modelPk: number) {
        return this.http.post(`${this.url}/download?modelPk=${modelPk}`, {}, { responseType: 'arraybuffer' }).pipe(map(res => res));
    }
}