import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { TimeCardPeriod } from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class TimeCardPeriodService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.LABOR}/api/timecardperiod`;

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number): Observable<TimeCardPeriod> {
        return this.http.get<TimeCardPeriod>(this.url + `?modelPk=${modelPk}`);
    }

    search(limit?: number): Observable<TimeCardPeriod[]> {
        return this.http.get<TimeCardPeriod[]>(this.url + `/search?limit=${limit ? limit : 0}`)
            .pipe(
                map(res => res ? res.map(x => new TimeCardPeriod(x)) : new Array())
            );
    }

    create(model: TimeCardPeriod): Observable<TimeCardPeriod> {
        return this.http.post<TimeCardPeriod>(this.url + '/create', JSON.stringify(model))
            .pipe(
                map(res => new TimeCardPeriod(res))
            );
    }

    update(model: TimeCardPeriod): Observable<TimeCardPeriod> {
        return this.http.put<TimeCardPeriod>(this.url + '/update', JSON.stringify(model))
            .pipe(
                map(res => new TimeCardPeriod(res))
            );
    }
}