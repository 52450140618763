import { WorkflowModuleModel } from './workflow.model';

import * as moment from 'moment';

export class GeneralFieldServiceModel {
    Pk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    OperatorId: string;
    OperatorName: string;
    Date: Date;
    Area: string;
    TypePk: number;
    TypeId: string;
    TypeName: string;
    Hours: number;
    Description: string;
    Comments: string;
    WoId: number;
    Status: string = 'New';
    CanApprove: boolean;
    WorkflowId: string = 'GFS';
    Workflows: WorkflowModuleModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
        this.OperatorId = obj.OperatorId;
        this.OperatorName = obj.OperatorName;

        if (obj.Date) {
            this.Date = moment(obj.Date).startOf('day').toDate();
        }

        this.Area = obj.Area;
        this.TypePk = obj.TypePk;
        this.TypeId = obj.TypeId;
        this.TypeName = obj.TypeName;
        this.Hours = obj.Hours;
        this.Description = obj.Description;
        this.Comments = obj.Comments;
        this.WoId = obj.WoId;
        this.Status = obj.Status ? obj.Status : obj.WOID ? 'Submitted' : 'New';
        this.CanApprove = obj.CanApprove;
        this.WorkflowId = obj.WorkflowId;

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }
    }

    getAllComments() {
        if (this.Comments) {
            return `${this.Description}\n${this.Comments}`;
        }

        return this.Description;
    }
}
