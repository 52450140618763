import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Common } from '../../core/services';

@Injectable({
    providedIn: 'root',
})
export class AccessGuard implements CanActivate {
    constructor(
        public common: Common,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (next.data && next.data.permissions) {
            const { permissions } = next.data;

            for (let permission of permissions) {
                const application = permission.application || 'OPS';

                if (!this.common.hasAccess(application, permission.module, permission.action)) {
                    this.common.hideLoader();
                    this.common.showError('Error', `User does not have access to ${permission.message}.`);

                    // When navigating directly to a page that the user does not have access to,
                    // the result is a blank page because the component isn't loaded. Redirect to avoid this
                    if (this.router.routerState.snapshot.url === '') {
                        this.router.navigate(['/'])
                    }

                    return false;
                }
            }
        }

        return true;
    }
}
