import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    PartModel,
    ProcedureLibraryModel,
    SearchCriteriaModel,
    SearchResultModel,
    ServiceReportModel,
    ServiceReportLaborModel,
    ServiceReportPartWarrantyModel,
    PreventativeMaintenanceModel
} from '../../shared/models';
import { Common } from './common.service';
import * as moment from 'moment';

@Injectable()
export class ServiceReportService {
    private baseUrl: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.SERVICE}/api`;
    private url: string = `${this.baseUrl}/service-report`;

    data: any = {};

    constructor(private common: Common, private http: HttpClient) {}

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<ServiceReportModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new ServiceReportModel(x));
                }
                return res;
            }));
    }

    get(modelPk: number) {
        return this.http.get(`${this.url}/get?srpk=${modelPk}`)
            .pipe(map(res => new ServiceReportModel(res)));
    }

    create(model: ServiceReportModel) {
        return this.http.post<ServiceReportModel>(`${this.url}/create`, JSON.stringify(model))
            .pipe(map(res => new ServiceReportModel(res)));
    }

    update(model: ServiceReportModel) {
        return this.http.put<ServiceReportModel>(`${this.url}/update`, JSON.stringify(model))
            .pipe(map(res => new ServiceReportModel(res)));
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?srpk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    createPart(model: PartModel ) {
        return this.http.post<PartModel>(`${this.url}/create-part`, JSON.stringify(model))
            .pipe(
                map(res => new PartModel(res))
            );
    }

    updatepart(model: PartModel) {
        return this.http.put<PartModel>(`${this.url}/update-part`, JSON.stringify(model))
            .pipe(
                map(res => new PartModel(res))
            );
    }

    deletePart(partPk: Number) {
        return this.http.delete<Boolean>(`${this.url}/delete-part?modelPk=${partPk}`, { observe: 'response'})
            .pipe(map(this.common.httpBooleanHandler));
    }

    createLabor(model: ServiceReportLaborModel) {
        return this.http.post<ServiceReportLaborModel>(`${this.url}/create-labor`, JSON.stringify(model))
            .pipe(map(res => new ServiceReportLaborModel(res)));
    }

    updateLabor(model: ServiceReportLaborModel) {
        return this.http.put<ServiceReportLaborModel>(`${this.url}/update-labor`, JSON.stringify(model))
            .pipe(map(res => new ServiceReportLaborModel(res)));
    }

    deleteLabor(srLaborPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete-labor?modelPk=${srLaborPk}`, { observe: 'response'})
            .pipe(map(this.common.httpBooleanHandler));
    }

    getWarrantyParts(assetId: string, locationId: string, partId: string, serviceDate: Date, woPk: string) {
        const date = moment(serviceDate).format('YYYY-MM-DD');
        locationId = locationId ? encodeURIComponent(locationId) : '';
        partId = encodeURIComponent(partId);

        return this.http.get<ServiceReportPartWarrantyModel[]>(`${this.url}/get-warranty-parts?assetId=${assetId}&partId=${partId}&serviceDate=${date}&woPk=${woPk}` + (locationId ? `&locationId=${locationId}` : ''))
            .pipe(
                map(res => res ? res.map(x => new ServiceReportPartWarrantyModel(x)) : new Array() as ServiceReportPartWarrantyModel[])
            );
    }

    getPreventativeMaintenanceTasks() {
        const key = 'PreventativeMaintenanceTasks';

        if (this.data[key]) {
            return of(this.data[key] as ProcedureLibraryModel[]);
        }

        return this.http.get<ProcedureLibraryModel[]>(`${this.baseUrl}/preventative-maintenance/tasks/get`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new ProcedureLibraryModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as ProcedureLibraryModel[];
            }));
    }

    downloadCheckList(serviceReportPk: number) {
        return this.http.post(`${this.url}/download-checklist?serviceReportPk=${serviceReportPk}`, {}, { responseType: 'arraybuffer'})
            .pipe(map(res => res));

    }

    getPreventativeMaintenances() {
        return this.http.get<PreventativeMaintenanceModel[]>(`${this.baseUrl}/preventative-maintenance/tracker/get`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new PreventativeMaintenanceModel(x));
                    return results;
                }
                return new Array() as PreventativeMaintenanceModel[];
            }));
    }
}
