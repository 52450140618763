export class MessageModel {
    Title: string;
    Text: string;
    // ErrorText: string;
    // Action: string;
    // Value: any;
    // FontColor: string;
    // BackgroundColor: string;
    // EnableSubmit: boolean = true;
    // Visible: boolean;
    // Buttons: ModalButtons;
    // AutoClose: boolean = true;
    // IsLoading: boolean = false;
    // Content: any = '';
    // ModalClass: string = '';

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Title = obj.Title;
        this.Text = obj.Text;
        // this.ErrorText = obj.ErrorText;
        // this.Action = obj.Action;
        // this.Value = obj.Value;
        // this.FontColor = obj.FontColor;
        // this.BackgroundColor = obj.BackgroundColor;
        // this.EnableSubmit = obj.EnableSubmit;
        // this.Visible = obj.Visible;
        // this.Buttons = obj.Buttons;
        // this.AutoClose = obj.AutoClose;
        // this.IsLoading = obj.IsLoading;
        // this.Content = obj.Content;
        // this.ModalClass = obj.ModalClass;
    }

    // show() {
    //     this.Visible = true;
    // }

    // hide() {
    //     this.Visible = false;
    // }
}
