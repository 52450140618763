import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[statusColor]'
})

export class StatusColorDirective implements AfterViewInit {
    @Input()
    public searchView: string;

    @Input()
    public status: string;

    constructor(private elRef: ElementRef) { }

    ngAfterViewInit(): void {

        const element = this.elRef.nativeElement;

        if (this.searchView.toLowerCase() == 'all') {

            if (this.status.toLowerCase() == 'pending') {
                element.classList.add('text-warning','font-weight-bold');
            }
            if (this.status.toLowerCase() == 'new') {
                element.classList.add('text-success','font-weight-bold');
            }
            if (this.status.toLowerCase() == 'denied') {
                element.classList.add('text-danger','font-weight-bold');
            }
        }
    }
}