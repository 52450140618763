import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';

import {
    ReportModel,
    SafetyIncidentModel,
    SearchCriteriaModel,
    SearchResultModel,
    SafetyIncidentOtherVehicleModel,
    SafetyIncidentWitnessModel,
    SafetyCorrectiveActionModel
} from '../../shared/models';

import { Common } from './common.service';

import * as moment from 'moment';

@Injectable()
export class SafetyService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.SAFETY}/api/safety`;

    constructor(private common: Common, private http: HttpClient) {}

    searchIncidents(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<SafetyIncidentModel>>(`${this.url}/incident/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new SafetyIncidentModel(x));
                }
                return res;
            }));
    }

    getIncident(modelPk: number) {
        return this.http.get<SafetyIncidentModel>(`${this.url}/incident/get/${modelPk}`)
            .pipe(
                map(res => new SafetyIncidentModel(res))
            );
    }

    saveIncident(model: SafetyIncidentModel) : Observable<SafetyIncidentModel> {
        const newModel = this.common.formatDateFields(model, 'IncidentDate', 'ScreeningDate');

        if (model.Pk) {
            return this.http.put<SafetyIncidentModel>(`${this.url}/incident/update`, newModel)
                .pipe(
                    map(res => new SafetyIncidentModel(res))
                );
        }

        return this.http.post<SafetyIncidentModel>(`${this.url}/incident/create`, newModel)
            .pipe(
                map(res => new SafetyIncidentModel(res))
            );
    }

    deleteIncident(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/incident/delete/${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    printIncident(modelPk: number) {
        return this.http.get<ReportModel>(`${this.url}/incident/print/${modelPk}`)
            .pipe(
                map(res => new ReportModel(res))
            );
    }

    createOtherVehicle(model: SafetyIncidentOtherVehicleModel) : Observable<SafetyIncidentOtherVehicleModel> {
        return this.http.post<SafetyIncidentOtherVehicleModel>(`${this.url}/incident/create/other-vehicle`, model)
            .pipe(
                map(res => new SafetyIncidentOtherVehicleModel(res))
            );
    }

    updateOtherVehicle(model: SafetyIncidentOtherVehicleModel) : Observable<SafetyIncidentOtherVehicleModel> {
        return this.http.put<SafetyIncidentOtherVehicleModel>(`${this.url}/incident/update/other-vehicle`, model)
            .pipe(
                map(res => new SafetyIncidentOtherVehicleModel(res))
            );
    }

    deleteOtherVehicle(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/incident/delete/other-vehicle/${modelPk}`, { observe: 'response'})
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    createWitness(model: SafetyIncidentWitnessModel) {
        return this.http.post<SafetyIncidentWitnessModel>(`${this.url}/incident/create/witness`, model)
            .pipe(
                map(res => new SafetyIncidentWitnessModel(res))
            );
    }

    updateWitness(model: SafetyIncidentWitnessModel) {
        return this.http.put<SafetyIncidentWitnessModel>(`${this.url}/incident/update/witness`, model)
            .pipe(
                map(res => new SafetyIncidentWitnessModel(res))
            );
    }

    deleteWitness(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/incident/delete/witness/${modelPk}`, { observe: 'response'})
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    saveCorrectiveAction(model: SafetyCorrectiveActionModel) {
        const newModel = this.common.formatDateFields(model, 'TargetCloseDate', 'CompletionDate');

        if (model.Pk) {
            return this.http.put<SafetyCorrectiveActionModel>(`${this.url}/corrective-action/update`, newModel)
                .pipe(
                    map(res => new SafetyCorrectiveActionModel(res))
                );
        }

        return this.http.post<SafetyCorrectiveActionModel>(`${this.url}/corrective-action/create`, newModel)
            .pipe(
                map(res => new SafetyCorrectiveActionModel(res))
            );
    }

    deleteCorrectiveAction(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/corrective-action/delete/${modelPk}`, { observe: 'response'})
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    downloadDataDump(asOfDate: Date) {
        const newDate = moment(asOfDate).format('YYYY-MM-DD');
        return this.http.post(`${this.url}/incident/download-data-dump?asOfDate=${newDate}`, { }, { responseType: 'arraybuffer' }).pipe(map(res => res));
    }
}
