import * as moment from 'moment';

export class SyncLogViewModel {
    Pk: number;
    ApplicationName: string;
    Status: string;
    UserId: string;
    FieldValueChanged: string;
    ErrorMessage: string;
    CreationDate: Date;
    CreatedyBy: number;
    ConvertedFieldValueChanged: SyncLogValueChangeModel[];

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ApplicationName = obj.ApplicationName;
        this.Status = obj.Status;
        this.UserId = obj.UserId;
        this.ErrorMessage = obj.ErrorMessage;
        this.CreatedyBy = obj.CreatedyBy;
        this.FieldValueChanged = obj.FieldValueChanged;

        this.CreationDate = moment(obj.CreationDate).toDate();

         if (obj.FieldValueChanged)
             this.ConvertedFieldValueChanged = JSON.parse(obj.FieldValueChanged);
    }
}


export class SyncLogValueChangeModel {
    FieldName: string;
    OldValue: string;
    NewValue: string;
}