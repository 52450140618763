import { LookupModel } from './lookup.model'

export class AutocompleteModel {
    LabelName: string;
    ControlText: string = "";
    List: LookupModel[] = new Array();
    SelectedItem: LookupModel = new LookupModel();

    constructor(labelName: string) {
        this.LabelName = labelName;
    }
}
