import { Directive, Injectable, Output, EventEmitter } from '@angular/core';

@Directive()
@Injectable()
export class ModuleNameService {
    @Output() moduleId: EventEmitter<any> = new EventEmitter();
    @Output() userId: EventEmitter<any> = new EventEmitter();
    @Output() showTitleBar: EventEmitter<any> = new EventEmitter();

    setName(value) {
        this.moduleId.emit(value);
    }
    getName() {
        return this.moduleId;
    }
    setUserId(value) {
        this.userId.emit(value);
    }
    getUserId() {
        return this.userId;
    }
    setShowTitleBar(value) {
        this.showTitleBar.emit(value);
    }
    getShowTitleBar() {
        return this.showTitleBar;
    }
}
