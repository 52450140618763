import { ApplicationUserRoleModel } from './application.model';

export class UserDetailModel {
    ApplicationUserPk: number;
    // todo: remove when redesign launches
    ApplicationUserRoles: ApplicationUserRoleModel[];
    Area: string;
    AssetId: string;
    LimitPersonalVehicleExpense: boolean = true;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    IsEnabled: boolean;
    UserId: string;
    FirstName: string;
    LastName: string;
    Nickname: string;
    UserRoles: ApplicationUserRoleModel[];
    HasDirectReports: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.ApplicationUserPk = obj.ApplicationUserPk;
        this.CreationDate = obj.CreationDate;
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = obj.LastUpdateDate;
        this.LastUpdateBy = obj.LastUpdateBy;
        this.IsEnabled = obj.IsEnabled;
        this.UserId = obj.UserId;
        this.FirstName = obj.FirstName;
        this.LastName = obj.LastName;
        this.Nickname = obj.Nickname;
        this.AssetId = obj.AssetId;
        this.LimitPersonalVehicleExpense = obj.LimitPersonalVehicleExpense;
        this.Area = obj.Area;
        this.HasDirectReports = obj.HasDirectReports;

        if (obj.UserRoles) {
            this.UserRoles = obj.UserRoles.map(x => new ApplicationUserRoleModel(x));
        }

        if (obj.ApplicationUserRoles) {
            this.ApplicationUserRoles = obj.ApplicationUserRoles.map(x => new ApplicationUserRoleModel(x));
        }
    }

    getFirstNicknameLast() {
        var nickName = this.Nickname ? "(" + this.Nickname + ") " : "";

        if (this.FirstName && this.LastName) {
            return `${this.FirstName} ${nickName}${this.LastName}`;
        }

        return this.FirstName ? this.FirstName : this.LastName;
    }
}
