import { Component, Input, SimpleChanges } from '@angular/core';
import { map } from 'rxjs/operators';

import {
    ApplicationUserModel,
    AssetActivityReportModel,
    ConfirmModel,
    DataflowGroupModel,
    DataflowFieldModel
} from '../../models';

import {
    AreaService,
    AssetActivityReportService,
    Common,
    DataflowService,
    LookupValueService,
    LookupService,
    MacolaCustomerService,
    SparkFileService,
    SparkModuleTypeService,
    UnitService,
    UserService
} from '../../../core/services';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { DataflowComponent } from './dataflow.component';

@Component({
    selector: 'asset-activity-dataflow',
    templateUrl: 'dataflow.component.html'
})
export class AssetActivityDataflowComponent extends DataflowComponent {
    @Input() mode: string;
    @Input() model: AssetActivityReportModel = new AssetActivityReportModel();

    dataLoaded: boolean = false;
    modelLoaded: boolean = false;
    modelOriginal: AssetActivityReportModel = new AssetActivityReportModel();

    constructor(
        public common: Common,
        public dataflowService: DataflowService,
        public sparkFileService: SparkFileService,
        public sparkModuleTypeService: SparkModuleTypeService,
        private areaService: AreaService,
        private assetActivityReportService: AssetActivityReportService,
        private lookupValueService: LookupValueService,
        private lookupService: LookupService,
        private macolaCustomerService: MacolaCustomerService,
        private unitService: UnitService,
        private userService: UserService
    ) {
        super(common, dataflowService, sparkFileService, sparkModuleTypeService);
    }

    ngOnInit() {
        this.common.handleRequests([
            this._getAssets(),
            this._getAreas(),
            this._getUsers(),
            this._getCustomers(),
            this._getLocations(),
            this._getLookupValues(),
            this._getStockrooms()
        ]).then(() => {
            this.registerLoad("data");

            // PK is not a valid number, so force dataflow fetch
            if (!this.model.Pk) {
                super.setInputs("AAR", this.mode, new AssetActivityReportModel());

                this.setManager();
            }
        });

        this.getStepTitle();
    }

    ngOnChanges(changes: SimpleChanges) {
        // The PK exists, but most fields do not when initialized.
        // When the CreatedBy exists, the API request has been processed
        if (changes.model && changes.model.currentValue && changes.model.currentValue.Pk && changes.model.currentValue.CreatedBy) {
            super.setInputs("AAR", this.mode, this.model);

            Object.keys(this.model).map(x => this.validation[x] = true);

            this.registerLoad("model");
        }
    }

    private _getAssets() {
        return this.unitService.getPortalUnitList().pipe(map(data => this.typeaheads["Asset"] = data));
    }

    private _getAreas() {
        return this.areaService.get().pipe(map(data => this.typeaheads["Area"] = data));
    }

    private _getUsers() {
        return this.userService.getAllUsers().pipe(map(data => this.typeaheads["User"] = data));
    }

    private _getCustomers() {
        // return this.macolaCustomerService.search().pipe(map(data => this.typeaheads["Customer"] = data));
        return this.lookupService.getLookupList("customer").pipe(map(data => this.typeaheads["Customer"] = data));
    }

    private _getLocations() {
        return this.lookupService.getLookupList("location").pipe(map(data => this.typeaheads["Location"] = data));
    }

    private _getStockrooms() {
        return this.lookupService.getLookupList("sr").pipe(map(data => this.typeaheads["Stockroom"] = data));
    }

    private _getLookupValues() {
        const types = [
            'COUNTY',
            'STATE'
        ];

        return this.lookupValueService.getByTypes(types).pipe(map(data => {
            types.map(x => {
                if (!this.typeaheads[x]) {
                    this.typeaheads[x] = this.common.sort(data.filter(y => y.Type == x), 'Sort');
                }
            });
        }));
    }

    saveModel() {
        this.formFooter.disableAll();

        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);

        if (!this.model.Pk) {
            this.model.Type = dataflow.Type;
        }

        this.assetActivityReportService.create(this.model)
            .subscribe(
                data => {
                    this.formFooter.enableAll();

                    if (this.modelOriginal.Pk) {
                        this.common.showMessage('Successfully updated Asset Activity Report.');
                    }

                    this.onSave.emit(data);
                },
                error => {
                    this.formFooter.enableAll();
                    this.common.showError('Error creating Asset Activity Report', error);
                }
            );
    }

    getDataflow() {
        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);

        return dataflow ? dataflow.Name : '';
    }

    onNextStep(bypass: boolean = false) {
        if (this.currentStep == this.totalSteps) {
            this.saveModel();
            return;
        }

        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);
        const group = dataflow.Groups[this.currentStep - 2];

        if (this.currentStep > 1 && group.CustomValidation && !bypass) {
            this.customValidation(group);
        }
        else {
            super.onNextStep();
        }
    }

    getStepTitle() {
        if (this.currentStep == 1) {
            this.stepTitle = 'New Asset Activity Report';
        }
        else {
            super.getStepTitle();
        }
    }

    // todo: refactor this to support other fields to validate?
    customValidation(selectedGroup?: DataflowGroupModel) {
        let messages = [];
        let groups: DataflowGroupModel[] = [];

        if (selectedGroup) {
            groups.push(selectedGroup);
        }
        else {
            const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);
            groups = dataflow.Groups.map(x => new DataflowGroupModel(x));
        }

        groups.map(group => {
            group.Fields.map(field => {
                let selectedValue;

                switch (field.Id) {
                    case "EngineSerialNumber":
                        selectedValue = this.typeaheads.Asset.find(x => x.Unit.toLowerCase() == this.model["AssetId"].toLowerCase());

                        if (selectedValue.EngineSerial != this.model.EngineSerialNumber && !this.validation[field.Id]) {
                            this.validation[field.Id] = false;
                            messages.push("The Engine Serial Number you entered does not match the Engine Serial Number in Maintenance Connection.");
                        }
                        break;

                    case "CompressorSerialNumber":
                        selectedValue = this.typeaheads.Asset.find(x => x.Unit.toLowerCase() == this.model["AssetId"].toLowerCase());

                        if (selectedValue.FrameSerial != this.model.CompressorSerialNumber && !this.validation[field.Id]) {
                            this.validation[field.Id] = false;
                            messages.push("The Compressor Serial Number you entered does not match the Compressor Serial Number in Maintenance Connection.");
                        }
                        break;

                    case "CoolerSerialNumber":
                        selectedValue = this.typeaheads.Asset.find(x => x.Unit.toLowerCase() == this.model["AssetId"].toLowerCase());

                        if (selectedValue.CoolerSerial != this.model.CoolerSerialNumber && !this.validation[field.Id]) {
                            this.validation[field.Id] = false;
                            messages.push("The Cooler Serial Number you entered does not match the Cooler Serial Number in Maintenance Connection.");
                        }
                        break;

                    case "CustomerName":
                    case "FromLocationName":
                    case "ToLocationName":
                        if (field.Options && field.Options.Mapping && !this.validation[field.Id] && !this.model[field.Options.Mapping.Name] && this.model[field.Options.Mapping.Description]) {
                            this.validation[field.Id] = false;
                            messages.push(`The ${field.Label} you entered does not match the existing options.`);
                        }
                        break;

                    default:
                        break;
                }
            });
        });

        if (messages.length) {
            var confirm = new ConfirmModel({
                Text: `<ul><li>${messages.join("</li><li>")}</li></ul> <div>Are you sure the information you entered is correct?</div>`,
                onConfirm: () => {
                    groups.map(group => {
                        group.Fields.map(field => {
                            if (this.validation[field.Id] === false) {
                                this.validation[field.Id] = true;
                            }
                        });
                    });

                    this.common.hideConfirm();

                    if (this.mode == "wizard") {
                        this.onNextStep(true);
                    }
                    else if (this.mode == "tab") {
                        this.onSave.emit();
                    }
                }
            });

            this.common.showConfirm(confirm);

            return false;
        }
        else {
            if (this.mode == "wizard") {
                this.onNextStep(true);
            }
            else if (this.mode == "tab") {
                return true;
            }
        }

        return true;
    }

    onModelChanged(field: DataflowFieldModel) {
        this.validation[field.Id] = this.model[field.Id] == this.modelOriginal[field.Id];

        super.onModelChanged(field);
    }

    onConfirmResetForm() {
        super.onConfirmResetForm();

        this.model = new AssetActivityReportModel();
        this.modelOriginal = new AssetActivityReportModel();

        this.setManager();
    }

    setManager() {
        const access = this.common.getUserRoleAccess();

        if (access.areaManager) {
            const match = (this.typeaheads["User"] as ApplicationUserModel[]).find(x => x.UserId == this.user.AppUser.UserId);

            if (match) {
                this.model.AreaManagerId = match.UserId;
                this.model.AreaManagerName = match.FullName;
                this.selectedTypeahead["AreaManagerName"] = match.FullName;
            }
        }
    }

    onTypeaheadSelect(field: DataflowFieldModel, selected: TypeaheadMatch) {
        super.onTypeaheadSelect(field, selected);

        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);

        if (field.Id == "AssetName" && dataflow.Type.toLowerCase() == "commissioning") {
            this.assetActivityReportService.getExisting(this.model.AssetId, dataflow.Type)
                .subscribe(data => {
                    if (data && data.Pk) {
                        this.common.showError(`${data.Type} already exists`, `A <strong>${data.Type}</strong> Asset Activity Report already exists for <strong>${this.model.AssetName}</strong>.`);
                        this.onConfirmResetForm();
                    }
                });
        }
    }
}