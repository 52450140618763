import { Component, ElementRef, Input, Optional } from '@angular/core';

import { SortDirective } from '../../directives/sort.directive';

@Component({
    selector: '[sort-header]',
    templateUrl: 'sort-header.component.html',
    host: {
        '(click)': 'onClick()'
    }
})
export class SortHeaderComponent {
    @Input('sort-header') id: string;
    @Input('sort-order') order: string = 'asc';

    constructor(
        public hostElement: ElementRef,
        @Optional() private sorter: SortDirective) {

        this.hostElement.nativeElement.classList.add('sortable');
    }

    ngOnInit() {
        // pull in value passed to header
        if (this.id == this.sorter.active && this.sorter.order) {
            this.order = this.sorter.order;
        }
    }

    isActive() {
        return this.sorter.active == this.id;
    }

    onClick() {
        // only change the sort direction if the column is active
        if (this.sorter.active == this.id) {
            this.order = this.order == 'asc' ? 'desc' : 'asc';
        }

        this.sorter.sort(this);
    }
}