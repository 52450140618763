import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';

import {
    SalesMassRenewalModel,
    SalesMassRenewalItemModel
} from '../../models';

import {
    Common,
    DataflowService,
    LookupService,
    SalesMassRenewalService,
    SparkFileService,
    SparkModuleTypeService,
    UnitService
} from '../../../core/services';

import { DataflowComponent } from './dataflow.component';

@Component({
    selector: 'mass-sales-order-renewal',
    templateUrl: 'dataflow.component.html'
})
export class MassSalesOrderRenewalDataflowComponent extends DataflowComponent {
    @Input() mode: string;
    @Input() model: SalesMassRenewalModel = new SalesMassRenewalModel();

    dataLoaded: boolean = false;
    modelLoaded: boolean = false;
    modelOriginal: SalesMassRenewalModel = new SalesMassRenewalModel();

    constructor(
        public common: Common,
        public dataflowService: DataflowService,
        public sparkFileService: SparkFileService,
        public sparkModuleTypeService: SparkModuleTypeService,
        private lookupService: LookupService,
        private salesMassRenewalService: SalesMassRenewalService,
        private unitService: UnitService
    ) {
        super(common, dataflowService, sparkFileService, sparkModuleTypeService);
    }

    ngOnInit() {
        this.common.handleRequests([
            this._getAssets(),
            this._getCustomers()
        ]).then(() => {
            this.registerLoad("data");

            // PK is not a valid number, so force dataflow fetch
            if (!this.model.Pk) {
                super.setInputs("SO", this.mode, {});
            }
        });

        this.getStepTitle();
    }

    private _getAssets() {
        return this.unitService.getPortalUnitList().pipe(map(data => this.typeaheads["Asset"] = data));
    }

    private _getCustomers() {
        return this.lookupService.getLookupList("customer").pipe(map(data => this.typeaheads["Customer"] = data));
    }

    getDataflow() {
        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);

        return dataflow ? dataflow.Name : '';
    }

    getStepTitle() {
        if (this.currentStep == 1) {
            this.stepTitle = 'New Mass SIS Renewal';
        }
        else {
            super.getStepTitle();
        }
    }

    saveModel() {
        this.formFooter.disableAll();

        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);

        if (!this.model.Pk) {
            this.model.Type = dataflow.Type;
        }

        this.model.Type = "SO";
        this.model.Items = [];

        this.results["Assets"].filter(x => x.Selected).map(x => {
            const item = new SalesMassRenewalItemModel();
            item.RenewalDate = this.model.RenewalDate;
            item.AssetId = x.Unit;
            item.AreaId = x.Area;
            item.TermInMonths = this.model.TermInMonths;
            item.Location = x.Location;

            this.model.Items.push(item);
        });

        this.salesMassRenewalService.save(this.model)
            .subscribe(
                data => {
                    this.formFooter.enableAll();

                    if (this.modelOriginal.Pk) {
                        this.common.showMessage('Successfully updated Mass SIS Renewal.');
                    }

                    this.onSave.emit(data);
                },
                error => {
                    this.formFooter.enableAll();
                    this.common.showError('Error creating Mass SIS Renewal', error);
                }
            );
    }
}