export class MacolaCustomerModel {
    Pk: number;
    Code: string;
    Name: string;
    CustomerId: string;
    ContactId: string;
    Contacts: MacolaContactModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Code = obj.Code;
        this.Name = obj.Name;
        this.CustomerId = obj.CustomerId;
        this.ContactId = obj.ContactId;

        if (obj.Contacts) {
            this.Contacts = obj.Contacts.map(x => new MacolaContactModel(x));
        }
    }
}

export class MacolaContactModel
{
    Pk: number;
    ContactId: string;
    FirstName: string;
    LastName: string;
    FullName: string;
    CustomerId: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ContactId = obj.ContactId;
        this.FirstName = obj.FirstName;
        this.LastName = obj.LastName;
        this.FullName = obj.FullName;
        this.CustomerId = obj.CustomerId;
    }
}