import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name:'splitTitleCase'
})
export class SplitTitleCase implements PipeTransform {
    transform(value: string, arg?: any): any {
        const reg = new RegExp(/([a-z])([A-Z])/g);
        return value.replace(reg, '$1 $2');
    }
}