<div class="form-row filelist">
    <div class="form-group col-sm-7 col-md-9" *ngIf="!readOnly">
        <div class="custom-file">
            <input id="SparkFileInput" type="file" class="custom-file-input" (change)="onAttached($event)" [disabled]="uploading">
            <label class="custom-file-label" for="SparkFileInput">
                <span>{{selected ? selected.name : 'Choose file'}}</span>
            </label>
        </div>
    </div>

    <div class="form-group col-sm-5 col-md-3 text-right" *ngIf="!readOnly">
        <button class="btn btn-secondary" [disabled]="!selected || uploading" (click)="onClear()">
            <i class="fas fa-times-circle"></i> <span class="ml-2">Clear</span>
        </button>
        <button class="btn btn-success" [disabled]="!selected || uploading" (click)="onAdd()">
            <i class="fas fa-plus-circle"></i> <span class="ml-2">Add</span>
        </button>
    </div>

    <ng-container *ngIf="showDocumentType && !readOnly">
        <label for="DocumentType" class="col-sm-2 col-md-2 col-lg-1 col-form-label">File Type:</label>
        <div class="col-sm-10 col-md-7 col-lg-8">
            <select id="DocumentType" name="DocumentType" class="form-control" [(ngModel)]="selectedDocumentType">
                <option *ngIf="!selectedDocumentType"></option>
                <option *ngFor="let type of documentTypes" [value]="type.Name">{{type.Name}} - {{type.Description}}</option>
            </select>
        </div>
    </ng-container>

    <div class="table-responsive" *ngIf="model && model[fieldId] && model[fieldId].length">
        <table class="table table-striped stack mb-0 mt-4" [class.mt-4]="!readOnly">
            <thead>
                <tr>
                    <th>Name</th>
                    <th *ngIf="showDocumentType">File Type</th>
                    <th class="actions">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let file of model[fieldId]; let i = index">
                    <td><span class="d-inline d-md-none">Name:</span> {{file.Description}}</td>
                    <td *ngIf="showDocumentType"><span class="d-inline d-md-none">File Type:</span> {{getDocumentType(file)}}</td>
                    <td class="actions">
                        <button class="btn btn-sm" *ngIf="!showActions(i) && !readOnly">
                            <loader [visible]="true"></loader>
                        </button>

                        <spark-file [model]="file" [status]="model.Status" *ngIf="file.Pk" [sparkModuleType]="sparkModuleType"></spark-file>

                        <button class="btn btn-sm btn-secondary" tooltip="Stop Upload" (click)="onCancelUpload(file, i)" *ngIf="!showActions(i) && !readOnly">
                            <i class="fas fa-ban"></i> <span class="d-inline d-md-none ml-2">Stop Upload</span>
                        </button>
                        <button class="btn btn-sm btn-danger" tooltip="Delete" (click)="onDelete(file, i)" *ngIf="showActions(i) && !readOnly">
                            <i class="fas fa-times-circle"></i> <span class="d-inline d-md-none ml-2">Delete</span>
                        </button>
                    </td>

                    <progressbar [value]="uploadProgress[i]" type="success" *ngIf="uploadProgress[i] > 0 && uploadProgress[i] < 100"></progressbar>
                </tr>
            </tbody>
        </table>
    </div>
</div>
