import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    FullLayoutComponent,
    SimpleLayoutComponent
} from './containers';

import { AuthGuard } from './shared/guards';

export const routes: Routes = [
    { path: 'code', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'error', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'home', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'id_token', redirectTo: '/dashboard', pathMatch: 'full' },
    {
       path: '',
       component: SimpleLayoutComponent,
       children: [
           { path: 'callback', loadChildren: () => import('./components/callback/callback.module').then(m => m.CallbackModule) }
       ]
    },
    {
        path: '',
        component: FullLayoutComponent,
        canActivate: [ AuthGuard ],
        data: {
            title: 'Home'
        },
        children: [
            { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule) },
            { path: 'help', loadChildren: () => import('./components/help/help.module').then(m => m.HelpModule) },
            { path: 'profile', loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule) },
            { path: 'dashboard', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'expenses', loadChildren: () => import('./components/expense-report/expense-report.module').then(m => m.ExpenseReportModule) },
            { path: 'operations/labor', loadChildren: () => import('./components/labor/labor.module').then(m => m.LaborModule) },
            { path: 'operations/operator', loadChildren: () => import('./components/operator-report/operator-report.module').then(m => m.OperatorReportModule) },
            { path: 'operations/runtime', loadChildren: () => import('./components/runtime-report/runtime-report.module').then(m => m.RuntimeReportModule) },
            { path: 'operations/service', loadChildren: () => import('./components/service-report/service-report.module').then(m => m.ServiceReportModule) },
            { path: 'operations/oil-sample', loadChildren: () => import('./components/oil-sample/oil-sample.module').then(m => m.OilSampleModule) },
            { path: 'operations/general-field', loadChildren: () => import('./components/general-field-service/general-field-service.module').then(m => m.GeneralFieldServiceModule) },
            { path: 'operations/part-lookup', loadChildren: () => import('./components/part-lookup/part-lookup.module').then(m => m.PartLookupModule) },
            { path: 'operations/purchase', loadChildren: () => import('./components/mc-purchase-order/mc-purchase-order.module').then(m => m.McPurchaseOrderModule) },
            { path: 'operations/timesheet', loadChildren: () => import('./components/timesheet/timesheet.module').then(m => m.TimesheetModule) },
            { path: 'operations/asset-activity', loadChildren: () => import('./components/asset-activity-report/asset-activity-report.module').then(m => m.AssetActivityReportModule) },
            { path: 'sales/sales-order', loadChildren: () => import('./components/sales-order/sales-order.module').then(m => m.SalesOrderModule) },
            { path: 'sales/invoicing-instruction', loadChildren: () => import('./components/invoicing-instruction/invoicing-instruction.module').then(m => m.InvoicingInstructionModule) },
            { path: 'assets', loadChildren: () => import('./components/asset/asset.module').then(m => m.AssetModule) },
            { path: 'supply-chain/mc-upload', loadChildren: () => import('./components/mc-upload/mc-upload.module').then(m => m.McUploadModule) },
            { path: 'safety/incident', loadChildren: () => import('./components/safety-incident/safety-incident.module').then(m => m.SafetyIncidentModule) },
            { path: 'supply-chain/inventory', loadChildren: () => import ('./components/inventory/inventory.module').then(m => m.InventoryModule)},
            { path: 'operations/fluids-request', loadChildren: () => import('./components/fluids-request/fluids-request.module').then(m => m.FluidsRequestModule) },
            { path: 'utilities/uploads', loadChildren: () => import('./components/utilities-upload/utilities-upload.module').then(m => m.UtilitiesUploadModule) },
            { path: 'operations/preventative-maintenance', loadChildren: () => import ('./components/preventative-maintenance/preventative-maintenance.module').then(m => m.PreventativeMaintenanceModule)},
            { path: 'sales', loadChildren: () => import('./components/projected-revenue/projected-revenue.module').then(m => m.ProjectedRevenueModule) },
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
