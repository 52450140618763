import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { ReportModel, InvoicingInstructionModel, SearchCriteriaModel, SearchResultModel, McUploadDetailModel } from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class InvoicingInstructionService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.SALES}/api/invoicing-instruction`;

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get<InvoicingInstructionModel>(`${this.url}/get?modelPk=${modelPk}`)
            .pipe(
                map(res => new InvoicingInstructionModel(res))
            );
    }

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<InvoicingInstructionModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new InvoicingInstructionModel(x));
                }
                return res;
            }));
    }

    create(model: InvoicingInstructionModel) {
        return this.http.post<InvoicingInstructionModel>(`${this.url}/create`, JSON.stringify(model))
            .pipe(
                map(res => new InvoicingInstructionModel(res))
            );
    }

    update(model: InvoicingInstructionModel) {
        return this.http.put<InvoicingInstructionModel>(`${this.url}/update`, JSON.stringify(model))
            .pipe(
                map(res => new InvoicingInstructionModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    print(modelPk: number) {
        return this.http.get<ReportModel>(this.url + `/print?modelPk=${modelPk}`)
            .pipe(
                map(res => new ReportModel(res))
            );
    }

    getLatestByAsset(assetId : string, type: string = '') {
        return this.http.get<InvoicingInstructionModel>(this.url + `/get-latest-by-asset?assetid=${assetId}${type ? `&type=${type}` : ''}`)
            .pipe(
                map(res => res ? new InvoicingInstructionModel(res) : null)
            )
    }

    import(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<any>(this.url + '/import', formData)
            .pipe(map(res => new McUploadDetailModel(res)));
    }
}