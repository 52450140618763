import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../../shared/global';
import { VehicleAllowanceExportModel, VehicleAllowanceExportItemModel } from '../../shared/models';
import { Common } from './common.service';
import { map } from 'rxjs/operators';

@Injectable()
export class VehicleAllowanceService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/vehicle-allowance-export`;

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get(`${this.url}/get/${modelPk}`)
            .pipe(map(res => new VehicleAllowanceExportModel(res)));
    }

    search() {
        return this.http.get<VehicleAllowanceExportModel[]>(`${this.url}/search`)
            .pipe(map(res => res ? res.map(x => new VehicleAllowanceExportModel(x)) : new Array()));
    }

    saveVehicleAllowance(model: VehicleAllowanceExportModel) {
        var newModel = new VehicleAllowanceExportModel(model);
        newModel.VehicleAllowanceExportItems.map(x => x.ApplicationUser = null);

        if (model.Pk) {
            return this.http.put<VehicleAllowanceExportModel>(`${this.url}/update`, newModel)
                .pipe(
                    map(res => new VehicleAllowanceExportModel(res))
                );
        }

        return this.http.post<VehicleAllowanceExportModel>(`${this.url}/create`, newModel)
            .pipe(
                map(res => new VehicleAllowanceExportModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete/${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }
}
