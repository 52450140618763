import { Component, OnInit, Input } from '@angular/core';
import { SparkFileComponent } from './spark-file.component';
import { Common, SecurityService, SparkFileService } from '../../../core/services';
import { SparkFileModel } from '../../models';

@Component({
  selector: 'spark-file-expense-report',
  templateUrl: './spark-file.component.html',
})
export class SparkFileExpenseReportComponent extends SparkFileComponent implements OnInit {
    @Input() attachments: SparkFileModel[] = new Array();

    constructor(
        public common: Common,
        public securityService: SecurityService,
        public sparkFileService: SparkFileService
    ) {
        super(common, securityService, sparkFileService);
    }

    ngOnInit() {
        if (this.attachments) {
            if (this.attachments.length > 1 ) {
                super.setModel(this.attachments[1]);
            }
            else {
                super.setModel(this.attachments[0]);
            }
        }
        else {
            var model = new SparkFileModel();
            model.Pk = 0;
            super.setModel(model);
        }

        super.setPath();
    }

    // override the isImage method in SparkFileComponent
    isImage() {
        // this is to show the preview button
        return this.attachments && this.attachments.length > 0;
    }

    // override openFile method
    openFile() {
        if (this.attachments) {
            var filePk = this.attachments.length > 1 ? this.attachments[1].Pk : this.attachments[0].Pk;
            this.sparkFileService.download(filePk)
                .subscribe(
                    data => {
                        if (data) {
                            this.common.downloadFile(this.attachments[0].Description, data);
                        }
                        else {
                            this.common.showError('Unexpected error', `We encountered an issue attempting to download ${this.attachments[0].Description}.`);
                        }
                    }
                )
        }
    }
}
