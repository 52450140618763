import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Common } from '../services';

@Injectable()
export class SearchInterceptor implements HttpInterceptor {
    constructor(private common: Common) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(finalize(() => {
            if (req.url.indexOf('search') > - 1) {
                this.common.emitChange('search_complete');
            }
        }));
    }
}