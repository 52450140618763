import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[validNumber]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ValidNumberDirective,
        multi: true
    }]
})
export class ValidNumberDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        const originalAmount = control.value;
        const amount = Math.round(parseFloat(originalAmount) * 100) / 100;

        return amount == originalAmount ? null : { 'validNumber': true };
    }
};
