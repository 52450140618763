import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { ReportModel, SalesOrderModel, SearchCriteriaModel, SearchResultModel } from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class SalesOrderService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.SALES}/api/sales-order`;

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get<SalesOrderModel>(`${this.url}/get?modelPk=${modelPk}`)
            .pipe(
                map(res => new SalesOrderModel(res))
            );
    }

    getLatestByAsset(assetId: string) {
        return this.http.get<SalesOrderModel>(`${this.url}/get-latest-by-asset?assetId=${assetId}`)
            .pipe(
                map(res => new SalesOrderModel(res))
            );
    }

    getByMassRenewal(salesMassRenewalPk: number) {
        return this.http.get<SalesOrderModel[]>(`${this.url}/get-by-mass-renewal?salesMassRenewalPk=${salesMassRenewalPk}`)
            .pipe(map(res => res.map(x => new SalesOrderModel(x))));
    }

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<SalesOrderModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new SalesOrderModel(x));
                }
                return res;
            }));
    }

    create(model: SalesOrderModel) {
        return this.http.post<SalesOrderModel>(`${this.url}/create`, JSON.stringify(model))
            .pipe(
                map(res => new SalesOrderModel(res))
            );
    }

    update(model: SalesOrderModel) {
        return this.http.put<SalesOrderModel>(`${this.url}/update`, JSON.stringify(model))
            .pipe(
                map(res => new SalesOrderModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    email(modelPk: number, notes: string, directions: string, attachment: File, recipients: string[]) {
        const formData = new FormData();

        if (attachment) {
            formData.append('file', attachment);
        }

        if (recipients.length) {
            recipients.map(x => formData.append('recipients', x));
        }

        return this.http.post<Boolean>(this.url + `/email?modelPk=${modelPk}&notes=${encodeURIComponent(notes)}&directions=${encodeURIComponent(directions)}`, attachment || recipients.length ? formData : {}, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    createContract(modelPk: number) {
        return this.http.post(`${this.url}/create-contract?modelPk=${modelPk}`, {}, { responseType: 'arraybuffer' }).pipe(map(res => res));
    }

    createSignedContract(modelPk: number, sparkFilePk: number) {
        return this.http.post<Boolean>(`${this.url}/create-signed-contract?modelPk=${modelPk}&sparkFilePk=${sparkFilePk}`, {}, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    print(modelPk: number) {
        return this.http.get<ReportModel>(this.url + `/print?modelPk=${modelPk}`)
            .pipe(
                map(res => new ReportModel(res))
            );
    }
}