import * as moment from 'moment';

export class SparkFileModel {
    Pk: number;
    SparkModuleTypePk: number;
    ModulePk: number;
    FileName: string;
    Description: string;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    DocumentTypeName: string;
    IsRemote: boolean;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.SparkModuleTypePk = obj.SparkModuleTypePk;
        this.ModulePk = obj.ModulePk;
        this.FileName = obj.FileName;
        this.Description = obj.Description;
        this.DocumentTypeName = obj.DocumentTypeName;
        this.IsRemote = obj.IsRemote;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }

    isDocument() {
        return !this.isImage();
    }

    isImage() {
        return (["png", "jpg", "jpeg", "gif", "bmp"].indexOf(this.getExtension().toLowerCase()) >= 0);
    }

    getExtension() {
        return this.FileName ? this.FileName.substring(this.FileName.lastIndexOf('.') + 1) : '';
    }
}