import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { LaborTypeModel } from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class LaborTypeService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.LABOR}/api/labor-type`;

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number): Observable<LaborTypeModel> {
        return this.http.get<LaborTypeModel>(`${this.url}?modelPk=${modelPk}`);
    }

    search(): Observable<LaborTypeModel[]> {
        return this.http.get<LaborTypeModel[]>(`${this.url}/search`);
    }

    save(model: LaborTypeModel) {
        if (model.Pk) {
            return this.http.put<LaborTypeModel>(`${this.url}/update`, model);
        }

        return this.http.post<LaborTypeModel>(`${this.url}/create`, model);

    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }
}