<modal id="sales-order-email" class="modal-md">
    <header>Send Installation Email</header>

    <form #emailForm="ngForm" novalidate autocomplete="off">
        <div class="form-row">
            <div class="form-group col-sm-4">
                <label>Account Manager:</label>
                <div>{{model.AccountManagerName}} </div>
            </div>
            <div class="form-group col-sm-4">
                <label>Unit Number:</label>
                <div>{{model.AssetName}} </div>
            </div>
            <div class="form-group col-sm-4">
                <label>Area:</label>
                <div>{{model.AreaId }}</div>
            </div>
            <div class="form-group col-sm-4">
                <label>Customer:</label>
                <div>{{model.CustomerName}}</div>
            </div>
            <div class="form-group col-sm-4">
                <label>Location:</label>
                <div>{{model.Location}} </div>
            </div>
            <div class="form-group col-sm-4">
                <label>Installation Date:</label>
                <div>{{model.EstimatedDate | date:'MM/dd/yyyy'}}</div>
            </div>
            <div class="form-group col-sm-4">
                <label>Cranes Set Up:</label>
                <div>{{model.InstallCraneSetup}}</div>
            </div>
            <div class="form-group col-sm-4">
                <label>Truck Set Up:</label>
                <div>{{model.InstallTruckSetup}}</div>
            </div>
            <div class="form-group col-sm-4">
                <label>Cranes Paid By:</label>
                <div>{{model.InstallCranes}}</div>
            </div>
            <div class="form-group col-sm-4">
                <label>Transportation In Paid By:</label>
                <div>{{model.InstallTransportation}}</div>
            </div>
            <div class="form-group col-sm-8">
                <label>Transportation In Split:</label>
                <div>{{model.InstallTransportationSplit}}</div>
            </div>
            <div class="form-group col-sm-4">
                <label>Transportation Out Paid By:</label>
                <div>{{model.InstallTransportationOut}}</div>
            </div>
            <div class="form-group col-sm-8">
                <label>Transportation Out Split:</label>
                <div>{{model.InstallTransportationOutSplit}}</div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-6">
                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="Notes">Notes:</label>
                        <textarea rows="3" #notesRef="ngModel" [(ngModel)]="notes" (ngModelChange)="validateEmailForm()" name="Notes" class="form-control" required></textarea>

                        <validation-message [isValid]="(notes && notes.length == 0)|| (notesRef.dirty || notesRef.touched)">
                            Please add notes
                        </validation-message>
                    </div>
                    <div class="form-group col-12">
                        <label for="Directions">Directions:</label>
                        <textarea rows="3" #directionsRef="ngModel" [(ngModel)]="directions" (ngModelChange)="validateEmailForm()" name="Directions" class="form-control" required></textarea>

                        <validation-message [isValid]="(directions && directions.length == 0) || (directionsRef.dirty || directionsRef.touched)">
                            Please add directions
                        </validation-message>
                    </div>
                </div>
            </div>

            <div class="form-group col-6">
                <label for="AreaManagerName">Area Managers:</label>
                <input name="AreaManagerName" id="AreaManagerName" type="search" class="form-control" [(ngModel)]="typeahead['AreaManager']" [typeahead]="selectableAreaManagers" [typeaheadScrollable]="true" typeaheadOptionField="DisplayName" (typeaheadOnSelect)="onAreaManagerChange($event)">

                <table class="table table-striped">
                    <tbody>
                        <tr *ngFor="let manager of selectedAreaManagers">
                            <td>{{manager.DisplayName}}</td>
                            <td class="actions">
                                <button class="btn btn-sm btn-danger" tooltip="Remove" (click)="onDeleteAreaManager(manager)">
                                    <i class="fas fa-times-circle"></i> <span class="d-inline d-md-none ml-2">Remove</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-12">
                <div class="custom-file">
                    <input type="file" class="custom-file-input" (change)="onEmailFileAttached($event)" />
                    <label class="custom-file-label">
                        <span>{{ emailAttachment ? emailAttachment.name : 'Choose file'}}</span>
                    </label>
                </div>
            </div>
        </div>
    </form>

    <modal-footer [model]="emailFooter"></modal-footer>
</modal>

<modal id="signed-contract">
    <header>Add Signed Contract</header>

    <form #signedContractForm="ngForm" novalidate>
        <div class="form-row">
            <div class="form-group col-sm-12">
                <div class="custom-file">
                    <input id="SignedContract" type="file" class="custom-file-input" (change)="onFileAttached($event)">
                    <label class="custom-file-label" for="SignedContract">
                        <span>{{selectedFile ? selectedFile.name : 'Choose file'}}</span>
                    </label>
                </div>
            </div>
        </div>

        <progressbar [value]="uploadProgress" type="success" *ngIf="uploadProgress > 0"></progressbar>
    </form>

    <modal-footer [model]="signedContractFooter"></modal-footer>
</modal>

<ol class="breadcrumb" *ngIf="!preview">
    <li class="breadcrumb-item d-sm-down-none">
        <button class="btn btn-link" (click)="onSave()" [disabled]="!canSave()">
            <i class="fas fa-save"></i> <span class="ml-2">Save</span>
        </button>
    </li>
    <li class="breadcrumb-item d-sm-down-none">
        <button class="btn btn-link" (click)="onBack()">
            <i class="fas fa-undo-alt"></i> <span class="ml-2">Back</span>
        </button>
    </li>
    <li class="breadcrumb-menu" *ngIf="access.actionMenu">
        <div class="btn-group" dropdown placement="bottom right">
            <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
                <i class="fas fa-bullseye"></i> <span class="ml-2 mr-1">Actions</span> <span class="caret"></span>
            </button>

            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                <li role="menuitem">
                    <a class="dropdown-item" (click)="onSubmit()" *ngIf="access.submit" href="javascript:;">
                        <i class="fas fa-check-circle"></i> Submit
                    </a>
                    <a class="dropdown-item" (click)="onDelete()" *ngIf="access.remove" href="javascript:;">
                        <i class="fas fa-times-circle"></i> Cancel
                    </a>
                    <a class="dropdown-item" (click)="onEmail()" *ngIf="access.email" href="javascript:;">
                        <i class="fas fa-envelope"></i> Email
                    </a>
                    <a class="dropdown-item" (click)="onCreateRenewal()" *ngIf="access.createRenewal" href="javascript:;">
                        <i class="fas fa-receipt"></i> Create Renewal
                    </a>
                    <a class="dropdown-item" (click)="onCreateInvoicingInstruction()" *ngIf="access.createNewInvoice" href="javascript:;">
                        <i class="fas fa-file-invoice"></i> Create Invoicing Instruction
                    </a>
                    <a class="dropdown-item" (click)="onCreateInvoicingInstruction()" *ngIf="access.createRenewInvoice" href="javascript:;">
                        <i class="fas fa-file-invoice"></i> Create Renew New II
                    </a>
                    <a class="dropdown-item" [class.disabled]="!access.print" (click)="access.print && onPrint()" href="javascript:;">
                        <i class="fas fa-print"></i> Print
                    </a>
                    <a class="dropdown-item" (click)="onAddContract()" *ngIf="access.createContract" href="javascript:;">
                        <i class="far fa-file"></i> Add Contract
                    </a>
                    <a class="dropdown-item" (click)="onAddSignedContract()" *ngIf="access.createContract" href="javascript:;">
                        <i class="far fa-file-alt"></i> Add Signed Contract
                    </a>
                    <workflow [model]="model" (onConfirm)="onLoad($event)"></workflow>
                </li>
            </ul>
        </div>
    </li>
</ol>

<div class="container-fluid" [class.preview-mode]="preview" [class.preview-mode-scroll]="preview">
    <div class="row" id="sales-order-detail">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fas fa-list"></i>
                    <strong *ngIf="!model.Pk">New Sales Information Sheet</strong>
                    <strong *ngIf="model.Pk">Sales Information Sheet #{{model.Pk}}</strong>
                </div>

                <div class="card-body">
                    <form #salesOrderForm="ngForm" novalidate autocomplete="off">
                        <div class="alert alert-primary" *ngIf="!submittable">
                            This SIS cannot be submitted because <strong>{{selectedAsset.UnitName}}</strong> is unavailable.
                        </div>

                        <div class="alert alert-warning" *ngIf="showReviewMessage">Please be sure to review all fields.</div>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label>Status:</label>
                                <div>{{common.getPendingStatus(model) | titlecase}}</div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label>Type:</label>
                                <div>{{model.Type.toLowerCase() == 'new' ? 'New' : 'Renewal'}}</div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label for="Date">Date:</label>
                                <p-calendar #dateRef="ngModel" name="Date" id="Date" [(ngModel)]="model.Date" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true" [disabled]="!access.save"></p-calendar>

                                <validation-message [isValid]="dateRef.invalid && (dateRef.dirty || dateRef.touched)">
                                    Please enter a Date
                                </validation-message>
                            </div>

                            <div class="form-group col-sm-6 col-md-4">
                                <label for="AssetId">Unit:</label>
                                <input #unitRef="ngModel" name="AssetId" id="AssetId" type="search" class="form-control" [(ngModel)]="typeahead['AssetId']" (typeaheadOnSelect)="onUnitChange($event)" [typeahead]="availableAssets" [typeaheadScrollable]="true" typeaheadOptionField="UnitName" required [disabled]="!access.save && !access.unit" (ngModelChange)="onTypeaheadChange('AssetId')" data-monitor-type="notify" (blur)="validateTypeahead('AssetId')">

                                <validation-message [isValid]="unitRef.invalid && (unitRef.dirty || unitRef.touched)">
                                    Please select a Unit
                                </validation-message>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-4 col-md-4">
                                <label for="Engine">Engine:</label>
                                <div>{{model.Engine || 'N/A'}}</div>
                            </div>
                            <div class="form-group col-sm-4 col-md-4">
                                <label for="Frame">Frame:</label>
                                <div>{{model.Frame || 'N/A'}}</div>
                            </div>
                            <div class="form-group col-sm-4 col-md-4">
                                <label for="Horsepower">Horsepower:</label>
                                <div>{{selectedAsset.Horsepower || 'N/A'}}</div>
                            </div>
                        </div>

                        <tabset #moduleTabs>
                            <tab>
                                <ng-template tabHeading>
                                    <i class="far fa-file-alt"></i> <span class="ml-2">Customer</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="AccountManagerName">Account Representative:</label>
                                        <input #accountManagerRef="ngModel" name="AccountManagerName" id="AccountManagerName" type="search" class="form-control" [(ngModel)]="typeahead['AccountManager']" [typeahead]="accountRepresentatives" [typeaheadScrollable]="true" typeaheadOptionField="DisplayName" (typeaheadOnSelect)="onAccountManagerChange($event)" required [disabled]="!access.save" (ngModelChange)="onTypeaheadChange('AccountManager')" data-monitor-type="notify" (blur)="validateTypeahead('AccountManager')">

                                        <validation-message [isValid]="accountManagerRef.invalid && (accountManagerRef.dirty || accountManagerRef.touched)">
                                            Please select an Account Representative
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="OperationsManagerName">Operations Representative:</label>
                                        <input #operationsManagerRef="ngModel" name="OperationsManagerName" id="OperationsManagerName" type="search" class="form-control" [(ngModel)]="typeahead['OperationsManager']" [typeahead]="areaManagers" [typeaheadScrollable]="true" typeaheadOptionField="DisplayName" (typeaheadOnSelect)="onOperationsManagerChange($event)" required [disabled]="!access.save" (ngModelChange)="onTypeaheadChange('OperationsManager')" data-monitor-type="notify" (blur)="validateTypeahead('OperationsManager')">

                                        <validation-message [isValid]="operationsManagerRef.invalid && (operationsManagerRef.dirty || operationsManagerRef.touched)">
                                            Please select an Operations Representative
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="CustomerName">Customer:</label>
                                        <input #customerRef="ngModel" name="CustomerName" id="CustomerName" type="search" class="form-control" [(ngModel)]="typeahead['Customer']" [typeahead]="customers" [typeaheadScrollable]="true" typeaheadOptionField="Name" (typeaheadOnSelect)="onCustomerChange($event)" required [disabled]="!access.save" (ngModelChange)="onTypeaheadChange('Customer')" data-monitor-type="notify" (blur)="validateTypeahead('Customer')">

                                        <validation-message [isValid]="customerRef.invalid && (customerRef.dirty || customerRef.touched)">
                                            Please select a Customer
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="AccountsPayableContactId">Accounts Payable Contact:</label>
                                        <select #accountsPayableContactRef="ngModel" name="AccountsPayableContactId" id="AccountsPayableContactId" class="form-control" [(ngModel)]="model.AccountsPayableContactId" required [disabled]="!access.save || !model.CustomerId" (ngModelChange)="onContactChange($event)" data-monitor-type="notify">
                                            <option *ngIf="!model.AccountsPayableContactId && contacts.length"></option>
                                            <option *ngFor="let contact of contacts" [ngValue]="contact.Pk.toString()">
                                                {{contact.FullName}}
                                            </option>
                                        </select>

                                        <validation-message [isValid]="accountsPayableContactRef.invalid && (accountsPayableContactRef.dirty || accountsPayableContactRef.touched)">
                                            Please select an Accounts Payable Contact
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="SalesContact">Sales Contact:</label>
                                        <input #salesContactRef="ngModel" name="SalesContact" id="SalesContact" type="text" class="form-control" [(ngModel)]="model.SalesContact" required [disabled]="!access.save && !access.salesContact" data-monitor-type="notify">

                                        <validation-message [isValid]="salesContactRef.invalid && (salesContactRef.dirty || salesContactRef.touched)">
                                            Please enter a Sales Contact
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="SalesContactNumber">Sales Contact Number:</label>
                                        <input #salesContactNumberRef="ngModel" name="SalesContactNumber" id="SalesContactNumber" type="text" class="form-control"  phoneNumberMask maxlength="14" [(ngModel)]="model.SalesContactNumber" required [disabled]="!access.save && !access.salesContactNumber" data-monitor-type="notify">

                                        <validation-message [isValid]="salesContactNumberRef.invalid && (salesContactNumberRef.dirty || salesContactNumberRef.touched)">
                                            Please enter a Sales Contact Number
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="ProbabilityPk">Probability:</label>
                                        <select #probabilityRef="ngModel" name="ProbabilityPk" id="ProbabilityPk" class="form-control" [(ngModel)]="model.ProbabilityPk" [disabled]="!access.save">
                                            <option *ngIf="!model.ProbabilityPk"></option>
                                            <option *ngFor="let item of lookupValues['SALES_ORDER_PROBABILITY']" [ngValue]="item.Pk">{{item.Description}}</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="CustomerEmail">Customer (Signer) Email Address:</label>
                                        <input #customerEmailRef="ngModel" name="CustomerEmail" id="CustomerEmail" type="text" class="form-control" [(ngModel)]="model.CustomerEmail" [disabled]="!access.save && !access.customerEmail" data-monitor-type="notify">
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="IsReplacingCompetitor">Replacing Competitor?</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.IsReplacingCompetitor" name="IsReplacingCompetitor" id="IsReplacingCompetitor" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="notify" (ngModelChange)="onReplacingCompetitorChange()">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" *ngIf="model.IsReplacingCompetitor">
                                        <label for="CompetitorPk">Competitor:</label>
                                        <select #competitorRef="ngModel" name="CompetitorPk" id="CompetitorPk" class="form-control" [(ngModel)]="model.CompetitorPk" required [disabled]="!access.save" data-monitor-type="notify">
                                            <option *ngIf="!model.CompetitorPk"></option>
                                            <option *ngFor="let item of lookupValues['SALES_ORDER_COMPETITOR']" [ngValue]="item.Pk">{{item.Description}}</option>
                                        </select>

                                        <validation-message [isValid]="competitorRef.invalid && (competitorRef.dirty || competitorRef.touched)">
                                            Please select a Competitor
                                        </validation-message>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="fas fa-map-marker-alt"></i> <span class="ml-2">Location</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="Location">Location Name:</label>
                                        <input #locationRef="ngModel" name="Location" id="Location" type="text" class="form-control" [(ngModel)]="model.Location" required [disabled]="!access.location" data-monitor-type="notify">

                                        <validation-message [isValid]="locationRef.invalid && (locationRef.dirty || locationRef.touched)">
                                            Please select a Location
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="AreaId">Kodiak Area:</label>
                                        <input #areaRef="ngModel" name="AreaId" id="AreaId" type="search" class="form-control" [(ngModel)]="typeahead['Area']" [typeahead]="areas" [typeaheadScrollable]="true" typeaheadOptionField="Name" (typeaheadOnSelect)="onAreaChange($event)" required [disabled]="!access.area" (ngModelChange)="onTypeaheadChange('Area')" data-monitor-type="notify" (blur)="validateTypeahead('Area')">

                                        <validation-message [isValid]="areaRef.invalid && (areaRef.dirty || areaRef.touched)">
                                            Please select a Kodiak Area
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="State">State:</label>
                                        <input #stateRef="ngModel" name="State" id="State" type="search" class="form-control" [(ngModel)]="typeahead['State']" [typeahead]="lookupValues['STATE']" [typeaheadScrollable]="true" (typeaheadOnSelect)="onStateChange($event)" typeaheadOptionField="Description" required [disabled]="!access.save" (ngModelChange)="onTypeaheadChange('State')" data-monitor-type="notify" (blur)="validateTypeahead('State')">

                                        <validation-message [isValid]="stateRef.invalid && (stateRef.dirty || stateRef.touched)">
                                            Please select a State
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="County">County/Parish:</label>
                                        <input #countyRef="ngModel" name="County" id="County" type="search" class="form-control" [(ngModel)]="typeahead['County']" [typeahead]="counties" [typeaheadScrollable]="true" (typeaheadOnSelect)="onCountyChange($event)" typeaheadOptionField="Name" required [disabled]="!access.save" (ngModelChange)="onTypeaheadChange('County')" data-monitor-type="notify" (blur)="validateTypeahead('County')">

                                        <validation-message [isValid]="countyRef.invalid && (countyRef.dirty || countyRef.touched)">
                                            Please select a County/Parish
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="IsInCityLimits">In City Limits?</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.IsInCityLimits" name="IsInCityLimits" id="IsInCityLimits" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="notify">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="fas fa-wrench"></i> <span class="ml-2">Job Specifics</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="RevenueType">Revenue Type:</label>
                                        <select #revenueTypeRef="ngModel" name="RevenueType" id="RevenueType" class="form-control" [(ngModel)]="model.RevenueType" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option *ngIf="!model.RevenueType"></option>
                                            <option *ngFor="let item of lookupValues['SALES_REVENUE_TYPE']">{{item.Description}}</option>
                                        </select>

                                        <validation-message [isValid]="revenueTypeRef.invalid && (revenueTypeRef.dirty || revenueTypeRef.touched)">
                                            Please select a Revenue Type
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" *ngIf="model.RevenueType == 'Contract Compression'">
                                        <label for="HasPurchaseOption">Purchase Option?</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.HasPurchaseOption" name="HasPurchaseOption" id="HasPurchaseOption" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="ApplicationTypePk">Application:</label>
                                        <select #applicationTypeRef="ngModel" name="ApplicationTypePk" id="ApplicationTypePk" class="form-control" [(ngModel)]="model.ApplicationTypePk" [disabled]="!access.save" data-monitor-type="workflow" required>
                                            <option *ngIf="!model.ApplicationType"></option>
                                            <option *ngFor="let item of lookupValues['SALES_APPLICATION_TYPE']" [ngValue]="item.Pk">{{item.Description}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Suction Pressure:</label>

                                        <div class="input-group">
                                            <input #suctionPressureMinRef="ngModel" [(ngModel)]="model.SuctionPressureMin" type="number" name="SuctionPressureMin" id="SuctionPressureMin" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Min</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="suctionPressureMinRef.invalid && (suctionPressureMinRef.dirty || suctionPressureMinRef.touched)">
                                            Please enter a Suction Pressure minimum
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label class="d-none d-sm-block">&nbsp;</label>

                                        <div class="input-group">
                                            <input #suctionPressureMaxRef="ngModel" [(ngModel)]="model.SuctionPressureMax" type="number" name="SuctionPressureMax" id="SuctionPressureMax" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Max</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="suctionPressureMaxRef.invalid && (suctionPressureMaxRef.dirty || suctionPressureMaxRef.touched)">
                                            Please enter a Suction Pressure maximum
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Discharge Pressure:</label>

                                        <div class="input-group">
                                            <input #dischargePressureMinRef="ngModel" [(ngModel)]="model.DischargePressureMin" type="number" name="DischargePressureMin" id="DischargePressureMin" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Min</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="dischargePressureMinRef.invalid && (dischargePressureMinRef.dirty || dischargePressureMinRef.touched)">
                                            Please enter a Discharge Pressure minimum
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label class="d-none d-sm-block">&nbsp;</label>

                                        <div class="input-group">
                                            <input #dischargePressureMaxRef="ngModel" [(ngModel)]="model.DischargePressureMax" type="number" name="DischargePressureMax" id="DischargePressureMax" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Max</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="dischargePressureMaxRef.invalid && (dischargePressureMaxRef.dirty || dischargePressureMaxRef.touched)">
                                            Please enter a Discharge Pressure maximum
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Suction Temperature:</label>

                                        <div class="input-group">
                                            <input #suctionTemperatureMinRef="ngModel" [(ngModel)]="model.SuctionTemperatureMin" type="number" name="SuctionTemperatureMin" id="SuctionTemperatureMin" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Min</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="suctionTemperatureMinRef.invalid && (suctionTemperatureMinRef.dirty || suctionTemperatureMinRef.touched)">
                                            Please enter a Suction Temperature minimum
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label class="d-none d-sm-block">&nbsp;</label>

                                        <div class="input-group">
                                            <input #suctionTemperatureMaxRef="ngModel" [(ngModel)]="model.SuctionTemperatureMax" type="number" name="SuctionTemperatureMax" id="SuctionTemperatureMax" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Max</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="suctionTemperatureMaxRef.invalid && (suctionTemperatureMaxRef.dirty || suctionTemperatureMaxRef.touched)">
                                            Please enter a Suction Temperature maximum
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="DischargeTemperature">Discharge Temperature:</label>
                                        <input #dischargeTemperatureRef="ngModel" [(ngModel)]="model.DischargeTemperature" type="number" name="DischargeTemperature" id="DischargeTemperature" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">

                                        <validation-message [isValid]="dischargeTemperatureRef.invalid && (dischargeTemperatureRef.dirty || dischargeTemperatureRef.touched)">
                                            Please enter a Discharge Temperature
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="SpecificGravity">Specific Gravity:</label>
                                        <input #specificGravityRef="ngModel" [(ngModel)]="model.SpecificGravity" type="number" name="SpecificGravity" id="SpecificGravity" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">

                                        <validation-message [isValid]="specificGravityRef.invalid && (specificGravityRef.dirty || specificGravityRef.touched)">
                                            Please enter a Specific Gravity
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Required Flow:</label>

                                        <div class="input-group">
                                            <input #requiredFlowMinRef="ngModel" [(ngModel)]="model.RequiredFlowMin" type="number" name="RequiredFlowMin" id="RequiredFlowMin" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Min</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="requiredFlowMinRef.invalid && (requiredFlowMinRef.dirty || requiredFlowMinRef.touched)">
                                            Please enter a Required Flow minimum
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label class="d-none d-sm-block">&nbsp;</label>

                                        <div class="input-group">
                                            <input #requiredFlowMaxRef="ngModel" [(ngModel)]="model.RequiredFlowMax" type="number" name="RequiredFlowMax" id="RequiredFlowMax" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Max</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="requiredFlowMaxRef.invalid && (requiredFlowMaxRef.dirty || requiredFlowMaxRef.touched)">
                                            Please enter a Required Flow maximum
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="Elevation">Elevation:</label>
                                        <input #elevationRef="ngModel" [(ngModel)]="model.Elevation" type="number" name="Elevation" id="Elevation" class="form-control" required numbersOnly [allowSign]="true" [disabled]="!access.save" data-monitor-type="workflow">

                                        <validation-message [isValid]="elevationRef.invalid && (elevationRef.dirty || elevationRef.touched)">
                                            Please enter an Elevation
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="IsPartialNace">Unit Partial NACE for Sour Gas:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.IsPartialNace" name="IsPartialNace" id="IsPartialNace" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="far fa-clock"></i> <span class="ml-2">Installation/Operations</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="EstimatedDate">Estimated Installation Date:</label>
                                        <p-calendar #estimatedDateRef="ngModel" name="EstimatedDate" id="EstimatedDate" [(ngModel)]="model.EstimatedDate" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true" [disabled]="!access.installDate"></p-calendar>

                                        <validation-message [isValid]="estimatedDateRef.invalid && (estimatedDateRef.dirty || estimatedDateRef.touched)">
                                            Please enter an Estimated Installation Date
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallTruckSetup">Trucks Set Up:</label>
                                        <select #installTruckSetupRef="ngModel" name="InstallTruckSetup" id="InstallTruckSetup" class="form-control" [(ngModel)]="model.InstallTruckSetup" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="installTruckSetupRef.invalid && (installTruckSetupRef.dirty || installTruckSetupRef.touched)">
                                            Please select a Trucks/Cranes Set Up option
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallCraneSetup">Cranes Set Up:</label>
                                        <select #installCraneSetupRef="ngModel" name="InstallCraneSetup" id="InstallCraneSetup" class="form-control" [(ngModel)]="model.InstallCraneSetup" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="installCraneSetupRef.invalid && (installCraneSetupRef.dirty || installCraneSetupRef.touched)">
                                            Please select a Trucks/Cranes Set Up option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallTransportation">Transportation In Paid By:</label>
                                        <select #installTransportationRef="ngModel" name="InstallTransportation" id="InstallTransportation" class="form-control" [(ngModel)]="model.InstallTransportation" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                            <option>Previous Customer</option>
                                            <option>Shared Billing</option>
                                        </select>

                                        <validation-message [isValid]="installTransportationRef.invalid && (installTransportationRef.dirty || installTransportationRef.touched)">
                                            Please select a Transportation In Paid By option
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" *ngIf="model.InstallTransportation == 'Shared Billing'">
                                        <label for="InstallTransportationSplit">Split:</label>
                                        <select #installTransportationSplitRef="ngModel" name="InstallTransportationSplit" id="InstallTransportationSplit" class="form-control" [(ngModel)]="model.InstallTransportationSplit" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>25%</option>
                                            <option>50%</option>
                                            <option>75%</option>
                                            <option>100%</option>
                                        </select>

                                        <validation-message [isValid]="installTransportationSplitRef.invalid && (installTransportationSplitRef.dirty || installTransportationSplitRef.touched)">
                                            Please select a Transportation In Split option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallTransportationOut">Transportation Out Paid By:</label>
                                        <select #installTransportationOutRef="ngModel" name="InstallTransportationOut" id="InstallTransportationOut" class="form-control" [(ngModel)]="model.InstallTransportationOut" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                            <option>Previous Customer</option>
                                            <option>Shared Billing</option>
                                        </select>

                                        <validation-message [isValid]="installTransportationOutRef.invalid && (installTransportationOutRef.dirty || installTransportationOutRef.touched)">
                                            Please select a Transportation Out Paid By option
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" *ngIf="model.InstallTransportationOut == 'Shared Billing'">
                                        <label for="InstallTransportationOutSplit">Split:</label>
                                        <select #installTransportationOutSplitRef="ngModel" name="InstallTransportationOutSplit" id="InstallTransportationOutSplit" class="form-control" [(ngModel)]="model.InstallTransportationOutSplit" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>25%</option>
                                            <option>50%</option>
                                            <option>75%</option>
                                            <option>100%</option>
                                        </select>

                                        <validation-message [isValid]="installTransportationOutSplitRef.invalid && (installTransportationOutSplitRef.dirty || installTransportationOutSplitRef.touched)">
                                            Please select a Transportation Out Split option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallCranes">Cranes Paid By:</label>
                                        <select #installCranesRef="ngModel" name="InstallCranes" id="InstallCranes" class="form-control" [(ngModel)]="model.InstallCranes" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                            <option>Previous Customer</option>
                                            <option>Shared Billing</option>
                                        </select>

                                        <validation-message [isValid]="installCranesRef.invalid && (installCranesRef.dirty || installCranesRef.touched)">
                                            Please select a Cranes option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="KodiakFirstCall">First Call by Kodiak?</label>
                                        <select #kodiakFirstCallRef="ngModel" name="KodiakFirstCall" id="KodiakFirstCall" class="form-control" [(ngModel)]="model.KodiakFirstCall" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>Yes</option>
                                            <option>No</option>
                                            <option>Special Provisions</option>
                                        </select>

                                        <validation-message [isValid]="kodiakFirstCallRef.invalid && (kodiakFirstCallRef.dirty || kodiakFirstCallRef.touched)">
                                            Please select First Call by Kodiak
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.KodiakFirstCall == 'Special Provisions'">
                                    <div class="form-group col-sm-12 col-md-8">
                                        <label for="CallNotes">Call Notes:</label>
                                        <textarea rows="3" [(ngModel)]="model.CallNotes" name="CallNotes" id="CallNotes" class="form-control" [disabled]="!access.save" data-monitor-type="workflow"></textarea>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="CatalystProvider">Catalyst Provided By:</label>
                                        <select #catalystProviderRef="ngModel" name="CatalystProvider" id="CatalystProvider" class="form-control" [(ngModel)]="model.CatalystProvider" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="catalystProviderRef.invalid && (catalystProviderRef.dirty || catalystProviderRef.touched)">
                                            Please select a Catalyst Provider
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="IsKodiakFirstCatalyst">Kodiak Provides 1st Catalyst:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                            <input [(ngModel)]="model.IsKodiakFirstCatalyst" name="IsKodiakFirstCatalyst" id="IsKodiakFirstCatalyst" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasBillBackBadFuel">Bill Back for Bad Fuel:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                            <input [(ngModel)]="model.HasBillBackBadFuel" name="HasBillBackBadFuel" id="HasBillBackBadFuel" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasBillBackInstall">Bill Back for Install:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                            <input [(ngModel)]="model.HasBillBackInstall" name="HasBillBackInstall" id="HasBillBackInstall" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasBillBackParts">Bill Back for Parts:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                            <input [(ngModel)]="model.HasBillBackParts" name="HasBillBackParts" id="HasBillBackParts" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="LiquidsProvider">Liquids Provided By:</label>
                                        <select #liquidsProviderRef="ngModel" name="LiquidsProvider" id="LiquidsProvider" class="form-control" [(ngModel)]="model.LiquidsProvider" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="liquidsProviderRef.invalid && (liquidsProviderRef.dirty || liquidsProviderRef.touched)">
                                            Please select a Liquids Provider
                                        </validation-message>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="fas fa-leaf fa-flip-horizontal"></i> <span class="ml-2">ecoView</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasEcoView">ecoView:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                            <input [(ngModel)]="model.HasEcoView" name="HasEcoView" id="HasEcoView" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="EcoViewPackagePk">Package:</label>
                                        <select #ecoViewPackageRef="ngModel" name="EcoViewPackagePk" id="EcoViewPackagePk" class="form-control" [(ngModel)]="model.EcoViewPackagePk" [disabled]="!access.save" required data-monitor-type="workflow" (ngModelChange)="onEcoViewPackageSelect()">
                                            <option *ngIf="!model.EcoViewPackagePk"></option>
                                            <option *ngFor="let item of lookupValues['SALES_ECOVIEW_PACKAGE']" [ngValue]="item.Pk">{{item.Description}}</option>
                                        </select>

                                        <validation-message [isValid]="ecoViewPackageRef.invalid && (ecoViewPackageRef.dirty || ecoViewPackageRef.touched)">
                                            Please select a Package for ecoView
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="EcoViewPackageRate">Monthly Rate:</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input #ecoViewPackageRateRef="ngModel" [(ngModel)]="model.EcoViewPackageRate" type="number" name="EcoViewPackageRate" id="EcoViewPackageRate" class="form-control" required numbersOnly [disabled]="!access.save" data-monitor-type="workflow" (ngModelChange)="calculateEcoViewDailyRate()">
                                        </div>

                                        <validation-message [isValid]="ecoViewPackageRateRef.invalid && (ecoViewPackageRateRef.dirty || ecoViewPackageRateRef.touched)">
                                            Please specify a Monthly Rate for ecoView
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView">
                                    <div class="form-group col-sm-6 col-md-4" *ngIf="model.HasEcoView">
                                        <label for="EcoViewTermInMonths">Term in Months:</label>
                                        <input #ecoViewTermInMonthsRef="ngModel" [(ngModel)]="model.EcoViewTermInMonths" type="number" name="EcoViewTermInMonths" id="EcoViewTermInMonths" class="form-control" required numbersOnly [disabled]="!access.save" data-monitor-type="workflow">

                                        <validation-message [isValid]="ecoViewTermInMonthsRef.invalid && (ecoViewTermInMonthsRef.dirty || ecoViewTermInMonthsRef.touched)">
                                            Please specify the Term in Months for ecoView
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasEcoViewInstall">Has installation charge for ecoView?</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                            <input [(ngModel)]="model.HasEcoViewInstall" name="HasEcoViewInstall" id="HasEcoViewInstall" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow" (ngModelChange)="onEcoViewInstallToggle()">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView && model.HasEcoViewInstall">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="EcoViewInstallCharge">Installation Charge:</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input #ecoViewInstallChargeRef="ngModel" [(ngModel)]="model.EcoViewInstallCharge" type="number" name="EcoViewInstallCharge" id="EcoViewInstallCharge" class="form-control" required numbersOnly [disabled]="!access.save" data-monitor-type="workflow">
                                        </div>

                                        <validation-message [isValid]="ecoViewInstallChargeRef.invalid && (ecoViewInstallChargeRef.dirty || ecoViewInstallChargeRef.touched)">
                                            Please specify an Installation Charge for ecoView
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="EcoViewInstallDate">Installation Date:</label>
                                        <p-calendar #ecoViewInstallDateRef="ngModel" name="EcoViewInstallDate" id="EcoViewInstallDate" [(ngModel)]="model.EcoViewInstallDate" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true" [disabled]="!access.save" data-monitor-type="workflow"></p-calendar>

                                        <validation-message [isValid]="ecoViewInstallDateRef.invalid && (ecoViewInstallDateRef.dirty || ecoViewInstallDateRef.touched)">
                                            Please enter an Installation Date for ecoView
                                        </validation-message>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="fas fa-file-alt"></i> <span class="ml-2">Contract Info</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="MonthlyRate">Monthly Rate:</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input #monthlyRateRef="ngModel" [(ngModel)]="model.MonthlyRate" type="number" name="MonthlyRate" id="MonthlyRate" class="form-control" required numbersOnly (ngModelChange)="calculateDailyRate()" [disabled]="!access.monthlyRate" data-monitor-type="workflow">
                                        </div>

                                        <validation-message [isValid]="monthlyRateRef.invalid && (monthlyRateRef.dirty || monthlyRateRef.touched)">
                                            Please specify a Monthly Rate
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="Term">Terms:</label>
                                        <select #termRef="ngModel" name="Term" id="Term" class="form-control" [(ngModel)]="model.Term" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option *ngIf="!model.Term"></option>
                                            <option value="monthly">Month to Month</option>
                                            <option value="contract">Contract</option>
                                        </select>

                                        <validation-message [isValid]="termRef.invalid && (termRef.dirty || termRef.touched)">
                                            Please specify a Term
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.Term == 'contract'">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="TermInMonths">Term in Months:</label>
                                        <input #termInMonthsRef="ngModel" [(ngModel)]="model.TermInMonths" type="number" name="TermInMonths" id="TermInMonths" class="form-control" required numbersOnly [disabled]="!access.termInMonths" data-monitor-type="workflow">

                                        <validation-message [isValid]="termInMonthsRef.invalid && (termInMonthsRef.dirty || termInMonthsRef.touched)">
                                            Please specify a Term in Months
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="ContractStatus">Contract Executed:</label>
                                        <select #contractStatusRef="ngModel" name="ContractStatus" id="ContractStatus" class="form-control" [(ngModel)]="model.ContractStatus" required [disabled]="!access.save && !access.contractStatus">
                                            <option *ngIf="!model.ContractStatus"></option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            <option value="Pending">Pending - awaiting customer signature</option>
                                        </select>

                                        <validation-message [isValid]="contractStatusRef.invalid && (contractStatusRef.dirty || contractStatusRef.touched)">
                                            Please specify a Contract Executed option
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="AdValBy">Ad Val By:</label>
                                        <select #adValByRef="ngModel" name="AdValBy" id="AdValBy" class="form-control" [(ngModel)]="model.AdValBy" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option *ngIf="!model.AdValBy"></option>
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="adValByRef.invalid && (adValByRef.dirty || adValByRef.touched)">
                                            Please select an Ad Val By option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="StandbyRate">Standby Rate:</label>
                                        <div class="input-group">
                                            <input #standbyRateRef="ngModel" [(ngModel)]="model.StandbyRate" type="number" name="StandbyRate" id="StandbyRate" class="form-control" required numbersOnly (ngModelChange)="calculateStandbyRateAmount()" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="standbyRateRef.invalid && (standbyRateRef.dirty || standbyRateRef.touched)">
                                            Please specify a Standby Rate
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="StandbyRateAmount">Standby Rate ($):</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input [(ngModel)]="model.StandbyRateAmount" type="number" name="StandbyRateAmount" id="StandbyRateAmount" class="form-control" readonly>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="PackagingStandbyRate">Standby Rate (at packaging facility):</label>
                                        <div class="input-group">
                                            <input [(ngModel)]="model.PackagingStandbyRate" type="number" name="PackagingStandbyRate" id="PackagingStandbyRate" class="form-control" numbersOnly (ngModelChange)="calculatePackagingStandbyRateAmount()" [disabled]="!access.save" data-monitor-type="workflow">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="PackagingStandbyRateAmount">Standby Rate $ (at packaging facility):</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input [(ngModel)]="model.PackagingStandbyRateAmount" type="number" name="PackagingStandbyRateAmount" id="PackagingStandbyRateAmount" class="form-control" readonly>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasEscalationClause">Escalation Clause:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.HasEscalationClause" [disabled]="!access.save && !access.contractStatus" name="HasEscalationClause" id="HasEscalationClause" class="switch-input" type="checkbox" >
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="IsPerformanceBased">Performance Based:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.IsPerformanceBased" name="IsPerformanceBased" id="IsPerformanceBased" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.IsPerformanceBased">
                                    <div class="form-group col-sm-12 col-md-8">
                                        <label for="PerformanceBasedNotes">Performance Based Notes:</label>
                                        <textarea #performanceBasedNotesRef="ngModel" rows="3" [(ngModel)]="model.PerformanceBasedNotes" name="PerformanceBasedNotes" id="PerformanceBasedNotes" class="form-control" required [disabled]="!access.save && !access.performanceBasedNotes" data-monitor-type="workflow"></textarea>

                                        <validation-message [isValid]="performanceBasedNotesRef.invalid && (performanceBasedNotesRef.dirty || performanceBasedNotesRef.touched)">
                                            Please enter Performance Based Notes
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasElectricMotor">Electric Motor:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.HasElectricMotor" name="HasElectricMotor" id="HasElectricMotor" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasElectricMotor">
                                    <div class="form-group col-sm-12 col-md-8">
                                        <label for="ElectricMotorNotes">Electric Motor Notes:</label>
                                        <textarea #electricMotorNotesRef="ngModel" rows="3" [(ngModel)]="model.ElectricMotorNotes" name="ElectricMotorNotes" id="ElectricMotorNotes" class="form-control" required [disabled]="!access.save" data-monitor-type="workflow"></textarea>

                                        <validation-message [isValid]="electricMotorNotesRef.invalid && (electricMotorNotesRef.dirty || electricMotorNotesRef.touched)">
                                            Please enter Electric Motor Notes
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasPriceIndexAdjustment">PPI Adjustment:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.HasPriceIndexAdjustment" name="HasPriceIndexAdjustment" id="HasPriceIndexAdjustment" class="switch-input" type="checkbox" [disabled]="!access.save" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-12 col-md-8">
                                        <label for="ContractNotes">Special Provisions:</label>
                                        <textarea #contractNotesRef="ngModel" rows="3" [(ngModel)]="model.ContractNotes" name="ContractNotes" id="ContractNotes" class="form-control" required [disabled]="!access.save" data-monitor-type="workflow"></textarea>

                                        <validation-message [isValid]="contractNotesRef.invalid && (contractNotesRef.dirty || contractNotesRef.touched)">
                                            Please enter Special Provisions
                                        </validation-message>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="fas fa-paperclip"></i> <span class="ml-2">Attachments</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-12 col-md-10">
                                        <spark-file-list [model]="this.model" [readOnly]="!access.save && !access.createContract" [sparkModuleType]="sparkModuleType"></spark-file-list>
                                    </div>
                                </div>
                            </tab>

                            <tab *ngIf="['completed', 'closed'].indexOf(model.Status.toLowerCase()) >= 0">
                                <ng-template tabHeading>
                                    <i class="fas fa-file-invoice"></i> <span class="ml-2">IIs</span>
                                </ng-template>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="table-responsive">
                                            <table class="table table-striped stack" sort sort-active="Pk" (sortChange)="onSort($event)">
                                                <thead>
                                                    <tr>
                                                        <th sort-header="Status">Status</th>
                                                        <th sort-header="Pk" sort-order="desc">II ID</th>
                                                        <th sort-header="Type">Type</th>
                                                        <th>Amendment Type</th>
                                                        <th sort-header="CustomerName">Customer</th>
                                                        <th sort-header="Location">Location</th>
                                                        <th sort-header="County">County</th>
                                                        <th sort-header="State">State</th>
                                                        <th sort-header="Area">Area</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of sortedInvoicingInstructions" (click)="onOpenDetail(item.Pk)" class="pointer">
                                                        <td><span class="d-inline d-md-none">Status:</span> {{item.Status | titlecase}}</td>
                                                        <td><span class="d-inline d-md-none">II ID</span> {{item.Pk}}</td>
                                                        <td><span class="d-inline d-md-none">Type</span> {{item.DisplayType | splitTitleCase}}</td>
                                                        <td><span class="d-inline d-md-none">Amendment Type</span> {{item.getAmendmentTypes()}}</td>
                                                        <td><span class="d-inline d-md-none">Customer</span> {{item.CustomerName}}</td>
                                                        <td><span class="d-inline d-md-none">Location</span> {{item.Location}}</td>
                                                        <td><span class="d-inline d-md-none">County</span> {{item.County}}</td>
                                                        <td><span class="d-inline d-md-none">State</span> {{item.State}}</td>
                                                        <td><span class="d-inline d-md-none">Area</span> {{item.AreaId}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </tab>
                        </tabset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="app-footer d-md-none d-lg-none" *ngIf="!preview">
    <div>
        <button class="btn btn-success" (click)="onSave()" [disabled]="!canSave()">
            <i class="fas fa-save"></i> <span class="ml-2">Save</span>
        </button>

        <button class="btn btn-secondary float-right" (click)="onBack()">
            <i class="fas fa-undo-alt"></i> <span class="ml-2">Back</span>
        </button>
    </div>
</footer>
