import { ApplicationUserModel } from './application.model';
import { ContactModel } from './contact.model';
import { LookupValueModel } from './lookup-value.model';
import { SparkFileModel } from './spark-file.model';
import { WorkflowModuleModel } from './workflow.model';

import * as moment from 'moment';

export class SafetyIncidentModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Status: string = 'NEW';
    CloseTypePk: number = null;
    IncidentDate: Date = new Date();
    ApplicationUserPk: number;
    Area: string;
    SupervisorPk: number = null;
    WasOnCustomerLocation: boolean;
    CustomerId: string;
    CustomerName: string;
    CustomerContact: string;
    LocationId: string;
    LocationName: string;
    NearestTown: string;
    StatePk: number = null;
    AssetId: string;
    AssetName: string;
    WasVehicleInvolved: boolean;
    HasMultipleVehicles: boolean;
    HasPropertyDamage: boolean;
    PropertyDamageOwnerPk: number = null;
    PropertyDamageDescription: string;
    PropertyDamageSeverityPk: number = null;
    PropertyDamageTotalCost: number;
    WasSpillIncident: boolean;
    SpillSeverityPk: number = null;
    SpillPropertyOwnerPk: number = null;
    SpillDescription: string;
    SpillMaterial: string;
    SpillInsideSecondaryContainment: boolean;
    SpillInsideTotalBarrels: number;
    SpillOutsideSecondaryContainment: boolean;
    SpillOutsideTotalBarrels: number;
    WasEmployeeInjured: boolean;
    WasFireIncident: boolean;
    WasUnitFire: boolean;
    FireTotalCost: number;
    FireCausePk: number;
    WasCustomerNotified: boolean;
    CustomerPhoneNumber: string;
    CustomerNotNotifiedReason: string;
    HoursWorkedPreviousPeriod: number;
    HoursWorkedPreviousDays: number;
    DaysSinceUninterruptedSleep: number;
    Description: string;
    WasDrugsScreened: boolean;
    ScreeningDate: Date;
    NoDrugScreenReason: string;
    HasWitness: boolean;
    Preparer: string;
    FireCause: LookupValueModel;
    CloseType: LookupValueModel;
    ApplicationUser: ApplicationUserModel;
    Supervisor: ApplicationUserModel;
    State: LookupValueModel;
    PropertyDamageOwner: LookupValueModel;
    PropertyDamageSeverity: LookupValueModel;
    SpillSeverity: LookupValueModel;
    SpillPropertyOwner: LookupValueModel;
    Files: SparkFileModel[] = new Array();
    Vehicle: SafetyIncidentVehicleModel;
    OtherVehicles: SafetyIncidentOtherVehicleModel[] = new Array();
    BodyInjury: SafetyIncidentBodyInjuryModel;
    InjuryCause:string;
    Witnesses: SafetyIncidentWitnessModel[] = new Array();
    Investigation: SafetyIncidentInvestigationModel;
    CorrectiveActions: SafetyCorrectiveActionModel[] = new Array();
    CanApprove: boolean;
    WorkflowId: string = 'SI';
    Workflows: WorkflowModuleModel[] = new Array();
    ExperienceLevel: string;
    LCR: string;
    IncidentType: string;
    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.Status = obj.Status;
        this.CloseTypePk = obj.CloseTypePk;

        if (obj.IncidentDate) {
            this.IncidentDate = moment(obj.IncidentDate).toDate();
        }
      

        this.ApplicationUserPk = obj.ApplicationUserPk;
        this.Area = obj.Area;
        this.SupervisorPk = obj.SupervisorPk;
        this.WasOnCustomerLocation = obj.WasOnCustomerLocation;
        this.CustomerId = obj.CustomerId;
        this.CustomerName = obj.CustomerName;
        this.CustomerContact = obj.CustomerContact;
        this.LocationId = obj.LocationId;
        this.LocationName = obj.LocationName;
        this.NearestTown = obj.NearestTown;
        this.StatePk = obj.StatePk;
        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.WasVehicleInvolved = obj.WasVehicleInvolved;
        this.HasMultipleVehicles = obj.HasMultipleVehicles;
        this.HasPropertyDamage = obj.HasPropertyDamage;
        this.PropertyDamageOwnerPk = obj.PropertyDamageOwnerPk;
        this.PropertyDamageDescription = obj.PropertyDamageDescription;
        this.PropertyDamageSeverityPk = obj.PropertyDamageSeverityPk;
        this.PropertyDamageTotalCost = obj.PropertyDamageTotalCost;
        this.WasSpillIncident = obj.WasSpillIncident;
        this.SpillSeverityPk = obj.SpillSeverityPk;
        this.SpillPropertyOwnerPk = obj.SpillPropertyOwnerPk;
        this.SpillDescription = obj.SpillDescription;
        this.SpillMaterial = obj.SpillMaterial;
        this.SpillInsideSecondaryContainment = obj.SpillInsideSecondaryContainment;
        this.SpillInsideTotalBarrels = obj.SpillInsideTotalBarrels;
        this.SpillOutsideSecondaryContainment = obj.SpillOutsideSecondaryContainment;
        this.SpillOutsideTotalBarrels = obj.SpillOutsideTotalBarrels;
        this.WasEmployeeInjured = obj.WasEmployeeInjured;
        this.WasFireIncident = obj.WasFireIncident;
        this.WasUnitFire = obj.WasUnitFire;
        this.FireTotalCost = obj.FireTotalCost;
        this.FireCausePk = obj.FireCausePk;
        this.WasCustomerNotified = obj.WasCustomerNotified;
        this.CustomerPhoneNumber = obj.CustomerPhoneNumber;
        this.CustomerNotNotifiedReason = obj.CustomerNotNotifiedReason;
        this.HoursWorkedPreviousPeriod = obj.HoursWorkedPreviousPeriod;
        this.HoursWorkedPreviousDays = obj.HoursWorkedPreviousDays;
        this.DaysSinceUninterruptedSleep = obj.DaysSinceUninterruptedSleep;
        this.Description = obj.Description;
        this.WasDrugsScreened = obj.WasDrugsScreened;
        this.InjuryCause = obj.InjuryCause;
        this.ExperienceLevel = obj.ExperienceLevel;
        this.LCR = obj.LCR;
        this.IncidentType = obj.IncidentType;
        if (obj.ScreeningDate) {
            this.ScreeningDate = moment(obj.ScreeningDate).toDate();
        }

        this.NoDrugScreenReason = obj.NoDrugScreenReason;
        this.HasWitness = obj.HasWitness;
        this.Preparer = obj.Preparer;

        if (obj.FireCause) {
            this.FireCause = new LookupValueModel(obj.FireCause);
        }

        if (obj.CloseType) {
            this.CloseType = new LookupValueModel(obj.CloseType);
        }

        if (obj.ApplicationUser) {
            this.ApplicationUser = new ApplicationUserModel(obj.ApplicationUser);
        }

        if (obj.Supervisor) {
            this.Supervisor = new ApplicationUserModel(obj.Supervisor);
        }

        if (obj.State) {
            this.State = new LookupValueModel(obj.State);
        }

        if (obj.PropertyDamageOwner) {
            this.PropertyDamageOwner = new LookupValueModel(obj.PropertyDamageOwner);
        }

        if (obj.PropertyDamageSeverity) {
            this.PropertyDamageSeverity = new LookupValueModel(obj.PropertyDamageSeverity);
        }

        if (obj.SpillSeverity) {
            this.SpillSeverity = new LookupValueModel(obj.SpillSeverity);
        }

        if (obj.SpillPropertyOwner) {
            this.SpillPropertyOwner = new LookupValueModel(obj.SpillPropertyOwner);
        }

        if (obj.Files) {
            this.Files = obj.Files.map(x => new SparkFileModel(x));
        }

        if (obj.Vehicle) {
            this.Vehicle = new SafetyIncidentVehicleModel(obj.Vehicle);
        }

        if (obj.OtherVehicles) {
            this.OtherVehicles = obj.OtherVehicles.map(x => new SafetyIncidentOtherVehicleModel(x));
        }

        if (obj.BodyInjury) {
            this.BodyInjury = new SafetyIncidentBodyInjuryModel(obj.BodyInjury);
        }

        if (obj.Witnesses) {
            this.Witnesses = obj.Witnesses.map(x => new SafetyIncidentWitnessModel(x));
        }

        if (obj.Investigation) {
            this.Investigation = new SafetyIncidentInvestigationModel(obj.Investigation);
            this.CloseTypePk = obj.CloseTypePk || 0;
        }

        if (obj.CorrectiveActions) {
            this.CorrectiveActions = obj.CorrectiveActions.map(x => new SafetyCorrectiveActionModel(x));
        }

        this.CanApprove = obj.CanApprove;
        this.WorkflowId = obj.WorkflowId;

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }
    }

    getAmendedStatus() {
        if (this.Status.toLowerCase() == 'closed' && this.CloseType && this.CloseType.Name.toLowerCase() != 'none') {
            return ' - ' + this.CloseType.Name;
        }

        return '';
    }
}

export class SafetyIncidentVehicleModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    SafetyIncidentPk: number;
    VehicleTypePk: number = null;
    Year: number;
    Make: string;
    Model: string;
    Color: string;
    LicensePlate: string;
    IsPreventable: boolean;
    PropertyDamageCost: number;
    WasDrivingToFromHome: boolean;
    WasDuringWorkHours: boolean;
    WasOnCall: boolean;
    WasCitationIssued: boolean;
    CitationDescription: string;
    WasTowed: boolean;
    TimeOfDayPk: number = null;
    CollisionDetails: string;
    VehicleType: LookupValueModel;
    TimeOfDay: LookupValueModel;
    EnvironmentDetails: SafetyIncidentVehicleEnvironmentModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.SafetyIncidentPk = obj.SafetyIncidentPk;
        this.VehicleTypePk = obj.VehicleTypePk;
        this.Year = obj.Year;
        this.Make = obj.Make;
        this.Model = obj.Model;
        this.Color = obj.Color;
        this.LicensePlate = obj.LicensePlate;
        this.IsPreventable = obj.IsPreventable;
        this.PropertyDamageCost = obj.PropertyDamageCost;
        this.WasDrivingToFromHome = obj.WasDrivingToFromHome;
        this.WasDuringWorkHours = obj.WasDuringWorkHours;
        this.WasOnCall = obj.WasOnCall;
        this.WasCitationIssued = obj.WasCitationIssued;
        this.CitationDescription = obj.CitationDescription;
        this.WasTowed = obj.WasTowed;
        this.TimeOfDayPk = obj.TimeOfDayPk;
        this.CollisionDetails = obj.CollisionDetails;
        this.VehicleType = obj.VehicleType;
        this.TimeOfDay = obj.TimeOfDay;

        if (obj.EnvironmentDetails) {
            this.EnvironmentDetails = obj.EnvironmentDetails.map(x => new SafetyIncidentVehicleEnvironmentModel(x));
        }
    }
}

export class SafetyIncidentVehicleEnvironmentModel {
    Pk: number;
    SafetyIncidentVehiclePk: number;
    LookupValuePk: number;
    Type: string;
    Detail: LookupValueModel;
    CreationDate: Date;
    CreatedBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.SafetyIncidentVehiclePk = obj.SafetyIncidentVehiclePk;
        this.LookupValuePk = obj.LookupValuePk;
        this.Type = obj.Type;

        if (obj.Detail) {
            this.Detail = new LookupValueModel(obj.Detail);
        }

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;
    }
}

export class SafetyIncidentWitnessModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    SafetyIncidentPk: number;
    ContactPk: number;
    Employer: string;
    IncidentDate: Date;
    Description: string;
    Contact: ContactModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.SafetyIncidentPk = obj.SafetyIncidentPk;
        this.ContactPk = obj.ContactPk;
        this.Employer = obj.Employer;

        if (obj.IncidentDate) {
            this.IncidentDate = moment(obj.IncidentDate).toDate();
        }

        this.Description = obj.Description;

        if (obj.Contact) {
            this.Contact = new ContactModel(obj.Contact);
        }
    }
}

export class SafetyIncidentInvestigationModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    SafetyIncidentPk: number;
    RiskRankingPk: number = null;
    IsRecordable: boolean;
    WasFatality: boolean;
    WasSupervisorNotified: boolean;
    SupervisorNotNotifiedReason: string;
    WasReportCompleted: boolean;
    ReportNotCompletedReason: string;
    WasCustomerNotified: boolean;
    CustomerNotNotifiedReason: string;
    WasWitnessCompleted: boolean;
    WitnessNotCompletedReason: string;
    WasFollowUp: boolean;
    NoFollowUpReason: string;
    WasSafetyNotified: boolean;
    WasReportFiled: boolean;
    Notes: string;
    RiskSeverityActual: number;
    RiskSeverityPotential: number;
    RiskLikelihoodActual: number;
    RiskRanking: LookupValueModel;
    RecordableInjuryType: LookupValueModel;
    Types: SafetyIncidentInvestigationTypeModel[] = new Array();
    RecordableInjuries: SafetyIncidentInvestigationInjuryModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.SafetyIncidentPk = obj.SafetyIncidentPk;
        this.RiskRankingPk = obj.RiskRankingPk;
        this.IsRecordable = obj.IsRecordable;
        this.WasFatality = obj.WasFatality;
        this.WasSupervisorNotified = obj.WasSupervisorNotified;
        this.SupervisorNotNotifiedReason = obj.SupervisorNotNotifiedReason;
        this.WasReportCompleted = obj.WasReportCompleted;
        this.ReportNotCompletedReason = obj.ReportNotCompletedReason;
        this.WasCustomerNotified = obj.WasCustomerNotified;
        this.CustomerNotNotifiedReason = obj.CustomerNotNotifiedReason;
        this.WasWitnessCompleted = obj.WasWitnessCompleted;
        this.WitnessNotCompletedReason = obj.WitnessNotCompletedReason;
        this.WasFollowUp = obj.WasFollowUp;
        this.NoFollowUpReason = obj.NoFollowUpReason;
        this.WasSafetyNotified = obj.WasSafetyNotified;
        this.WasReportFiled = obj.WasReportFiled;
        this.Notes = obj.Notes;
        this.RiskSeverityActual = obj.RiskSeverityActual;
        this.RiskSeverityPotential = obj.RiskSeverityPotential;
        this.RiskLikelihoodActual = obj.RiskLikelihoodActual;

        if (obj.RiskRanking) {
            this.RiskRanking = new LookupValueModel(obj.RiskRanking);
        }

        if (obj.RecordableInjuryType) {
            this.RecordableInjuryType = new LookupValueModel(obj.RecordableInjuryType);
        }

        if (obj.Types) {
            this.Types = obj.Types.map(x => new SafetyIncidentInvestigationTypeModel(x));
        }

        if (obj.RecordableInjuries) {
            this.RecordableInjuries = obj.RecordableInjuries.map(x => new SafetyIncidentInvestigationInjuryModel(x));
        }
    }
}

export class SafetyIncidentOtherVehicleModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    SafetyIncidentPk: number;
    ContactPk: number;
    LicenseStatePk: number = null;
    LicenseNumber: string;
    Insurance: string;
    InsurancePhone: string;
    InsurancePolicyNumber: string;
    Year: number;
    Make: string;
    Model: string;
    Color: string;
    LicensePlate: string;
    Contact: ContactModel;
    LicenseState: LookupValueModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.SafetyIncidentPk = obj.SafetyIncidentPk;
        this.ContactPk = obj.ContactPk;
        this.LicenseStatePk = obj.LicenseStatePk;
        this.LicenseNumber = obj.LicenseNumber;
        this.Insurance = obj.Insurance;
        this.InsurancePhone = obj.InsurancePhone;
        this.InsurancePolicyNumber = obj.InsurancePolicyNumber;
        this.Year = obj.Year;
        this.Make = obj.Make;
        this.Model = obj.Model;
        this.Color = obj.Color;
        this.LicensePlate = obj.LicensePlate;

        if (obj.Contact) {
            this.Contact = new ContactModel(obj.Contact);
        }

        if (obj.LicenseState) {
            this.LicenseState = new LookupValueModel(obj.LicenseState);
        }
    }
}

export class SafetyIncidentBodyInjuryModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    SafetyIncidentPk: number;
    WasPreferedClinicContacted: boolean;
    NotContactedReason: string;
    SeverityPk: number = null;
    TreatmentPk: number = null;
    PhysicianName: string;
    PhysicianPhone: string;
    ClinicHospitalName: string;
    Address: string;
    Diagnosis: string;
    OtherBodyPart: string;
    BodyParts: SafetyIncidentBodyPartModel[] = new Array();
    Severity: LookupValueModel;
    Treatment: LookupValueModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
        this.SafetyIncidentPk = obj.SafetyIncidentPk;
        this.WasPreferedClinicContacted = obj.WasPreferedClinicContacted;
        this.NotContactedReason = obj.NotContactedReason;
        this.SeverityPk = obj.SeverityPk;
        this.TreatmentPk = obj.TreatmentPk;
        this.PhysicianName = obj.PhysicianName;
        this.PhysicianPhone = obj.PhysicianPhone;
        this.ClinicHospitalName = obj.ClinicHospitalName;
        this.Address = obj.Address;
        this.Diagnosis = obj.Diagnosis;
        this.OtherBodyPart = obj.OtherBodyPart;

        if (obj.BodyParts) {
            this.BodyParts = obj.BodyParts.map(x => new SafetyIncidentBodyPartModel(x));
        }

        if (obj.Severity) {
            this.Severity = new LookupValueModel(obj.Severity);
        }

        if (obj.Treatment) {
            this.Treatment = new LookupValueModel(obj.Treatment);
        }
    }
}

export class SafetyIncidentBodyPartModel {
    Pk: number;
    SafetyIncidentBodyInjuryPk: number;
    LookupValuePk: number;
    BodyPart: LookupValueModel;
    CreationDate: Date;
    CreatedBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        this.SafetyIncidentBodyInjuryPk = obj.SafetyIncidentBodyInjuryPk;
        this.LookupValuePk = obj.LookupValuePk;

        if (obj.BodyPart) {
            this.BodyPart = new LookupValueModel(obj.BodyPart);
        }

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;
    }
}

export class SafetyCorrectiveActionModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    SafetyModulePk: number;
    SparkModuleTypePk: number;
    ResponsiblePartyPk: number;
    Description: string;
    TargetCloseDate: Date;
    CompletionDate: Date;
    Feedback: string;
    ResponsibleParty: ApplicationUserModel = new ApplicationUserModel();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.SafetyModulePk = obj.SafetyModulePk;
        this.SparkModuleTypePk = obj.SparkModuleTypePk;
        this.ResponsiblePartyPk = obj.ResponsiblePartyPk;
        this.Description = obj.Description;
        this.Feedback = obj.Feedback;

        if (obj.TargetCloseDate) {
            this.TargetCloseDate = moment(obj.TargetCloseDate).toDate();
        }

        if (obj.CompletionDate) {
            this.CompletionDate = moment(obj.CompletionDate).toDate();
        }

        if (obj.ResponsibleParty) {
            this.ResponsibleParty = new ApplicationUserModel(obj.ResponsibleParty);
        }
    }
}

export class SafetyIncidentInvestigationTypeModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    SafetyIncidentInvestigationPk: number;
    TypePk: number;
    Type: LookupValueModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;
        this.SafetyIncidentInvestigationPk = obj.SafetyIncidentInvestigationPk;
        this.TypePk = obj.TypePk;

        if (obj.BodyPart) {
            this.Type = new LookupValueModel(obj.Type);
        }
    }
}

export class SafetyIncidentInvestigationInjuryModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    SafetyIncidentInvestigationPk: number;
    TypePk: number;
    Days: number;
    Type: LookupValueModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;
        this.SafetyIncidentInvestigationPk = obj.SafetyIncidentInvestigationPk;
        this.TypePk = obj.TypePk;
        this.Days = obj.Days;

        if (obj.Type) {
            this.Type = new LookupValueModel(obj.Type);
        }
    }
}
