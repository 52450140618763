import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    LookupModel,
    ReportModel,
    RuntimeReportModel,
    RuntimeReportItemModel,
    RuntimeReportImportLogModel,
    SearchCriteriaModel,
    SearchResultModel
} from '../../shared/models';
import { Common } from './common.service';

import * as Moment from 'moment';

@Injectable()
export class RuntimeReportService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.RUNTIME}/api/runtime-report`;

    data: any = {};

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get<RuntimeReportModel>(`${this.url}?modelPk=${modelPk}`)
            .pipe(
                map(res => new RuntimeReportModel(res))
            );
    }

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<RuntimeReportModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new RuntimeReportModel(x));
                }
                return res;
            }));
    }

    create(model: RuntimeReportModel) {
        return this.http.post<RuntimeReportModel>(this.url + '/create', JSON.stringify({
            ...model,
            StartDate: Moment(model.StartDate).format('YYYY-MM-DD'),
            EndDate: Moment(model.EndDate).format('YYYY-MM-DD')
        })).pipe(
            map(res => new RuntimeReportModel(res))
        );
    }

    update(model: RuntimeReportModel) {
        return this.http.put<RuntimeReportModel>(this.url + '/update', JSON.stringify({
            ...model,
            StartDate: Moment(model.StartDate).format('YYYY-MM-DD'),
            EndDate: Moment(model.EndDate).format('YYYY-MM-DD')
        })).pipe(
            map(res => new RuntimeReportModel(res))
        );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(this.url + `/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    createItem(itemModel: RuntimeReportItemModel) {
        return this.http.post<RuntimeReportItemModel>(this.url + '/create-item', JSON.stringify({
            ...itemModel,
            StopTime: Moment(itemModel.StopTime).format('YYYY-MM-DD HH:mm'),
            StartTime: Moment(itemModel.StartTime).format('YYYY-MM-DD HH:mm')
        })).pipe(
            map(res => new RuntimeReportItemModel(res))
        );
    }

    updateItem(itemModel: RuntimeReportItemModel) {
        return this.http.put<RuntimeReportItemModel>(this.url + '/update-item', JSON.stringify({
            ...itemModel,
            StopTime: Moment(itemModel.StopTime).format('YYYY-MM-DD HH:mm'),
            StartTime: Moment(itemModel.StartTime).format('YYYY-MM-DD HH:mm')
        })).pipe(
            map(res => new RuntimeReportItemModel(res))
        );
    }

    deleteItem(itemPk: number) {
        return this.http.delete<Boolean>(this.url + `/delete-item?itemPk=${itemPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    print(modelPk: number) {
        return this.http.get<ReportModel>(this.url + `/print?modelPk=${modelPk}`)
            .pipe(
                map(res => new ReportModel(res))
            );
    }

    email(modelPk: number, recipient: string) {
        return this.http.post<Boolean>(this.url + `/email?modelPk=${modelPk}&recipient=${recipient}`, {}, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    getLatest(assetId: string) {
        return this.http.get<RuntimeReportModel>(this.url + `/get-latest?assetId=${assetId}`)
            .pipe(
                map(res => new RuntimeReportModel(res))
            );
    }

    getAlarmSerialNumbers() {
        // todo: consider renaming when Emit is incorporated
        const key = 'AlarmSerialNumbers';

        if (this.data[key]) {
            return of(this.data[key] as LookupModel[]);
        }

        return this.http.get<LookupModel[]>(this.url + '/get-alarm-serial-numbers')
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new LookupModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as LookupModel[];
            }));
    }

    getImportLogs(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<RuntimeReportImportLogModel>>(this.url + '/import/search', criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new RuntimeReportImportLogModel(x))
                }

                return res;
            }));
    }

    getExisting(model: RuntimeReportModel) {
        const url = `${this.url}/get-existing?modelPk=${model.Pk}&assetId=${model.AssetId}&startDate=${Moment(model.StartDate).format('YYYY-MM-DD')}`;

        return this.http.get<RuntimeReportModel[]>(url)
            .pipe(
                map(res => res ? res.map(x => new RuntimeReportModel(x)) : new Array())
            );
    }
}
