<div class="row">
    <div class="col-md-12">
        <div class="form-group" *ngIf="onFilter != null">
            <input type="text" placeholder="Search" class="form-control" [(ngModel)]="sessionModel.SearchText" (ngModelChange)="applyFilter()" />
        </div>

        <div class="form-group" *ngIf="showSelect">
            <select name="SearchView" [(ngModel)]="sessionModel.SearchView" (ngModelChange)="onSelect()" *ngIf="showSelectGroup" class="form-control">
                <optgroup *ngFor="let options of selectOptions" [label]="options.group">
                    <option *ngFor="let option of options.values" [value]="option.value">{{option.name}}</option>
                </optgroup>
            </select>

            <select name="SearchView" [(ngModel)]="sessionModel.SearchView" (ngModelChange)="onSelect()" *ngIf="!showSelectGroup" class="form-control">
                <option *ngFor="let option of selectOptions" [value]="option.value">{{option.name}}</option>
            </select>
        </div>

        <ng-content></ng-content>
    </div>

    <div class="col-md-3 col-sm-12">
        <label>Total Records: {{totalRecords}}</label>
    </div>

    <div class="col-md-9 col-sm-12" *ngIf="totalRecords">
        <pagination [totalItems]="totalRecords" (pageChanged)="setPage($event)" [maxSize]="5"
            [rotate]="true" [(ngModel)]="pageStart" [boundaryLinks]="true"
            [customFirstTemplate]="firstTemplate"
            [customLastTemplate]="lastTemplate"
            [customPreviousTemplate]="previousTemplate"
            [customNextTemplate]="nextTemplate"
        ></pagination>

        <ng-template #firstTemplate>
            <i class="fas fa-angle-double-left"></i>
        </ng-template>

        <ng-template #previousTemplate>
            <i class="fas fa-angle-left"></i> <span class="d-none d-md-inline ml-1">Previous</span>
        </ng-template>

        <ng-template #nextTemplate>
            <span class="d-none d-md-inline mr-2">Next</span> <i class="fas fa-angle-right"></i>
        </ng-template>

        <ng-template #lastTemplate>
            <i class="fas fa-angle-double-right"></i>
        </ng-template>
    </div>
</div>
