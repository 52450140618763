import { SparkFileModel } from './spark-file.model';
import { WorkflowModuleModel } from './workflow.model';
import { LookupValueModel } from './lookup-value.model'

import * as moment from 'moment';
import { SalesMassRenewalModel } from '.';

export class SalesOrderModel {
    Pk: number;
    Date: Date = moment().startOf('day').toDate();
    Type: string = 'New';
    // New
    // Renew
    // RenewRate
    AssetId: string = '';
    AssetName: string;
    Engine: string;
    Frame: string;
    Cylinders: string;
    AccountManagerId: string;
    AccountManagerName: string;
    OperationsManagerId: string;
    OperationsManagerName: string;
    CustomerId: string;
    CustomerName: string;
    CustomerEmail: string;
    AccountsPayableContactId: string;
    AccountsPayableContactName: string;
    SalesContact: string;
    SalesContactNumber: string;
    Location: string;
    AreaId: string;
    State: string;
    County: string;
    IsInCityLimits: boolean = false;
    RevenueType: string;
    HasPurchaseOption: boolean = false;
    SuctionPressureMin: number;
    SuctionPressureMax: number;
    DischargePressureMin: number;
    DischargePressureMax: number;
    SuctionTemperatureMin: number;
    SuctionTemperatureMax: number;
    DischargeTemperature: number;
    SpecificGravity: number;
    RequiredFlowMin: number;
    RequiredFlowMax: number;
    Elevation: number;
    IsPartialNace: boolean = false;
    EstimatedDate: Date;
    InstallTruckSetup: string = 'Kodiak';
    InstallCraneSetup: string = 'Kodiak';
    InstallTransportation: string = 'Customer';
    InstallTransportationSplit: string;
    InstallTransportationOut: string = 'Customer';
    InstallTransportationOutSplit: string;
    InstallCranes: string = 'Customer';
    KodiakFirstCall: string = 'Yes';
    CallNotes: string;
    CatalystProvider: string = 'Customer';
    IsKodiakFirstCatalyst: boolean = true;
    HasBillBackBadFuel: boolean = true;
    HasBillBackInstall: boolean = false;
    HasBillBackParts: boolean = false;
    LiquidsProvider: string = 'Kodiak';
    Term: string;
    TermInMonths?: number;
    ContractStatus: string = 'No';
    AdValBy: string;
    MonthlyRate: number;
    DailyRate: number;
    StandbyRate: number;
    StandbyRateAmount: number;
    PackagingStandbyRate: number;
    PackagingStandbyRateAmount: number;
    IsPerformanceBased: boolean = false;
    PerformanceBasedNotes: string;
    HasPriceIndexAdjustment: boolean = true;
    ContractNotes: string;
    IsReplacingCompetitor: boolean = false;
    CompetitorPk: number;
    Status: string = 'NEW';
    Competitor: LookupValueModel;
    ProbabilityPk: number;
    Probability: LookupValueModel;
    ApplicationTypePk: number;
    ApplicationType: LookupValueModel;
    HasElectricMotor: boolean = false;
    ElectricMotorNotes: string;
    Files: SparkFileModel[] = new Array();
    InvoicingInstructions: InvoicingInstructionModel[] = new Array();
    CanApprove: boolean = false;
    WorkflowId: string = 'SO_';
    Workflows: WorkflowModuleModel[] = new Array();
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    HasEscalationClause: boolean = false;
    HasEcoView: boolean = false;
    EcoViewPackagePk: number;
    EcoViewPackageRate: number;
    EcoViewPackageDailyRate: number;
    HasEcoViewInstall: boolean = false;
    EcoViewInstallCharge: number;
    EcoViewInstallDate: Date;
    EcoViewTermInMonths: number;
    Escalations: SalesOrderEscalationModel[] = new Array();
    SalesMassRenewalPk: number;
    SalesMassRenewal: SalesMassRenewalModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.Date) {
            this.Date = moment(obj.Date).startOf('day').toDate();
        }
        else {
            this.Date = moment().startOf('day').toDate();
        }

        this.Type = (obj.Type || this.Type).replace('RenewRate', 'Renew');
        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.Engine = obj.Engine;
        this.Frame = obj.Frame;
        this.Cylinders = obj.Cylinders;
        this.AccountManagerId = obj.AccountManagerId;
        this.AccountManagerName = obj.AccountManagerName;
        this.OperationsManagerId = obj.OperationsManagerId;
        this.OperationsManagerName = obj.OperationsManagerName;
        this.CustomerId = obj.CustomerId;
        this.CustomerName = obj.CustomerName;
        this.CustomerEmail = obj.CustomerEmail;
        this.AccountsPayableContactId = obj.AccountsPayableContactId;
        this.AccountsPayableContactName = obj.AccountsPayableContactName;
        this.SalesContact = obj.SalesContact;
        this.SalesContactNumber = obj.SalesContactNumber;
        this.Location = obj.Location;
        this.AreaId = obj.AreaId;
        this.State = obj.State;
        this.County = obj.County;
        this.IsInCityLimits = obj.IsInCityLimits;
        this.RevenueType = obj.RevenueType;
        this.HasPurchaseOption = obj.HasPurchaseOption;
        this.SuctionPressureMin = obj.SuctionPressureMin;
        this.SuctionPressureMax = obj.SuctionPressureMax;
        this.DischargePressureMin = obj.DischargePressureMin;
        this.DischargePressureMax = obj.DischargePressureMax;
        this.SuctionTemperatureMin = obj.SuctionTemperatureMin;
        this.SuctionTemperatureMax = obj.SuctionTemperatureMax;
        this.DischargeTemperature = obj.DischargeTemperature;
        this.SpecificGravity = obj.SpecificGravity;
        this.RequiredFlowMin = obj.RequiredFlowMin;
        this.RequiredFlowMax = obj.RequiredFlowMax;
        this.Elevation = obj.Elevation;
        this.IsPartialNace = obj.IsPartialNace;

        if (obj.EstimatedDate) {
            this.EstimatedDate = moment(obj.EstimatedDate).startOf('day').toDate();
        }

        this.InstallTruckSetup = obj.InstallTruckSetup;
        this.InstallCraneSetup = obj.InstallCraneSetup;
        this.InstallTransportation = obj.InstallTransportation;
        this.InstallTransportationSplit = obj.InstallTransportationSplit;
        this.InstallTransportationOut = obj.InstallTransportationOut;
        this.InstallTransportationOutSplit = obj.InstallTransportationOutSplit;
        this.InstallCranes = obj.InstallCranes;
        this.KodiakFirstCall = obj.KodiakFirstCall;
        this.CallNotes = obj.CallNotes;
        this.CatalystProvider = obj.CatalystProvider;
        this.IsKodiakFirstCatalyst = obj.IsKodiakFirstCatalyst;
        this.HasBillBackBadFuel = obj.HasBillBackBadFuel;
        this.HasBillBackInstall = obj.HasBillBackInstall;
        this.HasBillBackParts = obj.HasBillBackParts;
        this.LiquidsProvider = obj.LiquidsProvider;
        this.Term = obj.Term;
        this.TermInMonths = obj.TermInMonths;
        this.ContractStatus = obj.ContractStatus;
        this.AdValBy = obj.AdValBy;
        this.MonthlyRate = obj.MonthlyRate;
        this.DailyRate = obj.DailyRate;
        this.StandbyRate = obj.StandbyRate;
        this.StandbyRateAmount = obj.StandbyRateAmount;
        this.PackagingStandbyRate = obj.PackagingStandbyRate;
        this.PackagingStandbyRateAmount = obj.PackagingStandbyRateAmount;
        this.IsPerformanceBased = obj.IsPerformanceBased;
        this.PerformanceBasedNotes = obj.PerformanceBasedNotes;
        this.HasPriceIndexAdjustment = obj.HasPriceIndexAdjustment;
        this.ContractNotes = obj.ContractNotes;
        this.IsReplacingCompetitor = obj.IsReplacingCompetitor;
        this.CompetitorPk = obj.CompetitorPk;
        this.ProbabilityPk = obj.ProbabilityPk;
        this.ApplicationTypePk = obj.ApplicationTypePk;
        this.HasElectricMotor = obj.HasElectricMotor;
        this.ElectricMotorNotes = obj.ElectricMotorNotes;
        this.HasEscalationClause = obj.HasEscalationClause;
        this.HasEcoView = obj.HasEcoView;
        this.EcoViewPackagePk = obj.EcoViewPackagePk;
        this.EcoViewPackageRate = obj.EcoViewPackageRate;
        this.EcoViewPackageDailyRate = obj.EcoViewPackageDailyRate;
        this.HasEcoViewInstall = obj.HasEcoViewInstall;
        this.EcoViewInstallCharge = obj.EcoViewInstallCharge;

        if (obj.EcoViewInstallDate)
            this.EcoViewInstallDate = moment(obj.EcoViewInstallDate).toDate();

        this.EcoViewTermInMonths = obj.EcoViewTermInMonths;

        if (obj.Competitor) {
            this.Competitor = new LookupValueModel(obj.Competitor);
        }

        if (obj.Probability) {
            this.Probability = new LookupValueModel(obj.Probability)
        }

        if (obj.ApplicationType) {
            this.ApplicationType = new LookupValueModel(obj.ApplicationType)
        }

        this.Status = obj.Status;
        this.CanApprove = obj.CanApprove;

        if (obj.Files) {
            this.Files = obj.Files.map(x => new SparkFileModel(x));
        }

        if (obj.InvoicingInstructions) {
            this.InvoicingInstructions = obj.InvoicingInstructions.map(x => new InvoicingInstructionModel(x)).sort((a, b): number => {
                return a.Pk > b.Pk ? -1 : 1;
            });
        }
        if (obj.Escalations) {
            this.Escalations= obj.Escalations.map(x => new SalesOrderEscalationModel(x)).sort((a, b): number => {
                return moment(a.EscalationDate).toDate().getTime() > moment(b.EscalationDate).toDate().getTime() ? 1 : -1;
            });
        }
        if (obj.Type) {
            this.WorkflowId += obj.Type;
        }

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.SalesMassRenewalPk = obj.SalesMassRenewalPk;
        if (obj.SalesMassRenewal) {
            this.SalesMassRenewal = new SalesMassRenewalModel(obj.SalesMassRenewal);
        }
    }

    createRenewal() {
        const model = new SalesOrderModel(this);
        const latest = this.InvoicingInstructions[0];

        model.Pk = 0;
        model.Date = moment().startOf('day').toDate();
        model.Type = 'Renew';
        model.Status = 'NEW';
        model.InvoicingInstructions = [];
        model.Files = [];
        model.EstimatedDate = null;
        model.MonthlyRate = null;
        model.Term = null;
        model.TermInMonths = null;
        model.ContractStatus = "No";
        model.AdValBy = latest.AdValBy;
        model.DailyRate = null;
        model.StandbyRate = latest.StandbyRate;
        model.StandbyRateAmount = null;
        model.PackagingStandbyRate = latest.PackagingStandbyRate;
        model.PackagingStandbyRateAmount = null;
        model.IsPerformanceBased = latest.IsPerformanceBased;
        model.PerformanceBasedNotes = latest.PerformanceBasedNotes;
        model.HasPriceIndexAdjustment = false;
        model.IsKodiakFirstCatalyst = true;

        model.WorkflowId = 'SO_RENEW';
        model.Workflows = [];
        model.CreatedBy = null;
        model.CreationDate = null;
        model.LastUpdateBy = null;
        model.LastUpdateDate = null;

        // Populate from latest II
        model.InstallTruckSetup = latest.InstallTruckSetup;
        model.InstallCraneSetup = latest.InstallCraneSetup;
        model.InstallCranes = 'Customer';
        model.InstallTransportation = 'Customer';
        model.InstallTransportationSplit = null;
        model.InstallTransportationOut = 'Customer';
        model.InstallTransportationOutSplit = null;

        if (latest.AccountManagerId) {
            model.AccountManagerId = latest.AccountManagerId;
            model.AccountManagerName = latest.AccountManagerName;
        }
        else {
            model.AccountManagerId = null;
            model.AccountManagerName = null;
        }

        if (latest.OperationsManagerId) {
            model.OperationsManagerId = latest.OperationsManagerId;
            model.OperationsManagerName = latest.OperationsManagerName;
        }
        else {
            model.OperationsManagerId = null;
            model.OperationsManagerName = null;
        }

        if (latest.CustomerId) {
            model.CustomerId = latest.CustomerId;
            model.CustomerName = latest.CustomerName;
        }
        else {
            model.CustomerId = null;
            model.CustomerName = null;
        }

        if (latest.AccountsPayableContactId) {
            model.AccountsPayableContactId = latest.AccountsPayableContactId;
            model.AccountsPayableContactName = latest.AccountsPayableContactName;
        }
        else {
            model.AccountsPayableContactId = null;
            model.AccountsPayableContactName = null;
        }

        model.SalesContact = latest.SalesContact;
        model.SalesContactNumber = latest.SalesContactNumber;
        model.Location = latest.Location;
        model.AreaId = latest.AreaId;
        model.State = latest.State;
        model.County = latest.County;
        model.IsInCityLimits = latest.IsInCityLimits;
        model.ContractNotes = latest.ContractNotes;
        model.SalesMassRenewal = null;
        model.SalesMassRenewalPk = null;

        return model;
    }

    createInvoicingInstruction() {
        const model = new InvoicingInstructionModel();
        model.Type = this.Type == 'New' ? 'New' : 'RenewNew';
        model.DisplayType = model.Type;
        model.SalesOrderPk = this.Pk;
        model.SalesOrder = new SalesOrderModel(this);
        model.AssetId = this.AssetId;
        model.AssetName = this.AssetName;
        model.AccountManagerId = this.AccountManagerId;
        model.AccountManagerName = this.AccountManagerName;
        model.OperationsManagerId = this.OperationsManagerId;
        model.OperationsManagerName = this.OperationsManagerName;
        model.CustomerId = this.CustomerId;
        model.CustomerName = this.CustomerName;
        model.AccountsPayableContactId = this.AccountsPayableContactId;
        model.AccountsPayableContactName = this.AccountsPayableContactName;
        model.SalesContact = this.SalesContact;
        model.SalesContactNumber = this.SalesContactNumber;
        model.Location = this.Location;
        model.AreaId = this.AreaId;
        model.State = this.State;
        model.County = this.County;
        model.IsInCityLimits = this.IsInCityLimits;
        model.InstallTruckSetup = this.InstallTruckSetup;
        model.InstallCraneSetup = this.InstallCraneSetup;
        model.InstallTransportation = this.InstallTransportation;
        model.InstallTransportationSplit = this.InstallTransportationSplit;
        model.InstallTransportationOut = this.InstallTransportationOut;
        model.InstallTransportationOutSplit = this.InstallTransportationOutSplit;
        model.InstallCranes = this.InstallCranes;
        model.CatalystProvider = this.CatalystProvider;
        model.IsKodiakFirstCatalyst = this.IsKodiakFirstCatalyst;
        model.HasBillBackInstall = this.HasBillBackInstall;
        model.LiquidsProvider = this.LiquidsProvider;
        model.Term = this.Term;
        model.TermInMonths = this.TermInMonths;
        model.ContractStatus = this.ContractStatus;
        model.AdValBy = this.AdValBy;
        model.MonthlyRate = this.MonthlyRate;
        model.DailyRate = this.DailyRate;
        model.StandbyRate = this.StandbyRate;
        model.PackagingStandbyRate = this.PackagingStandbyRate;
        model.IsPerformanceBased = this.IsPerformanceBased;
        model.PerformanceBasedNotes = this.PerformanceBasedNotes;
        model.ContractNotes = this.ContractNotes;
        model.HasEcoView = this.HasEcoView;
        model.HasEcoView = this.HasEcoView;
        model.EcoViewPackagePk = this.EcoViewPackagePk;
        model.EcoViewPackageRate = this.EcoViewPackageRate;
        model.EcoViewPackageDailyRate = this.EcoViewPackageDailyRate;
        model.HasEcoViewInstall = this.HasEcoViewInstall;
        model.EcoViewInstallCharge = this.EcoViewInstallCharge;
        model.EcoViewTermInMonths = this.EcoViewTermInMonths;

        return model;
    }
}

export class SalesOrderEscalationModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    SalesOrderPk: number;
    SalesMassRenewalItemPk: number;
    EscalationDate: Date;
    Amount: number;
    TypePk: number;
    NewAmount: number;
    Type: LookupValueModel;
    SalesOrder: SalesOrderModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        this.Pk = obj.Pk;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.SalesOrderPk = obj.SalesOrderPk;
        this.SalesMassRenewalItemPk = obj.SalesMassRenewalItemPk;
        this.EscalationDate = moment(obj.EscalationDate).toDate();
        if (obj.EscalationDate) {
            this.EscalationDate = moment(obj.EscalationDate).toDate();
        }
        this.Amount = obj.Amount;
        this.TypePk = obj.TypePk;
        this.NewAmount = obj.NewAmount;
        if (obj.Type) {
            this.Type = new LookupValueModel(obj.Type);
        }
        if (obj.SalesOrder) {
            this.SalesOrder = new SalesOrderModel(obj.SalesOrder);
        }
    }
}

export class InvoicingInstructionModel {
    Pk: number;
    Date: Date;
    Type: string = 'New';
    DisplayType: string = 'New';
    // New
    // NewRate
    // RenewNew
    // RenewNewRate
    // Termination
    // RenewTerm
    // Reinstate
    // Amendment
    // AmendmentRate
    SalesOrderPk: number;
    ParentPk: number = null;
    IsAreaAmendment: boolean = false;
    IsCustomerAmendment: boolean = false;
    IsLocationAmendment: boolean = false;
    IsRateAmendment: boolean = false;
    IsRateTypeAmendment: boolean = false;
    IsTermAmendment: boolean = false;
    IsInformationAmendment: boolean = false;
    IsEcoViewAmendment: boolean = false;
    CostCenter: string;
    RateType: string;
    AssetId: string;
    AssetName: string;
    AccountManagerId: string;
    AccountManagerName: string;
    OperationsManagerId: string;
    OperationsManagerName: string;
    CustomerId: string;
    CustomerName: string;
    AccountsPayableContactId: string;
    AccountsPayableContactName: string;
    SalesContact: string;
    SalesContactNumber: string;
    Location: string;
    AreaId: string;
    State: string;
    County: string;
    IsInCityLimits: boolean;
    InstallTruckSetup: string;
    InstallCraneSetup: string;
    InstallTransportation: string;
    InstallTransportationSplit: string;
    InstallTransportationOut: string;
    InstallTransportationOutSplit: string;
    InstallCranes: string;
    CatalystProvider: string;
    IsKodiakFirstCatalyst: boolean;
    HasBillBackInstall: boolean;
    LiquidsProvider: string;
    ContractNotes: string;
    Notes: string;
    Term: string;
    TermInMonths: number;
    ContractStatus: string;
    AdValBy: string;
    MonthlyRate: number;
    MonthlyStandbyRate: number;
    DailyRate: number;
    DailyStandbyRate: number;
    StandbyRate: number;
    PackagingStandbyRate: number;
    PackagingDailyStandbyRate: number;
    PackagingMonthlyStandbyRate: number;
    IsPerformanceBased: boolean;
    PerformanceBasedNotes: string;
    ReleaseReasonPk: number;
    ReleaseReason: LookupValueModel;
    ReleaseComments: string;
    HasEcoView: boolean = false;
    EcoViewPackagePk: number;
    EcoViewPackageRate: number;
    EcoViewPackageDailyRate: number;
    HasEcoViewInstall: boolean = false;
    EcoViewInstallCharge: number;
    EcoViewBillingDate: Date;
    EcoViewTermInMonths: number;
    Status: string = 'NEW';
    Files: SparkFileModel[] = new Array();
    Parent: InvoicingInstructionModel;
    Children: InvoicingInstructionModel[] = new Array();
    SalesOrder: SalesOrderModel;
    CanApprove: boolean = false;
    WorkflowId: string = 'II_';
    Workflows: WorkflowModuleModel[] = new Array();
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.Date) {
            this.Date = moment(obj.Date).startOf('day').toDate();
        }

        // Replacing Type here instead of in each instance it is displayed
        // It is reset when creating/updating the II
        this.Type = obj.Type;
        this.DisplayType = (obj.Type || this.Type).replace(/Rate/gi, '');
        this.SalesOrderPk = obj.SalesOrderPk;
        this.ParentPk = obj.ParentPk;
        this.IsAreaAmendment = obj.IsAreaAmendment;
        this.IsCustomerAmendment = obj.IsCustomerAmendment;
        this.IsLocationAmendment = obj.IsLocationAmendment;
        this.IsRateAmendment = obj.IsRateAmendment;
        this.IsRateTypeAmendment = obj.IsRateTypeAmendment;
        this.IsTermAmendment = obj.IsTermAmendment;
        this.IsInformationAmendment = obj.IsInformationAmendment;
        this.IsEcoViewAmendment = obj.IsEcoViewAmendment;
        this.CostCenter = obj.CostCenter;
        this.RateType = obj.RateType;
        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.AccountManagerId = obj.AccountManagerId;
        this.AccountManagerName = obj.AccountManagerName;
        this.OperationsManagerId = obj.OperationsManagerId;
        this.OperationsManagerName = obj.OperationsManagerName;
        this.CustomerId = obj.CustomerId;
        this.CustomerName = obj.CustomerName;
        this.AccountsPayableContactId = obj.AccountsPayableContactId;
        this.AccountsPayableContactName = obj.AccountsPayableContactName;
        this.SalesContact = obj.SalesContact;
        this.SalesContactNumber = obj.SalesContactNumber;
        this.Location = obj.Location;
        this.AreaId = obj.AreaId;
        this.State = obj.State;
        this.County = obj.County;
        this.IsInCityLimits = obj.IsInCityLimits;
        this.InstallTruckSetup = obj.InstallTruckSetup;
        this.InstallCraneSetup = obj.InstallCraneSetup;
        this.InstallTransportation = obj.InstallTransportation;
        this.InstallTransportationSplit = obj.InstallTransportationSplit;
        this.InstallTransportationOut = obj.InstallTransportationOut;
        this.InstallTransportationOutSplit = obj.InstallTransportationOutSplit;
        this.InstallCranes = obj.InstallCranes;
        this.CatalystProvider = obj.CatalystProvider;
        this.IsKodiakFirstCatalyst = obj.IsKodiakFirstCatalyst;
        this.HasBillBackInstall = obj.HasBillBackInstall;
        this.LiquidsProvider = obj.LiquidsProvider;
        this.ContractNotes = obj.ContractNotes;
        this.Notes = obj.Notes;
        this.Term = obj.Term;
        this.TermInMonths = obj.TermInMonths;
        this.ContractStatus = obj.ContractStatus;
        this.AdValBy = obj.AdValBy;
        this.MonthlyRate = obj.MonthlyRate;
        this.MonthlyStandbyRate = obj.MonthlyStandbyRate;
        this.DailyRate = obj.DailyRate;
        this.DailyStandbyRate = obj.DailyStandbyRate;
        this.StandbyRate = obj.StandbyRate;
        this.PackagingStandbyRate = obj.PackagingStandbyRate;
        this.PackagingDailyStandbyRate = obj.PackagingDailyStandbyRate;
        this.PackagingMonthlyStandbyRate = obj.PackagingMonthlyStandbyRate;
        this.IsPerformanceBased = obj.IsPerformanceBased;
        this.PerformanceBasedNotes = obj.PerformanceBasedNotes;
        this.Status = obj.Status;
        this.CanApprove = obj.CanApprove;
        this.ReleaseReasonPk = obj.ReleaseReasonPk;
        this.ReleaseComments = obj.ReleaseComments;
        this.HasEcoView = obj.HasEcoView;
        this.EcoViewPackagePk = obj.EcoViewPackagePk;
        this.EcoViewPackageRate = obj.EcoViewPackageRate;
        this.EcoViewPackageDailyRate = obj.EcoViewPackageDailyRate;
        this.HasEcoViewInstall = obj.HasEcoViewInstall;
        this.EcoViewInstallCharge = obj.EcoViewInstallCharge;

        if (obj.EcoViewBillingDate)
            this.EcoViewBillingDate = moment(obj.EcoViewBillingDate).toDate();

        this.EcoViewTermInMonths = obj.EcoViewTermInMonths;

        if (obj.ReleaseReason) {
            this.ReleaseReason = new LookupValueModel(obj.ReleaseReason);
        }

        if (obj.SalesOrder) {
            this.SalesOrder = new SalesOrderModel(obj.SalesOrder);
        }

        if (obj.Parent) {
            this.Parent = new InvoicingInstructionModel(obj.Parent);
        }

        if (obj.Children) {
            this.Children = obj.Children.map(x => new InvoicingInstructionModel(x));
        }

        if (obj.Files) {
            this.Files = obj.Files.map(x => new SparkFileModel(x));
        }

        if (obj.Type) {
            this.WorkflowId += obj.Type;
        }

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }
        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }

    createChild(type: string) {
        const model = new InvoicingInstructionModel(this);
        model.Pk = 0;
        model.Parent = new InvoicingInstructionModel(this);
        model.ParentPk = this.Pk;
        model.Date = null;
        model.Type = type;
        model.DisplayType = type;
        model.Children = [];
        model.Status = 'NEW';
        model.Files = [];
        model.WorkflowId = 'II_' + type.toUpperCase();
        model.Workflows = [];
        model.CreatedBy = null;
        model.CreationDate = null;
        model.LastUpdateBy = null;
        model.LastUpdateDate = null;
        model.IsAreaAmendment = null;
        model.IsCustomerAmendment = null;
        model.IsLocationAmendment = null;
        model.IsRateAmendment = null;
        model.IsRateTypeAmendment = null;
        model.IsTermAmendment = null;
        model.IsInformationAmendment = null;
        model.IsEcoViewAmendment = null;
        model.Notes = null;
        model.ReleaseReason = null;
        model.ReleaseReasonPk = null;

        return model;
    }

    isAmendment() {
        return (this.Type || '').toLowerCase().indexOf('amendment') == 0;
    }

    getAmendmentTypes() {
        if (!this.isAmendment()) {
            return '';
        }

        let types = [];

        this.IsAreaAmendment && types.push('Area');
        this.IsCustomerAmendment && types.push('Contract Assumption');
        this.IsLocationAmendment && types.push('Location');
        this.IsRateAmendment && types.push('Monthly Rate');
        this.IsRateTypeAmendment && types.push('Rate Type');
        this.IsTermAmendment && types.push('Terms');
        this.IsInformationAmendment && types.push('Information Change');
        this.IsEcoViewAmendment && types.push('EcoView');

        return types.join(', ');
    }
}
