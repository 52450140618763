import { TimeCardPeriod } from './time-card.model';

import * as moment from 'moment';

export class PayrollExportModel {
    Pk: number = 0;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;
    TimeCardPeriodPk: number;
    Status: string = 'NEW';
    DateCompleted: Date = null;
    TimeCardPeriod: TimeCardPeriod;
    Users: PayrollExportUserModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.TimeCardPeriodPk = obj.TimeCardPeriodPk;
        this.Status = obj.Status;

        this.CreatedBy = obj.CreatedBy;
        if (obj.CreationDate)
            this.CreationDate = moment(obj.CreationDate).toDate();

        this.LastUpdateBy = obj.LastUpdateBy;
        if (obj.LastUpdateDate)
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();

        if (obj.DateCompleted)
            this.DateCompleted = moment(obj.DateCompleted).toDate();

        if (obj.TimeCardPeriod)
            this.TimeCardPeriod = new TimeCardPeriod(obj.TimeCardPeriod);

        if (obj.Users) {
            this.Users = obj.Users.map(x => new PayrollExportUserModel(x));
        }
    }
}

export class PayrollExportUserModel {
    Pk: number = 0;
    PayrollExportPk: number;
    PayrollId: string;
    UserId: string;
    DisplayName: string;
    Department: string;
    IsGuaranteed40: boolean;
    TotalRegularHours: number = 0;
    TotalOvertimeHours: number = 0;
    TotalOtherHours: number = 0;
    TotalNotApprovedHours: number = 0;
    Comments: string;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;
    Expanded: boolean = false;
    Items: PayrollExportUserItemModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.PayrollExportPk = obj.PayrollExportPk;
        this.PayrollId = obj.PayrollId;
        this.UserId = obj.UserId;
        this.DisplayName = obj.DisplayName;
        this.Department = obj.Department;
        this.IsGuaranteed40 = obj.IsGuaranteed40;
        this.Comments = obj.Comments;
        this.TotalRegularHours = obj.TotalRegularHours;
        this.TotalOvertimeHours = obj.TotalOvertimeHours;
        this.TotalOtherHours = obj.TotalOtherHours;
        this.TotalNotApprovedHours = obj.TotalNotApprovedHours;

        this.CreatedBy = obj.CreatedBy;

        if (obj.CreationDate)
            this.CreationDate = moment(obj.CreationDate).toDate();

        this.LastUpdateBy = obj.LastUpdateBy;

        if (obj.LastUpdateDate)
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();

        if (obj.Items) {
            this.Items = obj.Items.map(x => new PayrollExportUserItemModel(x));
        }
    }
}

export class PayrollExportUserItemModel {
    Pk: number = 0;
    PayrollExportUserPk: number;
    IsManual: boolean = false;
    RegularHours: number = 0;
    OvertimeHours: number = 0;
    OtherHours: number = 0;
    NotApprovedHours: number = 0;
    LaborCodeId: string;
    LaborCodeName: string;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.PayrollExportUserPk = obj.PayrollExportUserPk;
        this.IsManual = obj.IsManual;
        this.RegularHours = obj.RegularHours;
        this.OvertimeHours = obj.OvertimeHours;
        this.OtherHours = obj.OtherHours;
        this.NotApprovedHours = obj.NotApprovedHours;
        this.LaborCodeId = obj.LaborCodeId;
        this.LaborCodeName = obj.LaborCodeName;

        this.CreatedBy = obj.CreatedBy;

        if (obj.CreationDate)
            this.CreationDate = moment(obj.CreationDate).toDate();

        this.LastUpdateBy = obj.LastUpdateBy;

        if (obj.LastUpdateDate)
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
    }
}
