<span class="spark-file-wrapper">
    <img *ngIf="showThumbnail && isImage()" src="{{path + model.FileName + (accessToken || '')}}" class="image-preview" [class.preview-only]="previewOnly" (click)="showPreview()" />

    <button class="btn btn-sm btn-secondary" *ngIf="!showThumbnail && isImage()" (click)="showPreview()" tooltip="View Image">
        <i class="fas fa-search"></i> <span class="d-inline d-md-none ml-2">View</span>
    </button>

    <button class="btn btn-sm btn-success" (click)="openFile()" *ngIf="showDownload && !downloading" tooltip="Download">
        <i class="fas fa-download"></i> <span class="d-inline d-md-none ml-2">Download</span>
    </button>

    <button class="btn btn-sm btn-success" *ngIf="downloading">
        <i class="fas fa-spinner fa-pulse"></i> <span class="d-inline d-md-none ml-2">Downloading</span>
    </button>

    <modal [id]="'spark-file' + model.Pk" class="modal-lg" *ngIf="!previewOnly && isImage()">
        <div class="text-center">
            <img src="{{path + model.FileName + (accessToken || '')}}" class="image-preview-full" />
        </div>
    </modal>
</span>