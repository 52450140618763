import { Component, Input, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import {
    FluidsRequestModel
} from "../../shared/models";
import {
    Common
} from "../../core/services";

@Component({
    selector: 'fluids-request-move-tank-detail',
    templateUrl: './fluids-request-move-tank-detail.component.html'
})
export class FluidsRequestMoveTankDetailComponent {
    @Input() model: FluidsRequestModel = new FluidsRequestModel();
    @Input() lookupValues: any = {};
    @Input() types: any = {};
    @Input() access: any = {};

    modelOriginal: FluidsRequestModel = new FluidsRequestModel();
    view: string;
    selectedTabIndex: number = 0;

    constructor(
        public common: Common,
        private route: ActivatedRoute
    ) {
        this.common.setTitle('Operations | Move Tank Fluids Request Details');

        this.route.queryParams.subscribe((params: Params) => {
            this.model.Pk = params['id'];
            this.view = params['view'];
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.modelOriginal = new FluidsRequestModel(this.model);

        this.selectedTabIndex = this.model.NeedOil ? 0 : this.model.NeedCoolant ? 1 : this.model.NeedCompoundOil ? 2 : 0;
    }

    hasChanges() {
        const excludedFields = ['Creator', 'Items', 'Types', 'Type', 'StandHeight'];

        let hasChanges = this.common.getChanges(this.model, this.modelOriginal, excludedFields).length > 0 ||
            this.common.getChanges(this.model.Items[0], this.modelOriginal.Items[0], excludedFields).length > 0 ||
            this.model.Items[0].Types.length != this.modelOriginal.Items[0].Types.length;

        if (!hasChanges) {
            for (let i in this.types) {
                const type = this.modelOriginal.Items[0].Types.find(x => x.TypePk == this.types[i].TypePk);

                if (type && this.model['Need' + this.types[i].Type.Name]) {
                    hasChanges = this.common.getChanges(this.types[i], type, excludedFields).length > 0;

                    if (hasChanges) {
                        break;
                    }
                }
            }
        }

        return hasChanges;
    }

    onVendorUpdate() {
        const vendor = this.lookupValues['Vendor'].find(x => x.Id == this.model.VendorId);

        this.model.VendorName = vendor.Name;
    }
}
