import { AssetActivityReportModel } from './asset-activity-report.model';
import { FluidsRequestModel } from './fluids-request.model';
import { InvoicingInstructionModel, SalesOrderModel } from './sales-order.model';
import { McWorkOrderModel } from './mc-work-order.model';

import * as moment from 'moment';

export class UnitModel {
    Unit: string;
    UnitName: string;
    Area: string;
    AreaName: string;
    Region: string;
    RegionName: string;
    Division: string;
    DivisionName: string;
    Manufactured: Date;
    AvailableToShip: Date;
    PPIDate: Date;
    ClassificationID: string;
    Customer: string;
    UnitHours: number;
    Location: string;
    Type: string;
    HRs: number;
    Horsepower: number;
    AssetSerial: string;
    Engine: string;
    EngineSerial: string;
    EngineManufacturer: string;
    EngineClassification: string;
    Frame: string;
    FrameSerial: string;
    FrameManufacturer: string;
    FrameClassification: string;
    Cooler: string;
    CoolerSerial: string;
    CoolerManufacturer: string;
    CoolerClassification: string;
    AreaManager: string;
    AreaManagerId: string;
    RegionalManager: string;
    RegionalManagerId: string;
    DivisionManager: string;
    DivisionManagerId: string;
    Operator: string;
    OperatorId: string;
    Cylinders: string;
    County: string;
    State: string;
    Availability: string = 'Unknown';
    SalesOrderStatus: string;
    IsElectric: boolean;
    AssetActivityReports: AssetActivityReportModel[] = new Array();
    InvoicingInstructions: InvoicingInstructionModel[] = new Array();
    SalesOrders: SalesOrderModel[] = new Array();
    FluidsRequests: FluidsRequestModel[] = new Array();
    WorkOrders: McWorkOrderModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Unit = obj.Unit;
        this.UnitName = obj.UnitName;
        this.Area = obj.Area;
        this.AreaName = obj.AreaName;
        this.Region = obj.Region;
        this.RegionName = obj.RegionName;
        this.Division = obj.Division;
        this.DivisionName = obj.DivisionName;

        if (obj.Manufactured) {
            this.Manufactured = moment(obj.Manufactured).toDate();
        }

        if (obj.AvailableToShip) {
            this.AvailableToShip = moment(obj.AvailableToShip).toDate();
        }

        if (obj.PPIDate) {
            this.PPIDate = moment(obj.PPIDate).toDate();
        }

        this.ClassificationID = obj.ClassificationID;
        this.Customer = obj.Customer;
        this.UnitHours = obj.UnitHours;
        this.Location = obj.Location;
        this.Type = obj.Type;
        this.HRs = obj.HRs;
        this.Horsepower = obj.Horsepower;
        this.AssetSerial = obj.AssetSerial;
        this.Engine = obj.Engine;
        this.EngineSerial = obj.EngineSerial;
        this.EngineManufacturer = obj.EngineManufacturer;
        this.EngineClassification = obj.EngineClassification;
        this.Frame = obj.Frame;
        this.FrameSerial = obj.FrameSerial;
        this.FrameManufacturer = obj.FrameManufacturer;
        this.FrameClassification = obj.FrameClassification;
        this.Cooler = obj.Cooler;
        this.CoolerSerial = obj.CoolerSerial;
        this.CoolerManufacturer = obj.CoolerManufacturer;
        this.CoolerClassification = obj.CoolerClassification;
        this.AreaManager = obj.AreaManager;
        this.AreaManagerId = obj.AreaManagerId;
        this.Operator = obj.Operator;
        this.OperatorId = obj.OperatorId;
        this.RegionalManager = obj.RegionalManager;
        this.RegionalManagerId = obj.RegionalManagerId;
        this.DivisionManager = obj.DivisionManager;
        this.DivisionManagerId = obj.DivisionManagerId;
        this.Cylinders = obj.Cylinders;
        this.County = obj.County;
        this.State = obj.State;
        this.Availability = obj.Availability || 'Unknown';
        this.SalesOrderStatus = obj.SalesOrderStatus;
        this.IsElectric = (this.Engine || '').toLowerCase().indexOf('electric') >= 0;

        if (obj.AssetActivityReports) {
            this.AssetActivityReports = obj.AssetActivityReports.map(x => new AssetActivityReportModel(x)).sort((a, b): number => {
                return a.Pk > b.Pk ? -1 : 1;
            });
        }

        if (obj.InvoicingInstructions) {
            this.InvoicingInstructions = obj.InvoicingInstructions.map(x => new InvoicingInstructionModel(x)).sort((a, b): number => {
                return a.Pk > b.Pk ? -1 : 1;
            });
        }

        if (obj.SalesOrders) {
            this.SalesOrders = obj.SalesOrders.map(x => new SalesOrderModel(x)).sort((a, b): number => {
                return a.Pk > b.Pk ? -1 : 1;
            });
        }

        if (obj.FluidsRequests) {
            this.FluidsRequests = obj.FluidsRequests.map(x => new FluidsRequestModel(x)).sort((a, b): number => {
                return a.Pk > b.Pk ? -1 : 1;
            });
        }

        if (obj.WorkOrders) {
            this.WorkOrders = obj.WorkOrders.map(x => new McWorkOrderModel(x));
        }
    }
}
