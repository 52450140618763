import { McDetailModel } from './mc-detail.model';

import * as moment from 'moment';

export class McWorkOrderModel {
    Pk: number;
    Area: string;
    AssetId: string;
    AssetName: string;
    Problem: string;
    Reason: string;
    RequestedDate: Date;
    RequesterId: string;
    RequesterName: string;
    Type: string;
    TargetDate: Date;
    ServiceReportPk: number;
    Customer: string;
    Location: string;
    Documents: McWorkOrderDocumentModel[] = new Array();
    Notes: McWorkOrderNoteModel[] = new Array();
    Parts: McWorkOrderPartModel[] = new Array();
    StatusDetails: McDetailModel;
    AuthStatusDetails: McDetailModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Area = obj.Area;
        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.Pk = obj.Pk;
        this.Problem = obj.Problem;
        this.Reason = obj.Reason;
        this.RequestedDate = moment(obj.RequestedDate).toDate();
        this.RequesterId = obj.RequesterId;
        this.RequesterName = obj.RequesterName;
        this.Type = obj.Type;
        this.ServiceReportPk = obj.ServiceReportPk;
        this.Customer = obj.Customer;
        this.Location = obj.Location;

        if (obj.TargetDate) {
            this.TargetDate = moment(obj.TargetDate).toDate();
        }

        if (obj.Documents) {
            this.Documents = obj.Documents.map(x => new McWorkOrderDocumentModel(x));
        }

        if (obj.Notes) {
            this.Notes = obj.Notes.map(x => new McWorkOrderNoteModel(x));
        }

        if (obj.Parts) {
            this.Parts = obj.Parts.map(x => new McWorkOrderPartModel(x));
        }

        this.StatusDetails = new McDetailModel(obj.StatusDetails);
        this.AuthStatusDetails = new McDetailModel(obj.AuthStatusDetails);
    }
}

export class McWorkOrderPartModel {
    AccountId: string;
    AccountName: string;
    ChargePrice: number;
    PartId: string;
    PartName: string;
    Quantity: number;
    StockroomId: string;
    TotalCharge: number;
    TotalCost: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.AccountId = obj.AccountId;
        this.AccountName = obj.AccountName;
        this.ChargePrice = obj.ChargePrice;
        this.PartId = obj.PartId;
        this.PartName = obj.PartName;
        this.Quantity = obj.Quantity;
        this.StockroomId = obj.StockroomId;
        this.TotalCharge = obj.TotalCharge;
        this.TotalCost = obj.TotalCost;
    }
}

export class McWorkOrderDocumentModel {
    Pk: number;
    Name: string;
    Url: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Name = obj.Name;
        this.Url = obj.Url;
    }
}

export class McWorkOrderNoteModel {
    Note: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Note = obj.Note;
    }
}
