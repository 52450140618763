<div class="row" id="fluids-request-swap-unit-detail">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <i class="fas fa-calculator"></i>
                <strong>Fluids Request #{{model.Pk}}</strong>
            </div>

            <div class="card-body">
                <form #fluidRequestSwapUnitForm="ngForm" novalidate autocomplete="off">
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label>Status:</label>
                            <div>{{model.Status | titlecase}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label>Type:</label>
                            <div>{{model.Type?.Name}}</div>
                        </div>

                        <div class="form-group col-sm-6 col-md-4" *ngIf="model.Workflows.length">
                            <label>Submitted on:</label>
                            <div>{{model.Workflows[0].CreationDate | date: 'MM/dd/yyyy h:mm a'}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label>Unit:</label>
                            <div>{{model.AssetId}}</div>
                        </div>

                        <div class="form-group col-sm-6 col-md-4">
                            <label>Area:</label>
                            <div>{{model.AreaId}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label for="IsUrgent">
                                Is this an urgent request?
                            </label>
                            <div>
                                <label class="switch switch-text switch-success switch-lg">
                                    <input [(ngModel)]="model.IsUrgent" name="IsUrgent" id="IsUrgent" class="switch-input" type="checkbox" [disabled]="!access.save">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label>Old Location:</label>
                            <div>{{model.Items.length ? model.Items[0].FromLocation : ''}}</div>
                        </div>

                        <div class="form-group col-sm-6 col-md-4">
                            <label for="ToLocation">New Location:</label>
                            <input #toLocationRef="ngModel" [(ngModel)]="model.Items[0].ToLocation" type="text" name="ToLocation" id="ToLocation" class="form-control" required [disabled]="!access.save">

                            <validation-message [isValid]="toLocationRef.invalid && (toLocationRef.dirty || toLocationRef.touched)">
                                Please specify the new location
                            </validation-message>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label for="VendorId">Vendor:</label>
                            <select #vendorRef="ngModel" name="VendorId" id="VendorId" class="form-control" [(ngModel)]="model.VendorId" required [disabled]="!access.save" (ngModelChange)="onVendorUpdate()">
                                <option *ngIf="!model.VendorId"></option>
                                <option *ngFor="let item of lookupValues['Vendor']" [value]="item.Id">{{item.Name}}</option>
                            </select>

                            <validation-message [isValid]="vendorRef.invalid && (vendorRef.dirty || vendorRef.touched)">
                                Please select a Vendor
                            </validation-message>
                        </div>

                        <div class="form-group col-sm-6 col-md-4">
                            <label for="DeliveryDate">Date Needed:</label>
                            <p-calendar #deliveryDateRef="ngModel" name="DeliveryDate" id="DeliveryDate" [(ngModel)]="model.DeliveryDate" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true" [disabled]="!access.save" showTime="true" hourFormat="12" [hideOnDateTimeSelect]="false" [stepMinute]="5">
                                <p-footer>
                                    <div class="p-datepicker-buttonbar text-right">
                                        <button class="btn btn-secondary" (click)="common.closeCalendar(deliveryDateRef)">
                                            <i class="fas fa-times-circle"></i> <span class="ml-2">Close</span>
                                        </button>
                                    </div>
                                </p-footer>
                            </p-calendar>

                            <validation-message [isValid]="deliveryDateRef.invalid && (deliveryDateRef.dirty || deliveryDateRef.touched)">
                                Please enter the Date Needed
                            </validation-message>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label for="Longitude">Latitude Coordinate:</label>
                            <input #latitudeRef="ngModel" [(ngModel)]="model.Latitude" type="text" name="Latitude" id="Latitude" class="form-control" [disabled]="!access.save">

                            <validation-message [isValid]="latitudeRef.invalid && (latitudeRef.dirty || latitudeRef.touched)">
                                Please specify the Latitude Coordinate
                            </validation-message>
                        </div>
                        <div class="form-group col-sm-6 col-md-4">
                            <label for="Longitude">Longitude Coordinate:</label>
                            <input #longitudeRef="ngModel" [(ngModel)]="model.Longitude" type="text" name="Longitude" id="Longitude" class="form-control" [disabled]="!access.save">

                            <validation-message [isValid]="longitudeRef.invalid && (longitudeRef.dirty || longitudeRef.touched)">
                                Please specify the Longitude Coordinate
                            </validation-message>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-12 col-md-8">
                            <label for="Directions">Directions:</label>
                            <textarea #directionsRef="ngModel" rows="3" [(ngModel)]="model.Directions" name="Directions" id="Directions" class="form-control" [disabled]="!access.save"></textarea>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-12 col-md-8">
                            <label for="Comments">Comments:</label>
                            <textarea #commentsRef="ngModel" rows="3" [(ngModel)]="model.Comments" name="Comments" id="Comments" class="form-control" required [disabled]="!access.save"></textarea>

                            <validation-message [isValid]="commentsRef.invalid && (commentsRef.dirty || commentsRef.touched)">
                                Please enter Comments
                            </validation-message>
                        </div>
                    </div>

                    <tabset #moduleTabs>
                        <ng-template ngFor let-type [ngForOf]="lookupValues['FLUIDS_REQUEST_ITEM_TYPE']" let-i="index">
                            <tab [active]="i == selectedTabIndex">
                                <ng-template tabHeading>
                                    <i class="far fa-file-alt"></i> <span class="ml-2">{{type.Description}} Tank</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-12">
                                        <label [htmlFor]="'Need' + type.Name">
                                            Are you swapping out {{['a','e','i','o','u'].indexOf(type.Name[0].toLowerCase()) < 0 ? 'a' : 'an'}} {{type.Description.toLowerCase()}} tank?
                                        </label>
                                        <div>
                                            <label class="switch switch-text switch-success switch-lg">
                                                <input [(ngModel)]="model['Need' + type.Name]" [name]="'Need' + type.Name" [id]="'Need' + type.Name" class="switch-input" type="checkbox" [disabled]="!access.save">
                                                <span class="switch-label" data-on="Yes" data-off="No"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label [htmlFor]="type.Name + 'Product'">{{type.Description}} Product:</label>
                                        <select #productRef="ngModel" [name]="type.Name + 'Product'" [id]="type.Name + 'Product'" class="form-control" [(ngModel)]="types[type.Pk].ProductId" required [disabled]="!model['Need' + type.Name] || !access.save" (ngModelChange)="onProductUpdate(types[type.Pk])">
                                            <option *ngIf="!types[type.Pk].ProductId"></option>
                                            <option *ngFor="let item of lookupValues[type.Name + 'Products']" [value]="item.PartId">{{item.PartName}}</option>
                                        </select>

                                        <validation-message [isValid]="productRef.invalid && (productRef.dirty || productRef.touched)">
                                            Please select a Product
                                        </validation-message>
                                    </div>
                                </div>

                                <ng-container *ngIf="model.Status == 'PENDING' && (access.process || access.admin)">
                                    <hr>

                                    <div class="form-row">
                                        <div class="form-group col-sm-6 col-md-4">
                                            <label [htmlFor]="type.Name + 'InvoiceNumber'">Invoice Number:</label>
                                            <input #invoiceNumberRef="ngModel" [(ngModel)]="types[type.Pk].InvoiceNumber" type="text" [name]="type.Name + 'InvoiceNumber'" [id]="type.Name + 'InvoiceNumber'" class="form-control" required [disabled]="!model['Need' + type.Name]">

                                            <validation-message [isValid]="invoiceNumberRef.invalid && (invoiceNumberRef.dirty || invoiceNumberRef.touched)">
                                                Please enter the Invoice Number
                                            </validation-message>
                                        </div>

                                        <div class="form-group col-sm-6 col-md-4">
                                            <label [htmlFor]="type.Name + 'InvoiceDate'">Invoice Date:</label>
                                            <p-calendar #invoiceDateRef="ngModel" [name]="type.Name + 'InvoiceDate'" [id]="type.Name + 'InvoiceDate'" [(ngModel)]="types[type.Pk].InvoiceDate" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true" [disabled]="!model['Need' + type.Name]">
                                            </p-calendar>

                                            <validation-message [isValid]="invoiceDateRef.invalid && (invoiceDateRef.dirty || invoiceDateRef.touched)">
                                                Please enter the Invoice Date
                                            </validation-message>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-sm-6 col-md-4">
                                            <label [htmlFor]="type.Name + 'InvoiceQuantity'">Invoice Quantity:</label>
                                            <input #invoiceQuantityRef="ngModel" [(ngModel)]="types[type.Pk].InvoiceQuantity" type="number" [name]="type.Name + 'InvoiceQuantity'" [id]="type.Name + 'InvoiceQuantity'" class="form-control" required numbersOnly [allowDecimals]="false" [disabled]="!model['Need' + type.Name]">

                                            <validation-message [isValid]="invoiceQuantityRef.invalid && (invoiceQuantityRef.dirty || invoiceQuantityRef.touched)">
                                                Please enter the Invoice Quantity
                                            </validation-message>
                                        </div>

                                        <div class="form-group col-sm-6 col-md-4">
                                            <label [htmlFor]="type.Name + 'InvoicePrice'">Invoice Price:</label>
                                            <input #invoicePriceRef="ngModel" [(ngModel)]="types[type.Pk].InvoicePrice" type="text" [name]="type.Name + 'InvoicePrice'" [id]="type.Name + 'InvoicePrice'" class="form-control" required [disabled]="!model['Need' + type.Name]">

                                            <validation-message [isValid]="invoicePriceRef.invalid && (invoicePriceRef.dirty || invoicePriceRef.touched)">
                                                Please enter the Invoice Price
                                            </validation-message>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-sm-6 col-md-4">
                                            <label [htmlFor]="type.Name + 'InvoiceAmount'">Invoice Amount:</label>
                                            <input #invoiceAmountRef="ngModel" [(ngModel)]="types[type.Pk].InvoiceAmount" type="number" [name]="type.Name + 'InvoiceAmount'" [id]="type.Name + 'InvoiceAmount'" class="form-control" required numbersOnly [disabled]="!model['Need' + type.Name]">

                                            <validation-message [isValid]="invoiceAmountRef.invalid && (invoiceAmountRef.dirty || invoiceAmountRef.touched)">
                                                Please enter the Invoice Amount
                                            </validation-message>
                                        </div>

                                        <div class="form-group col-sm-6 col-md-4">
                                            <label [htmlFor]="type.Name + 'InvoiceTax'">Invoice Tax:</label>
                                            <input #invoiceTaxRef="ngModel" [(ngModel)]="types[type.Pk].InvoiceTax" type="text" [name]="type.Name + 'InvoiceTax'" [id]="type.Name + 'InvoiceTax'" class="form-control" required [disabled]="!model['Need' + type.Name]">

                                            <validation-message [isValid]="invoiceTaxRef.invalid && (invoiceTaxRef.dirty || invoiceTaxRef.touched)">
                                                Please enter the Invoice Tax
                                            </validation-message>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-sm-6 col-md-4">
                                            <label [htmlFor]="type.Name + 'InvoiceTotal'">Invoice Total:</label>
                                            <input #invoiceTotalRef="ngModel" [(ngModel)]="types[type.Pk].InvoiceTotal" type="number" [name]="type.Name + 'InvoiceTotal'" [id]="type.Name + 'InvoiceTotal'" class="form-control" required numbersOnly [disabled]="!model['Need' + type.Name]">

                                            <validation-message [isValid]="invoiceTotalRef.invalid && (invoiceTotalRef.dirty || invoiceTotalRef.touched)">
                                                Please enter the Invoice Total
                                            </validation-message>
                                        </div>
                                    </div>
                                </ng-container>
                            </tab>
                        </ng-template>
                    </tabset>
                </form>
            </div>
        </div>
    </div>
</div>