<div class="row">
    <div class="col-md-12">
        <div class="form-group" *ngIf="hasFilter">
            <input #searchInputRef type="text" placeholder="Search" class="form-control" [(ngModel)]="searchQuery" (debounceSearch)="onFiltered()" debounce [disabled]="isSearching" />
        </div>

        <div class="form-group" *ngIf="showSelect">
            <select name="Status" [(ngModel)]="searchModel.Status" (ngModelChange)="onSelect()" *ngIf="showSelectGroup" class="form-control">
                <optgroup *ngFor="let options of selectOptions" [label]="options.group">
                    <option *ngFor="let option of options.values" [value]="option.value">{{option.name}}</option>
                </optgroup>
            </select>

            <select name="Status" [(ngModel)]="searchModel.Status" (ngModelChange)="onSelect()" *ngIf="!showSelectGroup" class="form-control">
                <option *ngFor="let option of selectOptions" [value]="option.value">{{option.name}}</option>
            </select>
        </div>

        <ng-content></ng-content>
    </div>

    <div class="col-md-4 col-sm-12">
        <label>
            Total Records: {{totalRecords | number}} <span *ngIf="totalRecords">(page {{searchModel.Page | number}} of {{totalPages | number}})</span>
        </label>

        <div class="d-none d-md-block">
            <div class="form-inline">
                <label for="Limit">Records per page:</label>
                <select [(ngModel)]="searchModel.Limit" name="Limit" id="Limit" (ngModelChange)="onUpdatedLimit()" class="form-control form-control-sm ml-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                </select>
            </div>
        </div>
    </div>

    <div class="col-md-8 col-sm-12" *ngIf="totalRecords">
        <pagination [totalItems]="totalRecords" (pageChanged)="setPage($event)" [maxSize]="5" [itemsPerPage]="searchModel.Limit"
            [rotate]="true" [(ngModel)]="pageStart" [boundaryLinks]="true" (numPages)="updateTotal($event)"
            [customFirstTemplate]="firstTemplate"
            [customLastTemplate]="lastTemplate"
            [customPreviousTemplate]="previousTemplate"
            [customNextTemplate]="nextTemplate"
        ></pagination>

        <ng-template #firstTemplate>
            <i class="fas fa-angle-double-left"></i>
        </ng-template>

        <ng-template #previousTemplate>
            <i class="fas fa-angle-left"></i> <span class="d-none d-md-inline ml-1">Previous</span>
        </ng-template>

        <ng-template #nextTemplate>
            <span class="d-none d-md-inline mr-2">Next</span> <i class="fas fa-angle-right"></i>
        </ng-template>

        <ng-template #lastTemplate>
            <i class="fas fa-angle-double-right"></i>
        </ng-template>
    </div>
</div>
