import { DecimalPipe } from '@angular/common';
import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { NgControl, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, throwError, Subject } from 'rxjs';

import {
    AreaModel,
    ConfirmModel,
    MessageModel,
    ReportModel,
    UserModel
} from '../../shared/models';

import { AppGlobalService } from './app-global.service';
import { ModalService } from './modal.service';

import { ToastrService } from 'ngx-toastr';
import { TabDirective } from 'ngx-bootstrap/tabs';

import { Calendar } from 'primeng/calendar';

import * as moment from 'moment';
import * as PDFJS from 'pdfjs-dist';
 
@Directive()
@Injectable()
export class Common {
    @Output() isLoading: EventEmitter<any> = new EventEmitter();
    @Output() pageTitle: EventEmitter<any> = new EventEmitter();

    private _isLoading: Boolean = false;
    private _requests: any = {};
    showChildItemReplaceMessage: boolean = false;
    showCloseButton: boolean = false;
    showYesNoButtons: boolean = false;
    message: MessageModel;
    yesClicked: boolean = false;

    // Observable string sources
    private emitChangeSource = new Subject<any>();
    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();
    // Service message commands
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    constructor(
        private global: AppGlobalService,
        private modalService: ModalService,
        private router: Router,
        private toastr: ToastrService) {

    }

    setupModuleAcces() {
        const user = this.global.sparkUser as UserModel;
        const access = {};

        user.UserActions.map(x => {
            const actionId = x.ApplicationActionId.toLowerCase();

            if (!access[actionId]) {
                access[actionId] = {};
            }

            // let moduleId = x.ApplicationModuleDescription.replace(' ', '');
            // moduleId = moduleId.charAt(0).toLowerCase() + moduleId.substr(1);
            const moduleId = x.ApplicationModuleId;

            access[actionId][moduleId] = true;
        });

        return access;
    }

    hasAccess(application: string, module: string, action: string) {
        if (!this.global.sparkUser) {
            return false;
        }

        var hasAccess = false;
        var user = this.global.sparkUser;

        if (user == null || user.UserActions == null)
            return false;

        user.UserActions.forEach((item, index) => {
            if (item.ApplicationId.toLowerCase() == application.toLowerCase() &&
                item.ApplicationModuleId.toLowerCase() == module.toLowerCase() &&
                item.ApplicationActionId.toLowerCase() == action.toLowerCase()) {
                hasAccess = true;
                return;
            }
        });

        return hasAccess;
    }

    sort(data: any, field: string, order: string = 'asc') {
        let format = (value: any) => (value || '').toString().toLowerCase();
        let resolve = (value: any, properties: string) => {
            return properties.split('.').reduce((arr, i) => {
                return arr[i];
            }, value);
        };

        if (field.toLowerCase().indexOf('date') >= 0) {
            format = (value: any) => moment(value).toDate().getTime();
        }

        if (field.toLowerCase().indexOf('pk') >= 0 || field.toLowerCase().indexOf('hours') >= 0 ||
            field.toLowerCase().indexOf('amount') >= 0 || field.toLowerCase().indexOf('sort') >= 0) {
            format = (value: any) => value as number;
        }

        if (field.indexOf(',') > 0) {
            const fields = field.split(',');

            return data.sort((a, b): number => {
                const val1 = format(resolve(a, fields[0])).localeCompare(format(resolve(a, fields[1])));
                const val2 = format(resolve(b, fields[0])).localeCompare(format(resolve(b, fields[1])));

                if (order == 'asc') {
                    return val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
                }

                return val1 < val2 ? 1 : val1 > val2 ? -1 : 0;
            });
        }
        else {
            return data.sort((a, b): number => {
                const val1 = format(resolve(a, field));
                const val2 = format(resolve(b, field));

                if (order == 'asc') {
                    return val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
                }

                return val1 < val2 ? 1 : val1 > val2 ? -1 : 0;
            });
        }
    }

    sortByNumber(data: any, field: string, order: string = 'asc') {
        return data.sort((a, b): number => {
            const val1 = a[field] as number;
            const val2 = b[field] as number;

            if (order == 'asc') {
                return val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
            }

            return val1 < val2 ? 1 : val1 > val2 ? -1 : 0;
        });
    }

    filterRecordByKey(recordsToFilter: any[], recordsToFilterOut: any[]) {
        if (recordsToFilterOut == null)
            return recordsToFilter;

        if (recordsToFilterOut != null) {
            // Filter out the records that were passed in by Key.
            for (var i = 0; i < recordsToFilterOut.length; i++) {
                recordsToFilter = recordsToFilter.filter(item2 => item2.Key !== recordsToFilterOut[i].Key);
            }
        }

        return recordsToFilter;
    }

    getUser(): UserModel {
        var user = this.global.sparkUser;

        if (user == null) {
            return null;
        }

        let expireTime = new Date(user.ExpireTime);
        let currentTime = new Date(Date.now());

        if (!user.IsAuthenticated || expireTime < currentTime) {
            user.IsAuthenticated = false;
        }

        return new UserModel(user);
    }

    setUserStorage(resData: any) {
        localStorage.setItem('user', JSON.stringify(resData));
    }

    removeUserStorage() {
        localStorage.removeItem('user');
    }

    getGenericStorage(name: string) {
        var genericSession = localStorage.getItem(name);
        var generic = JSON.parse(genericSession);

        if (generic == null) {
            return null;
        }

        return generic;
    }

    setGenericStorage(name: string, data: any) {
        localStorage.setItem(name, JSON.stringify(data));
    }

    removeGenericStorage(name: string) {
        localStorage.removeItem(name);
    }

    getSparkStorage(name: string, data?: any) {
        var genericSession = localStorage.getItem('Spark.' + name);
        var generic = JSON.parse(genericSession);

        if (genericSession == null && data != null) {
            generic = data;
            this.setSparkStorage(name, data);
        }

        if (generic == null) {
            return null;
        }

        return generic;
    }

    setSparkStorage(name: string, data: any) {
        localStorage.setItem('Spark.' + name, JSON.stringify(data));
    }

    removeSparkStorage(name: string) {
        localStorage.removeItem('Spark.' + name);
    }

    numberToUndefined(value: number) {
        return (value > 0 ? value : undefined);
    }

    numberToEmpty(value: number) {
        return (value > 0 ? value.toString() : '');
    }

    timeConvertAMPM(timeToConvert: Date) {
        var hours = timeToConvert.getHours();
        var minutes = timeToConvert.getMinutes();
        var minutesString = ""
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutesString = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
        var strTime = hours + ':' + minutesString + ' ' + ampm;
        return strTime;
    }

    dateIsInPeriod(timeSheet: any, selectedDate: Date, hoursUntilPayrollCutoff: number) {
        var now = moment();
        var userSelectedDt = moment(selectedDate);
        var currentPeriodStartDt = moment(timeSheet.PayPeriodFirstDate);
        var currentPeriodEndDt = moment(timeSheet.PayPeriodLastDate).endOf('day');
        var finalCutoffDt = moment(currentPeriodStartDt).hours(hoursUntilPayrollCutoff);
        var priorPayPeriodStart = moment(currentPeriodStartDt).add(-14, 'days');

        let hoursAddedWithinGracePeriod = (now < finalCutoffDt && userSelectedDt >= priorPayPeriodStart && userSelectedDt <= currentPeriodEndDt);
        let selectedDateInCurrentPayPeriod: boolean = (userSelectedDt >= currentPeriodStartDt && userSelectedDt <= currentPeriodEndDt);

        return (selectedDateInCurrentPayPeriod || hoursAddedWithinGracePeriod);
    }

    httpErrorHandler(error: string) {
        this._requests = {};
        // todo: see how we can make this work in the catch of the promise array in handleRequests
        this.hideLoader();
        this.showError('An unexpected error occurred', error);
        return throwError(error);
    }

    httpBooleanHandler(res: HttpResponse<any>) {
        return res.status == 200;
    }

    // temporary method until a component is made to better handle modelstate errors
    parseErrors(errors: any) {
        let result = '';

        if (errors == undefined) {
            return result;
        }

        if (errors.Message) {
            if (errors.ErrorMessage) {
                result = `<li>${errors.Message}: ${errors.ErrorMessage}</li>`;
            }
            else if (errors.ExceptionMessage) {
                result = `<li>${errors.Message}: ${errors.ExceptionMessage}</li>`;
            }
            else {
                result = `<li>${errors.Message}</li>`;
            }
        }

        if (errors.ModelState) {
            for (let key in errors.ModelState) {
                if (key == 'model') {
                    continue;
                }

                result += `<li><b>${key.replace('model.', '')}</b><ul>`;

                for (let i in errors.ModelState[key]) {
                    result += `<li>${errors.ModelState[key][i]}</li>`;
                }

                result += '</ul></li>';
            }
        }

        if (!errors.Message && !errors.ModelState) {
            if (errors) {
                result += `<li>${errors}</li>`;
            }
            else {
                result += '<li>Request failed.</li>';
            }
        }

        return `<ul>${result}</ul>`;
    }

    validateWorkflowNotification(model: any) {
        const activeWorkflow = model.Workflows.find(x => x.Active);

        if (activeWorkflow) {
            const activeWorkflowDetail = activeWorkflow.Details.find(x => x.Pk > 0 && x.Status.toLowerCase() == "pending");

            if (activeWorkflowDetail && !activeWorkflowDetail.WorkflowDetail.Silent && activeWorkflowDetail.UsersNotified.toLowerCase() == '(no notifications sent)') {
                return false;
            }
        }

        return true;
    }

    getPendingStatus(model: any) {
        const status = model.Status;
        const activeWorkflowDetail = this.getActiveWorkflow(model);

        if (!activeWorkflowDetail) {
            return status
        }

        return `${status} ${activeWorkflowDetail.WorkflowDetail.Type.PendingText}`;
    }

    getPendingText(model: any) {
        const status = model.Status;
        const activeWorkflowDetail = this.getActiveWorkflow(model);

        if (!activeWorkflowDetail) {
            return status
        }

        return activeWorkflowDetail.WorkflowDetail.Type.PendingText;
    }

    getActiveWorkflow(model: any) {
        const activeWorkflow = model.Workflows.find(x => x.Active);

        if (!activeWorkflow) {
            return null;
        }

        const activeWorkflowDetail = activeWorkflow.Details.find(x => x.Pk > 0 && x.Status.toLowerCase() == "pending");

        // Individual module records fetch the full workflow history and includes any future steps. Future steps have
        // a null Status value, so there is a period between updating the pending WorkflowModuleDetail and creating
        // the next step with a real Pk. In that instance, return Pending, which will appear briefly, if at all.
        if (!activeWorkflowDetail) {
            return null;
        }

        return activeWorkflowDetail;
    }

    getFileType(extension: string) {
        var fileType = "image/png";

        switch (extension.toLowerCase()) {
            case "png":
                fileType = "image/png";
                break;
            case "gif":
                fileType = "image/gif";
                break;
            case "bmp":
                fileType = "image/bmp";
                break;
            case "jpg":
            case "jpeg":
                fileType = "image/jpeg";
                break;
            case "pdf":
                fileType = "application/pdf";
                break;
        }

        return fileType;
    }

    renderImage(file: any) {
        var imageType = this.getFileType(file.ImageName.substring(file.ImageName.lastIndexOf('.') + 1));

        if (imageType.indexOf("image") == -1) {
            return null;
        }

        return (file.ImageData.indexOf("base64") !== -1) ? file.ImageData : "data:" + imageType + ";base64," + file.ImageData;
    }

    resizeImage(fileData: string, extension: string, maxWidth: number = 1600, maxHeight: number = 1600): Promise<string> {
        return new Promise(resolve => {
            var canvasControl = document.createElement('canvas');
            var imageType = this.getFileType(extension);
            var tags = "data:" + imageType + ";base64,";

            var context = canvasControl.getContext('2d');
            var base_image = new Image();

            base_image.src = fileData.indexOf("base64") !== -1 ? fileData : (tags + fileData);
            base_image.onload = function () {
                var needsResizing = false;
                var width = base_image.width;
                var height = base_image.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                        needsResizing = true;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                        needsResizing = true;
                    }
                }

                if (needsResizing) {
                    canvasControl.width = width;
                    canvasControl.height = height;
                    context.drawImage(base_image, 0, 0, width, height);
                    fileData = canvasControl.toDataURL(imageType, .90).substring(tags.length);
                }

                resolve(fileData);
            }
        });
    }

    convertPdf(fileData: any, extension: string, maxWidth: number = 600, maxHeight: number = 450): Promise<string> {
        return new Promise(resolve => {
            const common = this;
            // todo: find out how to make pdfjs worker stop being named 0.chunk.js
            PDFJS.GlobalWorkerOptions.workerSrc = '0.chunk.js';

            // Gets the PDF document to then convert to an image.
            PDFJS.getDocument({ data: fileData }).promise.then(
                function getPdf(file) {
                    //
                    // Fetch the first page
                    //
                    file.getPage(1).then(function getPage(page) {
                        var viewport = page.getViewport({scale: 1.0});
                        //
                        // Prepare canvas using PDF page dimensions
                        //
                        var canvas = document.createElement('canvas');
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        //
                        // Render PDF page into canvas context
                        //
                        var task = page.render({ canvasContext: context, viewport: viewport });
                        task.promise.then(() => {
                            const fileData = canvas.toDataURL('image/jpeg', .90).substring('data:image/jpeg;base64,'.length);
                            common.resizeImage(fileData, 'jpg', maxWidth, maxHeight);
                            resolve(fileData);
                        });
                    });
                },
                function (error) {
                    console.log(error);
                }
            );
        });
    }

    downloadReport(fileName: string, report: ReportModel) {
        var arrBuffer = this.base64ToArrayBuffer(report.ReportStream);
        this.downloadFile(`${fileName}.${report.OutputType}`, arrBuffer);
    }

    downloadFile(fileName: string, arrayBuffer: any) {
        const link = document.createElement('a') as HTMLAnchorElement;
        link.download = fileName;

        const fileExtension = link.download.substring(link.download.lastIndexOf('.') + 1).toLowerCase();
        link.href = URL.createObjectURL(new Blob([arrayBuffer], { type: this.getFileType(fileExtension) }));
        link.click();
    }

    base64ToArrayBuffer(data) {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    arrayBufferToBase64(data) {
        var binary = '';
        var bytes = new Uint8Array(data);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    base64ToBlobParts(data) {
        var byteString = window.atob(data.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return [ab];
    }

    arrayBufferToFile(data: any, fileType: string, fileName: string): File {
        const blob = new Blob(data, { type: fileType });

        return this.blobToFile(blob, fileName);
    }

    blobToFile(data: Blob, fileName: string): File {
        const file: any = data;
        file.name = fileName;

        return file as File;
    }

    getStates() {
        return [
            { Abbreviation: 'AL', Name: 'Alabama' },
            { Abbreviation: 'AK', Name: 'Alaska' },
            { Abbreviation: 'AZ', Name: 'Arizona' },
            { Abbreviation: 'AR', Name: 'Arkansas' },
            { Abbreviation: 'CA', Name: 'California' },
            { Abbreviation: 'CO', Name: 'Colorado' },
            { Abbreviation: 'CT', Name: 'Connecticut' },
            { Abbreviation: 'DE', Name: 'Delaware' },
            { Abbreviation: 'FL', Name: 'Florida' },
            { Abbreviation: 'GA', Name: 'Georgia' },
            { Abbreviation: 'GU', Name: 'Guam' },
            { Abbreviation: 'HI', Name: 'Hawaii' },
            { Abbreviation: 'ID', Name: 'Idaho' },
            { Abbreviation: 'IL', Name: 'Illinois' },
            { Abbreviation: 'IN', Name: 'Indiana' },
            { Abbreviation: 'IA', Name: 'Iowa' },
            { Abbreviation: 'KS', Name: 'Kansas' },
            { Abbreviation: 'KY', Name: 'Kentucky' },
            { Abbreviation: 'LA', Name: 'Louisiana' },
            { Abbreviation: 'ME', Name: 'Maine' },
            { Abbreviation: 'MD', Name: 'Maryland' },
            { Abbreviation: 'MA', Name: 'Massachusetts' },
            { Abbreviation: 'MI', Name: 'Michigan' },
            { Abbreviation: 'MN', Name: 'Minnesota' },
            { Abbreviation: 'MS', Name: 'Mississippi' },
            { Abbreviation: 'MO', Name: 'Missouri' },
            { Abbreviation: 'MT', Name: 'Montana' },
            { Abbreviation: 'NE', Name: 'Nebraska' },
            { Abbreviation: 'NV', Name: 'Nevada' },
            { Abbreviation: 'NH', Name: 'New Hampshire' },
            { Abbreviation: 'NJ', Name: 'New Jersey' },
            { Abbreviation: 'NM', Name: 'New Mexico' },
            { Abbreviation: 'NY', Name: 'New York' },
            { Abbreviation: 'NC', Name: 'North Carolina' },
            { Abbreviation: 'ND', Name: 'North Dakota' },
            { Abbreviation: 'OH', Name: 'Ohio' },
            { Abbreviation: 'OK', Name: 'Oklahoma' },
            { Abbreviation: 'OR', Name: 'Oregon' },
            { Abbreviation: 'PA', Name: 'Pennsylvania' },
            { Abbreviation: 'RI', Name: 'Rhode Island' },
            { Abbreviation: 'SC', Name: 'South Carolina' },
            { Abbreviation: 'SD', Name: 'South Dakota' },
            { Abbreviation: 'TN', Name: 'Tennessee' },
            { Abbreviation: 'TX', Name: 'Texas' },
            { Abbreviation: 'UT', Name: 'Utah' },
            { Abbreviation: 'VT', Name: 'Vermont' },
            { Abbreviation: 'VI', Name: 'Virgin Islands' },
            { Abbreviation: 'VA', Name: 'Virginia' },
            { Abbreviation: 'WA', Name: 'Washington' },
            { Abbreviation: 'WV', Name: 'West Virginia' },
            { Abbreviation: 'WI', Name: 'Wisconsin' },
            { Abbreviation: 'WY', Name: 'Wyoming' }
        ];
    }

    round(value: number, precision: number) {
        var shift = function (number, exponent) {
            var numArray = ("" + number).split("e");
            return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + exponent) : exponent));
        };

        return shift(Math.round(shift(value, +precision)), -precision);
    }

    getTimeSpan(totalMinutes: number) {
        const hours = Math.floor(totalMinutes / 60);
        const hourText = new DecimalPipe('en-US').transform(hours, '1.') + ' hour' + (hours == 1 ? '' : 's');

        const minutes = totalMinutes - (hours * 60);
        const minuteText = new DecimalPipe('en-US').transform(minutes, '1.') + ' minute' + (minutes == 1 ? '' : 's');

        if (hours && minutes) {
            return `${hourText} ${minuteText}`;
        }
        else if (minutes == 0) {
            return hourText;
        }

        return minuteText;
    }

    setTitle(text: string) {
        this.pageTitle.emit(text);
    }

    getTitle() {
        return this.pageTitle;
    }

    showModal(id: string) {
        this.modalService.show(id);
    }

    hideModal(id: string, callback?: Function) {
        this.modalService.hide(id);

        if (callback) {
            setTimeout(() => { callback() }, 250);
        }
    }

    showLoader() {
        this.setLoading();
    }

    hideLoader() {
        this.setLoading(false);
    }

    setLoading(value: boolean = true) {
        if (this._isLoading == value) {
            return;
        }

        this._isLoading = value;
        this.isLoading.emit(value);
    }

    getLoading() {
        return this.isLoading;
    }

    showMessage(text: string) {
        this.toastr.success(text);
    }

    showError(title: string, error: string) {
        this.modalService.setMessage(title, error);
    }

    showConfirm(confirm: ConfirmModel) {
        this.modalService.setConfirm(confirm);
    }

    hideConfirm() {
        this.hideModal('confirm-modal');
    }

    isValidDate(dateToCheck: Date) {
        return Object.prototype.toString.call(dateToCheck) === '[object Date]';
    }

    handleRequests(methods: Observable<any>[]) {
        const id = new Date().getTime();

        this.showLoader();

        this._requests[id] = methods.map(x => {
            return new Promise<void>((resolve, reject) => {
                x.subscribe(() => resolve());
            });
        });

        return Promise.all(this._requests[id])
            .then((value) => {
                delete this._requests[id];

                if (Object.keys(this._requests).length == 0) {
                    this.hideLoader();
                }
            }).catch((error) => {
                // todo: see how we can beter handle this
                delete this._requests[id];

                this.hideLoader();
                this.showError('An unexpected error occurred', error);
            });
    }

    isIntegerKey(e: KeyboardEvent) {
        const charCode = e.which || e.keyCode;

        return !(charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105));
    }

    isDoubleKey(e: KeyboardEvent) {
        const charCode = e.which || e.keyCode;
        const target = e.target as HTMLInputElement;

        if (target.value.indexOf('.') >= 0 && (charCode == 110 || charCode == 190)) {
            return false;
        }

        return !(charCode > 31 && (charCode != 46 && charCode != 110 && charCode != 190 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)));
    }

    getUserRoleAccess() {
        const user = this.getUser();
        const roles = user.AppUser.UserRoles.filter(x => x.ApplicationRole.Id).map(x => x.ApplicationRole.Id);

        return {
            fieldTech: roles.find(x => x.toLowerCase() == 'ft') != undefined,
            fleet: roles.find(x => x.toLowerCase() == 'fleet') != undefined,
            admin: roles.find(x => x.toLowerCase() == 'admin') != undefined,            
            areaManager: roles.find(x => x.toLowerCase() == 'areamanager') != undefined,
            regionalManager: roles.find(x => x.toLowerCase() == 'regionalmanager') != undefined,
            regionalDirector: roles.find(x => x.toLowerCase() == 'regionaldirector') != undefined,
            opsVicePresident: roles.find(x => x.toLowerCase() == 'ovp') != undefined,
            sales: roles.find(x => x.toLowerCase() == 'sales') != undefined,
            apManager: roles.find(x => x.toLowerCase() == 'apmanager') != undefined,
            arManager: roles.find(x => x.toLowerCase() == 'armanager') != undefined,
            opsAdmin: roles.find(x => x.toLowerCase() == 'ops_admin') != undefined,
            humanResources: roles.find(x => x.toLowerCase() == 'hr') != undefined,
            purchasing: roles.find(x => x.toLowerCase() == 'pr') != undefined,
            warehousemen: roles.find(x => x.toLowerCase() == 'wh') != undefined,
            accountManager: roles.find(x => x.toLowerCase() == 'accountmanager') != undefined,
            contractAdmin: roles.find(x => x.toLowerCase() == 'contractadmin') != undefined,
            salesAdmin: roles.find(x => x.toLowerCase() == 'salesadmin') != undefined,
            salesManager: roles.find(x => x.toLowerCase() == 'salesmanager') != undefined,
            aarNotify: roles.find(x => x.toLowerCase() == 'aar_notify') != undefined,
            safetyAdmin: roles.find(x => x.toLowerCase() == 'safetyadmin') != undefined,
            safetyCoordinator: roles.find(x => x.toLowerCase() == 'safetycoordinator') != undefined,
            safetyManager: roles.find(x => x.toLowerCase() == 'safetymanager') != undefined
        };
    }

    getExcludedFields() {
        return ['WOID', 'WOPK', 'WorkflowId', 'Workflows', 'CanApprove', 'Status', 'CreationDate', 'CreatedBy', 'LastUpdateDate', 'LastUpdateBy', 'Files'];
    }

    getChanges(model: any, modelOriginal: any, excludedFields: string[] = []) {
        const diff = [];
        const exclude = this.getExcludedFields().concat(excludedFields);

        Object.keys(model).map(x => {
            if (exclude.indexOf(x) < 0) {
                let originalValue = (modelOriginal[x] || '').toString();
                let modelValue = (model[x] || '').toString();

                if (x.toLowerCase().indexOf('date') >= 0) {
                    const now = new Date();
                    originalValue = moment(modelOriginal[x] || now).toDate().getTime();
                    modelValue = moment(model[x] || now).toDate().getTime();
                }

                if (originalValue != modelValue) {
                    diff.push(x);
                }
            }
        });

        return diff;
    }

    showTabWithError(form: NgForm, tabs: TabDirective[]) {
        let fieldId;

        const activeTab = tabs.find(x => x.active);

        if (activeTab) {
            const parent: Element = activeTab.elementRef.nativeElement;
            const errorField = parent.querySelector('.ng-invalid');

            // The user is already on a tab that has an invalid field
            if (errorField) {
                return;
            }
        }

        for (let x in form.controls) {
            if (form.controls[x].invalid && !fieldId) {
                fieldId = x;
                break;
            }
        }

        if (!fieldId) {
            return;
        }

        const field = document.querySelector(`#${fieldId}`);

        if (!field) {
            return;
        }

        const tab = field.closest('tab');

        if (!tab) {
            return;
        }

        const i = Array.from(tab.parentNode.children).indexOf(tab);

        if (tabs[i]) {
            tabs[i].active = true;
        }
    }

    formatDateFields(model: any, ...fields: string[]) {
        const newModel = JSON.parse(JSON.stringify(model));

        if (fields.length) {
            fields.map(x => {
                if (newModel[x]) {
                    newModel[x] = moment(newModel[x]).format('YYYY-MM-DDTHH:mm');
                }
            });
        }

        return newModel;
    }

    expandAreaList(data: AreaModel[]): AreaModel[] {
        const list = JSON.parse(JSON.stringify(data));

        data.map(x => {
            const region = list.find(y => y.Pk == x.RegionPk);

            if (!region && x.RegionPk) {
                list.push(new AreaModel({
                    Pk: x.RegionPk,
                    Id: x.RegionId,
                    Name: x.RegionName,
                    DivisionPk: x.DivisionPk,
                    DivisionId: x.DivisionId,
                    DivisionName: x.DivisionName
                }));
            }

            const division = list.find(y => y.Pk == x.DivisionPk);

            if (!division && x.DivisionPk) {
                list.push(new AreaModel({
                    Pk: x.DivisionPk,
                    Id: x.DivisionId,
                    Name: x.DivisionName
                }));
            }
        });

        return this.sort(list, 'Id');
    }

    getFluidsRequestPurchaseOrder(deliveryDate: Date, assetId: string, type: string) {
        if (assetId.length > 5) {
            return '';
        }

        let purchaseOrderNumber = moment(deliveryDate).format('MMDDYY') + assetId;

        if (type == 'Coolant')
            purchaseOrderNumber += '-CLNT';
        if (type == 'CompoundOil')
            purchaseOrderNumber += '-CO';

        return purchaseOrderNumber;
    }

    closeCalendar(e: NgControl) {
        (e.valueAccessor as Calendar).hideOverlay();
    }

    isValidFileType(file: any, ext: string) {
        return file.name.toLowerCase().endsWith('.' + ext.toLowerCase());
    }

    isNumber(value: string | number): boolean {
        return ((value != null) && !isNaN(Number(value)));
    }
    sanitize(str: any) {       
        if (str === undefined || str === null || typeof(str) !== 'string' ) {
            return str;
        }
        let sanitizedStr = str;
        if (str.startsWith('=') || str.startsWith('+') || str.startsWith('-') || str.startsWith('@')) {
            sanitizedStr = "'" + sanitizedStr;
        }
        return sanitizedStr;
    }
}
