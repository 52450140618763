import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ApplicationUserModel,
    AreaModel,
    ConfirmModel,
    InvoicingInstructionModel,
    LookupModel,
    LookupValueModel,
    MacolaContactModel,
    MacolaCustomerModel,
    SalesOrderModel,
    SparkModuleTypeModel,
    StateData,
    UnitModel,
    UserModel,
    SalesOrderEscalationModel,
    ModalFooterModel
} from '../../shared/models';

import {
    AreaService,
    Common,
    InvoicingInstructionService,
    LookupService,
    LookupValueService,
    MacolaCustomerService,
    SalesOrderService,
    SparkFileService,
    SparkModuleTypeService,
    UnitService,
    UserService,
    WorkflowService
} from '../../core/services';

import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import * as moment from 'moment';

@Component({
    selector: 'invoicing-instruction-detail',
    templateUrl: './invoicing-instruction-detail.component.html'
})
export class InvoicingInstructionDetailComponent implements OnInit {
    @ViewChild('invoicingInstructionForm', { static: true }) invoicingInstructionForm: NgForm;
    @ViewChild('moduleTabs', { static: true }) tabs: TabsetComponent;

    @Input() public id: number = 0;
    @Input() public preview: boolean = false;

    access: any = {
        actionMenu: true,
        changeEffectiveDate: false,
        createAmendment: false,
        createReinstate: false,
        createRenewTerm: false,
        createTermination: false,
        save: true,
        delete: true,
        submit: true,
        print: false,
        editNotes: true
    };
    accountRepresentatives: ApplicationUserModel[] = new Array();
    hasAmendmentError: boolean = false;
    areaManagers: ApplicationUserModel[] = new Array();
    areas: AreaModel[] = new Array();
    childType: string;
    contacts: MacolaContactModel[] = new Array();
    counties: LookupModel[] = new Array();
    customers: MacolaCustomerModel[] = new Array();
    edit: any = {
        area: false,
        customer: false,
        location: false,
        rate: false,
        rateType: false,
        term: false,
        information: false,
        ecoView: false
    };
    latestSalesOrder: SalesOrderModel = new SalesOrderModel();
    loaded: boolean = false;
    locations: LookupModel[] = new Array();
    model: InvoicingInstructionModel = new InvoicingInstructionModel();
    modelOriginal: InvoicingInstructionModel = new InvoicingInstructionModel();
    selectedAsset: UnitModel = new UnitModel();
    selectedFile: File;
    sparkModuleType: SparkModuleTypeModel;
    states: LookupModel[] = new Array();
    typeahead: any = {};
    uploading: boolean = false;
    uploadProgress: number;
    user: UserModel = new UserModel();
    users: ApplicationUserModel[] = new Array();
    view: string;
    latestRenewTermII: InvoicingInstructionModel;
    previousII: InvoicingInstructionModel;
    lookupValues: any = {};
    selectedReason: LookupValueModel;

    confirmSubmit: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to submit the Invoicing Instruction?',
        onConfirm: () => { this.onConfirmSubmit() }
    });

    confirmDelete: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to cancel the Invoicing Instruction?',
        onConfirm: () => { this.onConfirmDelete() }
    });
  
    confirmLoseChanges: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to cancel and lose changes?',
        onConfirm: () => { this.goBack() }
    });

    constructor(
        public common: Common,
        private areaService: AreaService,
        private lookupService: LookupService,
        private lookupValueService: LookupValueService,
        private macolaCustomerService: MacolaCustomerService,
        private route: ActivatedRoute,
        private router: Router,
        private invoicingInstructionService: InvoicingInstructionService,
        private salesOrderService: SalesOrderService,
        private sparkFileService: SparkFileService,
        private sparkModuleTypeService: SparkModuleTypeService,
        private unitService: UnitService,
        private userService: UserService,
        private workflowService: WorkflowService
    ) {
        this.common.setTitle('Sales | Invoicing Instruction');

        this.route.queryParams.subscribe((params: Params) => {
            if (params['id']) {
                this.model.Pk = params['id'];

                if (this.loaded) {
                    this.tabs.tabs[0].active = true;
                    this.common.handleRequests([this._onLoad()])
                }
            }

            if (params['sopk']) {
                this.model.SalesOrderPk = params['sopk'];
            }

            // when the create amendment button is clicked
            if (params['parentPk']) {
                this.model.ParentPk = params['parentPk'];
                this.childType = params['type'];

                if (this.loaded) {
                    this.tabs.tabs[0].active = true;
                    this.common.handleRequests([this._onLoadByParent()]);
                }
            }

            this.view = params['view'];
        });

        this.user = this.common.getUser();

        this.access = Object.assign(this.access, {
            create: this.common.hasAccess('OPS', 'II', 'Create'),
            update: this.common.hasAccess('OPS', 'II', 'Update'),
            delete: this.common.hasAccess('OPS', 'II', 'Delete'),
            approve: this.common.hasAccess('OPS', 'II', 'Approve'),
            complete: this.common.hasAccess('OPS', 'II', 'Complete')
        }, this.common.getUserRoleAccess());

        if (!this.model.Pk && !this.access.create) {
            this.common.showError('Error', 'User does not have access to create Invoicing Instruction records.');
            this.goBack();
            return;
        }

        this.common.handleRequests([
            this._getAreas(),
            this._getStates(),
            this._getUsers(),
            this._getCustomers(),
            this._getLocations(),
            this._getModuleType(),
            this._getLookupValues()
        ]).then(() => {
            if (this.preview) {
                this.common.handleRequests([this._onLoad()]).then(() => {
                    this.loaded = true;
                });
            }
            else {
                if (this.model.Pk) {
                    this.common.handleRequests([this._onLoad()]).then(() => {
                        this.common.handleRequests([this._getLatestSalesOrder(), this._getLatestRenewTermII()]);
                    });
                }
                // if the page is refreshed while attempting to create other II types
                else if (this.model.ParentPk) {
                    this.common.handleRequests([this._onLoadByParent()]);
                }
                else if (this.model.SalesOrderPk) {
                    this.common.handleRequests([this._onLoadBySalesOrder()]);
                }

                this.loaded = true;
            }
        });
    }

    ngOnInit() {
        if (this.id) {
            this.model.Pk = this.id;
            this.access.save = false;
        }
    }

    private _onLoadBySalesOrder() {
        return this.salesOrderService.get(this.model.SalesOrderPk)
            .pipe(map(data => {
                const type = this.model.Type;

                this.latestSalesOrder = data;

                this.model = data.createInvoicingInstruction();

                this.calculateDailyRate();

                this.modelOriginal = new InvoicingInstructionModel(this.model);

                this._onLoadDetails();
            }));
    }

    private _onLoadByParent() {
        return this.invoicingInstructionService.get(this.model.ParentPk)
            .pipe(map(data => {
                this.model = data.createChild(this.childType);
                this.modelOriginal = new InvoicingInstructionModel(this.model);

                this._onLoadDetails();
            }));
    }

    private _onLoad(workflowStatus?: string) {
        // todo: probably remove this because of how IIs are created
        if (!this.model.Pk) {
            return of([]);
        }

        return this.invoicingInstructionService.get(this.model.Pk)
            .pipe(map(data => {
                this.model = data;
                this.modelOriginal = new InvoicingInstructionModel(data);

                if (workflowStatus != null) {
                    this.common.showMessage(`Successfully ${workflowStatus.toLowerCase()} Invoicing Instruction.`);

                    this._onLoadDetails();
                }
                else {
                    this._onLoadDetails();
                }
            }));
    }

    private _onLoadDetails() {
        this.typeahead['Unit'] = this.model.AssetName;

        if (this.model.AreaId) {
            const area = this.areas.find(x => x.Id == this.model.AreaId);

            if (area) {
                this.typeahead['Area'] = area.Name;
            }
        }

        this.typeahead['State'] = this.model.State;
        this.typeahead['County'] = this.model.County;

        if (this.model.State) {
            const state = this.states.find(x => x.Name == this.model.State);

            if (state) {
                this._getCounties(state.Description).subscribe();
            }
        }

        this.typeahead['AccountManager'] = this.model.AccountManagerName;
        this.typeahead['OperationsManager'] = this.model.OperationsManagerName;
        this.typeahead['Customer'] = this.model.CustomerName;

        if (this.model.CustomerId) {
            const customer = this.customers.find(x => x.Code == this.model.CustomerId);

            if (customer) {
                this.contacts = this.common.sort(customer.Contacts.slice(), 'FullName');
            }
        }

        if (this.model.ReleaseReasonPk) {
            this.selectedReason = this.lookupValues['TERM_RELEASE_REASON'].find(x => x.Pk == this.model.ReleaseReasonPk);
        }

        this.refreshAccess();
    }

    private _onSave(workflowStatus?: string): Observable<InvoicingInstructionModel> {
        let method;
        const status = this.model.Status.toLowerCase();

        // change the type to spawn different workflows when the monthly rate differs
        if (status == 'new' || status == 'denied') {
            if (this.model.isAmendment()) {
                this.model.Type = this.model.MonthlyRate < this.model.Parent.MonthlyRate ? 'AmendmentRate' : 'Amendment';
            }
            else if (this.model.Type.toLowerCase() == 'new') {
                this.model.Type = this.model.MonthlyRate < this.latestSalesOrder.MonthlyRate ? 'NewRate' : 'New';
            }
            else if (this.model.Type.toLowerCase() == 'renewnew') {
                this.model.Type = this.model.MonthlyRate < this.latestSalesOrder.MonthlyRate ? 'RenewNewRate' : 'RenewNew';
            }
        }

        if (!this.model.HasEcoView) {
            this.model.EcoViewPackagePk = null;
            this.model.EcoViewPackageRate = null;
            this.model.EcoViewPackageDailyRate = null;
            this.model.HasEcoViewInstall = false;
            this.model.EcoViewInstallCharge = null;
            this.model.EcoViewBillingDate = null;
            this.model.EcoViewTermInMonths = null;
        }
        else if (!this.model.HasEcoViewInstall) {
            this.model.EcoViewInstallCharge = null;
        }

        if (this.model.Pk) {
            method = this.invoicingInstructionService.update(this.model);
        }
        else {
            this.model.Parent = null;
            method = this.invoicingInstructionService.create(this.model);
        }

        return method.pipe(map((data: InvoicingInstructionModel) => {
            let action = !this.model.Pk ? 'created' : 'updated';

            this.common.showMessage(`Successfully ${action} the Invoicing Instruction.`);

            if (!this.model.Pk) {
                const params = { queryParams: { id: data.Pk } };

                this.router.navigate(['/sales/invoicing-instruction/detail'], params);
            }
            else {
                this.model = data;
                this.modelOriginal = new InvoicingInstructionModel(data);

                this.refreshAccess();
            }
        }));
    }

    private _getAreas() {
        return this.areaService.get().pipe(map(data => this.areas = data));
    }

    private _getStates() {
        return this.lookupService.getLookupList("state").pipe(map(data => this.states = data));
    }

    private _getCounties(abbreviation: string) {
        return this.lookupService.getDataByState(abbreviation, StateData.County).pipe(map(data => this.counties = data));
    }

    private _getUsers() {
        return this.userService.getAllUsers().pipe(map(data => {
            this.users = data;
            // todo: Remove ApplicationPk check when permissions are consolidated
            this.accountRepresentatives = data.filter(x => x.ApplicationUserRoles.filter(y => ['accountmanager', 'salesmanager'].indexOf(y.ApplicationRole.Id.toLowerCase()) >= 0 && y.ApplicationPk == 2).length >= 1);
            this.areaManagers = data.filter(x => x.ApplicationUserRoles.filter(y => y.ApplicationRole.Id.toLowerCase() == 'areamanager').length >= 1);
        }));
    }

    private _getCustomers() {
        return this.macolaCustomerService.search().pipe(map(data => this.customers = this.common.sort(data, 'Name')));
    }

    private _getLocations() {
        return this.lookupService.getLookupList("location").pipe(map(data => this.locations = data));
    }

    private _getModuleType() {
        return this.sparkModuleTypeService.getById("SO").pipe(map(data => this.sparkModuleType = data));
    }

    private _getLatestSalesOrder() {
        return this.salesOrderService.getLatestByAsset(this.model.AssetId).pipe(map(data => {
            this.latestSalesOrder = data;
            this.refreshAccess();
        }));
    }

    private _getLatestRenewTermII() {
        return this.invoicingInstructionService.getLatestByAsset(this.model.AssetId, 'RenewTerm').pipe(map(data => {
            this.latestRenewTermII = data;
        }));
    }

    private _getLookupValues() {
        const types = [
            'SALES_ECOVIEW_PACKAGE',
            'SALES_ECOVIEW_PACKAGE_INSTALL',
            'SALES_ECOVIEW_PACKAGE_RATE',
            'SALES_ESCALATION_TYPE',
            'TERM_RELEASE_REASON'
        ];

        return this.lookupValueService.getByTypes(types).pipe(map(data => {
            types.map(x => {
                if (!this.lookupValues[x]) {
                    this.lookupValues[x] = this.common.sort(data.filter(y => y.Type === x), 'Sort');
                }
            });
        }));
    }

    onLoad(workflowStatus?: string) {
        this.common.handleRequests([this._onLoad(workflowStatus)]);
    }

    onBack() {
        if (this.hasChanges()) {
            this.common.showConfirm(this.confirmLoseChanges);
        }
        else {
            this.goBack();
        }
    }

    goBack() {
        const params: any = {};

        if (this.view) {
            params.queryParams = { view: this.view };
        }

        this.router.navigate(['/sales/invoicing-instruction/list'], params);
    }

    refreshAccess() {
        const status = this.model.Status.toLowerCase();
        const type = this.model.Type.toLowerCase();

        if (!this.model.Pk) {
            this.access.save = !!(this.access.create);
            this.access.remove = false;
            this.access.submit = false;
        }
        // todo: consider refactoring this to be better optimized?
        else if (this.model.CreatedBy == this.user.ApplicationUserPk && ['new', 'denied'].indexOf(status) >= 0) {
            this.access.save = !!(this.access.update);
            this.access.remove = !!(this.access.delete);
            this.access.submit = true;
        }
        else {
            if (this.model.Pk && ['cancelled', 'completed', 'closed'].indexOf(status) < 0 && this.access.delete) {
                this.access.remove = true;
            }
            else {
                this.access.remove = false;
            }

            if (['completed', 'closed'].indexOf(status) >= 0) {
                this.access.save = false;
            }
            else {
                this.access.save = !!(this.access.update);
            }

            this.access.submit = false;
        }

        if (['pending', 'completed', 'closed'].indexOf(status) >= 0) {
            this.access.print = true;
        }
        else {
            this.access.print = false;
        }

        const isBillableType = ['termination', 'renewterm'].indexOf(type) < 0;
        const activeChildren = this.model.Children ? this.model.Children.filter(x => x.Status.toLowerCase() != 'cancelled') : [];
        const hasRenewalSalesOrder = (this.latestSalesOrder.Pk && this.latestSalesOrder.Pk != this.model.SalesOrderPk && this.latestSalesOrder.Type == 'Renew');

        if ((type == 'renewterm' || type == 'reinstate') && this.access.save) {
            this.access.save = false;
            this.access.changeEffectiveDate = true;
        }
        else {
            this.access.changeEffectiveDate = false;
        }

        if (this.access.create && status == 'completed' && isBillableType && activeChildren.length == 0) {
            this.access.createAmendment = true;
            this.access.createTermination = true;
        }
        else {
            this.access.createAmendment = false;
            this.access.createTermination = false;
        }

        if (this.access.save) {
            if (!this.model.isAmendment()) {
                this.edit.area = true;
                this.edit.customer = true;
                this.edit.location = true;
                this.edit.rate = true;
                this.edit.rateType = true;
                this.edit.term = true;
                this.edit.information = true;
                this.edit.ecoView = true;
            }
            else {
                this.edit.area = !!(this.model.IsAreaAmendment);
                this.edit.customer = !!(this.model.IsCustomerAmendment);
                this.edit.location = !!(this.model.IsLocationAmendment);
                this.edit.rate = !!(this.model.IsRateAmendment);
                this.edit.rateType = !!(this.model.IsRateTypeAmendment);
                this.edit.term = !!(this.model.IsTermAmendment);
                this.edit.information = !!(this.model.IsInformationAmendment);
                this.edit.ecoView = !!(this.model.IsEcoViewAmendment);
            }
        }
        else {
            this.edit.area = false;
            this.edit.customer = false;
            this.edit.location = false;
            this.edit.rate = false;
            this.edit.rateType = false;
            this.edit.term = false;
            this.edit.information = false;
            this.edit.ecoView = false;
        }

        this.access.createRenewTerm = status != 'new' && this.access.create && activeChildren.length == 0 && hasRenewalSalesOrder && isBillableType &&
            ['reviewed', 'completed'].indexOf(this.latestSalesOrder.Status.toLowerCase()) >= 0 && type != 'reinstate';

        const latest = activeChildren[0];

        // When SO is closed, a termination/renew term II was completed
        this.access.createReinstate = status != 'new' && this.access.create && !isBillableType && type != 'reinstate' &&
            (this.model.SalesOrder && this.model.SalesOrder.Status.toLowerCase() == 'closed');

        this.access.actionMenu = this.access.remove || this.access.submit || this.access.print ||
            this.access.createAmendment || this.access.createTermination || this.access.createRenewTerm || this.access.createReinstate ||
            (this.model.Workflows && this.model.Workflows.length);

        this.access.editNotes = this.access.save;
        if (['termination', 'reinstate', 'renewterm'].indexOf(type) >= 0) {
            this.access.editNotes = (['new', 'denied'].indexOf(status) >= 0) && (this.access.salesAdmin || this.access.admin);
        }
    }

    onSave() {
        return this.common.handleRequests([this._onSave()]);
    }

    canSave() {
        // todo: determine conditions to allow saving
        return ((this.access.save || this.access.changeEffectiveDate) && this.model.AssetId && this.model.Date);
    }

    hasChanges() {
        const diff = [];
        const exclude = this.common.getExcludedFields().concat(['Children', 'Parent', 'Files']);

        Object.keys(this.model).map(x => {
            if (exclude.indexOf(x) < 0) {
                let originalValue = (this.modelOriginal[x] || '').toString();
                let modelValue = (this.model[x] || '').toString();
                if (x.toLowerCase()=='salesorder') {
                    if (this.modelOriginal[x].Escalations.length !== this.model[x].Escalations.length) {
                        diff.push(x);;
                    }
                    else {
                        for (var i = 0; i < this.model[x].Escalations.length; i++) {
                            const now = new Date();
                            let originalEscalationDateValue = moment(this.modelOriginal[x].Escalations[i].EscalationDate || now).toDate().getTime();
                            let modelEscalationDateValue = moment(this.model[x].Escalations[i].EscalationDate || now).toDate().getTime();
                            if (isNaN(originalEscalationDateValue) && isNaN(modelEscalationDateValue)) { }
                            else {
                                if (originalEscalationDateValue !== modelEscalationDateValue) {
                                    diff.push(x);
                                }
                            }
                            if (this.modelOriginal[x].Escalations[i].Amount !== this.model[x].Escalations[i].Amount) {
                                diff.push(x);
                            }
                            if (this.modelOriginal[x].Escalations[i].NewAmount !== this.model[x].Escalations[i].NewAmount) {
                                diff.push(x);
                            }
                            if (this.modelOriginal[x].Escalations[i].TypePk !== this.model[x].Escalations[i].TypePk) {
                                diff.push(x);
                            }
                        }
                    }
                }
                if (x.toLowerCase().indexOf('date') >= 0) {
                    const now = new Date();
                    originalValue = moment(this.modelOriginal[x] || now).toDate().getTime();
                    modelValue = moment(this.model[x] || now).toDate().getTime();
                }

                if (originalValue != modelValue) {
                    diff.push(x);
                }
            }
        });

        return diff.length > 0;
    }

    onSubmit() {
        this.hasAmendmentError = false;

        // Required typeahead fields are "valid" in that they have text, but not that a selection was made
        const validTypeaheads = (
            (this.model.AssetId && this.model.AssetId.length > 0) &&
            (this.model.AreaId && this.model.AreaId.length > 0) &&
            (this.model.CustomerId && this.model.CustomerId.length > 0) &&
            (this.model.AccountManagerId && this.model.AccountManagerId.length > 0) &&
            (this.model.OperationsManagerId && this.model.OperationsManagerId.length > 0) &&
            (this.model.State && this.model.State.length > 0) &&
            (this.model.County && this.model.County.length > 0)
        );

        const validAmendment = !this.model.isAmendment() || (this.model.isAmendment() &&
            (
                this.model.IsAreaAmendment ||
                this.model.IsCustomerAmendment ||
                this.model.IsLocationAmendment ||
                this.model.IsRateAmendment ||
                this.model.IsRateTypeAmendment ||
                this.model.IsTermAmendment ||
                this.model.IsInformationAmendment ||
                this.model.IsEcoViewAmendment
            ));

        if (!(this.invoicingInstructionForm.valid && validTypeaheads && validAmendment)) {
            this.common.showError('Unable to submit', 'There are one or more required fields with missing information.');

            const controls = this.invoicingInstructionForm.controls;

            Object.keys(controls).map(x => {
                if (controls[x].invalid) {
                    controls[x].setErrors({ 'invalid': true });
                    controls[x].markAsDirty();
                }
            });

            if (this.model.isAmendment() && !validAmendment) {
                this.hasAmendmentError = true;
            }

            this.common.showTabWithError(this.invoicingInstructionForm, this.tabs.tabs);

            return;
        }

        this.common.showConfirm(this.confirmSubmit);
    }

    onConfirmSubmit() {
        this.confirmSubmit.disableAll();

        const submitSuccess = () => {
            this.common.hideConfirm();
            this.confirmSubmit.enableAll();

            this.common.showMessage('Successfully submitted Invoicing Instruction.');
            this.goBack();
        };

        const submitError = (error: any) => {
            this.common.showError('Error creating workflow for Invoicing Instruction', error);
            this.confirmSubmit.enableAll();
        };

        const callback = () => {
            this.workflowService.createWorkflowModule(this.model.Pk, this.model.WorkflowId)
                .subscribe(
                    data => {
                        this.model.Workflows.push(data);

                        submitSuccess();
                    },
                    error => {
                        submitError(error);
                    });
        };

        if (this.model.Type.toLowerCase() == 'renewterm') {
            this.model.Status = 'COMPLETED';

            this._onSave().subscribe(
                data => {
                    submitSuccess();
                },
                error => {
                    submitError(error);
                });
        }
        else if (this.canSave()) {
            this._onSave().subscribe(() => callback());
        }
        else {
            callback();
        }
    }

    onDelete() {
        this.common.showConfirm(this.confirmDelete);
    }

    onConfirmDelete() {
        this.confirmDelete.disableAll();

        this.invoicingInstructionService.delete(this.model.Pk)
            .subscribe(
                () => {
                    this.common.hideConfirm();
                    this.confirmDelete.enableAll();

                    this.common.showMessage('Successfully deleted Invoicing Instruction.');
                    this.goBack();
                },
                error => {
                    this.common.showError('Error deleting Invoicing Instruction', error);
                    this.confirmDelete.enableAll();
                }
            );
    }

    onAreaChange(selected: TypeaheadMatch) {
        const area = selected.item as AreaModel;

        this.typeahead['Area'] = area.Name;
        this.model.AreaId = area.Id;
    }

    onStateChange(selected: TypeaheadMatch) {
        const state = selected.item as LookupModel;

        this.typeahead['State'] = state.Name;
        this.model.State = state.Name;

        this.typeahead['County'] = null;
        this.model.County = null;

        this.common.handleRequests([this._getCounties(state.Description)]);
    }

    onCountyChange(selected: TypeaheadMatch) {
        const county = selected.item as LookupModel;

        this.typeahead['County'] = county.Name;
        this.model.County = county.Name;
    }

    onAccountManagerChange(selected: TypeaheadMatch) {
        const manager = selected.item as ApplicationUserModel;

        this.typeahead['AccountManager'] = manager.DisplayName;
        this.model.AccountManagerId = manager.UserId;
        this.model.AccountManagerName = manager.DisplayName;
    }

    onOperationsManagerChange(selected: TypeaheadMatch) {
        const manager = selected.item as ApplicationUserModel;

        this.typeahead['OperationsManager'] = manager.DisplayName;
        this.model.OperationsManagerId = manager.UserId;
        this.model.OperationsManagerName = manager.DisplayName;
    }

    onCustomerChange(selected: TypeaheadMatch) {
        const customer = selected.item as MacolaCustomerModel;

        this.typeahead['Customer'] = customer.Name;
        this.model.AccountsPayableContactId = null;
        this.model.AccountsPayableContactName = null;

        this.model.CustomerId = customer.Code;
        this.model.CustomerName = customer.Name;

        this.contacts = this.common.sort(customer.Contacts.slice(), 'FullName');

        if (customer.ContactId) {
            const contact = customer.Contacts.find(x => x.ContactId == customer.ContactId);

            if (contact) {
                this.model.AccountsPayableContactId = contact.Pk.toString();
                this.model.AccountsPayableContactName = contact.FullName;
            }
        }
    }

    onContactChange(contactPk: number) {
        const contact = this.contacts.find(x => x.Pk == contactPk);

        this.model.AccountsPayableContactName = contact.FullName;
    }

    onTypeaheadChange(typeaheadId: string) {
        switch (typeaheadId) {
            case 'Area':
                this.model.AreaId = null;
                break;

            case 'AccountManager':
                this.model.AccountManagerId = null;
                this.model.AccountManagerName = null;
                break;

            case 'OperationsManager':
                this.model.OperationsManagerId = null;
                this.model.OperationsManagerName = null;
                break;

            case 'Customer':
                this.model.CustomerId = null;
                this.model.CustomerName = null;
                this.model.AccountsPayableContactId = null;
                this.model.AccountsPayableContactName = null;
                break;

            case 'State':
                this.model.State = null;
                this.typeahead['County'] = null;
                this.model.County = null;
                this.counties = [];
                break;

            case 'County':
                this.model.County = null;
                break;

            default:
                break;
        }
    }

    validateTypeahead(typeaheadId: string) {
        let reset: boolean = false;

        if (typeaheadId == 'Unit' && !this.model.AssetId) {
            reset = true;
        }

        if (typeaheadId == 'Area' && !this.model.AreaId) {
            reset = true;
        }

        if (typeaheadId == 'AccountManager' && !this.model.AccountManagerId) {
            reset = true;
        }

        if (typeaheadId == 'OperationsManager' && !this.model.OperationsManagerId) {
            reset = true;
        }

        if (typeaheadId == 'Customer' && !this.model.CustomerId) {
            reset = true;
        }

        if (typeaheadId == 'State' && !this.model.State) {
            reset = true;
        }

        if (typeaheadId == 'County' && !this.model.County) {
            reset = true;
        }

        if (reset) {
            this.typeahead[typeaheadId] = null;
            this.onTypeaheadChange(typeaheadId);
        }
    }

    calculateDailyRate(property: string = 'model') {
        if (!this.model.MonthlyRate) {
            this.model.DailyRate = 0;
        }
        else {
            this.model.DailyRate = this.common.round(this.model.MonthlyRate / 30.4, 2);

            // this.invoicingInstructionForm.controls['DailyRate'].setValue(this.model.DailyRate);
        }

        this.calculateStandbyRateAmount(property);
        this.calculatePackagingStandbyRateAmount(property);
    }

    calculateStandbyRateAmount(property: string = 'model') {
        if (!this.model.MonthlyRate || !this.model.StandbyRate) {
            this.model.MonthlyStandbyRate = 0;
            this.model.DailyStandbyRate = 0;
            return;
        }

        this.model.MonthlyStandbyRate = this.common.round(this.model.MonthlyRate * (this.model.StandbyRate / 100), 2);
        this.model.DailyStandbyRate = this.common.round(this.model.MonthlyStandbyRate / 30.4, 2);

        this.invoicingInstructionForm.controls['MonthlyStandbyRate'].setValue(this.model.MonthlyStandbyRate);
        // this.invoicingInstructionForm.controls['DailyStandbyRate'].setValue(this.model.DailyStandbyRate);
    }

    calculatePackagingStandbyRateAmount(property: string = 'model') {
        if (!this.model.MonthlyRate || !this.model.PackagingStandbyRate) {
            this.model.PackagingMonthlyStandbyRate = 0;
            this.model.PackagingDailyStandbyRate = 0;
            return;
        }

        this.model.PackagingMonthlyStandbyRate = this.common.round(this.model.MonthlyRate * (this.model.PackagingStandbyRate / 100), 2);
        this.model.PackagingDailyStandbyRate = this.common.round(this.model.PackagingMonthlyStandbyRate / 30.4, 2);

        this.invoicingInstructionForm.controls['PackagingMonthlyStandbyRate'].setValue(this.model.PackagingMonthlyStandbyRate);
        // this.invoicingInstructionForm.controls['PackagingDailyStandbyRate'].setValue(this.model.PackagingDailyStandbyRate);
    }

    onCreateChild(type: string) {
        const params = { queryParams: { parentPk: this.model.Pk, type } };

        this.router.navigate(['/sales/invoicing-instruction/detail'], params);
    }

    onPrint() {
        this.common.showLoader();

        this.invoicingInstructionService.print(this.model.Pk)
            .subscribe(
                data => {
                    if (data) {
                        this.common.downloadReport(`${this.model.AssetId}_${moment(this.model.Date).format('MMDDYYYY')}`, data);
                    }
                    else {
                        this.common.showError('Error printing Invoicing Instruction', 'An error occurred printing the Invoicing Instruction. Please try again. If the problem persists, contact IT.');
                    }

                    this.common.hideLoader();
                },
                error => {
                    this.common.showError('Error printing Invoicing Instruction', error);
                    this.common.hideLoader();
                }
            );
    }

    hasChange(field: string) {
        if (this.model.Type.toLowerCase() == 'renewnew' && this.latestRenewTermII) {
            if (['MonthlyRate', 'StandbyRate', 'DailyRate', 'DailyStandbyRate', 'MonthlyStandbyRate'].indexOf(field) >= 0) {
                this.previousII = this.latestRenewTermII;
                return true;
            }

            return false;
        }
        else if (!this.model.isAmendment() || !this.model.Parent) {
            return false;
        }

        this.previousII = this.model.Parent;

        let newValue = (this.model[field] || '').toString();
        let oldValue = (this.model.Parent[field] || '').toString();

        if (field.toLowerCase().indexOf('date') >= 0) {
            if (!this.model[field] && !this.modelOriginal[field]) {
                return false;
            }

            newValue = moment(this.model[field] || '').startOf('day').toDate().getTime();
            oldValue = moment(this.model.Parent[field] || '').startOf('day').toDate().getTime();
        }

        return newValue != oldValue;
    }

    onAmendmentToggle(type: string, value: boolean) {
        this.hasAmendmentError = false;

        switch (type) {
            case 'Area':
                this.edit.area = value;

                if (!value && !this.edit.location) {
                    this.model.AreaId = this.modelOriginal.AreaId;
                }
                break;

            case 'Customer':
                this.edit.customer = value;

                if (!value) {
                    this.model.CustomerId = this.modelOriginal.CustomerId;
                    this.model.CustomerName = this.modelOriginal.CustomerName;
                    this.model.AccountsPayableContactId = this.modelOriginal.AccountsPayableContactId;
                    this.model.AccountsPayableContactName = this.modelOriginal.AccountsPayableContactName;
                    this.model.SalesContact = this.modelOriginal.SalesContact;
                    this.model.SalesContactNumber = this.modelOriginal.SalesContactNumber;
                }
                break;

            case 'Location':
                this.edit.location = value;

                if (!value) {
                    if (!this.edit.area) {
                        this.model.AreaId = this.modelOriginal.AreaId;
                    }

                    this.model.Location = this.modelOriginal.Location;
                    this.model.County = this.modelOriginal.County;
                    this.model.State = this.modelOriginal.State;
                    this.model.IsInCityLimits = this.modelOriginal.IsInCityLimits;
                }
                break;

            case 'Rate':
                this.edit.rate = value;

                if (!value) {
                    this.model.MonthlyRate = this.modelOriginal.MonthlyRate;
                    this.model.StandbyRate = this.modelOriginal.StandbyRate;
                    this.model.DailyRate = this.modelOriginal.DailyRate;
                    this.model.DailyStandbyRate = this.modelOriginal.DailyStandbyRate;
                    this.model.MonthlyStandbyRate = this.modelOriginal.MonthlyStandbyRate;
                    this.model.PackagingStandbyRate = this.modelOriginal.PackagingStandbyRate;
                    this.model.PackagingDailyStandbyRate = this.modelOriginal.PackagingDailyStandbyRate;
                    this.model.PackagingMonthlyStandbyRate = this.modelOriginal.PackagingMonthlyStandbyRate;
                }
                break;

            case 'RateType':
                this.edit.rateType = value;

                if (!value) {
                    this.model.RateType = this.modelOriginal.RateType;
                }
                break;

            case 'Terms':
                this.edit.term = value;

                if (!value) {
                    this.model.TermInMonths = this.modelOriginal.TermInMonths;
                }
                break;

            case 'Information':
                this.edit.information = value;

                if (!value) {
                    this.model.AccountManagerId = this.modelOriginal.AccountManagerId;
                    this.model.AccountManagerName = this.modelOriginal.AccountManagerName;
                    this.model.OperationsManagerId = this.modelOriginal.OperationsManagerId;
                    this.model.OperationsManagerName = this.modelOriginal.OperationsManagerName;
                    this.model.CostCenter = this.modelOriginal.CostCenter;
                    this.model.InstallTruckSetup = this.modelOriginal.InstallTruckSetup;
                    this.model.InstallCraneSetup = this.modelOriginal.InstallCraneSetup;
                    this.model.InstallTransportation = this.modelOriginal.InstallTransportation;
                    this.model.InstallTransportationSplit = this.modelOriginal.InstallTransportationSplit;
                    this.model.InstallTransportationOut = this.modelOriginal.InstallTransportationOut;
                    this.model.InstallTransportationOutSplit = this.modelOriginal.InstallTransportationOutSplit;
                    this.model.InstallCranes = this.modelOriginal.InstallCranes;
                    this.model.CatalystProvider = this.modelOriginal.CatalystProvider;
                    this.model.IsKodiakFirstCatalyst = this.modelOriginal.IsKodiakFirstCatalyst;
                    this.model.HasBillBackInstall = this.modelOriginal.HasBillBackInstall;
                    this.model.LiquidsProvider = this.modelOriginal.LiquidsProvider;
                    this.model.ContractNotes = this.modelOriginal.ContractNotes;
                    this.model.Term = this.modelOriginal.Term;
                    this.model.TermInMonths = this.modelOriginal.TermInMonths;
                    this.model.AdValBy = this.modelOriginal.AdValBy;
                    this.model.IsPerformanceBased = this.modelOriginal.IsPerformanceBased;
                    this.model.PerformanceBasedNotes = this.modelOriginal.PerformanceBasedNotes;
                }
                break;

            case 'EcoView':
                this.edit.ecoView = value;

                if (!value) {
                    this.model.HasEcoView = this.modelOriginal.HasEcoView;
                    this.model.EcoViewPackagePk = this.modelOriginal.EcoViewPackagePk;
                    this.model.EcoViewPackageRate = this.modelOriginal.EcoViewPackageRate;
                    this.model.EcoViewPackageDailyRate = this.modelOriginal.EcoViewPackageDailyRate;
                    this.model.HasEcoViewInstall = this.modelOriginal.HasEcoViewInstall;
                    this.model.EcoViewInstallCharge = this.modelOriginal.EcoViewInstallCharge;
                    this.model.EcoViewBillingDate = this.modelOriginal.EcoViewBillingDate;
                    this.model.EcoViewTermInMonths = this.modelOriginal.EcoViewTermInMonths;
                }
                break;
        }
    }

    onTermsChange() {
        this.model.TermInMonths = this.model.Term == 'monthly' ? 0 : null;
    }

    onEscalationDelete(modulePk) {
        this.model.SalesOrder.Escalations.splice(modulePk, 1);
    }

    onAdd() {
        if (!this.model.SalesOrder.Escalations) {
            this.model.SalesOrder.Escalations = new Array();
        }
        let salesOrderEscalation = new SalesOrderEscalationModel();
        salesOrderEscalation.SalesOrderPk = this.model.SalesOrderPk;
        this.model.SalesOrder.Escalations.push(salesOrderEscalation);
    }

    onReleaseReasonChange(reasonPk: number) {
        this.model.ReleaseComments = null;
        this.selectedReason = this.lookupValues['TERM_RELEASE_REASON'].find(x => x.Pk == reasonPk);
    }

    onTransportationUpdate(fieldId: string) {
        if (this.model[fieldId] != 'Shared Billing') {
            this.model[fieldId + 'Split'] = null;
        }
        else if (this.model.Parent[fieldId] == 'Shared Billing') {
            this.model[fieldId + 'Split'] = this.model.Parent[fieldId + 'Split'];
        }
    }

    getPackage(packagePk: number) {
        const ecoViewPackage = this.lookupValues['SALES_ECOVIEW_PACKAGE'].find(x => x.Pk == packagePk);

        if (ecoViewPackage) {
            return ecoViewPackage.Description;
        }

        return 'N/A';
    }

    onEcoViewPackageSelect() {
        const ecoViewPackage = this.lookupValues['SALES_ECOVIEW_PACKAGE'].find(x => x.Pk == this.model.EcoViewPackagePk);

        if (ecoViewPackage) {
            const rate = this.lookupValues['SALES_ECOVIEW_PACKAGE_RATE'].find(x => x.Id == ecoViewPackage.Id);

            if (rate) {
                this.model.EcoViewPackageRate = rate.Description;
                this.calculateEcoViewDailyRate();
            }
        }

        this.onEcoViewInstallToggle();
    }

    onEcoViewInstallToggle() {
        if (!this.model.HasEcoViewInstall || !this.model.EcoViewPackagePk) {
            return;
        }

        const ecoViewPackage = this.lookupValues['SALES_ECOVIEW_PACKAGE'].find(x => x.Pk == this.model.EcoViewPackagePk);

        if (ecoViewPackage) {
            const install = this.lookupValues['SALES_ECOVIEW_PACKAGE_INSTALL'].find(x => x.Id == ecoViewPackage.Id);

            if (install) {
                this.model.EcoViewInstallCharge = install.Description;
            }
        }
    }

    calculateEcoViewDailyRate() {
        if (!this.model.EcoViewPackageRate) {
            this.model.EcoViewPackageDailyRate = 0;
        }
        else {
            this.model.EcoViewPackageDailyRate = this.common.round(this.model.EcoViewPackageRate / 30.4, 2);
            // this.invoicingInstructionForm.controls['EcoViewPackageDailyRate'].setValue(this.model.EcoViewPackageDailyRate);
        }
    }
}
