import { Component, Inject, OnInit, OnDestroy } from '@angular/core'
import { Event, NavigationError, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Common } from './core/services';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit, OnDestroy {
    private url: string;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,

        public common: Common,
        private location: Location,
        private router: Router
    ) {
        this.url = this.location.path();

        if (this.url.indexOf('code') >= 0) {
            this.url = '';
        }

        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                document.querySelector('body').classList.remove('sidebar-mobile-show');
                this.common.hideConfirm();
                this.common.showLoader();
            }

            if (event instanceof NavigationError) {
                this.common.showError('Unexpected error', event.error);
                this.common.hideLoader();
            }
        });
    }

    ngOnInit() {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                if (this.url) {
                    this.common.setGenericStorage('ReturnUrl', this.url);
                }

                if (this.authService.instance.getAllAccounts().length > 0) {
                    this.router.navigate(['/callback']);
                }
                else {
                    this.login();
                }
            });
    }

    ngOnDestroy() {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    login() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        }
        else {
            this.authService.loginRedirect();
        }
    }
}
