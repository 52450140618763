export class OperatorModel {
    Pk: number;
    Id: string;
    Name: string;
    Email: string;
    Initials: string;
    Type: string;
    TypeDesc: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Id = obj.Id;
        this.Name = obj.Name;
        this.Email = obj.Email;
        this.Initials = obj.Initials;
        this.Type = obj.Type;
        this.TypeDesc = obj.TypeDesc;
    }
}
