export class MacolaAssetModel {
    Unit: string;
    UnitName: string;
    Area: string;
    AreaName: string;
    Type: string;
    UnitDescription: string;
    Selected: boolean = false;
    LimitVehicleExpense: boolean = false;
    Enabled: boolean;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Unit = (obj.Unit||'').trim();
        this.UnitName = (obj.UnitName||'').trim();

        if (obj.Unit && obj.UnitName) {
            this.UnitDescription = `${this.Unit} - ${this.UnitName}`;
        }
        else if (obj.Unit) {
            this.UnitDescription = this.Unit;
        }
        else if (obj.UnitName) {
            this.UnitDescription = this.UnitName;
        }
        else {
            this.UnitDescription = 'Macola unit data missing';
        }

        this.Area = (obj.Area||'').trim();
        this.AreaName = (obj.AreaName||'').trim();
        this.Type = obj.Type;
        this.LimitVehicleExpense = obj.LimitVehicleExpense;
        this.Enabled = obj.Enabled;
    }
}
