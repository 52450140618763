import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { MacolaCustomerModel } from '../../shared/models';

@Injectable()
export class MacolaCustomerService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/macola-customer`;

    data: any = {};

    constructor(private http: HttpClient) {}

    search() {
        const key = 'MacolaCustomer';

        if (this.data[key]) {
            return of(this.data[key] as MacolaCustomerModel[]);
        }

        return this.http.get<MacolaCustomerModel[]>(`${this.url}/search`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new MacolaCustomerModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as MacolaCustomerModel[];
            }));
    }
}
