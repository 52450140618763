import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../../shared/global';
import { OilSampleModel, OilSampleUnitModel, SearchCriteriaModel } from '../../shared/models';
import { Common } from './common.service';
import { map } from 'rxjs/operators';

@Injectable()
export class OilSampleService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.OIL}/api/oil-sample`;

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get<OilSampleModel>(`${this.url}/get?modelPk=${modelPk}`)
            .pipe(map(res => new OilSampleModel(res)));
    }

    search<T>(criteria: SearchCriteriaModel) {
        return this.http.post<T>(`${this.url}/search`, criteria).pipe(map(res => res));
    }

    update(model: OilSampleModel) {
        if (model.Pk == 0) {
            return this.http.post<OilSampleModel>(`${this.url}/create`, JSON.stringify(model))
                .pipe(map(res => new OilSampleModel(res)));
        }

        return this.http.put<OilSampleModel>(`${this.url}/update`, JSON.stringify(model))
            .pipe(map(res => new OilSampleModel(res)));
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }
}
