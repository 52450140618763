import * as moment from 'moment';

export class DataflowTypeModel {
    Pk: number;
    SparkModuleTypePk: number;
    Name: string;
    Label: string;
    Dataflows: DataflowModel[] = new Array();
    CreationDate: Date;
    CreatedBy: string;
    LastUpdateDate: Date;
    LastUpdateBy: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.SparkModuleTypePk = obj.SparkModuleTypePk;
        this.Name = obj.Name;
        this.Label = obj.Label;

        if (obj.Dataflows) {
            this.Dataflows = obj.Dataflows.map(x => new DataflowModel(x));
        }

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }
}

export class DataflowModel {
    Pk: number;
    DataflowTypePk: number;
    Name: string;
    Type: string;
    Label: string;
    Groups: DataflowGroupModel[] = new Array();
    CreationDate: Date;
    CreatedBy: string;
    LastUpdateDate: Date;
    LastUpdateBy: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.DataflowTypePk = obj.DataflowTypePk;
        this.Name = obj.Name;
        this.Type = obj.Type;
        this.Label = obj.Label;

        if (obj.Groups) {
            this.Groups = obj.Groups.map(x => new DataflowGroupModel(x));
        }

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }
}

export class DataflowGroupModel {
    Pk: number;
    DataflowPk: number;
    Step: number;
    Title: string;
    CustomValidation: boolean = false;
    Fields: DataflowFieldModel[] = new Array();
    CreationDate: Date;
    CreatedBy: string;
    LastUpdateDate: Date;
    LastUpdateBy: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.DataflowPk = obj.DataflowPk;
        this.Step = obj.Step;
        this.Title = obj.Title;
        this.CustomValidation = obj.CustomValidation;

        if (obj.Fields) {
            this.Fields = obj.Fields.map(x => new DataflowFieldModel(x)).sort((a, b) => a.Sort < b.Sort ? -1 : a.Sort > b.Sort ? 1 : 0);
        }

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }
}

export class DataflowFieldModel {
    Pk: number;
    DataflowGroupPk: number;
    ParentPk: number;
    Sort: number;
    Type: string;
    Id: string;
    Label: string;
    Required: boolean = false;
    Options: any = {};
    Editable: boolean = true;
    DefaultValue: any;
    WorkflowDetails: DataflowFieldWorkflowDetailModel[] = new Array();
    CreationDate: Date;
    CreatedBy: string;
    LastUpdateDate: Date;
    LastUpdateBy: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.DataflowGroupPk = obj.DataflowGroupPk;
        this.ParentPk = obj.ParentPk;
        this.Sort = obj.Sort;
        this.Type = obj.Type;
        this.Id = obj.Id;
        this.Label = obj.Label;
        this.Required = obj.Required || false;

        if (obj.Options) {
            try {
                this.Options = JSON.parse(obj.Options);
            }
            catch {
                this.Options = obj.Options;
            }
        }

        // todo: figure out how we're going to use WorkflowDetails to determine editable flag?
        this.Editable = obj.Editable || (this.Pk ? false : true);
        this.DefaultValue = obj.Type == 'bit' ? obj.DefaultValue as boolean || false : ((obj.DefaultValue || '').length ? obj.DefaultValue : null);

        if (obj.WorkflowDetails) {
            this.WorkflowDetails = obj.WorkflowDetails.map(x => new DataflowFieldWorkflowDetailModel(x));
        }

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }
}

export class DataflowFieldWorkflowDetailModel {
    Pk: number;
    DataflowFieldPk: number;
    WorkflowDetailPk: number;
    CreationDate: Date;
    CreatedBy: string;
    LastUpdateDate: Date;
    LastUpdateBy: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.DataflowFieldPk = obj.DataflowFieldPk;
        this.WorkflowDetailPk = obj.WorkflowDetailPk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }
}