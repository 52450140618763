import {Pipe, PipeTransform} from '@angular/core'

@Pipe({ name: 'filter' })

export class FilterPipe implements PipeTransform {
    transform(values: any[], filter: string): any[] {
        if (!values || !values.length) return [];
        if (!filter) return values;
        return values.filter(v => {
            for (var p in v) {
                if (String(v[p]).toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                        return v
            }
        });
    }
}
