import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { OperatorReportModel, ReportModel, SearchCriteriaModel, SearchResultModel } from '../../shared/models';
import { Common } from './common.service';
import * as moment from 'moment';

@Injectable()
export class OperatorReportService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.OPERATOR}/api/operator-report`;

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get<OperatorReportModel>(`${this.url}/get?modelPk=${modelPk}`)
            .pipe(map(res => new OperatorReportModel(res)));
    }

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<OperatorReportModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new OperatorReportModel(x));
                }
                return res;
            }));
    }

    getLatest(unitId: string) {
        return this.http.get<OperatorReportModel>(`${this.url}/get-latest?unitId=${unitId}`)
            .pipe(map(res => new OperatorReportModel(res)));
    }

    update(model: OperatorReportModel) {
        if (model.Pk == 0) {
            return this.http.post<OperatorReportModel>(`${this.url}/create`, JSON.stringify(model))
                .pipe(map(res => new OperatorReportModel(res)));
        }

        return this.http.put<OperatorReportModel>(`${this.url}/update`, JSON.stringify(model))
            .pipe(map(res => new OperatorReportModel(res)));
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    getAreaByDate(assetId: string, statusDate: Date) {
        return this.http.get(`${this.url}/get-area-by-date?assetId=${assetId}&date=${moment(statusDate).format('YYYY-MM-DD')}`, { responseType: 'text'});
    }

    print(modelPk: number) {
        return this.http.get<ReportModel>(this.url + `/print?modelPk=${modelPk}`)
            .pipe(
                map(res => new ReportModel(res))
            );
    }
}
