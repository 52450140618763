export class P66OilConsumptionModel {
    Id: number = 0;
    AssetId: string = '';
    EffectiveDate: Date =new Date() ;
    CurrentLevel: string = '';
    SevenDaysAvg: number = 0;
    FourteenDaysAvg: number = 0;
    TwentyOneDaysAvg: number = 0;
    CreationDate: Date = new Date();
    CreatedBy: number = 0;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        this.Id = obj.Id;
        this.AssetId = obj.AssetId;
        this.EffectiveDate = obj.EffectiveDate;
        this.CurrentLevel = obj.CurrentLevel;
        this.SevenDaysAvg = obj.SevenDaysAvg;
        this.FourteenDaysAvg = obj.FourteenDaysAvg;
        this.TwentyOneDaysAvg = obj.TwentyOneDaysAvg;
        this.CreationDate = obj.CreationDate;
        this.CreatedBy = obj.CreatedBy;
    }
}

export class UnitListImportModel {
    Type: string;
    UnitListType: string = 'ALL';
    ImportMonth: Date = null;
}