export class LevelValueFieldsModel {
    Pk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    EngineOil: number;
    OilTank: number;
    Coolant: number;
    Engine: number;
    Compressor: number;
    OperatorReportPk: number;

    constructor(obj?: any) {
        if (obj != null && obj.length) {
            var singleObj = obj[0];

            this.Pk = singleObj.Pk;
            this.OperatorReportPk = singleObj.OperatorReportPk;
            this.CreationDate = singleObj.CreationDate;
            this.CreatedBy = singleObj.CreatedBy;
            this.LastUpdateDate = singleObj.LastUpdateDate;
            this.LastUpdateBy = singleObj.LastUpdateBy;
            this.EngineOil = this.numberToUndefined(singleObj.EngineOil);
            this.OilTank = this.numberToUndefined(singleObj.OilTank);
            this.Coolant = this.numberToUndefined(singleObj.Coolant);
            this.Engine = this.numberToUndefined(singleObj.Engine);
            this.Compressor = this.numberToUndefined(singleObj.Compressor);
        }
    }

    differentToObject(objectToCompare: LevelValueFieldsModel) {
        var object1ToCompare: LevelValueFieldsModel = new LevelValueFieldsModel([this]);
        var object2ToCompare: LevelValueFieldsModel = new LevelValueFieldsModel([objectToCompare]);

        if (object1ToCompare.EngineOil != object2ToCompare.EngineOil ||
            object1ToCompare.OilTank != object2ToCompare.OilTank ||
            object1ToCompare.Coolant != object2ToCompare.Coolant ||
            object1ToCompare.Engine != object2ToCompare.Engine ||
            object1ToCompare.Compressor != object2ToCompare.Compressor) {
            return true;
        }

        return false;
    }

    private numberToUndefined(value: number) {
        return (value > 0 ? value : undefined);
    }
}
