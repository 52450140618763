import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { AreaModel } from '../../shared/models';

@Injectable()
export class AreaService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/area`;

    data: any = {};

    constructor(private http: HttpClient) {}

    get() {
        const key = 'Areas';

        if (this.data[key]) {
            return of(this.data[key] as AreaModel[]);
        }

        return this.http.get<AreaModel[]>(this.url)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new AreaModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as AreaModel[];
            }));
    }
}