export class ReportModel {
    MimeType: string;
    OutputType: string;
    ReportStream: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.MimeType = obj.MimeType;
        this.OutputType = obj.OutputType.toLowerCase();
        this.ReportStream = obj.ReportStream;
    }
}