<div class="row">
    <div class="col-12">
        <p>For additional support, please contact <a href="mailto:it.support@kodiakgas.com">it.support@kodiakgas.com</a>.</p>
    </div>
</div>

<div class="row">
    <div class="col-sm-4">
        <p><strong>General Field Service</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK - GFS.pdf" target="_blank">GFS Manual (PDF)</a></li>
        </ul>
    </div>

    <div class="col-sm-4">
        <p><strong>Service Reports</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK - Service Reports.pdf" target="_blank">Service Reports Manual (PDF)</a></li>
        </ul>
    </div>

    <div class="col-sm-4">
        <p><strong>Timesheets</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK - Timesheets.pdf" target="_blank">Timesheets Manual (PDF)</a></li>
        </ul>
    </div>

    <div class="col-sm-4">
        <p><strong>Expense Reports</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK - Expense Reports.pdf" target="_blank">Expense Report Manual (PDF)</a></li>
            <li><a href="https://www.youtube.com/watch?v=GEBp0SNrM9g" target="_blank">Expense Report Training Video</a></li>
        </ul>
    </div>

    <div class="col-sm-4">
        <p><strong>Runtime Reports</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK - Runtime Reports.pdf" target="_blank">Runtime Report Manual (PDF)</a></li>
            <li><a href="https://www.youtube.com/watch?v=mZ1QogIyuX0" target="_blank">Runtime Report Training Video</a></li>
        </ul>
    </div>

    <div class="col-sm-4">
        <p><strong>Operator Reports</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK - Operator Reports.pdf" target="_blank">Operator Report Manual (PDF)</a></li>
            <li><a href="https://www.youtube.com/watch?v=BB6HP-LgzaM" target="_blank">Operator Report Training Video</a></li>
        </ul>
    </div>

    <div class="col-sm-4">
        <p><strong>Oil Samples</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK - Oil Samples.pdf" target="_blank">Oil Samples Manual (PDF)</a></li>
            <li><a href="https://www.youtube.com/watch?v=WPTDOsfuGPQ" target="_blank">Oil Samples Training Video</a></li>
        </ul>
    </div>

    <div class="col-sm-4">
        <p><strong>Parts Lookup</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK - Parts Lookup.pdf" target="_blank">Parts Lookup Manual (PDF)</a></li>
            <li><a href="https://www.youtube.com/watch?v=IAxIXc4Taxc" target="_blank">Parts Lookup Training Video</a></li>
        </ul>
    </div>

    <div class="col-sm-4">
        <p><strong>Asset Activity Report</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK-AAR.pdf" target="_blank">Asset Activity Report Manual (PDF)</a></li>
        </ul>
    </div>

    <div class="col-sm-4">
        <p><strong>Sales Training Guide</strong></p>
        <ul>
            <li><a href="assets/docs/SPARK - Sales.pdf" target="_blank">Sales Training Guide (PDF)</a></li>
        </ul>
    </div>
</div>
