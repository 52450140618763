import { Component, OnInit, Input } from '@angular/core';
import { SparkCarouselItemComponent } from './spark-carousel-item.component';

@Component({
    selector: 'spark-carousel',
    templateUrl: 'spark-carousel.component.html'
})
export class SparkCarouselComponent implements OnInit {
    @Input()
    set activeItem(index: number){
        if (this.items.length && index !== this.currentIndex) {
            this.selectItem(index);
        }
    }

    get activeItem(): number {
        return this.currentIndex;
    }

    protected items: Array<SparkCarouselItemComponent> = new Array();
    protected currentIndex: number;

    constructor() { }

    ngOnInit() { }

    addItem(item: SparkCarouselItemComponent) {
        this.items.push(item);

        if (this.items.length === 1) {
            this.currentIndex = undefined;
            this.activeItem = 0;
        }
    }

    removeItem(item: SparkCarouselItemComponent) {
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
    }

    private selectItem(index: number) {
        const currentItem = this.items[this.currentIndex];

        if (currentItem) {
            currentItem.active = false;
        }

        const nextItem = this.items[index];
        if (nextItem) {
            this.currentIndex = index;
            nextItem.active = true;
            this.activeItem = index;
        }
    }
}
