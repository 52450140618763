export class PanelValueModel {
    Key: number;
    Pk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    OperatorReportPk: number;
    Stage: string;
    Throw: string;
    SuctionPanelTemp: string;
    SuctionPanel: string;
    SuctionKill: string;
    PocNumber: string;
    DischargePanelTemp: string;
    DischargePanel: string;
    DischargeKill: string;
    ShowDelete: boolean = false;

    constructor(obj: any, key: number) {
        this.Key = key;

        if (obj != null) {
            this.Pk = obj.Pk;
            this.CreationDate = obj.CreationDate;
            this.CreatedBy = obj.CreatedBy;
            this.LastUpdateDate = obj.LastUpdateDate;
            this.LastUpdateBy = obj.LastUpdateBy;
            this.OperatorReportPk = obj.OperatorReportPk;
            this.Stage = this.numberToEmpty(obj.Stage);
            this.Throw = this.numberToEmpty(obj.Throw);
            this.PocNumber = this.numberToEmpty(obj.PocNumber);
            this.SuctionPanelTemp = this.numberToEmpty(obj.SuctionPanelTemp);
            this.SuctionPanel = this.numberToEmpty(obj.SuctionPanel);
            this.SuctionKill = this.numberToEmpty(obj.SuctionKill);
            this.DischargePanelTemp = this.numberToEmpty(obj.DischargePanelTemp);
            this.DischargePanel = this.numberToEmpty(obj.DischargePanel);
            this.DischargeKill = this.numberToEmpty(obj.DischargeKill);
            this.ShowDelete = obj.ShowDelete != undefined ? obj.ShowDelete : false;
        }
    }

    differentToObject(objectToCompare: PanelValueModel) {
        if (this.Stage != objectToCompare.Stage ||
            this.Throw != objectToCompare.Throw ||
            this.PocNumber != objectToCompare.PocNumber ||
            this.SuctionPanelTemp != objectToCompare.SuctionPanelTemp ||
            this.SuctionPanel != objectToCompare.SuctionPanel ||
            this.SuctionKill != objectToCompare.SuctionKill ||
            this.DischargePanelTemp != objectToCompare.DischargePanelTemp ||
            this.DischargePanel != objectToCompare.DischargePanel ||
            this.DischargeKill != objectToCompare.DischargeKill) {
            return true;
        }

        return false;
    }

    private numberToEmpty(value: number) {
        return (value > 0 ? value.toString() : '');
    }
}
