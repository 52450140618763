export class SearchCriteriaModel {
    SearchQuery: string;
    Status: string = 'new';
    Page: number = 1;
    Limit: number = 10;
    Direction: string = 'asc';
    SortBy: string = 'CreationDate';
    ThenBy: string = 'CreationDate';
    DirectReports: boolean = false;
    ApprovalType: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.SearchQuery = obj.SearchQuery || '';
        this.Status = obj.Status || 'new';
        this.Page = Number(obj.Page) || 1;
        this.Limit = Number(obj.Limit) || 10;
        this.Direction = obj.Direction || 'asc';
        this.SortBy = obj.SortBy || 'CreationDate';
        this.ThenBy = obj.ThenBy || 'CreationDate';
        this.DirectReports = obj.DirectReports || false;
        this.ApprovalType = obj.ApprovalType;
    }
}
