import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { AssetActivityReportModel, ReportModel, SearchCriteriaModel, SearchResultModel } from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class AssetActivityReportService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.ASSET_ACTIVITY}/api/asset-activity-report`;

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get<AssetActivityReportModel>(`${this.url}?modelPk=${modelPk}`)
            .pipe(
                map(res => new AssetActivityReportModel(res))
            );
    }

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<AssetActivityReportModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new AssetActivityReportModel(x));
                }
                return res;
            }));
    }

    create(model: AssetActivityReportModel) {
        return this.http.post<AssetActivityReportModel>(`${this.url}/create`, JSON.stringify(model)).pipe(
            map(res => new AssetActivityReportModel(res))
        );
    }

    update(model: AssetActivityReportModel) {
        return this.http.put<AssetActivityReportModel>(`${this.url}/update`, JSON.stringify(model)).pipe(
            map(res => new AssetActivityReportModel(res))
        );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    getExisting(assetId: string, type: string) {
        return this.http.get<AssetActivityReportModel>(`${this.url}/get-existing?assetId=${assetId}&type=${type}`)
            .pipe(
                map(res => new AssetActivityReportModel(res))
            );
    }

    print(modelPk: number) {
        return this.http.get<ReportModel>(this.url + `/print?modelPk=${modelPk}`)
            .pipe(
                map(res => new ReportModel(res))
            );
    }
}