import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { McReorderPointModel, McPartModel, McTransferPartModel, McUploadDetailModel, McPOModel, McNewPartModel } from '../../shared/models/mc-upload.model';

import { Globals } from '../../shared/global';

@Injectable()
export class McUploadService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.SUPPLY_CHAIN}/api/mc-upload/`;

    constructor(private http: HttpClient) {}

    uploadReorderPoints(models: McReorderPointModel[]) {
        return this.http.post<McUploadDetailModel>(this.url + 'upload-reorder-points', models)
            .pipe(map(res => new McUploadDetailModel(res)));
    }

    updateBins(models: McPartModel[]) {
        return this.http.post<McUploadDetailModel>(this.url + 'update-bins', models)
            .pipe(map(res => new McUploadDetailModel(res)));
    }

    importPO(models: McPOModel[]) {
        return this.http.post<McUploadDetailModel>(this.url + 'import-purchase-orders', models)
            .pipe(map(res => new McUploadDetailModel(res)));
    }

    transferParts(models: McTransferPartModel[]) {
        return this.http.post<McUploadDetailModel>(this.url + 'transfer-parts', models)
            .pipe(map(res => new McUploadDetailModel(res)));
    }

    addExistingParts(models: McPartModel[]) {
        return this.http.post<McUploadDetailModel>(this.url + 'add-existing-parts', models)
            .pipe(map(res => new McUploadDetailModel(res)));
    }

    removeParts(models: McPartModel[]) {
        return this.http.post<McUploadDetailModel>(this.url + 'remove-parts', models)
            .pipe(map(res => new McUploadDetailModel(res)));
    }

    addParts(models: McNewPartModel[]) {
        return this.http.post<McUploadDetailModel>(this.url + 'add-parts', models)
            .pipe(map(res => new McUploadDetailModel(res)));
    }

    addUnits(models: McNewPartModel[]) {
        return this.http.post<McUploadDetailModel>(this.url + 'add-units', models)
            .pipe(map(res => new McUploadDetailModel(res)));
    }
}
