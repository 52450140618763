import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Common } from '../../../core/services';
import { SearchCriteriaModel, SearchResultModel } from '../../models';

@Component({
    selector: 'search-container',
    templateUrl: 'search-container.component.html'
})
export class SearchContainerComponent implements OnInit {
    @ViewChild('searchInputRef', { static : false }) searchInputRef: ElementRef;
    @Input() results: SearchResultModel<any> = new SearchResultModel<any>();
    @Input() selectOptions: Array<any> = new Array();
    @Input() storageKey: string = null;
    @Input() hasFilter: boolean = true;

    @Output() onSearch: EventEmitter<any> = new EventEmitter();

    pageSize: number = 10;
    pageStart: number = 1;
    searchModel: SearchCriteriaModel;
    searchQuery: string;
    showSelect: boolean = true;
    showSelectGroup: boolean = true;
    totalPages: number = 0;
    totalRecords: number = 0;
    isSearching: boolean = false;

    constructor(public common: Common) {}

    ngOnInit() {
        if (!this.storageKey) {
            throw "SearchContainerComponent: storage key missing.";
        }

        this.searchModel = new SearchCriteriaModel(this.common.getSparkStorage(this.storageKey));
        this.searchQuery = this.searchModel.SearchQuery;

        if (!this.selectOptions || this.selectOptions.length == 0) {
            this.showSelect = false;
        }
        else if (!this.selectOptions[0].group) {
            this.showSelectGroup = false;
        }

        this.common.changeEmitted$.subscribe((text) => {
            if (text == "search_complete") {
                this.isSearching = false;
                const self = this;
                setTimeout(() => {
                    self.searchInputRef.nativeElement.focus();
                });
            }
        });
    }

    ngOnChanges() {
        this.searchModel = new SearchCriteriaModel(this.common.getSparkStorage(this.storageKey));
        this.pageStart = this.searchModel.Page;

        this.showSelect = this.selectOptions && this.selectOptions.length > 0;
        this.showSelectGroup = this.showSelect && this.selectOptions[0].group;

        if (!this.results || this.results.Results.length == 0) {
            this.searchModel.Page = 1;
            this.totalRecords = 0;
        }
        else {
            this.totalRecords = this.results.TotalResults;
        }
    }

    onSelect() {
        // reset to page 1 when changing the status
        this.searchModel.Page = 1;
        this.pageStart = 1;

        this.refreshItems();
    }

    onFiltered() {
        if (this.searchModel.SearchQuery != this.searchQuery) {
            this.searchModel.SearchQuery = this.searchQuery;

            this.searchModel.Page = 1;
            this.pageStart = 1;
            this.refreshItems();
        }
    }

    onUpdatedLimit() {
        const nextTotalPages = Math.ceil(this.totalRecords / this.searchModel.Limit);

        // Update the current page to the last when there are fewer available
        if (this.searchModel.Page > nextTotalPages && nextTotalPages < this.totalPages) {
            this.searchModel.Page = nextTotalPages || 1;
            this.pageStart = nextTotalPages || 1;
        }

        this.refreshItems();
    }

    setPage(page: any) {
        if (page.page == this.pageStart) {
            return;
        }

        this.searchModel.Page = page.page;

        this.refreshItems();
    }

    refreshItems() {
        this.common.setSparkStorage(this.storageKey, this.searchModel);
        this.isSearching = true;
        this.onSearch.emit(this.searchModel);
    }

    updateTotal(count: number) {
        const self = this;
        setTimeout(() => {
            self.totalPages = count;
        })
    }
}