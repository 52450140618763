<ol class="breadcrumb" *ngIf="!preview">
    <li class="breadcrumb-item d-sm-down-none">
        <button class="btn btn-link" (click)="onSave()" [disabled]="!canSave()">
            <i class="fas fa-save"></i> <span class="ml-2">Save</span>
        </button>
    </li>
    <li class="breadcrumb-item d-sm-down-none">
        <button class="btn btn-link" (click)="onBack()">
            <i class="fas fa-undo-alt"></i> <span class="ml-2">Back</span>
        </button>
    </li>
    <li class="breadcrumb-menu" *ngIf="access.actionMenu">
        <div class="btn-group" dropdown placement="bottom right">
            <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
                <i class="fas fa-bullseye"></i> <span class="ml-2 mr-1">Actions</span> <span class="caret"></span>
            </button>

            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                <li role="menuitem">
                    <a class="dropdown-item" (click)="onSubmit()" *ngIf="access.submit" href="javascript:;">
                        <i class="fas fa-check-circle"></i> Submit
                    </a>
                    <a class="dropdown-item" (click)="onDelete()" *ngIf="access.remove" href="javascript:;">
                        <i class="fas fa-times-circle"></i> Cancel
                    </a>
                    <a class="dropdown-item" (click)="onCreateChild('Amendment')" *ngIf="access.createAmendment" href="javascript:;">
                        <i class="fas fa-file-invoice"></i> Create Amendment II
                    </a>
                    <a class="dropdown-item" (click)="onCreateChild('Termination')" *ngIf="access.createTermination" href="javascript:;">
                        <i class="fas fa-file-invoice"></i> Create Termination II
                    </a>
                    <a class="dropdown-item" (click)="onCreateChild('RenewTerm')" *ngIf="access.createRenewTerm" href="javascript:;">
                        <i class="fas fa-file-invoice"></i> Create Renew Term II
                    </a>
                    <a class="dropdown-item" (click)="onCreateChild('Reinstate')" *ngIf="access.createReinstate" href="javascript:;">
                        <i class="fas fa-file-invoice"></i> Create Reinstate II
                    </a>
                    <a class="dropdown-item" [class.disabled]="!access.print" (click)="access.print && onPrint()" href="javascript:;">
                        <i class="fas fa-print"></i> Print
                    </a>
                    <workflow [model]="model" (onConfirm)="onLoad($event)"></workflow>
                </li>
            </ul>
        </div>
    </li>
</ol>

<div class="container-fluid" [class.preview-mode]="preview" [class.preview-mode-scroll]="preview">
    <div class="row" id="invoicing-instruction-detail">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <i class="fas fa-list"></i>
                    <strong *ngIf="!model.Pk">New Invoicing Instruction</strong>
                    <strong *ngIf="model.Pk">Invoicing Instruction #{{model.Pk}}</strong>
                </div>

                <div class="card-body">
                    <form #invoicingInstructionForm="ngForm" novalidate>
                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label>Status:</label>
                                <div>{{common.getPendingStatus(model) | titlecase}}</div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label>Type:</label>
                                <div>{{model.DisplayType | splitTitleCase}}</div>
                            </div>
                        </div>

                        <div class="row" *ngIf="model.isAmendment()">
                            <div class="col-sm-6 col-md-4">
                                <label>Amendment Type:</label>
                            </div>

                            <div class="col-12" *ngIf="hasAmendmentError">
                                <p class="d-block error invalid-feedback">
                                    An Amendment Type must be specified before submitting.
                                </p>
                            </div>
                        </div>

                        <div class="form-row" *ngIf="model.isAmendment()">
                            <div class="form-group col-sm-6 col-md-4">
                                <div>
                                    <label class="switch switch-default switch-success switch-lg">
                                        <input [(ngModel)]="model.IsAreaAmendment" name="IsAreaAmendment" id="IsAreaAmendment" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onAmendmentToggle('Area', $event)">
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    <span class="ml-2">Area</span>
                                </div>
                            </div>

                            <div class="form-group col-sm-6 col-md-4">
                                <div>
                                    <label class="switch switch-default switch-success switch-lg">
                                        <input [(ngModel)]="model.IsCustomerAmendment" name="IsCustomerAmendment" id="IsCustomerAmendment" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onAmendmentToggle('Customer', $event)">
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    <span class="ml-2">Contract Assumption</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row" *ngIf="model.isAmendment()">
                            <div class="form-group col-sm-6 col-md-4">
                                <div>
                                    <label class="switch switch-default switch-success switch-lg">
                                        <input [(ngModel)]="model.IsLocationAmendment" name="IsLocationAmendment" id="IsLocationAmendment" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onAmendmentToggle('Location', $event)">
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    <span class="ml-2">Location</span>
                                </div>
                            </div>

                            <div class="form-group col-sm-6 col-md-4">
                                <div>
                                    <label class="switch switch-default switch-success switch-lg">
                                        <input [(ngModel)]="model.IsRateAmendment" name="IsRateAmendment" id="IsRateAmendment" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onAmendmentToggle('Rate', $event)">
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    <span class="ml-2">Monthly Rate</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row" *ngIf="model.isAmendment()">
                            <div class="form-group col-sm-6 col-md-4">
                                <div>
                                    <label class="switch switch-default switch-success switch-lg">
                                        <input [(ngModel)]="model.IsRateTypeAmendment" name="IsRateTypeAmendment" id="IsRateTypeAmendment" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onAmendmentToggle('RateType', $event)">
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    <span class="ml-2">Rate Type</span>
                                </div>
                            </div>

                            <div class="form-group col-sm-6 col-md-4">
                                <div>
                                    <label class="switch switch-default switch-success switch-lg">
                                        <input [(ngModel)]="model.IsTermAmendment" name="IsTermAmendment" id="IsTermAmendment" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onAmendmentToggle('Terms', $event)">
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    <span class="ml-2">Terms</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row" *ngIf="model.isAmendment()">
                            <div class="form-group col-sm-6 col-md-4">
                                <div>
                                    <label class="switch switch-default switch-success switch-lg">
                                        <input [(ngModel)]="model.IsInformationAmendment" name="IsInformationAmendment" id="IsInformationAmendment" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onAmendmentToggle('Information', $event)">
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    <span class="ml-2">Information Change</span>
                                </div>
                            </div>

                            <div class="form-group col-sm-6 col-md-4">
                                <div>
                                    <label class="switch switch-default switch-success switch-lg">
                                        <input [(ngModel)]="model.IsEcoViewAmendment" name="IsEcoViewAmendment" id="IsEcoViewAmendment" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onAmendmentToggle('EcoView', $event)">
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    <span class="ml-2">ecoView</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4">
                                <label for="Date">Effective Date:</label>
                                <p-calendar #dateRef="ngModel" name="Date" id="Date" [(ngModel)]="model.Date" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true" [disabled]="!access.save && !access.changeEffectiveDate"></p-calendar>

                                <validation-message [isValid]="dateRef.invalid && (dateRef.dirty || dateRef.touched)">
                                    Please enter a Date
                                </validation-message>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-4" *ngIf="hasChange('Date')">
                                <label>Old Effective Date:</label>
                                <div>{{model.Parent.Date | date:'MM/dd/yyyy'}}</div>
                            </div>
                        </div>

                        <div class="form-row" *ngIf="model.Type == 'Termination'">
                            <div class="form-group col-sm-6 col-md-4">
                                <label for="ReleaseReasonPk">Release Reason:</label>
                                <select #releaseReasonRef="ngModel" name="ReleaseReasonPk" id="ReleaseReasonPk" class="form-control" [(ngModel)]="model.ReleaseReasonPk" required [disabled]="!access.save" (ngModelChange)="onReleaseReasonChange($event)">
                                    <option *ngIf="!model.ReleaseReasonPk"></option>
                                    <option *ngFor="let item of lookupValues['TERM_RELEASE_REASON']" [ngValue]="item.Pk">{{item.Description}}</option>
                                </select>

                                <validation-message [isValid]="releaseReasonRef.invalid && (releaseReasonRef.dirty || releaseReasonRef.touched)">
                                    Please select a Release Reason
                                </validation-message>
                            </div>

                            <div class="form-group col-sm-6 col-md-4" *ngIf="selectedReason?.Name == 'Rate'">
                                <label for="ReleaseComments">Quoted Rate:</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input #releaseCommentsRef="ngModel" [(ngModel)]="model.ReleaseComments" type="number" name="ReleaseComments" id="ReleaseComments" class="form-control" required numbersOnly [disabled]="!access.save">
                                </div>

                                <validation-message [isValid]="releaseCommentsRef.invalid && (releaseCommentsRef.dirty || releaseCommentsRef.touched)">
                                    Please specify the Quoted Rate
                                </validation-message>
                            </div>

                            <div class="form-group col-sm-6 col-md-4" *ngIf="selectedReason?.Name == 'Other'">
                                <label for="ReleaseComments">Explanation:</label>
                                <input #releaseCommentsRef="ngModel" name="ReleaseComments" id="ReleaseComments" type="text" class="form-control" [(ngModel)]="model.ReleaseComments" required autocomplete="kgs" [disabled]="!access.save" maxlength="150">

                                <validation-message [isValid]="releaseCommentsRef.invalid && (releaseCommentsRef.dirty || releaseCommentsRef.touched)">
                                    Please provide an Explanation
                                </validation-message>
                            </div>
                        </div>

                        <tabset #moduleTabs>
                            <tab>
                                <ng-template tabHeading>
                                    <i class="far fa-file-alt"></i> <span class="ml-2">Details</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="RateType">Rate Type:</label>
                                        <select #rateTypeRef="ngModel" name="RateType" id="RateType" class="form-control" [(ngModel)]="model.RateType" required [disabled]="!edit.rateType">
                                            <option *ngIf="!model.RateType"></option>
                                            <option>Full</option>
                                            <option>Standby</option>
                                            <option>Standby (at packaging facility)</option>
                                            <option>Standby and Full</option>
                                            <option>Standby to Full</option>
                                            <option>Full to Standby</option>
                                            <option>Throughput</option>
                                            <option>Discount</option>
                                        </select>

                                        <validation-message [isValid]="rateTypeRef.invalid && (rateTypeRef.dirty || rateTypeRef.touched)">
                                            Please select a Rate Type
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="Unit">Unit:</label>
                                        <input #unitRef="ngModel" name="Unit" id="Unit" type="search" class="form-control" [(ngModel)]="model.AssetName" readonly>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('RateType')">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Old Rate Type:</label>
                                        <div>{{model.Parent.RateType}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="AccountManagerName">Account Representative:</label>
                                        <input #accountManagerRef="ngModel" name="AccountManagerName" id="AccountManagerName" type="search" class="form-control" [(ngModel)]="typeahead['AccountManager']" [typeahead]="accountRepresentatives" [typeaheadScrollable]="true" typeaheadOptionField="DisplayName" (typeaheadOnSelect)="onAccountManagerChange($event)" required autocomplete="kgs" [disabled]="!access.save" (ngModelChange)="onTypeaheadChange('AccountManager')" (blur)="validateTypeahead('AccountManager')">

                                        <validation-message [isValid]="accountManagerRef.invalid && (accountManagerRef.dirty || accountManagerRef.touched)">
                                            Please select an Account Representative
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="OperationsManagerName">Operations Representative:</label>
                                        <input #operationsManagerRef="ngModel" name="OperationsManagerName" id="OperationsManagerName" type="search" class="form-control" [(ngModel)]="typeahead['OperationsManager']" [typeahead]="areaManagers" [typeaheadScrollable]="true" typeaheadOptionField="DisplayName" (typeaheadOnSelect)="onOperationsManagerChange($event)" required autocomplete="kgs" [disabled]="!access.save" (ngModelChange)="onTypeaheadChange('OperationsManager')" (blur)="validateTypeahead('OperationsManager')">

                                        <validation-message [isValid]="operationsManagerRef.invalid && (operationsManagerRef.dirty || operationsManagerRef.touched)">
                                            Please select an Operations Representative
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('AccountManagerId') || hasChange('OperationsManagerId')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('AccountManagerId')">
                                        <label>Old Account Representative:</label>
                                        <div>{{model.Parent.AccountManagerName}}</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('OperationsManagerId')">
                                        <label>Old Operations Representative:</label>
                                        <div>{{model.Parent.OperationsManagerName}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="CustomerName">Customer:</label>
                                        <input #customerRef="ngModel" name="CustomerName" id="CustomerName" type="search" class="form-control" [(ngModel)]="typeahead['Customer']" [typeahead]="customers" [typeaheadScrollable]="true" typeaheadOptionField="Name" (typeaheadOnSelect)="onCustomerChange($event)" required autocomplete="kgs" [disabled]="!edit.customer" (ngModelChange)="onTypeaheadChange('Customer')" (blur)="validateTypeahead('Customer')">

                                        <validation-message [isValid]="customerRef.invalid && (customerRef.dirty || customerRef.touched)">
                                            Please select a Customer
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="AccountsPayableContactId">Accounts Payable Contact:</label>
                                        <select #accountsPayableContactRef="ngModel" name="AccountsPayableContactId" id="AccountsPayableContactId" class="form-control" [(ngModel)]="model.AccountsPayableContactId" required [disabled]="!edit.customer || !model.CustomerId" (ngModelChange)="onContactChange($event)">
                                            <option *ngIf="!model.AccountsPayableContactId && contacts.length"></option>
                                            <option *ngFor="let contact of contacts" [ngValue]="contact.Pk.toString()">
                                                {{contact.FullName}}
                                            </option>
                                        </select>

                                        <validation-message [isValid]="accountsPayableContactRef.invalid && (accountsPayableContactRef.dirty || accountsPayableContactRef.touched)">
                                            Please select an Accounts Payable Contact
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('CustomerId') || hasChange('AccountsPayableContactId')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('CustomerId')">
                                        <label>Old Customer:</label>
                                        <div>{{model.Parent.CustomerName}}</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('AccountsPayableContactId')">
                                        <label>Old Accounts Payable Contact:</label>
                                        <div>{{model.Parent.AccountsPayableContactName}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="SalesContact">Sales Contact:</label>
                                        <input #salesContactRef="ngModel" name="SalesContact" id="SalesContact" type="text" class="form-control" [(ngModel)]="model.SalesContact" required autocomplete="kgs" [disabled]="!edit.customer">

                                        <validation-message [isValid]="salesContactRef.invalid && (salesContactRef.dirty || salesContactRef.touched)">
                                            Please enter a Sales Contact
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="SalesContactNumber">Sales Contact Number:</label>
                                        <input #salesContactNumberRef="ngModel" name="SalesContactNumber" id="SalesContactNumber" type="text" class="form-control" phoneNumberMask maxlength="14" [(ngModel)]="model.SalesContactNumber" required autocomplete="kgs" [disabled]="!edit.customer">

                                        <validation-message [isValid]="salesContactNumberRef.invalid && (salesContactNumberRef.dirty || salesContactNumberRef.touched)">
                                            Please enter a Sales Contact Number
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('SalesContact') || hasChange('SalesContactNumber')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('SalesContact')">
                                        <label>Old Sales Contact:</label>
                                        <div>{{model.Parent.SalesContact}}</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('SalesContactNumber')">
                                        <label>Old Sales Contact Number:</label>
                                        <div>{{model.Parent.SalesContactNumber}}</div>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="fas fa-map-marker-alt"></i> <span class="ml-2">Location</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="Location">Location Name:</label>
                                        <input #locationRef="ngModel" name="Location" id="Location" type="text" class="form-control" [(ngModel)]="model.Location" required [disabled]="!edit.location">

                                        <validation-message [isValid]="locationRef.invalid && (locationRef.dirty || locationRef.touched)">
                                            Please select a Location
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="AreaId">Kodiak Area:</label>
                                        <input #areaRef="ngModel" name="AreaId" id="AreaId" type="search" class="form-control" [(ngModel)]="typeahead['Area']" [typeahead]="areas" [typeaheadScrollable]="true" typeaheadOptionField="Name" (typeaheadOnSelect)="onAreaChange($event)" required autocomplete="kgs" [disabled]="!edit.location && !edit.area" (ngModelChange)="onTypeaheadChange('Area')" (blur)="validateTypeahead('Area')">

                                        <validation-message [isValid]="areaRef.invalid && (areaRef.dirty || areaRef.touched)">
                                            Please select a Kodiak Area
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('Location') || hasChange('AreaId')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('Location')">
                                        <label>Old Location Name:</label>
                                        <div>{{model.Parent.Location}}</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('AreaId')">
                                        <label>Old Kodiak Area:</label>
                                        <div>Area {{model.Parent.AreaId}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="State">State:</label>
                                        <input #stateRef="ngModel" name="State" id="State" type="search" class="form-control" [(ngModel)]="typeahead['State']" [typeahead]="states" [typeaheadScrollable]="true" (typeaheadOnSelect)="onStateChange($event)" typeaheadOptionField="Name" required autocomplete="kgs" [disabled]="!edit.location" (ngModelChange)="onTypeaheadChange('State')" (blur)="validateTypeahead('State')">

                                        <validation-message [isValid]="stateRef.invalid && (stateRef.dirty || stateRef.touched)">
                                            Please select a State
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="County">County/Parish:</label>
                                        <input #countyRef="ngModel" name="County" id="County" type="search" class="form-control" [(ngModel)]="typeahead['County']" [typeahead]="counties" [typeaheadScrollable]="true" (typeaheadOnSelect)="onCountyChange($event)" typeaheadOptionField="Name" required autocomplete="kgs" [disabled]="!edit.location" (ngModelChange)="onTypeaheadChange('County')" (blur)="validateTypeahead('County')">

                                        <validation-message [isValid]="countyRef.invalid && (countyRef.dirty || countyRef.touched)">
                                            Please select a County/Parish
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('State') || hasChange('County')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('State')">
                                        <label>Old State:</label>
                                        <div>{{model.Parent.State}}</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('County')">
                                        <label>Old County/Parish:</label>
                                        <div>{{model.Parent.County}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="IsInCityLimits">In City Limits?</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.IsInCityLimits" name="IsInCityLimits" id="IsInCityLimits" class="switch-input" type="checkbox" [disabled]="!edit.location">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('IsInCityLimits')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('IsInCityLimits')">
                                        <label>Old In City Limits?</label>
                                        <div>{{model.Parent.IsInCityLimits ? 'Yes' : 'No'}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-12 col-md-8">
                                        <label for="CostCenter">Cost Center:</label>
                                        <textarea #contractCostCenterRef="ngModel" rows="3" [(ngModel)]="model.CostCenter" name="CostCenter" id="CostCenter" class="form-control" [disabled]="!access.save" maxlength="250"></textarea>

                                        <validation-message [isValid]="contractCostCenterRef.invalid && (contractCostCenterRef.dirty || contractCostCenterRef.touched)">
                                            Please specify a Cost Center
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('CostCenter')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('CostCenter')">
                                        <label>Old Cost Center:</label>
                                        <div>{{model.Parent.CostCenter}}</div>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="far fa-clock"></i> <span class="ml-2">Installation/Operations</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallTruckSetup">Trucks Set Up:</label>
                                        <select #installTruckSetupRef="ngModel" name="InstallTruckSetup" id="InstallTruckSetup" class="form-control" [(ngModel)]="model.InstallTruckSetup" required [disabled]="!access.save">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="installTruckSetupRef.invalid && (installTruckSetupRef.dirty || installTruckSetupRef.touched)">
                                            Please select a Trucks/Cranes Set Up option
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallCraneSetup">Cranes Set Up:</label>
                                        <select #installCraneSetupRef="ngModel" name="InstallCraneSetup" id="InstallCraneSetup" class="form-control" [(ngModel)]="model.InstallCraneSetup" required [disabled]="!access.save">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="installCraneSetupRef.invalid && (installCraneSetupRef.dirty || installCraneSetupRef.touched)">
                                            Please select a Trucks/Cranes Set Up option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('InstallTruckSetup') || hasChange('InstallCraneSetup')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('InstallTruckSetup')">
                                        <label>Old Trucks Set Up:</label>
                                        <div>{{model.Parent.InstallTruckSetup}}</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('InstallCraneSetup')">
                                        <label>Old Cranes Set Up:</label>
                                        <div>{{model.Parent.InstallCraneSetup}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallTransportation">Transportation In Paid By:</label>
                                        <select #installTransportationRef="ngModel" name="InstallTransportation" id="InstallTransportation" class="form-control" [(ngModel)]="model.InstallTransportation" required [disabled]="!access.save" (ngModelChange)="onTransportationUpdate('InstallTransportation')">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                            <option>Previous Customer</option>
                                            <option>Shared Billing</option>
                                        </select>

                                        <validation-message [isValid]="installTransportationRef.invalid && (installTransportationRef.dirty || installTransportationRef.touched)">
                                            Please select a Transportation In Paid By option
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" *ngIf="model.InstallTransportation == 'Shared Billing'">
                                        <label for="InstallTransportationSplit">Split:</label>
                                        <select #installTransportationSplitRef="ngModel" name="InstallTransportationSplit" id="InstallTransportationSplit" class="form-control" [(ngModel)]="model.InstallTransportationSplit" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>25%</option>
                                            <option>50%</option>
                                            <option>75%</option>
                                            <option>100%</option>
                                        </select>

                                        <validation-message [isValid]="installTransportationSplitRef.invalid && (installTransportationSplitRef.dirty || installTransportationSplitRef.touched)">
                                            Please select a Transportation In Split option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('InstallTransportation') || hasChange('InstallTransportationSplit')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('InstallTransportation')">
                                        <label>Old Transportation In:</label>
                                        <div>{{model.Parent.InstallTransportation}}</div>
                                    </div>
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('InstallTransportationSplit')">
                                        <label>Old Split:</label>
                                        <div>{{model.Parent.InstallTransportationSplit}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallTransportationOut">Transportation Out Paid By:</label>
                                        <select #installTransportationOutRef="ngModel" name="InstallTransportationOut" id="InstallTransportationOut" class="form-control" [(ngModel)]="model.InstallTransportationOut" required [disabled]="!access.save" (ngModelChange)="onTransportationUpdate('InstallTransportationOut')">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                            <option>Previous Customer</option>
                                            <option>Shared Billing</option>
                                        </select>

                                        <validation-message [isValid]="installTransportationOutRef.invalid && (installTransportationOutRef.dirty || installTransportationOutRef.touched)">
                                            Please select a Transportation Out Paid By option
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" *ngIf="model.InstallTransportationOut == 'Shared Billing'">
                                        <label for="InstallTransportationOutSplit">Split:</label>
                                        <select #installTransportationOutSplitRef="ngModel" name="InstallTransportationOutSplit" id="InstallTransportationOutSplit" class="form-control" [(ngModel)]="model.InstallTransportationOutSplit" required [disabled]="!access.save" data-monitor-type="workflow">
                                            <option>25%</option>
                                            <option>50%</option>
                                            <option>75%</option>
                                            <option>100%</option>
                                        </select>

                                        <validation-message [isValid]="installTransportationOutSplitRef.invalid && (installTransportationOutSplitRef.dirty || installTransportationOutSplitRef.touched)">
                                            Please select a Transportation Out Split option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('InstallTransportationOut') || hasChange('InstallTransportationOutSplit')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('InstallTransportationOut')">
                                        <label>Old Transportation Out:</label>
                                        <div>{{model.Parent.InstallTransportationOut}}</div>
                                    </div>
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('InstallTransportationOutSplit')">
                                        <label>Old Split:</label>
                                        <div>{{model.Parent.InstallTransportationOutSplit}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="InstallCranes">Cranes Paid By:</label>
                                        <select #installCranesRef="ngModel" name="InstallCranes" id="InstallCranes" class="form-control" [(ngModel)]="model.InstallCranes" required [disabled]="!access.save">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                            <option>Previous Customer</option>
                                            <option>Shared Billing</option>
                                        </select>

                                        <validation-message [isValid]="installCranesRef.invalid && (installCranesRef.dirty || installCranesRef.touched)">
                                            Please select a Cranes option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('InstallCranes')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('InstallCranes')">
                                        <label>Old Cranes:</label>
                                        <div>{{model.Parent.InstallCranes}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="CatalystProvider">Catalyst Provided By:</label>
                                        <select #catalystProviderRef="ngModel" name="CatalystProvider" id="CatalystProvider" class="form-control" [(ngModel)]="model.CatalystProvider" required [disabled]="!access.save">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="catalystProviderRef.invalid && (catalystProviderRef.dirty || catalystProviderRef.touched)">
                                            Please select a Catalyst Provider
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('CatalystProvider')">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Old Catalyst Provided By:</label>
                                        <div>{{model.Parent.CatalystProvider}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="IsKodiakFirstCatalyst">Kodiak Provides 1st Catalyst:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.IsKodiakFirstCatalyst" name="IsKodiakFirstCatalyst" id="IsKodiakFirstCatalyst" class="switch-input" type="checkbox" [disabled]="!access.save">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasBillBackInstall">Bill Back for Install:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.HasBillBackInstall" name="HasBillBackInstall" id="HasBillBackInstall" class="switch-input" type="checkbox" [disabled]="!access.save">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('IsKodiakFirstCatalyst') || hasChange('HasBillBackInstall')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('IsKodiakFirstCatalyst')">
                                        <label>Old Kodiak Provides 1st Catalyst:</label>
                                        <div>{{model.Parent.IsKodiakFirstCatalyst ? 'Yes' : 'No'}}</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('HasBillBackInstall')">
                                        <label>Old Bill Back for Install:</label>
                                        <div>{{model.Parent.HasBillBackInstall ? 'Yes' : 'No'}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="LiquidsProvider">Liquids Provided By:</label>
                                        <select #liquidsProviderRef="ngModel" name="LiquidsProvider" id="LiquidsProvider" class="form-control" [(ngModel)]="model.LiquidsProvider" required [disabled]="!access.save">
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="liquidsProviderRef.invalid && (liquidsProviderRef.dirty || liquidsProviderRef.touched)">
                                            Please select a Liquids Provider
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('LiquidsProvider')">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Old Liquids Provided By:</label>
                                        <div>{{model.Parent.LiquidsProvider}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-12 col-md-8">
                                        <label for="ContractNotes">Special Provisions (Additional Contract or Installation Terms):</label>
                                        <textarea rows="3" [(ngModel)]="model.ContractNotes" name="ContractNotes" id="ContractNotes" class="form-control" [disabled]="!access.save"></textarea>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-12 col-md-8">
                                        <label for="Notes">Notes:</label>
                                        <textarea #notesRef="ngModel" rows="3" [(ngModel)]="model.Notes" name="Notes" id="Notes" class="form-control" required [disabled]="!access.editNotes"></textarea>

                                        <validation-message [isValid]="notesRef.invalid && (notesRef.dirty || notesRef.touched)">
                                            Please specify Notes
                                        </validation-message>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="fas fa-leaf fa-flip-horizontal"></i> <span class="ml-2">ecoView</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasEcoView">ecoView:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                            <input [(ngModel)]="model.HasEcoView" name="HasEcoView" id="HasEcoView" class="switch-input" type="checkbox" [disabled]="!edit.ecoView" data-monitor-type="workflow">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('HasEcoView')">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Old ecoView:</label>
                                        <div>{{model.Parent.HasEcoView ? 'Yes' : 'No'}}</div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="EcoViewPackagePk">Package:</label>
                                        <select #ecoViewPackageRef="ngModel" name="EcoViewPackagePk" id="EcoViewPackagePk" class="form-control" [(ngModel)]="model.EcoViewPackagePk" [disabled]="!edit.ecoView" required data-monitor-type="workflow" (ngModelChange)="onEcoViewPackageSelect()">
                                            <option *ngIf="!model.EcoViewPackagePk"></option>
                                            <option *ngFor="let item of lookupValues['SALES_ECOVIEW_PACKAGE']" [ngValue]="item.Pk">{{item.Description}}</option>
                                        </select>

                                        <validation-message [isValid]="ecoViewPackageRef.invalid && (ecoViewPackageRef.dirty || ecoViewPackageRef.touched)">
                                            Please select a Package for ecoView
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="EcoViewPackageRate">Monthly Rate:</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input #ecoViewPackageRateRef="ngModel" [(ngModel)]="model.EcoViewPackageRate" type="number" name="EcoViewPackageRate" id="EcoViewPackageRate" class="form-control" required numbersOnly [disabled]="!edit.ecoView" data-monitor-type="workflow" (ngModelChange)="calculateEcoViewDailyRate()">
                                        </div>

                                        <validation-message [isValid]="ecoViewPackageRateRef.invalid && (ecoViewPackageRateRef.dirty || ecoViewPackageRateRef.touched)">
                                            Please specify a Monthly Rate for ecoView
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView && (hasChange('EcoViewPackagePk') || hasChange('EcoViewPackageRate'))">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('EcoViewPackagePk')">
                                        <label>Old Package:</label>
                                        <div>{{getPackage(model.Parent.EcoViewPackagePk)}}</div>
                                    </div>
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('EcoViewPackageRate')">
                                        <label>Old Monthly Rate:</label>
                                        <div>${{model.Parent.EcoViewPackageRate||0}}</div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView">
                                    <div class="form-group col-sm-6 col-md-4" *ngIf="model.HasEcoView">
                                        <label for="EcoViewTermInMonths">Term in Months:</label>
                                        <input #ecoViewTermInMonthsRef="ngModel" [(ngModel)]="model.EcoViewTermInMonths" type="number" name="EcoViewTermInMonths" id="EcoViewTermInMonths" class="form-control" required numbersOnly [disabled]="!edit.ecoView" data-monitor-type="workflow">

                                        <validation-message [isValid]="ecoViewTermInMonthsRef.invalid && (ecoViewTermInMonthsRef.dirty || ecoViewTermInMonthsRef.touched)">
                                            Please specify the Term in Months for ecoView
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView && hasChange('EcoViewTermInMonths')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('EcoViewTermInMonths')">
                                        <label>Old Term in Months:</label>
                                        <div>{{model.Parent.EcoViewTermInMonths}}</div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="EcoViewBillingDate">Effective Billing Date:</label>
                                        <p-calendar #ecoViewBillingDateRef="ngModel" name="EcoViewBillingDate" id="EcoViewBillingDate" [(ngModel)]="model.EcoViewBillingDate" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true" [disabled]="!edit.ecoView" data-monitor-type="workflow"></p-calendar>

                                        <validation-message [isValid]="ecoViewBillingDateRef.invalid && (ecoViewBillingDateRef.dirty || ecoViewBillingDateRef.touched)">
                                            Please enter an Effective Billing Date for ecoView
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView && hasChange('EcoViewBillingDate')">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Old Effective Billing Date:</label>
                                        <div>{{model.Parent.EcoViewBillingDate | date:'MM/dd/yyyy'}}</div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="HasEcoViewInstall">Has installation charge for ecoView?</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                            <input [(ngModel)]="model.HasEcoViewInstall" name="HasEcoViewInstall" id="HasEcoViewInstall" class="switch-input" type="checkbox" [disabled]="!edit.ecoView" data-monitor-type="workflow" (ngModelChange)="onEcoViewInstallToggle()">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView && hasChange('HasEcoViewInstall')">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Old Has installation charge for ecoView?</label>
                                        <div>{{model.Parent.HasEcoViewInstall ? 'Yes' : 'No'}}</div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView && model.HasEcoViewInstall">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="EcoViewInstallCharge">Installation Charge:</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input #ecoViewInstallChargeRef="ngModel" [(ngModel)]="model.EcoViewInstallCharge" type="number" name="EcoViewInstallCharge" id="EcoViewInstallCharge" class="form-control" required numbersOnly [disabled]="!edit.ecoView" data-monitor-type="workflow">
                                        </div>

                                        <validation-message [isValid]="ecoViewInstallChargeRef.invalid && (ecoViewInstallChargeRef.dirty || ecoViewInstallChargeRef.touched)">
                                            Please specify an Installation Charge for ecoView
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.HasEcoView && model.HasEcoViewInstall && hasChange('EcoViewInstallCharge')">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Old Installation Charge:</label>
                                        <div>${{model.Parent.EcoViewInstallCharge}}</div>
                                    </div>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="fas fa-file-alt"></i> <span class="ml-2">Contract Info</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="Term">Terms:</label>
                                        <select #termRef="ngModel" name="Term" id="Term" class="form-control" [(ngModel)]="model.Term" required [disabled]="!access.save" (ngModelChange)="onTermsChange()">
                                            <option *ngIf="!model.Term"></option>
                                            <option value="monthly">Month to Month</option>
                                            <option value="contract">Contract</option>
                                        </select>

                                        <validation-message [isValid]="termRef.invalid && (termRef.dirty || termRef.touched)">
                                            Please specify a Term
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" *ngIf="model.Term == 'contract'">
                                        <label for="TermInMonths">Term in Months:</label>
                                        <input #termInMonthsRef="ngModel" [(ngModel)]="model.TermInMonths" type="number" name="TermInMonths" id="TermInMonths" class="form-control" required numbersOnly [disabled]="!access.save || !edit.term">

                                        <validation-message [isValid]="termInMonthsRef.invalid && (termInMonthsRef.dirty || termInMonthsRef.touched)">
                                            Please specify a Term in Months
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('Term') || hasChange('TermInMonths')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('Term')">
                                        <label>Old Terms:</label>
                                        <div>{{model.Parent.Term == 'contract' ? 'Contract' : 'Month to Month'}}</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('TermInMonths')">
                                        <label>Old Term in Months:</label>
                                        <div>{{model.Parent.TermInMonths}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="ContractStatus">Contract Executed:</label>
                                        <select #contractStatusRef="ngModel" name="ContractStatus" id="ContractStatus" class="form-control" [(ngModel)]="model.ContractStatus" required [disabled]="!access.save">
                                            <option>Yes</option>
                                            <option>No</option>
                                            <option value="Pending">Pending - awaiting customer signature</option>
                                        </select>

                                        <validation-message [isValid]="contractStatusRef.invalid && (contractStatusRef.dirty || contractStatusRef.touched)">
                                            Please specify a Contract Executed option
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="AdValBy">Ad Val By:</label>
                                        <select #adValByRef="ngModel" name="AdValBy" id="AdValBy" class="form-control" [(ngModel)]="model.AdValBy" required [disabled]="!access.save">
                                            <option *ngIf="!model.AdValBy"></option>
                                            <option>Customer</option>
                                            <option>Kodiak</option>
                                        </select>

                                        <validation-message [isValid]="adValByRef.invalid && (adValByRef.dirty || adValByRef.touched)">
                                            Please select an Ad Val By option
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('ContractStatus') || hasChange('AdValBy')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('ContractStatus')">
                                        <label>Old Contract Executed:</label>
                                        <div>{{model.Parent.ContractStatus}}</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('AdValBy')">
                                        <label>Old Ad Val By:</label>
                                        <div>{{model.Parent.AdValBy}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="IsPerformanceBased">Performance Based:</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="model.IsPerformanceBased" name="IsPerformanceBased" id="IsPerformanceBased" class="switch-input" type="checkbox" [disabled]="!access.save">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('IsPerformanceBased')">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label>Old Performance Based:</label>
                                        <div>{{model.Parent.IsPerformanceBased ? 'Yes' : 'No'}}</div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="model.IsPerformanceBased">
                                    <div class="form-group col-sm-12 col-md-8">
                                        <label for="PerformanceBasedNotes">Performance Based Notes:</label>
                                        <textarea #performanceBasedNotesRef="ngModel" rows="3" [(ngModel)]="model.PerformanceBasedNotes" name="PerformanceBasedNotes" id="PerformanceBasedNotes" class="form-control" required [disabled]="!access.save"></textarea>

                                        <validation-message [isValid]="performanceBasedNotesRef.invalid && (performanceBasedNotesRef.dirty || performanceBasedNotesRef.touched)">
                                            Please enter Performance Based Notes
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="MonthlyRate">Monthly Rate:</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input #monthlyRateRef="ngModel" [(ngModel)]="model.MonthlyRate" type="number" name="MonthlyRate" id="MonthlyRate" class="form-control" required numbersOnly (ngModelChange)="calculateDailyRate()" [disabled]="!edit.rate">
                                        </div>

                                        <validation-message [isValid]="monthlyRateRef.invalid && (monthlyRateRef.dirty || monthlyRateRef.touched)">
                                            Please specify a Monthly Rate
                                        </validation-message>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('MonthlyRate')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('MonthlyRate')">
                                        <label>Old Monthly Rate:</label>
                                        <div>${{previousII.MonthlyRate}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="StandbyRate">Standby Rate:</label>
                                        <div class="input-group">
                                            <input #standbyRateRef="ngModel" [(ngModel)]="model.StandbyRate" type="number" name="StandbyRate" id="StandbyRate" class="form-control" required numbersOnly (ngModelChange)="calculateStandbyRateAmount()" [disabled]="!edit.rate">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="standbyRateRef.invalid && (standbyRateRef.dirty || standbyRateRef.touched)">
                                            Please specify a Standby Rate
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="MonthlyStandbyRate">Monthly Standby Rate:</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input [(ngModel)]="model.MonthlyStandbyRate" type="number" name="MonthlyStandbyRate" id="MonthlyStandbyRate" class="form-control" readonly>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('StandbyRate') || hasChange('MonthlyStandbyRate')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('StandbyRate')">
                                        <label>Old Standby Rate:</label>
                                        <div>{{previousII.StandbyRate}}%</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('MonthlyStandbyRate')">
                                        <label>Old Monthly Standby Rate:</label>
                                        <div>${{previousII.MonthlyStandbyRate}}</div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="PackagingStandbyRate">Standby Rate (at packaging facility):</label>
                                        <div class="input-group">
                                            <input #packagingStandbyRateRef="ngModel" [(ngModel)]="model.PackagingStandbyRate" type="number" name="PackagingStandbyRate" id="PackagingStandbyRate" class="form-control" numbersOnly (ngModelChange)="calculatePackagingStandbyRateAmount()" [disabled]="!edit.rate">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>

                                        <validation-message [isValid]="packagingStandbyRateRef.invalid && (packagingStandbyRateRef.dirty || packagingStandbyRateRef.touched)">
                                            Please specify a Standby Rate (at packaging facility)
                                        </validation-message>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4">
                                        <label for="PackagingMonthlyStandbyRate">Monthly Standby Rate (at packaging facility):</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input [(ngModel)]="model.PackagingMonthlyStandbyRate" type="number" name="PackagingMonthlyStandbyRate" id="PackagingMonthlyStandbyRate" class="form-control" readonly>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="hasChange('PackagingStandbyRate') || hasChange('PackagingMonthlyStandbyRate')">
                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('PackagingStandbyRate')">
                                        <label>Old Standby Rate (at packaging facility):</label>
                                        <div>{{model.Parent.PackagingStandbyRate}}%</div>
                                    </div>

                                    <div class="form-group col-sm-6 col-md-4" [class.hidden]="!hasChange('PackagingMonthlyStandbyRate')">
                                        <label>Old Monthly Standby Rate (at packaging facility):</label>
                                        <div>${{model.Parent.PackagingMonthlyStandbyRate}}</div>
                                    </div>
                                </div>

                                <div *ngIf="model.SalesOrder?.HasEscalationClause">
                                    <h6>Escalation Clause</h6>

                                    <button *ngIf="access.save" class="btn btn-success mb-2" (click)="onAdd()">
                                        <i class="fas fa-plus-circle"></i> <span class="ml-2">Add</span>
                                    </button>

                                    <table class="table table-striped stack">
                                        <thead>
                                            <tr>
                                                <th> Date </th>
                                                <th> Amount </th>
                                                <th> Type </th>
                                                <th> New Amount </th>
                                                <th *ngIf="access.save"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let escalationItem of model.SalesOrder.Escalations; let i=index">
                                                <td>
                                                    <span class="d-inline d-md-none">Date:</span>
                                                    <p-calendar #escalationDateRef="ngModel" name="Date{{i}}" id="Date{{i}}" [(ngModel)]="escalationItem.EscalationDate" dateFormat="mm/dd/yy" readonlyInput="true"  [disabled]="!access.save"  inputStyleClass="form-control" [required]="true"></p-calendar>
                                                    <validation-message [isValid]="escalationDateRef.invalid && (escalationDateRef.dirty || escalationDateRef.touched)">
                                                        Please specify date
                                                    </validation-message>
                                                </td>
                                                <td>
                                                    <span class="d-inline d-md-none">Amount:</span>
                                                    <input #escalationAmountRef="ngModel" type="number" class="form-control" [(ngModel)]="escalationItem.Amount"  [disabled]="!access.save" name="Amount{{i}}" required id="Amount{{i}}">
                                                    <validation-message [isValid]="escalationAmountRef.invalid && (escalationAmountRef.dirty || escalationAmountRef.touched)">
                                                        Please specify amount
                                                    </validation-message>
                                                </td>
                                                <td>
                                                    <span class="d-inline d-md-none">Type:</span>
                                                    <select #escalationTypeRef="ngModel"  class="form-control" [(ngModel)]="escalationItem.TypePk" name="escalationType{{i}}" id="escalationType{{i}}" [disabled]="!access.save"  required>
                                                        <option *ngIf="!escalationItem.TypePk"></option>
                                                        <option *ngFor="let item of lookupValues['SALES_ESCALATION_TYPE']" [ngValue]="item.Pk">{{item.Description}}</option>
                                                    </select>
                                                    <validation-message [isValid]="escalationTypeRef.invalid && (escalationTypeRef.dirty || escalationTypeRef.touched)">
                                                        Please select type
                                                    </validation-message>
                                                </td>
                                                <td>
                                                    <span class="d-inline d-md-none">New Amount:</span>
                                                    <input #escalationNewAmountRef="ngModel" type="number" class="form-control" [(ngModel)]="escalationItem.NewAmount" [disabled]="!access.save"  required name="NewAmount{{i}}" id="NewAmount{{i}}">
                                                    <validation-message [isValid]="escalationNewAmountRef.invalid && (escalationNewAmountRef.dirty || escalationNewAmountRef.touched)">
                                                        Please specify new amount
                                                    </validation-message>
                                                </td>
                                                <td *ngIf="access.save" class="actions">
                                                    <span class="d-inline d-md-none">Delete:</span>
                                                    <button class="btn btn-danger" (click)="onEscalationDelete(i)" tooltip="Delete">
                                                        <i class="fas fa-times-circle"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </tab>

                            <tab>
                                <ng-template tabHeading>
                                    <i class="fas fa-paperclip"></i> <span class="ml-2">Attachments</span>
                                </ng-template>

                                <div class="form-row">
                                    <div class="form-group col-sm-12 col-md-10">
                                        <spark-file-list [model]="this.model" [readOnly]="!access.save" [sparkModuleType]="sparkModuleType"></spark-file-list>
                                    </div>
                                </div>
                            </tab>
                        </tabset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="app-footer d-md-none d-lg-none" *ngIf="!preview">
    <div>
        <button class="btn btn-success" (click)="onSave()" [disabled]="!canSave()">
            <i class="fas fa-save"></i> <span class="ml-2">Save</span>
        </button>

        <button class="btn btn-secondary float-right" (click)="onBack()">
            <i class="fas fa-undo-alt"></i> <span class="ml-2">Back</span>
        </button>
    </div>
</footer>
