import { ApplicationUserModel } from './application.model';
import { WorkflowModuleModel } from './workflow.model';

import * as moment from 'moment';

export class RuntimeReportModel {
    Pk: number = 0;
    StartDate: Date;
    EndDate: Date;
    Type: string;
    Status: string;
    AssetId: string;
    AssetName: string;
    Customer: string;
    AreaId: string;
    Location: string;
    MonitorId: string;
    TotalKodiakDowntime: number;
    TotalCustomerDowntime: number;
    TotalUptime: number;
    TotalRuntime: number;
    WOPK: number;
    CreationDate: Date;
    CreatedBy: number;
    CreatedById: string;
    CreatedByName: string;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    LineItems: RuntimeReportItemModel[] = new Array();
    Creator: ApplicationUserModel;
    CanApprove: boolean;
    WorkflowId: string = 'RR';
    Workflows: WorkflowModuleModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Type = obj.Type;
        this.Status = obj.Status;
        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.Customer = obj.Customer;
        this.AreaId = obj.AreaId;
        this.Location = obj.Location;
        this.MonitorId = obj.MonitorId;
        this.TotalKodiakDowntime = obj.TotalKodiakDowntime;
        this.TotalCustomerDowntime = obj.TotalCustomerDowntime;
        this.TotalUptime = obj.TotalUptime;
        this.TotalRuntime = obj.TotalRuntime;
        this.WOPK = obj.WOPK;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.CreatedById = obj.CreatedById;
        this.CreatedByName = obj.CreatedByName;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.CanApprove = obj.CanApprove;
        this.WorkflowId = obj.WorkflowId;
        this.WOPK = obj.WOPK;

        if (obj.StartDate) {
            this.StartDate = moment(obj.StartDate).startOf('day').toDate();
        }

        if (obj.EndDate) {
            this.EndDate = moment(obj.EndDate).startOf('day').toDate();
        }

        if (obj.LineItems) {
            this.LineItems = obj.LineItems.map(x => new RuntimeReportItemModel(x));

            this.LineItems.sort((a, b) => {
                const val1 = new Date(a.StopTime);
                const val2 = new Date(b.StopTime);
                return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
            });
        }

        if (obj.Creator) {
            this.Creator = new ApplicationUserModel(obj.Creator);
        }

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }
    }
}

export class RuntimeReportItemModel {
    Pk: number = 0;
    RuntimeReportPk: number;
    StopTime: Date;
    StartTime: Date;
    Downtime: number;
    IsKodiakDowntime: boolean;
    FaultCodeId: string;
    FaultCodeName: string;
    Blowdown: boolean;
    Reason: string;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.RuntimeReportPk = obj.RuntimeReportPk;
        this.Downtime = obj.Downtime;
        this.IsKodiakDowntime = obj.IsKodiakDowntime;
        this.FaultCodeId = obj.FaultCodeId;
        this.FaultCodeName = obj.FaultCodeName;
        this.Blowdown = obj.Blowdown;
        this.Reason = obj.Reason;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;

        if (obj.StopTime) {
            this.StopTime = moment(obj.StopTime).toDate();
        }

        if (obj.StartTime) {
            this.StartTime = moment(obj.StartTime).toDate();
        }
    }
}

export class RuntimeReportImportItemModel {
    Pk: number = 0;
    MonitorId: string;
    StopTime: Date;
    StartTime: Date;
    TotalDowntime: number;
    Description: string;
    CreationDate: Date;
    CreatedBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.MonitorId = obj.MonitorId;
        this.StopTime = obj.StopTime;
        this.StartTime = obj.StartTime;
        this.TotalDowntime = obj.TotalDowntime;
        this.Description = obj.Description;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
    }
}

export class RuntimeReportImportLogModel {
    Pk: number;
    HasErrors: boolean;
    Details: string;
    Arguments: any;
    Type: string;
    CreationDate: Date;
    LastUpdateDate: Date;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.HasErrors = obj.HasErrors;
        this.Details = obj.Details;
        this.Arguments = obj.Arguments;
        this.Type = obj.Type;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();

        if (this.CreationDate.getTime() == this.LastUpdateDate.getTime()) {
            this.HasErrors = true;
        }
    }
}