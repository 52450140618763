import { Injectable } from "@angular/core";
import { Globals } from "../../shared/global";
import { Common } from "./common.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import {
    FluidsRequestItemModel,
    FluidsRequestProcessModel,
    FluidsRequestModel,
    FluidsRequestReconcileModel,
    MacolaBatchModel,
    McUploadDetailModel,
    PartModel,
    ReportModel,
    SearchCriteriaModel,
    SearchResultModel
} from "../../shared/models";

@Injectable()
export class FluidsRequestService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.FLUID_REQUEST}/api/fluids-request`;

    constructor(private common: Common, private http: HttpClient) {}

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<FluidsRequestModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new FluidsRequestModel(x));
                }
                return res;
            }));
    }

    get(modelPk: number)  {
        return this.http.get<FluidsRequestModel>(`${this.url}/get?modelPk=${modelPk}`)
            .pipe(
                map(res => new FluidsRequestModel(res))
            );
    }

    save(model: FluidsRequestModel) {
        const newModel: FluidsRequestModel = this.common.formatDateFields(model, 'DeliveryDate');

        for (let x in newModel.Items) {

            if (newModel.Items[x].Pk < 0) {
                newModel.Items[x].Pk = 0;
            }

            for (let y in newModel.Items[x].Types) {
                newModel.Items[x].Types[y] = this.common.formatDateFields(newModel.Items[x].Types[y], 'DeliveryDate');
            }
        }

        if (model.Pk) {
            return this.http.put<FluidsRequestModel>(`${this.url}/update`, newModel)
                .pipe(
                    map(res => new FluidsRequestModel(res))
                );
        }

        return this.http.post<FluidsRequestModel>(`${this.url}/create`, newModel)
            .pipe(
                map(res => new FluidsRequestModel(res))
            );
    }

    updateItemType(model: FluidsRequestProcessModel) {
        const newModel: FluidsRequestProcessModel = this.common.formatDateFields(model, 'InvoiceDate');

        return this.http.put<FluidsRequestProcessModel>(`${this.url}/update/item-type`, newModel)
            .pipe(
                map(res => new FluidsRequestProcessModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    getHistoryByAsset(assetId: string) {
        return this.http.get<FluidsRequestItemModel[]>(`${this.url}/get-history-by-asset?assetId=${assetId}`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new FluidsRequestItemModel(x));

                    return results;
                }

                return new Array() as FluidsRequestItemModel[];
            }));
    }

    export(modelPk: number) {
        return this.http.post<ReportModel>(`${this.url}/export?modelPk=${modelPk}`, {}).pipe(map(res => new ReportModel(res)));
    }

    reconcile(models: FluidsRequestReconcileModel[]) {
        for (let x in models) {
            models[x] = this.common.formatDateFields(models[x], 'InvoiceDate');
        }

        return this.http.post<McUploadDetailModel>(`${this.url}/reconcile`, models).pipe(map(res => new McUploadDetailModel(res)));
    }

    process(model: MacolaBatchModel) {
        return this.http.post<string>(`${this.url}/process`, model);
    }

    getProducts() {
        return this.http.get<PartModel[]>(`${this.url}/get/products`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new PartModel(x));

                    return results;
                }

                return new Array() as PartModel[];
            }));
    }
}