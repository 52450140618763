import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { map } from "rxjs/operators";

import {
    Common,
    FluidsRequestService,
    LookupService,
    LookupValueService,
    WorkflowService
} from "src/app/core/services";

import {
    ConfirmModel,
    FluidsRequestItemTypeModel,
    FluidsRequestModel,
    LookupValueModel,
    PartModel,
    UserModel
} from "src/app/shared/models";

import { FluidsRequestEmergencyFillTankDetailComponent } from "./fluids-request-emergency-fill-tank-detail.component";
import { FluidsRequestFillTanksDetailComponent } from "./fluids-request-fill-tanks-detail.component";
import { FluidsRequestMoveTankDetailComponent } from "./fluids-request-move-tank-detail.component";
import { FluidsRequestNewTankDetailComponent } from "./fluids-request-new-tank-detail.component";
import { FluidsRequestOilOnlyDetailComponent } from "./fluids-request-oil-only-detail.component";
import { FluidsRequestShutdownTankDetailComponent } from "./fluids-request-shutdown-tank-detail.component";
import { FluidsRequestSwapUnitDetailComponent } from "./fluids-request-swap-unit-detail.component";

@Component({
    selector: 'fluids-request-detail',
    templateUrl:'./fluids-request-detail.component.html'
})
export class FluidsRequestDetailComponent implements OnInit {
    @ViewChild(FluidsRequestNewTankDetailComponent, { static : false }) newTankDetailComponent;
    @ViewChild(FluidsRequestMoveTankDetailComponent, { static : false }) moveTankDetailComponent;
    @ViewChild(FluidsRequestShutdownTankDetailComponent, { static : false }) shutdownTankDetailComponent;
    @ViewChild(FluidsRequestFillTanksDetailComponent, { static : false }) fillTanksDetailComponent;
    @ViewChild(FluidsRequestEmergencyFillTankDetailComponent, { static : false }) emergencyFillDetailComponent;
    @ViewChild(FluidsRequestOilOnlyDetailComponent, { static : false }) oilOnlyDetailComponent;
    @ViewChild(FluidsRequestSwapUnitDetailComponent, { static : false }) swapUnitDetailComponent;

    @Input() id: number = 0;
    @Input() preview: boolean = false;

    access: any = {
        save: false,
        delete: false
    };

    loaded: boolean = false;
    lookupValues: any = {};
    model: FluidsRequestModel = new FluidsRequestModel();
    types: any = {};
    user: UserModel = new UserModel();
    view: string;

    confirmLoseChanges: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to cancel and lose changes?',
        onConfirm: () => { this.goBack() }
    });

    confirmDelete: ConfirmModel = new ConfirmModel({
        onConfirm: () => { this.onConfirmDelete() }
    });

    confirmSubmit: ConfirmModel = new ConfirmModel({
        onConfirm: () => { this.onConfirmSubmit() }
    });

    constructor(
        public common: Common,
        public route: ActivatedRoute,
        public router: Router,
        public fluidsRequestService: FluidsRequestService,
        public lookupService: LookupService,
        public lookupValueService: LookupValueService,
        public workflowService: WorkflowService
    ) {
        this.route.queryParams.subscribe((params: Params) => {
            this.model.Pk = params['id'];
            this.view = params['view'];
        });

        this.user = this.common.getUser();

        this.access = Object.assign(this.access, {
            delete: this.common.hasAccess('OPS', 'FR', 'Delete'),
            process: this.common.hasAccess('OPS', 'FR', 'Process'),
            update: this.common.hasAccess('OPS', 'FR', 'Update')
        }, this.common.getUserRoleAccess());
    }

    ngOnInit() {
        if (this.id) {
            this.model.Pk = this.id;
        }

        this.common.handleRequests([
            this._getLookupValues(),
            this._getProducts(),
            this._getVendors()
        ]).then(() => {
            this.lookupValues['FLUIDS_REQUEST_ITEM_TYPE'].map((x: LookupValueModel) => {
                this.lookupValues[x.Name + "Products"] = this.lookupValues["Products"].filter((y: PartModel) => y.LocationId.toUpperCase() == 'FR-' + x.Name.toUpperCase());
            });

            if (this.model.Pk) {
                this.common.handleRequests([this._onLoad()]);
            }
            else {
                this.refreshAccess();
            }
        });
    }

    private _getLookupValues() {
        const types = [
            'FLUIDS_REQUEST_ITEM_TYPE',
            'TANK_STAND_HEIGHT'
        ];

        return this.lookupValueService.getByTypes(types).pipe(map(data => {
            types.map(x => {
                if (!this.lookupValues[x]) {
                    this.lookupValues[x] = this.common.sort(data.filter(y => y.Type == x), 'Sort');
                }
            });
        }));
    }

    private _getVendors() {
        return this.lookupService.getLookupList('fluids-request-vendor').pipe(map(data => this.lookupValues["Vendor"] = this.common.sort(data, 'Name')));
    }

    private _getProducts() {
        return this.fluidsRequestService.getProducts().pipe(map(data => this.lookupValues["Products"] = this.common.sort(data, 'Name')));
    }

    private _onLoad(workflowStatus?: string) {
        this.loaded = false;

        return this.fluidsRequestService.get(this.model.Pk)
            .pipe(map(data => {
                this.model = data;

                if (workflowStatus != null) {
                    this.common.showMessage(`Successfully ${workflowStatus.toLowerCase()} ${this.model.Type.Name} Fluids Request.`);
                }

                this.confirmDelete.Content.Text = `Are you sure you want to delete the ${this.model.Type.Name} Fluids Request?`;
                this.confirmSubmit.Content.Text = `Are you sure you want to submit the ${this.model.Type.Name} Fluids Request?`;

                this.refreshAccess();
            }));
    }

    private _onSave() {
        if (this.model.Type.Type.toLowerCase() == 'filltanks'){
            this.model.Items.map(item => {
                const date = item.Types[0].DeliveryDate;

                item.Types.map(x => {
                    x.DeliveryDate = date;
                    x.PurchaseOrderNumber = this.common.getFluidsRequestPurchaseOrder(date, item.AssetId, x.Type.Name);
                });
            });
        }
        else {
            this.model.Items[0].Types = [];

            this.lookupValues['FLUIDS_REQUEST_ITEM_TYPE'].map((x: LookupValueModel) => {
                if (this.model['Need' + x.Name]) {
                    let item = this.types[x.Pk];

                    item.DeliveryDate = this.model.DeliveryDate;
                    item.PurchaseOrderNumber = this.common.getFluidsRequestPurchaseOrder(this.model.DeliveryDate, this.model.AssetId, x.Name);

                    this.model.Items[0].Types.push(item);
                }
            });
        }

        return this.fluidsRequestService.save(this.model)
            .pipe(map(data => {
                this.common.hideLoader();
                this.common.showMessage(`Successfully updated ${this.model.Type.Name} Fluids Request.`);

                this.model = data;

                this.refreshAccess();
            }));
    }

    refreshAccess() {
        this.lookupValues['FLUIDS_REQUEST_ITEM_TYPE'].map((x: LookupValueModel) => {
            let existing = this.model.Items[0].Types.find(y => y.TypePk == x.Pk);

            if (!existing) {
                existing = new FluidsRequestItemTypeModel({
                    Type: x,
                    TypePk: x.Pk
                });
            }

            this.types[x.Pk] = existing;
        });

        const status = this.model.Status.toLowerCase();
        const creator = this.model.CreatedBy == this.user.ApplicationUserPk;

        if (!this.model.Pk) {
            this.access.save = true;
            this.access.delete = false;
            this.access.submit = false;
        }
        else if (creator && status == 'new') {
            this.access.save = true;
            this.access.delete = true;
            this.access.submit = true;
        }
        else {
            this.access.save = false;
            this.access.delete = false;
            this.access.submit = false;
        }

        if (this.preview) {
            this.access.save = false;
        }

        this.loaded = true;
    }

    onLoad(workflowStatus?: string) {
        this.common.handleRequests([this._onLoad(workflowStatus)]);
    }

    onSave() {
        if (!this.hasChanges())
            return;

        // Prevent saving since all tanks were deactivated
        if (!this.model.NeedOil && !this.model.NeedCoolant && !this.model.NeedCompoundOil) {
            this.common.showError(`Unable to save ${this.model.Type.Name} Fluids Request`, 'At least one tank is required before changes can be saved.');
            return;
        }

        this.common.handleRequests([this._onSave()]);
    }

    onBack() {
        if (this.hasChanges()) {
            this.common.showConfirm(this.confirmLoseChanges);
        }
        else {
            this.goBack();
        }
    }

    goBack() {
        const params: any  = {};

        if (this.view) {
            params.queryParams = { view: this.view };
        }

        this.router.navigate(['/operations/fluids-request/list'], params);
    }

    onDelete() {
        this.common.showConfirm(this.confirmDelete);
    }

    onConfirmDelete() {
        this.confirmDelete.disableAll();

        this.fluidsRequestService.delete(this.model.Pk).subscribe( (data) => {
            this.common.hideConfirm();
            this.confirmDelete.enableAll();

            this.common.showMessage(`Successfully deleted ${this.model.Type.Name} Fluids Request.`);
            this.goBack();
        }, error => {
            this.common.showError(`Error deleting ${this.model.Type.Name} Fluids Request`, error);
            this.confirmDelete.enableAll();
        });
    }

    onSubmit() {
        this.common.showConfirm(this.confirmSubmit);
    }

    onConfirmSubmit() {
        this.confirmSubmit.disableAll();

        const submit = () => {
            this.workflowService.createWorkflowModule(this.model.Pk, this.model.WorkflowId)
                .subscribe(
                    data => {
                        this.common.hideConfirm();
                        this.confirmSubmit.enableAll();

                        this.model.Workflows.push(data);
                        this.common.showMessage('Successfully submitted New Tank Fluids Request.');
                        this.goBack();
                    },
                    error => {
                        this.common.showError('Error creating workflow for New Tank Fluids Request', error);
                        this.confirmSubmit.enableAll();
                    }
                );
        }

        if (!this.hasChanges()) {
            submit();
            return;
        }

        this._onSave().subscribe(() => submit());
    }

    onExportExcel() {
        this.common.showLoader();

        this.fluidsRequestService.export(this.model.Pk)
            .subscribe(
                data => {
                    if (data) {
                        this.common.downloadReport(`${this.model.Type.Type}_FluidsRequest_${this.model.Pk}`, data);
                    }
                    else {
                        this.common.showError('Error creating excel file', 'An error occured creating excel file. Please try again. If the problem persists, contact IT.')
                    }
                    this.common.hideLoader();
                },
                error => {
                    this.common.showError('Error exporting to excel', error);
                    this.common.hideLoader();
                }
            );
    }

    hasChanges() {
        let hasChanges: boolean = false;

        switch (this.model.Type.Type.toLowerCase()) {
            case 'newtank':
                hasChanges = this.newTankDetailComponent.hasChanges();
                break;
            case 'movetank':
                hasChanges = this.moveTankDetailComponent.hasChanges();
                break;
            case 'shutdowntank':
                hasChanges = this.shutdownTankDetailComponent.hasChanges();
                break;
            case 'filltanks':
                hasChanges = this.fillTanksDetailComponent.hasChanges();
                break;
            case 'emergencyfill':
                hasChanges = this.emergencyFillDetailComponent.hasChanges();
                break;
            case 'oilonly':
                hasChanges = this.oilOnlyDetailComponent.hasChanges();
                break;
            case 'swapunit':
                hasChanges = this.swapUnitDetailComponent.hasChanges();
                break;
        }

        return hasChanges;
    }
}