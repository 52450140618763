export class FileModel {
    Pk: number = 0;
    ServiceReportPk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    ImageName: string;
    ImageData: string;
    FileName: string;
    FileData: string;
    Description: string;
    File: File;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ServiceReportPk = obj.ServiceReportPk;
        this.CreationDate = obj.CreationDate;
        this.CreatedBy = obj.CreatedBy;
        this.FileName = obj.FileName;
        this.Description = obj.Description == null ? '' : obj.Description;
        this.FileData = obj.FileData;

        if (this.isImage()) {
            this.ImageName = this.FileName;
            this.ImageData = this.FileData;
        }
    }

    // if it's not an image, assume it's a document
    isDocument() {
        return !this.isImage();
    }

    isImage() {
        return (["png", "jpg", "jpeg", "gif", "bmp"].indexOf(this.getExtension().toLowerCase()) >= 0);
    }

    getExtension() {
        return this.FileName ? this.FileName.substring(this.FileName.lastIndexOf('.') + 1) : '';
    }
}
