import { UserActionModel } from './user.action.model';
import { UserDetailModel } from './user.detail.model';

export class UserModel {
    ApplicationUserPk: number;
    UserId: string;
    IsAuthenticated: boolean;
    AuthCode: string;
    ExpireTime: Date;
    AppUser: UserDetailModel = new UserDetailModel();
    UserActions: UserActionModel[];
    OriginalUser: UserModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.ApplicationUserPk = obj.ApplicationUserPk;
        this.UserId = obj.UserId;
        this.IsAuthenticated = obj.IsAuthenticated;
        this.AuthCode = obj.AuthCode;
        this.ExpireTime = obj.ExpireTime;
        this.AppUser = new UserDetailModel(obj.AppUser);
        this.UserActions = obj.UserActions;

        if (obj.OriginalUser != null) {
            this.OriginalUser = new UserModel(obj.OriginalUser);
        }
    }
}
