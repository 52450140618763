import { Component, OnInit } from '@angular/core';

import {
    MessageModel
} from '../../shared/models';

import {
    Common,
    ModalService
} from '../../core/services';

@Component({
    selector: 'app-layout',
    templateUrl: 'simple-layout.component.html',
})
export class SimpleLayoutComponent implements OnInit {
    isLoading: boolean = false;
    messageModal: MessageModel = new MessageModel();

    constructor(
        public common: Common,
        private modalService: ModalService
    ) {
        this.common.getLoading().subscribe(x => this.isLoading = x);
    }

    ngOnInit() {
        this.modalService.message.subscribe((x: MessageModel) => {
            this.messageModal = x;
            this.messageModal && this.common.showModal('message-modal');
        });
    }
}
