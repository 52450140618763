export class PressureValueModel {
    Key: number;
    Pk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    PressureNum: string;
    OperatorReportPk: number;
    Operating: string;
    LowKills: string;
    HighKills: string;
    ShowDelete: boolean;

    constructor(obj: any, key: number) {
        this.Key = key;

        if (obj != null) {
            this.Pk = obj.Pk;
            this.CreationDate = obj.CreationDate;
            this.CreatedBy = obj.CreatedBy;
            this.LastUpdateDate = obj.LastUpdateDate;
            this.LastUpdateBy = obj.LastUpdateBy;
            this.PressureNum = obj.PressureNum;
            this.OperatorReportPk = obj.OperatorReportPk;
            this.LowKills = this.numberToEmpty(obj.LowKills);
            this.HighKills = this.numberToEmpty(obj.HighKills);
            this.Operating = this.numberToEmpty(obj.Operating);
            this.ShowDelete = obj.ShowDelete != undefined ? obj.ShowDelete : false;
        }
    }

    differentToObject(objectToCompare: PressureValueModel) {
        if (this.PressureNum != objectToCompare.PressureNum ||
            this.LowKills != objectToCompare.LowKills ||
            this.HighKills != objectToCompare.HighKills ||
            this.Operating != objectToCompare.Operating) {
            return true;
        }

        return false;
    }

    private numberToEmpty(value: number) {
        return (value > 0 ? value.toString() : '');
    }
}
