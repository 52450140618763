export class ModalFooterModel {
    CloseIcon: string = 'fas fa-ban';
    CloseText: string = 'Cancel';
    ConfirmIcon: string = 'fas fa-check-circle';
    ConfirmText: string = 'Submit';
    DisableClose: boolean = false;
    DisableConfirm: boolean = true;
    IsLoading: boolean = false;
    onConfirm: Function = () => {};
    onClose: Function = () => {};

    constructor(obj?: any) {
        if (!obj) {
            return;
        }

        this.CloseIcon = obj.CloseIcon || this.CloseIcon;
        this.CloseText = obj.CloseText || this.CloseText;
        this.ConfirmIcon = obj.ConfirmIcon || this.ConfirmIcon;
        this.ConfirmText = obj.ConfirmText || this.ConfirmText;
        this.DisableClose = obj.DisableClose != null ? obj.DisableClose : this.DisableClose;
        this.DisableConfirm = obj.DisableConfirm != null ? obj.DisableConfirm : this.DisableConfirm;
        this.onConfirm = obj.onConfirm;
        this.onClose = obj.onClose;
    }

    disableAll(isLoading: boolean = true) {
        this.DisableClose = true;
        this.DisableConfirm = true;
        this.IsLoading = isLoading;
    }

    enableAll(isLoading: boolean = false) {
        this.DisableClose = false;
        this.DisableConfirm = false;
        this.IsLoading = isLoading;
    }

    resetAll() {
        this.IsLoading = false;
        this.DisableClose = false;
        this.DisableConfirm = true;
    }
}
