import { Component, OnInit, OnDestroy, HostBinding, Input } from '@angular/core';
import { SparkCarouselComponent } from './spark-carousel.component';

@Component({
    selector: 'spark-carousel-item',
    templateUrl: 'spark-carousel-item.component.html'
})
export class SparkCarouselItemComponent implements OnInit, OnDestroy {
    @HostBinding('class.active')
    @Input()
    active: boolean = false;

    protected parent: SparkCarouselComponent;

    constructor(parent: SparkCarouselComponent) {
        this.parent = parent;
     }

    ngOnInit() {
        this.parent.addItem(this);
    }

    ngOnDestroy() {
        this.parent.removeItem(this);
    }

}
