export class UserActionModel {
    ApplicationPk: number;
    ApplicationId: string;
    ApplicationDescription: string;

    ApplicationRolePk: number;
    ApplicationRoleId: string;
    ApplicationRoleDescription: string;

    ApplicationActionPk: number;
    ApplicationActionId: string;
    ApplicationActionDescription: string;
    ApplicationActionCreationDate: Date;
    ApplicationActionCreatedBy: number;

    ApplicationModulePk: number;
    ApplicationModuleId: string;
    ApplicationModuleDescription: string;

    ApplicationUserPk: number;
    ApplicationUserId: string;

    ApplicationRoleModuleActionPk: number;

    IsEnabled: boolean;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.ApplicationPk = obj.ApplicationPk;
        this.ApplicationId = obj.ApplicationId;
        this.ApplicationDescription = obj.ApplicationDescription;

        this.ApplicationRolePk = obj.ApplicationRolePk;
        this.ApplicationRoleId = obj.ApplicationRoleId;
        this.ApplicationRoleDescription = obj.ApplicationRoleDescription;

        this.ApplicationActionPk = obj.ApplicationActionPk;
        this.ApplicationActionId = obj.ApplicationActionId;
        this.ApplicationActionDescription = obj.ApplicationActionDescription;
        this.ApplicationActionCreationDate = obj.ApplicationActionCreationDate;
        this.ApplicationActionCreatedBy = obj.ApplicationActionCreatedBy;

        this.ApplicationModulePk = obj.ApplicationModulePk;
        this.ApplicationModuleId = obj.ApplicationModuleId;
        this.ApplicationModuleDescription = obj.ApplicationModuleDescription;

        this.ApplicationUserPk = obj.ApplicationUserPk;
        this.ApplicationUserId = obj.ApplicationUserId;

        this.ApplicationRoleModuleActionPk = obj.ApplicationRoleModuleActionPk;

        this.IsEnabled = obj.IsEnabled;
    }
}
