import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { LookupModel, StateData } from '../../shared/models';

@Injectable()
export class LookupService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/lv`;

    data: any = {};

    constructor(private http: HttpClient) {}

    getLookupList(type: string) {
        const key = 'LookupList';

        if (this.data[key] && this.data[key][type]) {
            return of(this.data[key][type] as LookupModel[]);
        }

        return this.http.get<LookupModel[]>(`${this.url}/get/${type}`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new LookupModel(x));

                    if (!this.data[key]) {
                        this.data[key] = {};
                    }

                    if (!this.data[key][type]) {
                        this.data[key][type] = results;
                    }

                    return results;
                }

                return new Array() as LookupModel[];
            }));
    }

    getDataByState(abbreviation: string, type: StateData) {
        const key = `${abbreviation}${type}`;

        if (this.data[key] && this.data[key][type]) {
            return of(this.data[key][type] as LookupModel[]);
        }

        return this.http.get<LookupModel[]>(this.url + `/GetDataByState?abbreviation=${abbreviation}&dataType=${type}`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new LookupModel(x));

                    if (!this.data[key]) {
                        this.data[key] = {};
                    }

                    if (!this.data[key][type]) {
                        this.data[key][type] = results;
                    }

                    return results;
                }

                return new Array() as LookupModel[];
            }));
    }
}