<workflow-history *ngIf="showWorkflowHistory" [model]="model" (onCancel)="onCloseHistory()"></workflow-history>

<span *ngIf="mode == 'menu'">
    <a class="dropdown-item" (click)="onSubmit(detailType.FinalText)" href="javascript:;" *ngIf="showButtons">
        <i class="fas fa-check-circle"></i> {{detailType.ButtonText | titlecase}}
    </a>

    <a class="dropdown-item" (click)="onSubmit('Denied')" href="javascript:;" *ngIf="showDeny()">
        <i class="fas fa-minus-circle"></i> Deny
    </a>

    <a class="dropdown-item" (click)="onShowHistory()" href="javascript:;" *ngIf="model.Workflows.length && showHistoryButton">
        <i class="fas fa-history"></i> Workflow History
    </a>

    <a class="dropdown-item" (click)="onUndo()" href="javascript:;" *ngIf="showRollbackButton && model.Status.toLowerCase() != 'pending' && model.Workflows.length">
        <i class="fas fa-undo-alt"></i> {{rollbackText}}
    </a>
</span>

<span *ngIf="mode == 'inline'">
    <button class="btn btn-sm btn-success" (click)="onSubmit(detailType.FinalText)" href="javascript:;" *ngIf="showButtons" [tooltip]="detailType.ButtonText">
        <i class="fas fa-check-circle"></i> <span class="d-inline d-md-none ml-2">{{detailType.ButtonText | titlecase}}</span>
    </button>

    <button class="btn btn-sm btn-danger" (click)="onSubmit('Denied')" href="javascript:;" *ngIf="showDeny()" tooltip="Deny">
        <i class="fas fa-minus-circle"></i> <span class="d-inline d-md-none ml-2">Deny</span>
    </button>

    <button class="btn btn-sm btn-info" (click)="onShowHistory()" href="javascript:;" *ngIf="model.Workflows.length && showHistoryButton" tooltip="Workflow History">
        <i class="fas fa-history"></i> <span class="d-inline d-md-none ml-2">Workflow History</span>
    </button>

    <button class="btn btn-sm btn-primary" (click)="onUndo()" href="javascript:;" *ngIf="showRollbackButton && model.Status.toLowerCase() != 'pending' && model.Workflows.length" [tooltip]="rollbackText">
        <i class="fas fa-undo-alt"></i> <span class="d-inline d-md-none ml-2">{{rollbackText}}</span>
    </button>
</span>

<span *ngIf="mode == 'modal'">
    <button class="btn btn-success" (click)="onSubmit(detailType.FinalText)" [disabled]="isLoading" *ngIf="showButtons">
        <i class="fas fa-check-circle mr-1"></i> {{detailType.ButtonText | titlecase}}
    </button>

    <button class="btn btn-danger" (click)="onSubmit('Denied')" [disabled]="isLoading" *ngIf="showDeny()">
        <i class="fas fa-minus-circle mr-1"></i> Deny
    </button>

    <button class="btn btn-secondary" (click)="onConfirmCancel()" [disabled]="isLoading">
        <i class="fas fa-ban mr-1"></i> Close
    </button>

    <span class="ml-2">
        <loader [visible]="isLoading"></loader>
    </span>
</span>

<modal [id]="'workflow-action-modal' + model.Pk" *ngIf="activeWorkflow && detailType">
    <header>Confirm</header>

    <p>Are you sure you want to mark this {{activeWorkflow.Workflow.Name}} as {{action}}?</p>

    <div class="form-row" *ngIf="showForm">
        <div class="form-group col-sm-12">
            <p *ngIf="action == 'denied'">Please include a reason for denial.</p>

            <textarea rows="3" [(ngModel)]="comments" (input)="handleCommentChange()" id="WorkflowComments" class="form-control mt-2" [maxlength]="model.WorkflowId == 'PO' ? 50 : 8000"></textarea>
            <small *ngIf="model.WorkflowId == 'PO'">{{comments.length}} of 50 characters</small>
        </div>
    </div>

    <modal-footer [model]="confirmFooter"></modal-footer>
</modal>
