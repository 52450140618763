import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import * as containers from './containers';

const APP_CONTAINERS = [
    containers.FullLayoutComponent,
    containers.SimpleLayoutComponent
];

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppGlobalService } from './core/services';

@NgModule({
    declarations: [
        AppComponent,
        ...APP_CONTAINERS
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CoreModule,
        SharedModule
    ],
    providers: [
        AppGlobalService,
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
