import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Globals } from '../../shared/global';
import { Common } from './common.service';
import { DollarPerHorsepowerModel } from '../../shared/models';
@Injectable()

export class DollarPerHorsepowerService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.SALES}/api/dollar-per-horsepower`;

    constructor(private common: Common, private http: HttpClient) { }

    get(modelPk: number) {
        return this.http.get<DollarPerHorsepowerModel>(`${this.url}/get/${modelPk}`)
            .pipe(
                map(res => new DollarPerHorsepowerModel(res))
            );
    }

    search() {
        return this.http.get<DollarPerHorsepowerModel[]>(`${this.url}/search`)
            .pipe(map(res => res ? res.map(x => new DollarPerHorsepowerModel(x)) : new Array() as DollarPerHorsepowerModel[]));
    }

    create(model: DollarPerHorsepowerModel) {
        return this.http.post<DollarPerHorsepowerModel>(`${this.url}/create`, JSON.stringify(model))
            .pipe(
                map(res => new DollarPerHorsepowerModel(res))
            );
    }

    update(model: DollarPerHorsepowerModel) {
        return this.http.put<DollarPerHorsepowerModel>(`${this.url}/update`, JSON.stringify(model))
            .pipe(
                map(res => new DollarPerHorsepowerModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete/${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }
}
