export class ModuleHistoryModel {
    FieldName: string;
    Description: string;
    OldValue: string;
    NewValue: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.FieldName = obj.FieldName;
        this.Description = obj.Description;
        this.OldValue = obj.OldValue;
        this.NewValue = obj.NewValue;
    }
}