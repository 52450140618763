<div id="loader" [hidden]="!isLoading">
    <loader visible="true"></loader>
</div>

<modal id="message-of-the-day">
    <header>{{messages.length == 1 ? messages[0].Title : 'Message of the Day'}}</header>

    <ng-container *ngTemplateOutlet="messages.length == 1 ? singleMessage : multipleMessages"></ng-container>

    <ng-template #singleMessage>
        <p>{{messages[0].Body}}</p>
    </ng-template>

    <ng-template #multipleMessages>
        <div *ngFor="let message of messages">
            <h5>{{message.Title}}</h5>
            <p>{{message.Body}}</p>
        </div>
    </ng-template>

    <div class="form-row">
        <div class="form-group col-12">
            <label for="MessageConfirmed">I have read the message of the day:</label>

            <div>
                <label class="switch switch-default switch-success switch-lg">
                    <input [(ngModel)]="messageConfirmed" name="MessageConfirmed" id="MessageConfirmed" class="switch-input" type="checkbox">
                    <span class="switch-label"></span>
                    <span class="switch-handle"></span>
                </label>
            </div>
        </div>
    </div>

    <modal-footer>
        <button class="btn btn-secondary" (click)="onConfirmMessageOfTheDay()" [disabled]="!messageConfirmed">
            <i class="fas fa-check-circle mr-1"></i> Confirm
        </button>
    </modal-footer>
</modal>

<modal id="log-in-as">
    <header>Log In As Username</header>

    <form #logInAsForm="ngForm" (ngSubmit)="onLogInAs()" novalidate>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="Username">Username:</label>
                    <input id="Username" name="Username" type="text" class="form-control" [(ngModel)]="logInAsUsername"
                        [typeahead]="userList" [typeaheadScrollable]="true" typeaheadOptionField="UserId" autocomplete="off"
                        (ngModelChange)="onChange()" required #userNameRef="ngModel">
                    <validation-message [isValid]="userNameRef.invalid && userNameRef.dirty">
                        User not found
                    </validation-message>
                </div>
            </div>
        </div>

        <hr />
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Role:</label>
                    <select class="form-control" [(ngModel)]="selectedRolePk" (ngModelChange)="GetUsersByRole(selectedRolePk)" [ngModelOptions]="{standalone: true}">
                        <option *ngIf="!selectedRolePk"></option>
                        <option *ngFor="let role of roleList" [value]="role.Pk">{{role.Description}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Username:</label>
                    <select [disabled]="selectedRolePk == 0" class="form-control" [(ngModel)]="logInAsUserId" (ngModelChange)="onChange()" [ngModelOptions]="{standalone: true}">
                        <option *ngIf="!logInAsUserId"></option>
                        <option *ngFor="let user of usersInRole" [value]="user.UserId">{{user.UserId}}</option>
                    </select>
                </div>
            </div>
        </div>
    </form>

    <modal-footer [model]="logInFooter"></modal-footer>
</modal>

<modal id="message-modal">
    <header>{{messageModal.Title}}</header>

    <div class="row">
        <div class="col-sm-12">
            <div [innerHTML]="messageModal.Text"></div>
        </div>
    </div>
</modal>

<modal id="confirm-modal">
    <header>{{confirmModel.Content.Title}}</header>

    <div class="row">
        <div class="col-sm-12">
            <div [innerHTML]="confirmModel.Content.Text"></div>
        </div>
    </div>

    <modal-footer [model]="confirmModel.Footer"></modal-footer>
</modal>

<header class="app-header navbar">
    <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
        <span class="navbar-toggler-icon"></span>
    </button>
    <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>
        <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand"></a>

    <!-- <ul class="nav navbar-nav d-md-down-none">
        <li class="nav-item">{{pageTitle}}</li>
    </ul> -->

    <ul class="nav navbar-nav ml-auto">
        <li class="nav-item" *ngIf="user && user.OriginalUser != null">
            <a class="nav-link pointer" href="javascript:;" (click)="onRevertUser()" title="Go back as {{user.OriginalUser.UserId}}">
                <i class="fas fa-user-friends"></i>
            </a>
        </li>
        <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
                <i class="fas fa-user-circle mr-2"></i>
                <span class="d-none d-sm-inline mr-1">{{user.AppUser.FirstName}} {{user.AppUser.LastName}}</span>
                <span class="caret"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="dropdown-header text-center"><strong>Account</strong></div>
                <a class="dropdown-item d-block d-sm-none" href="javascript:;" *ngIf="user && user.OriginalUser != null">
                    <i class="fas fa-user-friends"></i> {{user.AppUser.FirstName}} {{user.AppUser.LastName}}
                </a>
                <a class="dropdown-item" routerLink="/profile">
                    <i class="fa fa-user"></i> Profile
                </a>
                <a class="dropdown-item" href="javascript:;" (click)="onShowForm()" *ngIf="access.logInAs">
                    <i class="fas fa-user-friends"></i> Log In As Username
                </a>
                <a class="dropdown-item" href="javascript:;" (click)="onLogOut()">
                    <i class="fa fa-lock"></i> Log Out
                </a>
                <a class="dropdown-item" routerLink="/help">
                    <i class="fa fa-question-circle"></i> Help
                </a>
            </div>
        </li>
    </ul>
</header>

<div class="app-body">
    <div class="sidebar">
        <nav class="sidebar-nav">
            <ul class="nav">
                <li class="nav-item">
                    <a class="nav-link pointer" routerLinkActive="active" (click)="onDashboardClick()">
                        <i class="icon-speedometer"></i> Dashboard
                    </a>
                </li>
                <!-- <li class="nav-item">
        <a class="nav-link disabled" routerLinkActive="active">
            <i class="fas fa-chart-pie"></i> Sales
        </a>
    </li> -->
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/expenses/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-dollar-sign"></i> Expense Reports
                    </a>
                </li>
                <li class="nav-item" *ngIf="(!access.fieldTech || access.admin) && access.read.labor">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/timesheet">
                        <i class="fas fa-clock"></i> Your Timesheet
                    </a>
                </li>
                <li class="nav-item" *ngIf="(!access.fieldTech || access.admin) && access.read.labor">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/general-field/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-hammer"></i> GFS Reports
                    </a>
                </li>
                <li class="nav-item" *ngIf="(!access.sales || access.admin)">
                    <a class="nav-link" routerLinkActive="active" routerLink="/assets/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-cubes"></i> Assets
                    </a>
                </li>
                <li class="nav-item" *ngIf="(access.create.safetyIncident || user.AppUser.HasDirectReports || access.admin)">
                    <a class="nav-link" routerLinkActive="active" routerLink="/safety/incident/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-hard-hat"></i> Safety Incidents
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://forms.office.com/Pages/ResponsePage.aspx?id=lKdPw1YFNUu9NzZtw33lF4w5f0XC80ZNm4JXz99n1wdUNDMwSlNQMDBaQzBEOU5UWjdXWERMQTA3OC4u"
                       target="_blank">
                        <i class="fas fa-newspaper"></i> BBS
                    </a>
                </li>
                <div *ngIf="access.fieldTech || access.admin">
                <li class="divider"></li>

                <li class="nav-title">
                    Operations
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/timesheet">
                        <i class="fas fa-clock"></i> Your Timesheet
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/service/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-file-alt"></i> Service Reports
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/operator/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-file-alt"></i> Operator Reports
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/general-field/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-hammer"></i> GFS Reports
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/oil-sample/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-tint"></i> Oil Samples
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/part-lookup/list">
                        <i class="fas fa-wrench"></i> Part Lookup
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/runtime/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-running"></i> Runtime Reports
                    </a>
                </li>
                <li class="nav-item" *ngIf="devMode">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/fluids-request/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-oil-can"></i> Fluids Requests
                    </a>
                </li>
                <li class="nav-item" *ngIf="showTasks">
                    <a class="nav-link" routerLinkActive="active" routerLink="/operations/preventative-maintenance/list" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-gavel"></i> Preventative Maintenance
                    </a>
                </li>
</div>

                <div *ngIf="access.process.fluidsRequest && devMode">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Fluids Requests
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/fluids-request/list" [queryParams]="{ view: 'all' }" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-oil-can"></i> Fluids Requests
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/utilities/uploads" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-search-dollar"></i> Reconcile
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/fluids-request/list" [queryParams]="{ view: 'ready' }" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-money-bill"></i> Process
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/fluids-request/list" [queryParams]="{ view: 'unprocessed' }" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-clock"></i> Unprocessed
                        </a>
                    </li>
                </div>

                <div *ngIf="access.sales">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Sales
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/assets/list" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-cubes"></i> Assets
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="access.read.salesOrder">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/sales-order/list" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-receipt"></i> Sales Information Sheets
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="access.read.invoicingInstruction">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/invoicing-instruction/list" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-file-invoice"></i> Invoicing Instructions
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="access.read.salesOrder">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/sales-order/mass/list" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-receipt"></i> Mass SIS Renewals
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="access.read.invoicingInstruction">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/invoicing-instruction/mass/list" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-file-invoice"></i> Mass II Renewals
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="access.read.ppi">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/ppi-maintenance">
                            <i class="fas fas fa-chart-line"></i> PPI Maintenance
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="access.read.dollarPerHorsepower">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/dollar-per-horsepower">
                            <i class="fas fa-money-bill"></i> Dollar per Horsepower
                        </a>
                    </li>
                </div>

                <!-- todo: refactor this -->
                <div *ngIf="access.sales && (access.workflow.sales || access.admin)">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Approvals Required
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/sales-order/list" [queryParams]="{ view: 'pending' }">
                            <i class="fas fa-receipt"></i> Sales Information Sheets ({{counts.SO}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/invoicing-instruction/list" [queryParams]="{ view: 'pending' }">
                            <i class="fas fa-file-invoice"></i> Invoicing Instructions ({{counts.II}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/sales-order/mass/list" [queryParams]="{ view: access.contractAdmin ? 'finalized' : 'pending' }">
                            <i class="fas fa-receipt"></i> Mass SIS Renewals ({{counts.MASS_SO}})
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="access.salesAdmin || access.contractAdmin || access.admin">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/invoicing-instruction/mass/list" [queryParams]="{ view: 'pending' }">
                            <i class="fas fa-file-invoice"></i> Mass II Renewals ({{counts.MASS_II}})
                        </a>
                    </li>
                </div>

                <div *ngIf="access.sales && access.salesAdmin">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Approvals Required
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/sales/sales-order/mass/list" [queryParams]="{ view: 'approved' }">
                            <i class="fas fa-receipt"></i> Mass SIS Renewals ({{counts.MASS_SO}})
                        </a>
                    </li>
                </div>

                <div *ngIf="access.humanResources || access.admin">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Human Resources
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/payroll-export">
                            <i class="far fa-newspaper"></i> Process Payroll
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/vehicle-allowance">
                            <i class="fas fa-truck-pickup"></i> Vehicle Allowance
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/hr-report">
                            <i class="fas fa-file-alt"></i> Reports
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/time-card-period">
                            <i class="fas fa-clock"></i> Payroll Period
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/labor-type">
                            <i class="fas fa-building"></i> Labor Types
                        </a>
                    </li>
                </div>

                <div *ngIf="access.supplyChain || access.create.inventoryCount">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Supply Chain
                    </li>
                    <li class="nav-item" *ngIf="access.create.mcUpload">
                        <a class="nav-link" routerLinkActive="active" routerLink="/supply-chain/mc-upload">
                            <i class="fas fa-file-upload"></i> MC Uploads
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="access.create.inventoryCount">
                        <a class="nav-link" routerLinkActive="active" routerLink="/supply-chain/inventory/count/list" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-calculator"></i> Inventory Count
                        </a>
                    </li>
                </div>

                <div *ngIf="access.create.utilities">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Utilities
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/utilities/uploads">
                            <i class="fas fa-file-upload"></i> Uploads
                        </a>
                    </li>
                </div>

                <div *ngIf="(access.close.inventoryCount || access.admin)">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Approvals Required
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/supply-chain/inventory/count/list" [queryParams]="{ view: 'pending' }">
                            <i class="fas fa-calculator"></i> Inventory Count ({{counts.IC}})
                        </a>
                    </li>
                </div>

                <div *ngIf="(access.opsAdmin || access.admin)">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Approvals Required
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/asset-activity/list" [queryParams]="{ view: 'pending' }">
                            <i class="fas fa-expand-arrows-alt"></i> Asset Activity Reports ({{counts.AAR}})
                        </a>
                    </li>
                </div>

                <div *ngIf="(access.readAll.assetActivityReport || access.create.assetActivityReport || access.admin)">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Operations
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/asset-activity/list" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-expand-arrows-alt"></i> Asset Activity Reports
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="!access.fieldTech && showTasks">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/preventative-maintenance/list" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fas fa-gavel"></i> Preventative Maintenance
                        </a>
                    </li>
                </div>

                <div *ngIf="access.operations && (access.workflow.operations || access.admin)">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Approvals Required
                    </li>
                    <li class="nav-item" *ngIf="access.approve.labor">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/labor/approvals">
                            <i class="fas fa-hammer"></i> Labor ({{counts.GFS}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/service/list" [queryParams]="{ view: 'pending' }">
                            <i class="fas fa-file-alt"></i> Service Reports ({{counts.SR}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/operator/list" [queryParams]="{ view: 'pending' }">
                            <i class="fas fa-file-alt"></i> Operator Reports ({{counts.OR}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/oil-sample/list" [queryParams]="{ view: 'pending' }">
                            <i class="fas fa-tint"></i> Oil Samples ({{counts.OS}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/runtime/list" [queryParams]="{ view: 'pending' }">
                            <i class="fas fa-running"></i> Runtime Reports ({{counts.RR}})
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user.AppUser.HasDirectReports">
                        <a class="nav-link" routerLinkActive="active" routerLink="/expenses/list" [queryParams]="{ view: 'pending_approval' }">
                            <i class="fas fa-dollar-sign"></i> Expense Reports ({{counts.ER}})
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="access.approve.purchaseOrder">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/purchase/list">
                            <i class="fas fa-file-invoice-dollar"></i> Purchase Orders ({{counts.PO}})
                        </a>
                    </li>
                </div>

                <div *ngIf="access.approve.labor && !(access.areaManager || access.regionalManager || access.admin)">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Approvals Required
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/labor/approvals">
                            <i class="fas fa-hammer"></i> Labor ({{counts.GFS}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/expenses/list" [queryParams]="{ view: 'pending_approval' }">
                            <i class="fas fa-dollar-sign"></i> Expense Reports ({{counts.ER}})
                        </a>
                    </li>
                </div>

                <div *ngIf="(access.close.safetyIncident || access.admin || access.approve.safetyIncident)">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Approvals Required
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/safety/incident/list" [queryParams]="{ view: access.approve.safetyIncident ? 'pending' : 'approved' }">
                            <i class="fas fa-hard-hat"></i> Safety Incidents ({{counts.SI}})
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user.AppUser.HasDirectReports">
                        <a class="nav-link" routerLinkActive="active" routerLink="/expenses/list" [queryParams]="{ view: 'pending_approval' }">
                            <i class="fas fa-dollar-sign"></i> Expense Reports ({{counts.ER}})
                        </a>
                    </li>
                </div>

                <div *ngIf="access.admin">
                    <li class="divider"></li>

                    <li class="nav-title">
                        Admin
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/message-of-the-day">
                            <i class="fas fa-envelope"></i> Message of the Day
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/users">
                            <i class="fas fa-users"></i> Users
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/security">
                            <i class="fas fa-lock"></i> Security
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/settings">
                            <i class="fas fa-cog"></i> Settings
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/workflows">
                            <i class="fas fa-hammer"></i> Workflows
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/lookup-value">
                            <i class="fas fa-list-ul"></i> Lookup Values
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/operations/runtime/import">
                            <i class="fas fa-file-alt"></i> Runtime Import Log
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/admin/adsync">
                            <i class="fas fa-sync-alt"></i> Active Directory Sync
                        </a>
                    </li>
                    <li class="divider"></li>
                    <li class="divider"></li>
                </div>
            </ul>
        </nav>
    </div>

    <main class="main">
        <router-outlet></router-outlet>
    </main>
</div>

<!-- <footer class="app-footer">
</footer> -->
