import { ApplicationUserModel } from './application.model';
import { DataflowModel } from './dataflow.model';
import { LookupValueModel } from './lookup-value.model';
import { WorkflowModuleModel } from './workflow.model';

import * as moment from 'moment';

export class FluidsRequestModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    TypePk: number;
    Status: string;
    AssetId: string;
    AreaId: string;
    RegionName: string;
    Latitude: number;
    Longitude: number;
    VendorId: string;
    VendorName: string;
    DeliveryDate: Date;
    NeedOil: boolean = false;
    NeedCoolant: boolean = false;
    NeedCompoundOil: boolean = false;
    UseContainments: boolean;
    IsUrgent: boolean;
    UsePreviousOrder: boolean;
    Directions: string;
    Comments: string;
    TotalAmount: number;
    WorkflowId: string = 'FR_';
    Workflows: WorkflowModuleModel[] = new Array();
    Creator: ApplicationUserModel;
    Type: DataflowModel;
    Items: FluidsRequestItemModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            this.Items = [new FluidsRequestItemModel()];
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.TypePk = obj.TypePk;
        this.Status = obj.Status;
        this.AssetId = obj.AssetId;
        this.AreaId = obj.AreaId;
        this.RegionName = obj.RegionName;
        this.Latitude = obj.Latitude;
        this.Longitude = obj.Longitude;
        this.VendorId = obj.VendorId;
        this.VendorName = obj.VendorName;

        if (obj.DeliveryDate) {
            this.DeliveryDate = moment(obj.DeliveryDate).toDate();
        }

        this.NeedOil = obj.NeedOil;
        this.NeedCoolant = obj.NeedCoolant;
        this.NeedCompoundOil = obj.NeedCompoundOil;
        this.UseContainments = obj.UseContainments;
        this.IsUrgent = obj.IsUrgent;
        this.Directions = obj.Directions;
        this.Comments = obj.Comments;
        this.TotalAmount = obj.TotalAmount;

        if (obj.Creator) {
            this.Creator = new ApplicationUserModel(obj.Creator);
        }

        if (obj.Type) {
            this.Type = new DataflowModel(obj.Type);
            this.WorkflowId += this.Type.Type.toUpperCase();
        }

        if (obj.Items) {
            this.Items = obj.Items.map(x => new FluidsRequestItemModel(x)).sort((a, b) => a.AssetId < b.AssetId ? -1 : 1);
        }

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }
    }
}

export class FluidsRequestItemModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    FluidsRequestPk: number;
    AssetId: string;
    Operator: string;
    FromLocation: string;
    ToLocation: string;
    Horsepower: number;
    EngineMake: string;
    EngineModel: string;
    CompressorMake: string;
    CompressorModel: string;
    Types: FluidsRequestItemTypeModel[] = new Array();
    FluidsRequest: FluidsRequestModel;
    Expanded: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.AssetId = obj.AssetId;
        this.FluidsRequestPk = obj.FluidsRequestPk;
        this.Operator = obj.Operator;
        this.FromLocation = obj.FromLocation;
        this.ToLocation = obj.ToLocation;
        this.Horsepower = obj.Horsepower;
        this.EngineMake = obj.EngineMake;
        this.EngineModel = obj.EngineModel;
        this.CompressorMake = obj.CompressorMake;
        this.CompressorModel = obj.CompressorModel;

        if (obj.FluidsRequest) {
            this.FluidsRequest = new FluidsRequestModel(obj.FluidsRequest);
        }

        if (obj.Types) {
            this.Types = obj.Types.map(x => new FluidsRequestItemTypeModel(x));
        }
    }
}

export class FluidsRequestItemTypeModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    FluidsRequestItemPk: number;
    TypePk: number;
    DeliveryDate: Date;
    ProductId: string;
    ProductName: string;
    StandHeightPk: number;
    Capacity: number;
    Quantity: number;
    CurrentLevel: number;
    PurchaseOrderNumber: string;
    InvoiceDate: Date;
    InvoiceQuantity: number;
    InvoicePrice: number;
    InvoiceAmount: number;
    InvoiceTax: number;
    InvoiceTotal: number;
    InvoiceNumber: string;
    Type: LookupValueModel;
    StandHeight: LookupValueModel;
    FluidsRequestItem: FluidsRequestItemModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.TypePk = obj.TypePk;
        this.FluidsRequestItemPk = obj.FluidsRequestItemPk;
        this.DeliveryDate = moment(obj.DeliveryDate).toDate();
        this.PurchaseOrderNumber = obj.PurchaseOrderNumber;
        this.ProductId = obj.ProductId;
        this.ProductName = obj.ProductName;
        this.StandHeightPk = obj.StandHeightPk;
        this.Capacity = obj.Capacity;
        this.Quantity = obj.Quantity;
        this.CurrentLevel = obj.CurrentLevel;
        this.InvoiceDate = obj.InvoiceDate;
        this.InvoiceQuantity = obj.InvoiceQuantity;
        this.InvoicePrice = obj.InvoicePrice;
        this.InvoiceAmount = obj.InvoiceAmount;
        this.InvoiceTax = obj.InvoiceTax;
        this.InvoiceTotal = obj.InvoiceTotal;
        this.InvoiceNumber = obj.InvoiceNumber;

        if (obj.InvoiceDate) {
            this.InvoiceDate = moment(obj.InvoiceDate).toDate();
        }

        if (obj.Type) {
            this.Type = new LookupValueModel(obj.Type);
        }

        if (obj.StandHeight) {
            this.StandHeight = new LookupValueModel(obj.StandHeight);
        }

        if (obj.FluidsRequestItem) {
            this.FluidsRequestItem = new FluidsRequestItemModel(obj.FluidsRequestItem);
        }
    }
}

export class FluidsRequestReconcileModel {
    PurchaseOrderNumber: string;
    InvoiceDate: Date;
    InvoiceQuantity: number;
    InvoicePrice: number;
    InvoiceAmount: number;
    InvoiceTax: number;
    InvoiceTotal: number;
    InvoiceNumber: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.PurchaseOrderNumber = obj.PurchaseOrderNumber;
        this.InvoiceDate = moment(obj.InvoiceDate).toDate();
        this.InvoiceQuantity = obj.InvoiceQuantity;
        this.InvoicePrice = obj.InvoicePrice;
        this.InvoiceAmount = obj.InvoiceAmount;
        this.InvoiceTax = obj.InvoiceTax;
        this.InvoiceTotal = obj.InvoiceTotal;
        this.InvoiceNumber = obj.InvoiceNumber;
    }
}

export class FluidsRequestProcessModel {
    FluidsRequestPk: number;
    FluidsRequestItemTypePk: number;
    RequestType: string;
    VendorName: string;
    AssetId: string;
    PurchaseOrderNumber: string;
    InvoiceDate: Date;
    InvoiceQuantity: number;
    InvoicePrice: number;
    InvoiceAmount: number;
    InvoiceTax: number;
    InvoiceTotal: number;
    InvoiceNumber: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.FluidsRequestPk = obj.FluidsRequestPk;
        this.FluidsRequestItemTypePk = obj.FluidsRequestItemTypePk;
        this.RequestType = obj.RequestType;
        this.VendorName = obj.VendorName;
        this.AssetId = obj.AssetId;
        this.PurchaseOrderNumber = obj.PurchaseOrderNumber;
        this.InvoiceDate = moment(obj.InvoiceDate).toDate();
        this.InvoiceQuantity = obj.InvoiceQuantity;
        this.InvoicePrice = obj.InvoicePrice;
        this.InvoiceAmount = obj.InvoiceAmount;
        this.InvoiceTax = obj.InvoiceTax;
        this.InvoiceTotal = obj.InvoiceTotal;
        this.InvoiceNumber = obj.InvoiceNumber;
    }
}