import * as moment from 'moment';

export class SparkMessageModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Title: string;
    Body: string;
    ActiveDate: Date;
    ExpirationDate: Date;
    Views: SparkMessageViewModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.Title = obj.Title;
        this.Body = obj.Body;
        this.ActiveDate = moment(obj.ActiveDate).toDate();
        this.ExpirationDate = moment(obj.ExpirationDate).toDate();

        if (obj.Views) {
            this.Views = obj.Views.map(x => new SparkMessageViewModel(x));
        }
    }
}

export class SparkMessageViewModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    SparkMessagePk: number;
    HasConfirmed: boolean;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.SparkMessagePk = obj.SparkMessagePk;
        this.HasConfirmed = obj.HasConfirmed;
    }
}