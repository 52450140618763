export class SessionModel {
    SearchText: string = '';
    SearchView: string = 'new';
    CurrentIndex: number = 1;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.SearchText = obj.SearchText;
        this.SearchView = obj.SearchView || 'new';
        this.CurrentIndex = obj.CurrentIndex <= 0 ? 1 : obj.CurrentIndex;
    }
}
