import { Component, Input, AfterViewInit } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ConfirmModel,
    LookupModel,
    SparkFileModel,
    SparkModuleTypeModel
} from '../../models';

import {
    Common,
    LookupService,
    SparkFileService
} from '../../../core/services';

@Component({
    selector: 'spark-file-list',
    templateUrl: 'spark-file-list.component.html'
})
export class SparkFileListComponent implements AfterViewInit {
    @Input() fieldId: string = 'Files';
    @Input() model: any;
    @Input() readOnly: boolean = false;
    @Input() sparkModuleType: SparkModuleTypeModel = new SparkModuleTypeModel();
    @Input() showDocumentType: boolean = false;
    @Input() defaultDocumentType: string = '';

    deleting: any = {};
    documentTypes: LookupModel[] = [];
    selected: File;
    selectedDocumentType: string = '';
    uploadProgress: any = {};
    uploadSubscription: Subscription = new Subscription();
    uploading: boolean = false;

    confirmDeleteFile: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to delete the file?'
    });

    constructor(
        private common: Common,
        private sparkFileService: SparkFileService,
        private lookupService: LookupService
    ) {
        this.common.handleRequests([
            this._getDocumentTypes()
        ]);
    }
    ngAfterViewInit() {
        if (this.defaultDocumentType != '') {
            this.selectedDocumentType = this.defaultDocumentType;
        }
    }
    _getDocumentTypes() {
        return this.lookupService.getLookupList('document-type').pipe(map(data => this.documentTypes = this.common.sort(data, 'Name')));
    }

    onAttached(event: any) {
        if (event.target.files.length == 0) {
            this.selected = null;
            return;
        }
        var file = event.target.files[0] as File;
        const limit = 2090000000;
        const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
        var isImage = ["png", "jpg", "jpeg", "gif", "bmp"].indexOf(fileExtension) >= 0;
        var filename = file.name;
        if (file.size > limit) {
            const fileSize = (file.size / 1024) / 1024;
            this.selected = null;
            this.common.showError('File Too Large', `The attached file <strong>${file.name}</strong> is too large at <strong>${fileSize.toFixed(2)} MB</strong>.
                The file size limit is <strong>${(limit / 1024 / 1024)} MB</strong>.`);
            return;
        }
        else {
            if (isImage) {
                var imageType = file.type;
                var reader = new FileReader();
                reader.onload = (file: any) => {
                    this.common.resizeImage(file.target.result, fileExtension).then(result => {
                        if (result.indexOf("base64") === -1) {
                            result = "data:" + imageType + ";base64," + result;
                            var ab = this.common.base64ToBlobParts(result);
                            let resizedFile = this.common.arrayBufferToFile(ab, imageType, filename);
                            this.selected = resizedFile;
                            return;
                        }
                    });
                }
                reader.readAsDataURL(file);
            }
        }

        this.selected = file;
    }

    onAdd() {
        if (!this.model[this.fieldId]) {
            this.model[this.fieldId] = [];
        }

        // Temporary model to display until the file is uploaded
        // to the server and the proper model is returned
        const file = new SparkFileModel();
        file.FileName = this.selected.name;
        file.Description = this.selected.name;
        file.DocumentTypeName = this.selectedDocumentType;
        this.model[this.fieldId].push(file);

        var index = this.model[this.fieldId].length - 1;
        this.uploading = true;

        this.uploadSubscription = this.sparkFileService.create(this.selected, this.sparkModuleType.Pk, this.model.Pk, this.selectedDocumentType)
            .pipe()
            .subscribe(
                data => {
                    if (data.type == HttpEventType.UploadProgress) {
                        this.uploadProgress[index] = Math.round(100 * data.loaded / data.total);
                    }

                    if (data.type == HttpEventType.Response) {
                        this.uploading = false;

                        // this needs to change if we support attaching multiple files at once
                        this.model[this.fieldId][index] = new SparkFileModel(data.body[0]);

                        // this.uploadProgress[field.Id + index] = 0;
                        this.selected = null;
                        this.selectedDocumentType = this.defaultDocumentType;

                        const e = document.getElementById('SparkFileInput') as HTMLInputElement;

                        if (e) {
                            e.value = '';
                        }
                    }
                },
                error => {
                    this.uploadProgress[index] = 0;
                    this.uploading = false;

                    this.model[this.fieldId].slice(index, 1);

                    this.common.showError('Error Uploading File', error);
                }
            );

    }

    onClear() {
        this.selected = null;
        this.selectedDocumentType = this.defaultDocumentType;
    }

    showActions(index: number) {
        return !this.deleting[index] && this.model[this.fieldId][index].Pk;
    }

    onCancelUpload(file: SparkFileModel, index: number) {
        (document.getElementById('SparkFileInput') as HTMLInputElement).value = '';

        this.uploadSubscription.unsubscribe();

        this.model[this.fieldId].splice(index, 1);

        this.uploading = false;
        this.uploadProgress[index] = 0;
        this.selected = null;

        this.common.showMessage(`Successfully cancelled upload.`);
    }

    onDelete(file: SparkFileModel, index: number) {
        if (file.Pk) {
            const file = this.model[this.fieldId][index] as SparkFileModel;
            this.confirmDeleteFile.Content.Text = `Are you sure you want to delete the file <strong>${file.Description}</strong>?`;
            this.confirmDeleteFile.Footer.onConfirm = () => this.onConfirmDelete(file, index);
            this.common.showConfirm(this.confirmDeleteFile);
        }
        else {
            this.onConfirmDelete(file, index);
        }
    }

    onConfirmDelete(file: SparkFileModel, index: number) {
        this.confirmDeleteFile.disableAll();

        this.deleting[index] = 1;

        this.sparkFileService.delete(file.Pk, file.FileName)
            .subscribe(
                () => {
                    this.common.hideConfirm();
                    this.confirmDeleteFile.enableAll();

                    delete this.deleting[index];
                    this.model[this.fieldId].splice(index, 1);

                    this.common.showMessage(`Successfully deleted file.`);
                },
                error => {
                    this.common.showError('Error deleting file', error);
                    this.confirmDeleteFile.enableAll();
                }
            );
    }

    getDocumentType(file: SparkFileModel) {
        const documentType = this.documentTypes.find(x => x.Name == file.DocumentTypeName);

        return documentType ? `${documentType.Name} - ${documentType.Description}` : '';
    }
}
