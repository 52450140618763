import { Directive, ElementRef, EventEmitter, Injectable, Output } from '@angular/core';

import { ModalComponent } from '../../shared/components/modal/modal.component';

import { ConfirmModel, MessageModel } from '../../shared/models';

@Directive()
@Injectable()
export class ModalService {
    @Output() confirm: EventEmitter<any> = new EventEmitter();
    @Output() message: EventEmitter<any> = new EventEmitter();

    private modals: any[] = [];
 
    add(modal: any) {
        // increase z-index so that secondary modals appear on top of previous ones
        const children = (modal.el as ElementRef).nativeElement.children as HTMLCollection;
        const body = children[0] as HTMLElement;
        const background = children[1] as HTMLElement;
        const zIndex = parseInt(getComputedStyle(body).zIndex, 0);

        body.style.zIndex = (zIndex + this.modals.length + 1).toString();
        background.style.zIndex = (zIndex + this.modals.length).toString();

        this.modals.push(modal);
    }
 
    remove(id: string) {
        this.modals = this.modals.filter(x => x.id !== id);
    }
 
    show(id: string) {
        let modal: ModalComponent = this.modals.find(x => x.id === id);
        modal && modal.show();
    }
 
    hide(id: string) {
        let modal: ModalComponent = this.modals.find(x => x.id === id);
        modal && modal.hide();
    }

    setMessage(title: string, text: string) {
        this.message.emit(new MessageModel({
            Title: title,
            Text: text
        }));
    }

    setConfirm(confirm: ConfirmModel) {
        this.confirm.emit(confirm);
    }
}
