export class ProcedureLibraryModel {
    Pk: number;
    ProcedureId: string;
    ProcedureName: string;
    TargetHours: number;
    Classifications: ClassificationModel[] = [];
    Tasks: ProcedureTaskModel[] = [];

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ProcedureId = obj.ProcedureId;
        this.ProcedureName = obj.ProcedureName;
        this.TargetHours = obj.TargetHours;

        if (obj.Classifications) {
            this.Classifications = obj.Classifications.map(x => new ClassificationModel(x));
        }
        if (obj.Tasks) {
            this.Tasks = obj.Tasks.map(x => new ProcedureTaskModel(x));
        }
    }
}

export class ProcedureTaskModel {
    Pk: number;
    ProcedurePk: number;
    TaskNo: number;
    TaskAction: string;
    Header: boolean;
    HasValue: boolean;
    ValueType: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ProcedurePk = obj.ProcedurePk;
        this.TaskNo = obj.TaskNo;
        this.TaskAction = obj.TaskAction;
        this.Header = obj.Header;
        this.HasValue = obj.HasValue;
        this.ValueType = obj.ValueType;
    }
}

export class ClassificationModel {
    Pk: number;
    ClassificationId: string;
    ClassificationName: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ClassificationId = obj.ClassificationId;
        this.ClassificationName = obj.ClassificationName;
    }
}
