<modal id="workflow-history" class="modal-lg" *ngIf="model.Workflows.length" [showHeaderCloseButton]="true" (onClose)="onModalClose()">
    <header>Workflow History for {{model.Workflows[(model.Workflows.length - 1)].Workflow.Name}} #{{model.Pk}}</header>

    <div *ngFor="let workflow of model.Workflows" class="card">
        <div class="card-header">
            <strong>Submitted on {{workflow.CreationDate | date:'MM/dd/yyyy hh:mm a'}}</strong>
        </div>

        <div class="card-body">
            <div *ngIf="!workflow.Details.length">
                This workflow has no steps.
            </div>

            <div class="table-responsive" *ngIf="workflow.Details.length">
                <table class="table table-striped stack">
                    <thead>
                        <tr>
                            <th>Step</th>
                            <th>Recipient</th>
                            <th>Users Notified</th>
                            <th>Comments</th>
                            <th>Status</th>
                            <!-- <th>Created By</th>
                            <th>Created On</th> -->
                            <th>Updated By</th>
                            <th>Updated On</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let detail of workflow.Details">
                            <td><span class="d-inline d-md-none">Step:</span> {{detail.WorkflowDetail.Type.PendingText}}</td>
                            <td><span class="d-inline d-md-none">Recipient:</span> {{getWorkflowDetailType(detail.WorkflowDetail)}}</td>
                            <td><span class="d-inline d-md-none">Users Notified:</span> {{sortUsers(detail.UsersNotified)}}</td>
                            <td><span class="d-inline d-md-none">Comments:</span> {{detail.Comments}}</td>
                            <td>
                                <span class="d-inline d-md-none">Status:</span> {{detail.Status | titlecase}} <span *ngIf="detail.getStatus() == 'pending'">{{detail.WorkflowDetail.Type.PendingText}}</span>
                            </td>
                            <!-- <td>
                                <span class="d-inline d-md-none">Created By:</span> {{detail.CreatedById}}
                            </td>
                            <td>
                                <span class="d-inline d-md-none">Created On:</span>
                                <div *ngIf="detail.Pk">{{detail.CreationDate | date:'MM/dd/yyyy'}}</div>
                                <div *ngIf="detail.Pk">{{detail.CreationDate | date:'hh:mm a'}}</div>
                            </td> -->
                            <td>
                                <span class="d-inline d-md-none">Updated By:</span> <span *ngIf="detail.getStatus() != 'pending'">{{detail.Updater?.UserId}}</span>
                            </td>
                            <td>
                                <span class="d-inline d-md-none mr-1">Updated On:</span>
                                <div *ngIf="detail.getStatus() != 'pending'">
                                    <div class="d-inline d-md-block" *ngIf="detail.Pk">{{detail.LastUpdateDate | date:'MM/dd/yyyy'}}</div>
                                    <div class="d-inline d-md-block" *ngIf="detail.Pk">
                                        <span class="d-inline d-md-none ml-1"></span>{{detail.LastUpdateDate | date:'hh:mm a'}}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <modal-footer>
        <button class="btn btn-secondary" (click)="onModalClose()">
            <i class="fas fa-times-circle mr-1"></i> Close
        </button>
    </modal-footer>
</modal>