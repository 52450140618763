import * as moment from 'moment';

export class PreventativeMaintenanceModel {
    Division: string;
    DivisionName: string;
    Region: string;
    RegionName: string;
    Area: string;
    AreaName: string;
    AssetId: string;
    AssetName: string;
    Customer: string;
    Location: string;
    Operator: string;
    Horsepower: number;
    Latitude: number;
    Longitude: number;
    CurrentHours: number;
    PreventativeMaintenanceId: string;
    PreventativeMaintenanceName: string;
    IsPMActive: boolean;
    PMFrequency: string;
    PMInterval: number;
    ProcedurePk: number;
    ProcedureID: string;
    ProcedureName: string;
    LastPMWOPK: number;
    LastPMDate: Date;
    LastPMHours: string;
    ElapsedM1Reading: number;
    NextPMDate: Date;
    NextPMHours: number;
    IsPastDue: boolean;
    IsUpcoming: boolean;
    HoursUntilNextPMDate: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.CurrentHours = obj.CurrentHours;
        this.Customer = obj.Customer || '';
        this.ElapsedM1Reading = obj.ElapsedM1Reading;
        this.Horsepower = obj.Horsepower;
        this.IsPMActive = obj.IsPMActive;
        this.LastPMHours = obj.LastPMHours;
        this.LastPMWOPK = obj.LastPMWOPK;
        this.Latitude = obj.Latitude;
        this.Longitude = obj.Longitude;
        this.Location = obj.Location || '';
        this.Operator = obj.Operator;
        this.PMFrequency = obj.PMFrequency;
        this.PMInterval = obj.PMInterval;
        this.PreventativeMaintenanceId = obj.PreventativeMaintenanceId;
        this.PreventativeMaintenanceName = obj.PreventativeMaintenanceName;
        this.ProcedureID = obj.ProcedureID;
        this.ProcedureName = obj.ProcedureName;
        this.ProcedurePk = obj.ProcedurePk;
        this.Area = obj.Area;
        this.AreaName = obj.AreaName;
        this.Region = obj.Region;
        this.RegionName = obj.RegionName;
        this.Division = obj.Division;
        this.DivisionName = obj.DivisionName;
        this.NextPMHours = obj.NextPMHours;

        if (obj.LastPMDate) {
            this.LastPMDate = moment(obj.LastPMDate).toDate();
        }

        if (obj.NextPMDate) {
            this.NextPMDate = moment(obj.NextPMDate).startOf('day').toDate();

            var hoursDiff = moment.duration(moment(this.NextPMDate).diff(moment())).asHours();
            this.IsPastDue = hoursDiff <= -24;
            this.IsUpcoming = hoursDiff > -24 && hoursDiff <= 240;
            this.HoursUntilNextPMDate = Math.floor(hoursDiff);
        }
    }
}