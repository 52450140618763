import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    SearchCriteriaModel,
    SearchResultModel,
    SparkMessageModel,
    SparkMessageViewModel
} from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class SparkMessageService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/spark-message`;

    constructor(private common: Common, private http: HttpClient) {}

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<SparkMessageModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new SparkMessageModel(x));
                }
                return res;
            }));
    }

    get(modelPk: number)  {
        return this.http.get<SparkMessageModel>(`${this.url}/get?modelPk=${modelPk}`)
            .pipe(
                map(res => new SparkMessageModel(res))
            );
    }

    save(model: SparkMessageModel) {
        const newModel: SparkMessageModel = this.common.formatDateFields(model, 'ActiveDate', 'ExpirationDate');

        if (model.Pk) {
            return this.http.put<SparkMessageModel>(`${this.url}/update`, newModel)
                .pipe(
                    map(res => new SparkMessageModel(res))
                );
        }

        return this.http.post<SparkMessageModel>(`${this.url}/create`, newModel)
            .pipe(
                map(res => new SparkMessageModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    getActive() {
        return this.http.get<SparkMessageModel[]>(`${this.url}/get-active`)
            .pipe(
                map(res => res ? res.map(x => new SparkMessageModel(x)) : new Array() as SparkMessageModel[])
            );
    }

    confirm(model: SparkMessageViewModel) {
        return this.http.post<SparkMessageViewModel>(`${this.url}/confirm`, model)
            .pipe(
                map(res => new SparkMessageViewModel(res))
            );
    }
}