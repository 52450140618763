import { Component, Input, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import {
    FluidsRequestItemTypeModel,
    FluidsRequestModel,
    PartModel
} from "src/app/shared/models";
import {
    Common
} from "src/app/core/services";

@Component({
    selector: 'fluids-request-oil-only-detail',
    templateUrl: './fluids-request-oil-only-detail.component.html'
})
export class FluidsRequestOilOnlyDetailComponent {
    @Input() model: FluidsRequestModel = new FluidsRequestModel();
    @Input() lookupValues: any = {};
    @Input() types: any = {};
    @Input() access: any = {};

    modelOriginal: FluidsRequestModel = new FluidsRequestModel();
    view: string;

    constructor(
        public common: Common,
        private route: ActivatedRoute
    ) {
        this.common.setTitle('Operations | Oil Only Fluids Request Details');

        this.route.queryParams.subscribe((params: Params) => {
            this.model.Pk = params['id'];
            this.view = params['view'];
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.modelOriginal = new FluidsRequestModel(this.model);
    }

    hasChanges() {
        const excludedFields = ['Creator', 'Items', 'Types', 'Type', 'StandHeight'];

        const hasChanges = this.common.getChanges(this.model, this.modelOriginal, excludedFields).length > 0 ||
            this.common.getChanges(this.model.Items[0].Types[0], this.modelOriginal.Items[0].Types[0], excludedFields).length > 0;

        return hasChanges;
    }

    onVendorUpdate() {
        const vendor = this.lookupValues['Vendor'].find(x => x.Id == this.model.VendorId);

        this.model.VendorName = vendor.Name;
    }

    onProductUpdate(item: FluidsRequestItemTypeModel) {
        const product = this.lookupValues['OilProducts'].find(x => x.PartId == item.ProductId);

        item.ProductName = product.PartName;
    }

    getUnitOfMeasurement(productId: string) {
        const product = this.lookupValues["Products"].find((x: PartModel) => x.PartId == productId);
        let result = '';

        if (product) {
            result = product.IssueUnits.toLowerCase();

            if (result[result.length - 1] != 's') {
                result += 's';
            }
        }

        return result;
    }
}