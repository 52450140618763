import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import {
    ConfirmModel,
    MessageModel,
    ModalFooterModel,
    UserModel,
    ApplicationUserModel,
    ApplicationRoleModel,
    SparkMessageModel,
    SparkMessageViewModel
} from '../../shared/models';

import {
    AppGlobalService,
    Common,
    ModalService,
    SparkMessageService,
    SparkModuleTypeService,
    UserService,
    SecurityService
} from '../../core/services';

import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-layout',
    templateUrl: 'full-layout.component.html',
    providers: []
})
export class FullLayoutComponent implements OnInit {
    @ViewChild('logInAsForm', { static: true }) logInAsForm: NgForm;

    access: any = {};
    counts: any = {};
    devMode: boolean = !environment.production;
    showTasks: boolean = false;
    isLoading: boolean = true;
    logInAsUsername: string;

    confirm: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to log out?',
        onConfirm: () => { this.onConfirmLogOut() }
    });

    logInFooter: ModalFooterModel = new ModalFooterModel({
        ConfirmText: 'Log In As',
        DisableConfirm: true,
        onConfirm: () => { this.onLogInAs() },
        onClose: () => { this.common.hideModal('log-in-as') }
    });

    confirmModel: ConfirmModel = new ConfirmModel();
    messageModal: MessageModel = new MessageModel();
    pageTitle: string = '';
    user: UserModel = new UserModel();
    userList: ApplicationUserModel[] = new Array();
    roleList: ApplicationRoleModel[] = new Array();
    selectedRolePk: number;
    usersInRole: ApplicationUserModel[] = new Array();
    logInAsUserId: string;
    messageConfirmed: boolean = false;
    messages: SparkMessageModel[] = new Array();

    constructor(
        public common: Common,
        private authService: MsalService,
        private global: AppGlobalService,
        private modalService: ModalService,
        private router: Router,
        private sparkMessageService: SparkMessageService,
        private sparkModuleTypeService: SparkModuleTypeService,
        private userService: UserService,
        private securityService: SecurityService
    ) {
        this.common.getLoading().subscribe(x => this.isLoading = x);

        this.common.changeEmitted$.subscribe(
            (text) => {
                if (text == "refresh_count") {
                    this.refresh();
                }
                if(text == "new_user"){
                    this._getUserApprovalCounts();
                }
            }
        );

        this.user = this.common.getUser();

        if (this.user == null) {
            this.user = new UserModel();
        }

        this.refresh();
    }

    ngOnInit() {
        this.common.getTitle().subscribe(x => this.pageTitle = x);

        this.modalService.message.subscribe((x: MessageModel) => {
            this.messageModal = x;
            this.messageModal && this.common.showModal('message-modal');
        });

        this.modalService.confirm.subscribe((x: ConfirmModel) => {
            const onClose = this.confirmModel.Footer.onClose;

            this.confirmModel = x;
            this.confirmModel.Footer.onClose = () => {
                onClose && onClose();
                this.common.hideModal('confirm-modal');
            };

            this.confirmModel && this.common.showModal('confirm-modal');
        });

        this.securityService.getApplicationSettings().subscribe(() => this.refresh());
        this.sparkMessageService.getActive().subscribe(
            data => {
                data.map(x => {
                    const confirmed = x.Views.find(y => y.CreatedBy == this.user.AppUser.ApplicationUserPk);

                    if (!confirmed) {
                        this.messages.push(x);
                    }
                });

                if (this.messages.length) {
                    this.common.showModal('message-of-the-day');
                }
            });
    }

    private _getModuleTypes() {
        return this.sparkModuleTypeService.search();
    }

    private _getUserApprovalCounts() {
        return this.userService.userCounts$.pipe(map(x =>this.counts = x));
    }

    private _getUsers() {
        return this.userService.getAllUsers().pipe(map(data => this.userList = data));
    }

    private _getRoles() {
        return this.securityService.getApplicationRoles(2).pipe(map(data => {
            this.roleList = this.common.sort(data, 'Description');
        }));
    }

    GetUsersByRole(rolePk: number) {
        this.usersInRole = new Array();
        return this.userService.getUsersByRole(rolePk).subscribe(data => {
            this.usersInRole = this.common.sort(data, 'UserId');
        });
    }

    onRevertUser() {
        this.global.userObject = this.common.getSparkStorage('LoggedInAs').userObject;

        this.common.removeSparkStorage('LoggedInAs');

        this.user = new UserModel(this.user.OriginalUser);
        this.global.sparkUser = this.user;
        this.router.navigate(['/dashboard'], { queryParams: { 'userChange': new Date().getTime() } });

        this.refresh();
    }

    onLogInAs() {
        this.logInFooter.disableAll();

        if (this.logInAsUsername || this.logInAsUserId) {
            let userNameToLoginAs = this.logInAsUserId ? this.logInAsUserId : this.logInAsUsername;

            this.userService.getUserToLogin(userNameToLoginAs)
                .subscribe(
                    data => {
                        if (data && data.ApplicationUserPk) {
                            this.user = data;
                            this.global.sparkUser = data;

                            this.common.hideModal('log-in-as');

                            this.refresh();
                            this.router.navigate(['/dashboard'], { queryParams: { 'userChange': new Date().getTime() } });
                        }
                        else {
                            this.logInAsForm.controls['Username'].setErrors({ 'invalid': true });
                        }

                        this.logInFooter.enableAll();
                    },
                    error => {
                        this.logInAsForm.controls['Username'].setErrors({ 'invalid': true });
                        this.logInFooter.enableAll();
                    });
        }
    }

    onShowForm() {
        this.logInAsForm.resetForm();
        this.logInAsUsername = '';
        this.logInAsUserId = '';
        this.selectedRolePk = 0;
        this.usersInRole = new Array();
        this.common.showModal('log-in-as');

        setTimeout(() => {
            document.getElementById('Username').focus();
        });
    }

    onChange() {
        this.logInFooter.DisableConfirm = (this.logInAsUsername || this.logInAsUserId || '').length == 0;
    }

    onLogOut() {
        this.common.showConfirm(this.confirm);
    }

    onConfirmLogOut() {
        this.common.showLoader();
        this.common.removeUserStorage();
        this.router.navigate(['/']);
        this.authService.logout();
    }

    getUsersApprovalCountsOnDashboardClick(){
        return this.userService.getUsersApprovalCounts(this.user.ApplicationUserPk).pipe(map(x => this.counts = x));
    }

    onDashboardClick(){
        this.common.handleRequests([this.getUsersApprovalCountsOnDashboardClick()]);
        this.router.navigate(['/dashboard']);
    }

    refresh() {
        this.counts = {};

        this.access = this.common.getUserRoleAccess();
        this.access.logInAs = this.common.hasAccess('USR', 'USR', 'LoginAs');
        this.access.readGfs = this.common.hasAccess('OPS', 'GFS', 'Create');
        this.access.approveGfs = this.common.hasAccess('OPS', 'GFS', 'Approve');

        // possible refactoring access building
        // ReadAll
        this.access.readAll = {
            assetActivityReport: this.common.hasAccess('OPS', 'AAR', 'ReadAll')
        };

        // Read
        this.access.read = {
            invoicingInstruction: this.common.hasAccess('OPS', 'II', 'Read'),
            labor: this.common.hasAccess('OPS', 'GFS', 'Create'),
            oilSample: this.common.hasAccess('OPS', 'OS', 'Read'),
            operatorReport: this.common.hasAccess('OPS', 'OR', 'Read'),
            runtimeReport: this.common.hasAccess('OPS', 'RR', 'Read'),
            salesOrder: this.common.hasAccess('OPS', 'SO', 'Read'),
            serviceReport: this.common.hasAccess('OPS', 'SR', 'Read'),
            dollarPerHorsepower: this.common.hasAccess('OPS', 'DPHP', 'Read'),
            ppi: this.common.hasAccess('OPS', 'PPI', 'Read')
        };

        // Create
        this.access.create = {
            assetActivityReport: this.common.hasAccess('OPS', 'AAR', 'Create'),
            mcUpload: this.common.hasAccess('OPS', 'MCU', 'Create'),
            inventoryCount: this.common.hasAccess('OPS', 'IC', 'Create'),
            safetyIncident: this.common.hasAccess('OPS', 'SI', 'Create'),
            utilities: this.common.hasAccess('OPS', 'UTILITIES', 'Create'),
        };

        // Workflows
        // Approve
        this.access.approve = {
            invoicingInstruction: this.common.hasAccess('OPS', 'II', 'Approve'),
            labor: this.common.hasAccess('OPS', 'GFS', 'Approve'),
            oilSample: this.common.hasAccess('OPS', 'OS', 'Approve'),
            operatorReport: this.common.hasAccess('OPS', 'OR', 'Approve'),
            purchaseOrder: this.common.hasAccess('OPS', 'PO', 'Approve'),
            runtimeReport: this.common.hasAccess('OPS', 'RR', 'Approve'),
            safetyIncident: this.common.hasAccess('OPS', 'SI', 'Approve'),
            salesOrder: this.common.hasAccess('OPS', 'SO', 'Approve'),
            serviceReport: this.common.hasAccess('OPS', 'SR', 'Approve')
        };

        // Review
        this.access.review = {
            assetActivityReport: this.common.hasAccess('OPS', 'AAR', 'Review'),
            salesOrder: this.common.hasAccess('OPS', 'SO', 'Review')
        };

        // Complete
        this.access.complete = {
            salesOrder: this.common.hasAccess('OPS', 'SO', 'Complete'),
            invoicingInstruction: this.common.hasAccess('OPS', 'II', 'Complete')
        };

        // Process
        this.access.process = {
            fluidsRequest: this.common.hasAccess('OPS', 'FR', 'Process')
        };

        // Finalize
        this.access.finalize = {
        };

        // Close
        this.access.close = {
            safetyIncident: this.common.hasAccess('OPS', 'SI', 'Close'),
            inventoryCount: this.common.hasAccess('OPS', 'IC', 'Close')
        };

        // Grouping module access
        this.access.operations = (this.access.read.oilSample || this.access.read.operatorReport || this.access.read.runtimeReport || this.access.read.serviceReport);
        this.access.sales = (this.access.read.salesOrder || this.access.read.invoicingInstruction);
        this.access.supplyChain = (this.access.create.mcUpload || this.access.create.inventoryCount);

        this.access.approve.sales = (this.access.approve.salesOrder || this.access.approve.invoicingInstruction);
        this.access.review.sales = (this.access.review.salesOrder || this.access.review.invoicingInstruction);
        this.access.complete.sales = (this.access.complete.salesOrder || this.access.complete.invoicingInstruction);
        this.access.workflow = {
            operations: (this.access.approve.oilSample || this.access.approve.operatorReport || this.access.approve.runtimeReport || this.access.approve.serviceReport),
            sales: (this.access.approve.sales || this.access.review.sales || this.access.complete.sales),
            safetyIncident: (this.access.close.safetyIncident || this.access.approve.safetyIncident)
        };

        this.access.showApprovalLinks = (this.access.workflow.operations || this.access.workflow.sales || this.access.workflow.safetyIncident);

        if (this.access.logInAs) {
            this.common.handleRequests([this._getUsers(), this._getRoles()]);
        }

        this.common.emitChange("new_user");
        this.common.handleRequests([this._getModuleTypes(), this._getUserApprovalCounts()]);

        //// todo: tweak this to actually check permissions
        //const getApprovalCounts = this.access.areaManager || this.access.regionalManager || this.access.opsAdmin ||
        //                          this.access.contractAdmin || this.access.salesAdmin || this.access.salesManager ||
        //                          this.access.admin;

        //if (getApprovalCounts) {
        //    this.common.emitChange("new_user");
        //    this.common.handleRequests([this._getModuleTypes(), this._getUserApprovalCounts()]);
        //}
        //else {
        //    this.common.handleRequests([this._getModuleTypes()]);
        //}

        const taskAreas = this.securityService.getAppSetting<any>("ServiceReportTaskAreas") || '';
        this.showTasks = taskAreas.length == 0 || taskAreas.indexOf(this.user.AppUser.Area) >= 0;
    }

    onConfirmMessageOfTheDay() {
        this.messages.map(x => {
            const view = new SparkMessageViewModel({
                SparkMessagePk: x.Pk,
                HasConfirmed: true
            });

            this.sparkMessageService.confirm(view).subscribe(() => {
                this.common.hideModal('message-of-the-day');
            });
        })
    }
}
