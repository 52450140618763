import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[minimumCategoryAmount]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: MinimumCategoryAmountDirective,
        multi: true
    }]
})
export class MinimumCategoryAmountDirective implements Validator {
    @Input('minimumCategoryAmount') amount: number;

    validate(control: AbstractControl): ValidationErrors {
        const categoryField = control.parent.controls['Category'];
        const amountField = control.parent.controls['Amount'];

        if (!categoryField || !amountField) {
            return null;
        }

        const validAmount = parseFloat(amountField.value) >= this.amount;

        return (categoryField.value || '').toLowerCase().indexOf('capital') < 0 || validAmount ? null : { 'categoryAmount': true };
    }
};
