<div class="modal-footer" #button>
    <ng-content></ng-content>

    <div *ngIf="!hasButton">
        <button class="btn btn-secondary" (click)="model.onConfirm()" [disabled]="model.DisableConfirm">
            <i [ngClass]="['mr-1', model.ConfirmIcon]"></i> {{model.ConfirmText}}
        </button>

        <button class="btn btn-secondary" (click)="model.onClose()" [disabled]="model.DisableClose">
            <i [ngClass]="['mr-1', model.CloseIcon]"></i> {{model.CloseText}}
        </button>

        <span class="ml-2">
            <loader [visible]="model.IsLoading"></loader>
        </span>
    </div>
</div>
