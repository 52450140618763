import { Component, Input, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import {
    FluidsRequestItemModel,
    FluidsRequestItemTypeModel,
    FluidsRequestModel
} from "../../shared/models";
import {
    Common
} from "../../core/services";

@Component({
    selector: 'fluids-request-fill-tanks-detail',
    templateUrl: './fluids-request-fill-tanks-detail.component.html'
})
export class FluidsRequestFillTanksDetailComponent {
    @Input() model: FluidsRequestModel = new FluidsRequestModel();
    @Input() lookupValues: any = {};
    @Input() types: any = {};
    @Input() access: any = {};

    items: FluidsRequestItemModel[] = new Array();
    modelOriginal: FluidsRequestModel = new FluidsRequestModel();
    selectedAsset: string;
    view: string;
    columnCount: number = 8;
    totalGallonsOrdered: any = {};

    constructor(
        public common: Common,
        private route: ActivatedRoute
    ) {
        this.common.setTitle('Operations | Fill Tanks Fill Tanks Fluids Request Details');

        this.route.queryParams.subscribe((params: Params) => {
            this.model.Pk = params['id'];
            this.view = params['view'];
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.modelOriginal = new FluidsRequestModel(this.model);

        this.items = [];

        this.model.Items.map(x => {
            const item = x;
            item.Types = this.common.sort(x.Types, 'Type.Sort');

            this.items.push(item);
        });

        if (this.model.NeedOil)
            this.columnCount++;
        if (this.model.NeedCoolant)
            this.columnCount++;
        if (this.model.NeedCompoundOil)
            this.columnCount++;

        this.lookupValues['FLUIDS_REQUEST_ITEM_TYPE'].map(x => this.calculateTotal(x.Id));
    }

    calculateTotal(typeId: string) {
        this.totalGallonsOrdered[typeId] = 0;
        let total = 0;
        this.items.map(x => {
            const type =  x.Types.find(type => type.Type.Id == typeId);
            if (type) {
                total += ( type.Quantity || 0);
            }
        });

        this.totalGallonsOrdered[typeId] = total;
    }

    hasChanges() {
        const excludedFields = ['Creator', 'Items', 'Types', 'Type', 'StandHeight'];

        let hasChanges = this.common.getChanges(this.model, this.modelOriginal, excludedFields).length > 0;

        if (!hasChanges) {
            for (let x in this.items) {
                for (let y in this.items[x].Types) {
                    const item = this.items[x].Types[y];
                    const originalItem = this.modelOriginal.Items[x].Types.find(z => z.TypePk == item.TypePk);

                    if (this.common.getChanges(item, originalItem, excludedFields).length > 0) {
                        hasChanges = true;
                        break;
                    }
                }

                if (hasChanges) {
                    break;
                }
            }
        }

        return hasChanges;
    }

    onVendorUpdate() {
        const vendor = this.lookupValues['Vendor'].find(x => x.Id == this.model.VendorId);

        this.model.VendorName = vendor.Name;
    }

    onShowHistory(item: FluidsRequestItemModel) {
        this.selectedAsset = item.AssetId;
    }

    onCloseHistory() {
        this.selectedAsset = null;
    }

    onToggle(typeName: string) {
        const item = this.lookupValues['FLUIDS_REQUEST_ITEM_TYPE'].find(x => x.Name == typeName);

        if (this.model['Need' + typeName]) {
            this.items.map(x => {
                x.Types.push(new FluidsRequestItemTypeModel({
                    Type: item,
                    TypePk: item.Pk
                }));

                x.Types = this.common.sort(x.Types, 'Type.Sort');
            });
        }
        else {
            this.items.map(x => {
                x.Types = x.Types.filter(y => y.TypePk != item.Pk);
            });
        }
    }
}
