import { Injectable } from '@angular/core';
import { Globals } from '../../shared/global';
import { HttpClient } from '@angular/common/http';
import { SearchCriteriaModel, SearchResultModel } from '../../shared/models';
import { LookupValueModel } from '../../shared/models/lookup-value.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Common } from './common.service';

@Injectable()
export class LookupValueService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/lookup-value`;

    data: any = {};

    constructor(private common: Common ,private http: HttpClient) {}

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<LookupValueModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new LookupValueModel(x));
                }
                return res;
            }));
    }

    get(modelPk: number) {
        return this.http.get<LookupValueModel>(`${this.url}/get/${modelPk}`)
            .pipe(map(res => new LookupValueModel(res) ));
    }

    getTypes() {
        return this.http.get<string[]>(`${this.url}/get-types`);
    }

    getByTypes(types: string[]) {
        let isCached = true;

        for (let type of types) {
            if (!this.data[type]) {
                isCached = false;
                break;
            }
        }

        if (isCached) {
            let results = [];

            types.map(type => {
                results = results.concat(this.data[type]);
            });

            return of(results);
        }

        return this.http.post<LookupValueModel[]>(`${this.url}/get-by-types`, types)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new LookupValueModel(x));

                    types.map(type => {
                        if (!this.data[type]) {
                            this.data[type] = results.filter(x => x.Type == type);
                        }
                    });

                    return results;
                }

                return new Array() as LookupValueModel[];
            }));
    }

    create(model: LookupValueModel) {
        return this.http.post<LookupValueModel>(`${this.url}/create`, model)
            .pipe(map(res => {
                const result = new LookupValueModel(res);

                if (this.data[model.Type]) {
                    this.data[model.Type].push(result);
                }

                return result;
            }));
    }

    update(model: LookupValueModel) {
        return this.http.put<LookupValueModel>(`${this.url}/update`, model)
            .pipe(map(res => {
                const result = new LookupValueModel(res);

                if (this.data[model.Type]) {
                    let index = -1;

                    for (let i = 0; i < this.data[model.Type].length; i++) {
                        if ((this.data[model.Type][i] as LookupValueModel).Pk == model.Pk) {
                            index = i;
                            break;
                        }
                    }

                    if (index >= 0) {
                        this.data[model.Type][index] = result;
                    }
                }

                return result;
            }));
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete/${modelPk}`, { observe: 'response'})
            .pipe(map(res => {
                if (res.status == 200) {
                    Object.keys(this.data).map(x => {
                        this.data[x] = this.data[x].filter((y: LookupValueModel) => y.Pk != modelPk);
                    });
                }

                return res.status == 200;
            }));
    }
}
