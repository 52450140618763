import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { McUploadDetailModel, P66OilConsumptionModel, UnitListImportModel } from '../../shared/models';
import { Globals } from '../../shared/global';
import { Common } from "./common.service";

@Injectable()
export class UtilitiesUploadService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.ASSET}/api/unit/`;

    constructor(private common: Common, private http: HttpClient) { }

    uploadP66OilConsumption(models: P66OilConsumptionModel[]) {
        return this.http.post<McUploadDetailModel>(this.url + 'import/p66-oil-consumption', models)
            .pipe(map(res => new McUploadDetailModel(res)));
    }

    getHistoricalData(importModel: UnitListImportModel) {
        return this.http.post<boolean>(this.url + 'get-historical-data', importModel)
            .pipe(map(res => res as boolean));
    }

    importHistoricalData(attachment: File, importModel: UnitListImportModel) {
        const formData = new FormData();
        formData.append('file', attachment);
        formData.append('importModel', JSON.stringify(importModel));
        return this.http.post<any>(this.url + 'import/historical-data', formData)
            .pipe(map(res => new McUploadDetailModel(res)));
    }
}
