export class EngineValueModel {
    Name: string;
    Operating: string;
    Kills: string;

    constructor(name: string, operating: number, kills: number) {
        this.Name = name;
        this.Operating = this.numberToEmpty(operating);
        this.Kills = this.numberToEmpty(kills);
    }

    numberToEmpty(value: number) {
        return (value > 0 ? value.toString() : '');
    }
}
