import { ApplicationUserModel } from './application.model';

import * as moment from 'moment';

export class MacolaBatchModel {
    Pk: number;
    BatchId: string;
    BatchDate: Date;
    Type: string;
    Keys: number[] = new Array();
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Creator: ApplicationUserModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.BatchId = obj.BatchId;
        this.BatchDate = moment(obj.BatchDate).startOf('day').toDate();
        this.Type = obj.Type;
        this.Keys = obj.Keys;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;

        if (obj.Creator) {
            this.Creator = new ApplicationUserModel(obj.Creator);
        }
    }
}
