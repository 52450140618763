import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { map } from "rxjs/operators";

import {
    ConfirmModel,
    FluidsRequestItemModel,
    FluidsRequestItemTypeModel,
    ModalFooterModel
} from "../../shared/models";
import {
    Common,
    FluidsRequestService,
    LookupService,
    LookupValueService
} from "../../core/services";

@Component({
    selector: 'fluids-request-history',
    templateUrl: './fluids-request-history.component.html'
})
export class FluidsRequestHistoryComponent {
    @Input() assetId: string;

    @Output() onClose: EventEmitter<any> = new EventEmitter();

    lookupValues: any = {};
    models: FluidsRequestItemModel[] = new Array();
    filteredResults: FluidsRequestItemModel[] = new Array();
    storageKey: string = 'FluidsRequestHistory';

    modalFooter: ModalFooterModel = new ModalFooterModel();

    constructor(
        public common: Common,
        private route: ActivatedRoute,
        private router: Router,
        private fluidsRequestService: FluidsRequestService,
        private lookupService: LookupService,
        private lookupValueService: LookupValueService
    ) {
    }

    ngOnInit() {
        this.common.handleRequests([
            this._getLookupValues()
        ]);
    }

    private _getHistory() {
        return this.fluidsRequestService.getHistoryByAsset(this.assetId).pipe(map(data => this.models = this.common.sort(data, 'DeliveryDate', 'desc')));
    }

    private _getLookupValues() {
        const types = [
            'FLUIDS_REQUEST_ITEM_TYPE'
        ];

        return this.lookupValueService.getByTypes(types).pipe(map(data => {
            types.map(x => {
                if (!this.lookupValues[x]) {
                    this.lookupValues[x] = this.common.sort(data.filter(y => y.Type == x), 'Sort');
                }
            })
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.assetId) {
            if (this.assetId) {
                this.modalFooter.IsLoading = true;
                this.common.showModal('fluids-request-history');

                this._getHistory().subscribe(data => {
                    this.models = data;

                    this.models.map(x => {
                        if (['swapunit', 'shutdowntank'].indexOf(x.FluidsRequest.Type.Type.toLowerCase()) >= 0) {
                            this.lookupValues['FLUIDS_REQUEST_ITEM_TYPE'].map(type => {
                                const item = x.Types.find(y => y.TypePk == type.Pk);

                                if (!item) {
                                    x.Types.push(new FluidsRequestItemTypeModel({
                                        Type: type,
                                        TypePk: type.Pk
                                    }));
                                }
                            });
                        }
                    });

                    this.modalFooter.IsLoading = false;
                }, error => {
                    this.modalFooter.IsLoading = false;
                    // show error?
                });
            }
            else {
                this.models = [];
                this.common.hideModal('fluids-request-history');
            }
        }
    }

    onConfirmClose() {
        this.onClose.emit();
    }
}