import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
    MessageModel,
    ModalFooterModel,
    WorkflowModuleModel,
    WorkflowModuleDetailModel,
    WorkflowDetailTypeModel
} from '../../models';

import {
    Common,
    McPurchaseOrderService,
    WorkflowService
} from '../../../core/services';

@Component({
    selector: 'workflow',
    templateUrl: 'workflow.component.html'
})
export class WorkflowComponent {
    @Input() mode: string = 'menu';
    @Input() model: any = {};
    @Input() isLoading: boolean = false;
    @Input() allowAction: boolean = true;
    @Input() showHistoryButton: boolean = true;
    @Input() showRollbackButton: boolean = false;
    @Input() rollbackText: string  = 'Undo';
    @Input() skipConfirm: boolean = false;

    @Output() onPreventAction: EventEmitter<any> = new EventEmitter();
    @Output() onConfirm: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();

    action: string = '';
    activeWorkflow: WorkflowModuleModel;
    activeWorkflowDetail: WorkflowModuleDetailModel;
    messageModel: MessageModel;
    showButtons: boolean = false;
    showForm: boolean = true;
    comments: string = '';
    detailType: WorkflowDetailTypeModel;
    isLoaded: boolean = false;
    showWorkflowHistory: boolean = false;

    confirmFooter: ModalFooterModel = new ModalFooterModel({
        ConfirmText: 'Yes',
        CloseText: 'No',
        DisableConfirm: false,
        onConfirm: () => { this.onConfirmSubmit() },
        onClose: () => { this.onClose() }
    });

    constructor(
        public common: Common,
        private mcPurchaseOrderService: McPurchaseOrderService,
        private workflowService: WorkflowService
    ) { }

    ngOnChanges() {
        if (this.model.WorkflowId == 'PO') {
            this.showButtons = this.model.Pk ? true : false;
            this.detailType = new WorkflowDetailTypeModel({
                ButtonText: 'Approve',
                PendingText: 'Approval',
                FinalText: 'Approved'
            });
            this.activeWorkflow = new WorkflowModuleModel({
                Workflow: {
                    Name: 'Purchase Order'
                }
            });
            return;
        }

        this.activeWorkflow = this.model.Workflows.find(x => x.Active);

        if (!this.activeWorkflow) {
            this.activeWorkflowDetail = null;
            this.detailType = null;
            this.showButtons = false;
            return;
        }

        this.activeWorkflowDetail = this.activeWorkflow.Details.find(x => x.Status.toLowerCase() == "pending");

        if (!this.model || this.model.Workflows.length == 0 || !this.model.CanApprove || !this.activeWorkflowDetail) {
            this.showButtons = false;
            return;
        }

        this.detailType = this.activeWorkflowDetail.WorkflowDetail.Type;
        this.showButtons = true;
    }

    onUndo() {
        this.common.showLoader();

        const detail = this.activeWorkflow.Details[this.activeWorkflow.Details.length - 1];

        this.workflowService.rollbackStep(detail)
            .subscribe(
                data => {
                    this.onConfirm.emit("OPENED");
                },
                error => {
                    this.showForm = false;
                    this.common.showError('Error undoing workflow detail', error);
                }
            );
    }

    onSubmit(action: string) {
        this.action = action.toLowerCase();

        if (this.action != 'denied' && !this.allowAction) {
            this.onPreventAction.emit();
            return;
        }

        if (this.skipConfirm && this.action != 'denied') {
            this.onConfirmSubmit();
            return;
        }

        this.showForm = this.action == 'denied';
        this.common.showModal('workflow-action-modal' + this.model.Pk);

        this.confirmFooter.DisableConfirm = this.showForm;
    }

    onConfirmSubmit() {
        this.confirmFooter.disableAll();

        if (this.skipConfirm && this.mode == 'inline') {
            this.common.showLoader();
        }

        // todo: remove this when POs are handled from without Spark
        if (this.model.WorkflowId == 'PO') {
            this.model.UDFChar2 = this.comments;
            this.mcPurchaseOrderService.update(this.model, this.action)
                .subscribe(
                    data => {
                        this.common.hideModal('workflow-action-modal' + this.model.Pk);
                        this.confirmFooter.enableAll();

                        this.onConfirm.emit(this.action);
                        this.comments = "";
                        this.common.emitChange("refresh_count");
                    },
                    error => {
                        this.common.showError(`Error marking Purchase Order as ${status.toLowerCase()}.`, error);
                        this.confirmFooter.enableAll();
                        
                    }
                );
        }
        else {
            this.activeWorkflowDetail.Status = this.action.toUpperCase();
            this.activeWorkflowDetail.Comments = this.comments;

            this.workflowService.updateWorkflowModuleDetail(this.activeWorkflowDetail)
                .subscribe(
                    data => {
                        this.common.hideModal('workflow-action-modal' + this.model.Pk);
                        this.confirmFooter.enableAll();

                        this.onConfirm.emit(this.activeWorkflowDetail.Status);
                        this.comments = "";
                        this.common.emitChange("refresh_count");
                    },
                    error => {
                        this.common.showError('Error saving workflow detail', error);
                        this.confirmFooter.enableAll();
                       
                    }
                );
        }
    }

    onClose() {
        this.comments = '';
        this.common.hideModal('workflow-action-modal' + this.model.Pk);
    }

    onConfirmCancel() {
        this.onCancel.emit();
    }

    onShowHistory() {
        this.showWorkflowHistory = true;
    }

    onCloseHistory() {
        this.showWorkflowHistory = false;
    }

    handleCommentChange() {
        // if statements written so that EnableSubmit isn't updated with every key stroke
        if (this.comments && this.comments.trim().length >= 4) {
            if (this.confirmFooter.DisableConfirm) {
                this.confirmFooter.DisableConfirm = false;
            }
        }
        else if (!this.confirmFooter.DisableConfirm) {
            this.confirmFooter.DisableConfirm = true;
        }
    }

    showDeny() {
        return this.showButtons && this.detailType.ButtonText.toLowerCase() != 'notify';
    }
}
