export class McDetailModel {
    Value: string;
    Description: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Value = obj.Value;
        this.Description = obj.Description;
    }
}
