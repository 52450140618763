import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/shared/global';
import { map } from 'rxjs/operators';
import { SearchCriteriaModel, SearchResultModel, SyncLogViewModel, SyncLogValueChangeModel } from 'src/app/shared/models';

@Injectable()
export class ActiveDirectorySyncService {
    private url: string = null;

    constructor(private http: HttpClient) {
        this.url = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/active-directory-sync`;
    }

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<SyncLogViewModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new SyncLogViewModel(x));
                }
                return res;
            }));
    }
}