import { Component, ElementRef, Input, Output, OnDestroy, OnInit, Renderer2, ViewChild, EventEmitter } from '@angular/core';

import { ModalService } from '../../../core/services';

@Component({
    selector: 'modal',
    templateUrl: 'modal.component.html'
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() class: string = '';
    @Input() showHeaderCloseButton: boolean = false;

    @Output() onClose: EventEmitter<any> = new EventEmitter();

    @ViewChild('header', { static: true }) header: ElementRef;
    @ViewChild('footer', { static: true }) footer: ElementRef;

    element: any;
    hasFooter: boolean = false;
    hasHeader: boolean = true;
    bodyPadding: number = 0;

    constructor(
        private el: ElementRef,
        private modalService: ModalService,
        private renderer: Renderer2
    ) {
        this.element = el.nativeElement;
    }

    ngOnInit() {
        if (!this.id) {
            throw "modal must have an id";
        }

        document.body.appendChild(this.element);
        this.modalService.add(this);

        this.hasFooter = this.footer.nativeElement.childNodes.length > 0;

        if (this.header.nativeElement.childNodes[0].childNodes.length == 0) {
            // hide the header
            this.renderer.setAttribute(this.element.getElementsByClassName('modal-header')[0], 'hidden', 'true');
        }
    }

    ngOnDestroy() {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // the timeouts allow CSS transitions to process
    show() {
        this.element.classList.add('show');

        if (!document.body.classList.contains('modal-open')) {
            this.bodyPadding = parseInt(window.getComputedStyle(document.body).getPropertyValue('padding-right') || '0', 10);
            document.body.style.paddingRight = this.bodyPadding + (window.innerWidth - document.body.clientWidth) + "px";

            document.body.classList.add('modal-open');
        }

        this.element.children[1].classList.add('in', 'show');

        const self = this;

        setTimeout(() => {
            self.element.children[0].classList.add('in', 'show');
        }, 150);
    }

    hide() {
        this.element.children[0].classList.remove('in', 'show');

        const self = this;

        setTimeout(() => {
            self.element.children[1].classList.remove('in', 'show');
            if (document.getElementsByClassName('modal show').length == 0) {
                document.body.classList.remove('modal-open');
                document.body.style.paddingRight = this.bodyPadding + "px";
            }
        }, 150);

        setTimeout(() => {
            self.element.classList.remove('show');
            this.onClose.emit();
        }, 350);
    }
}
