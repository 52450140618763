import { Component, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";

import {
    Common
} from '../../../core/services';

@Component({
    selector: 'workflow-history',
    templateUrl: 'workflow-history.component.html'
})
export class WorkflowHistoryComponent implements AfterViewInit {
    @Input() model: any = {};

    @Output() onCancel: EventEmitter<any> = new EventEmitter();

    constructor(
        public common: Common
    ) { }

    ngAfterViewInit() {
        this.common.showModal('workflow-history');
    }

    getWorkflowDetailType(detail: any) {
        let result = "";

        switch (detail.Recipient.toLowerCase()) {
            case "g":
                result = detail.ApplicationRole.Description;
                break;

            case "s":
                result = "Supervisor";
                break;

            case "am":
                result = "Area Manager";
                break;

            case "rm":
                result = "Regional Manager";
                break;

            case "ovp":
                result = "Operations Vice President";
                break;

            case "accountmanager":
                result = 'Account Manager';
                break;

            default:
                break;
        }

        return result;
    }

    sortUsers(users: string) {
        if (users == null) {
            return '';
        }

        const list = users.split(',').map(x => x.trim()).sort();
        return list.join(', ');
    }

    onModalClose() {
        this.common.hideModal('workflow-history');
        this.onCancel.emit();
    }
}