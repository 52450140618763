import { ApplicationUserModel } from './application.model';
import { WorkflowModuleModel } from './workflow.model';

export class InventoryCountModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Status: string;
    CountDate: Date;
    LocationId: string;
    LocationName: string;
    Notes: string;
    Creator: ApplicationUserModel;
    CanApprove: boolean;
    WorkflowId: string = 'IC';
    Items: InventoryCountItemModel[] = new Array();
    Workflows: WorkflowModuleModel[] = new Array();

    constructor(obj? : any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = new Date(obj.CreationDate);
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = new Date(obj.LastUpdateDate);
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        if (obj.CountDate) {
            this.CountDate = new Date(obj.CountDate);
        }

        this.Status = obj.Status;
        this.LocationId = obj.LocationId;
        this.LocationName = obj.LocationName;
        this.Notes = obj.Notes;
        this.CanApprove = obj.CanApprove;
        this.WorkflowId = obj.WorkflowId;

        if (obj.Creator) {
            this.Creator = new ApplicationUserModel(obj.Creator);
        }

        if (obj.Items) {
            this.Items = obj.Items.map(x => new InventoryCountItemModel(x));
        }

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }
    }
}

export class InventoryCountItemModel {
    Pk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    InventoryCountPk: number;
    PartLocationPk: number;
    PartId: string;
    PartName: string;
    ManufacturerName: string;
    Bin: string;
    OnHand: number;
    Quantity: number;
    Notes: string;

    constructor(obj? : any) {
        if (obj == null) {
            return;
        }

        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = new Date(obj.CreationDate);
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = new Date(obj.LastUpdateDate);
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.InventoryCountPk = obj.InventoryCountPk;
        this.PartLocationPk = obj.PartLocationPk;
        this.PartId = obj.PartId;
        this.PartName = obj.PartName;
        this.ManufacturerName = obj.ManufacturerName;
        this.Bin = obj.Bin;
        this.OnHand = obj.OnHand;
        this.Quantity = obj.Quantity;
        this.Notes = obj.Notes || '';
    }
}