import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Common } from '../../../core/services';
import { PagingBoxModel, SessionModel } from '../../models';

@Component({
    selector: 'pagination-container',
    templateUrl: 'pagination-container.component.html'
})
export class PaginationContainerComponent implements OnInit {
    @Input() results: Array<any> = new Array();
    @Input() selectOptions: Array<any> = new Array();
    @Input() storageKey: string = null;
    @Input() onFilter: Function = null;

    @Output() onPaged: EventEmitter<any> = new EventEmitter();
    @Output() onSelected: EventEmitter<any> = new EventEmitter();

    filteredRecords: Array<any> = new Array();
    pageSize: number = 10;
    pageStart: number = 1;
    sessionModel: SessionModel;
    showSelect: boolean = true;
    showSelectGroup: boolean = true;
    totalRecords: number = 0;

    constructor(public common: Common) {}

    ngOnInit() {
        if (!this.storageKey) {
            throw "PaginationContainerComponent: storage key missing.";
        }

        if (this.sessionModel == null) {
            this.sessionModel = new SessionModel(this.common.getGenericStorage(this.storageKey));
        }

        if (!this.selectOptions || this.selectOptions.length == 0) {
            this.showSelect = false;
        }
        else if (!this.selectOptions[0].group) {
            this.showSelectGroup = false;
        }
    }

    ngOnChanges() {
        if (this.sessionModel == null) {
            this.sessionModel = new SessionModel(this.common.getGenericStorage(this.storageKey));
            this.pageStart = this.sessionModel.CurrentIndex;
        }

        this.showSelect = this.selectOptions && this.selectOptions.length > 0;
        this.showSelectGroup = this.showSelect && this.selectOptions[0].group;

        if (!this.results || this.results.length == 0) {
            this.sessionModel.CurrentIndex = 1;
            this.filteredRecords = this.results;
            this.totalRecords = 0;
            this.refreshItems();
            return;
        }

        this.applyFilter();
    }

    onSelect() {
        this.common.setGenericStorage(this.storageKey, this.sessionModel);
        this.onSelected.emit(this.sessionModel.SearchView.toLowerCase());
    }

    applyFilter() {
        this.pageStart = 1;
        this.sessionModel.CurrentIndex = 1;
        this.filteredRecords = this.onFilter ? this.onFilter(this.results, this.sessionModel.SearchText) : this.results;

        // Get the page count to check to make sure the current page
        // index is not outside of the bounds.  If it is outside of the
        // range then the index is set to the last page.
        var pageCount = Math.floor(this.results.length / this.pageSize);
        pageCount = this.results.length == 0 ? 0 : this.results.length % this.pageSize > 0 ? pageCount + 1 : pageCount;

        if (this.sessionModel.CurrentIndex > pageCount)
            this.sessionModel.CurrentIndex = pageCount;

        this.totalRecords = this.filteredRecords.length;

        this.refreshItems();
    }

    refreshItems() {
        const pageRecords = this.filteredRecords.slice((this.sessionModel.CurrentIndex - 1) * this.pageSize, (this.sessionModel.CurrentIndex) * this.pageSize);

        this.common.setGenericStorage(this.storageKey, this.sessionModel);
        this.onPaged.emit(pageRecords);
    }

    setPage(page: any) {
        this.sessionModel.CurrentIndex = page.page;
        this.common.setGenericStorage(this.storageKey, this.sessionModel);

        this.refreshItems();
    }
}
