import { Component, Input, SimpleChanges, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from "@angular/router";

import {
    FluidsRequestItemTypeModel,
    FluidsRequestModel,
    PartModel
} from "../../shared/models";
import {
    Common
} from "../../core/services";

@Component({
    selector: 'fluids-request-new-tank-detail',
    templateUrl: './fluids-request-new-tank-detail.component.html'
})
export class FluidsRequestNewTankDetailComponent {
    @ViewChild('fluidRequestNewTankForm') fluidRequestNewTankForm: NgForm;

    @Input() model: FluidsRequestModel = new FluidsRequestModel();
    @Input() lookupValues: any = {};
    @Input() types: any = {};
    @Input() access: any = {};

    modelOriginal: FluidsRequestModel = new FluidsRequestModel();
    view: string;
    selectedTabIndex: number = 0;

    constructor(
        public common: Common,
        public route: ActivatedRoute
    ) {
        this.common.setTitle('Operations | New Tank Fluids Request Details');

        this.route.queryParams.subscribe((params: Params) => {
            this.model.Pk = params['id'];
            this.view = params['view'];
        });

        this.selectedTabIndex = this.model.NeedOil ? 0 : this.model.NeedCoolant ? 1 : this.model.NeedCompoundOil ? 2 : 0;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.modelOriginal = new FluidsRequestModel(this.model);
    }

    hasChanges() {
        const excludedFields = ['Creator', 'Items', 'Types', 'Type', 'StandHeight'];

        let hasChanges = this.common.getChanges(this.model, this.modelOriginal, excludedFields).length > 0 ||
            this.common.getChanges(this.model.Items[0], this.modelOriginal.Items[0], excludedFields).length > 0 ||
            this.model.Items[0].Types.length != this.modelOriginal.Items[0].Types.length;

        if (!hasChanges) {
            for (let i in this.types) {
                const type = this.modelOriginal.Items[0].Types.find(x => x.TypePk == this.types[i].TypePk);

                if (type && this.model['Need' + this.types[i].Type.Name]) {
                    hasChanges = this.common.getChanges(this.types[i], type, excludedFields).length > 0;

                    if (hasChanges) {
                        break;
                    }
                }
            }
        }

        return hasChanges;
    }

    onVendorUpdate() {
        const vendor = this.lookupValues['Vendor'].find(x => x.Id == this.model.VendorId);

        this.model.VendorName = vendor.Name;
    }

    onProductUpdate(item: FluidsRequestItemTypeModel) {
        const product = this.lookupValues[item.Type.Name + 'Products'].find(x => x.PartId == item.ProductId);

        item.ProductName = product.PartName;
    }

    onToggle(fieldName: string) {
        var controls = this.fluidRequestNewTankForm.controls;
        controls[fieldName + 'Capacity'].markAsPristine();
        controls[fieldName + 'StandHeight'].markAsPristine();
        controls[fieldName + 'Quantity'].markAsPristine();
        controls[fieldName + 'Product'].markAsPristine();
    }

    getUnitOfMeasurement(productId: string) {
        const product = this.lookupValues["Products"].find((x: PartModel) => x.PartId == productId);
        let result = '';

        if (product) {
            result = product.IssueUnits.toLowerCase();

            if (result[result.length - 1] != 's') {
                result += 's';
            }
        }

        return result;
    }
}
