import { SalesOrderEscalationModel, SalesOrderModel, InvoicingInstructionModel } from './sales-order.model';
import { WorkflowModuleModel } from './workflow.model';

import * as moment from 'moment';

export class SalesMassRenewalModel {
    Pk: number;
    Status: string;
    Type: string;
    RenewalDate: Date;
    CustomerId: string;
    CustomerName: string;
    TermInMonths: number;
    TermNotes: string;
    RenewNotes: string;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Items: SalesMassRenewalItemModel[] = new Array();
    CanApprove: boolean;
    WorkflowId: string = 'MASS_';
    Workflows: WorkflowModuleModel[] = new Array();
    ParentPk: number = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        if (obj.RenewalDate)
            this.RenewalDate = moment(obj.RenewalDate).startOf('day').toDate();

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.Pk = obj.Pk;
        this.Status = obj.Status;
        this.Type = obj.Type;
        this.CustomerId = obj.CustomerId;
        this.CustomerName = obj.CustomerName;
        this.TermInMonths = obj.TermInMonths;
        this.TermNotes = obj.TermNotes;
        this.RenewNotes = obj.RenewNotes;

        if (obj.Items) {
            this.Items = obj.Items.map(x => new SalesMassRenewalItemModel(x));
        }

        this.CanApprove = obj.CanApprove;

        if (obj.Type) {
            this.WorkflowId += obj.Type;
        }

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }
    }
}

export class SalesMassRenewalItemModel {
    Pk: number;
    SalesMassRenewalPk: number;
    SalesOrderPk: number;
    InvoicingInstructionPk: number;
    PreviousInvoicingInstructionPk: number;
    RenewalDate: Date;
    AssetId: string;
    Location: string;
    AreaId: string;
    MonthlyRate: number;
    TermInMonths: number;
    TermNotes: string;
    RenewNotes: string;
    HasEscalationClause: boolean;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Selected: boolean = false;
    Expanded: boolean = false;

    SalesOrder: SalesOrderModel;
    InvoicingInstruction: InvoicingInstructionModel;
    PreviousInvoicingInstruction: InvoicingInstructionModel;
    Escalations: SalesOrderEscalationModel[] = new Array();

    // UI only fields
    Engine: string;
    Horsepower: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        if (obj.RenewalDate)
            this.RenewalDate = moment(obj.RenewalDate).startOf('day').toDate();

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.Pk = obj.Pk;
        this.SalesMassRenewalPk = obj.SalesMassRenewalPk;
        this.SalesOrderPk = obj.SalesOrderPk;
        this.InvoicingInstructionPk = obj.InvoicingInstructionPk;
        this.PreviousInvoicingInstructionPk = obj.PreviousInvoicingInstructionPk;
        this.AssetId = obj.AssetId;
        this.Location = obj.Location;
        this.AreaId = obj.AreaId;
        this.MonthlyRate = obj.MonthlyRate;
        this.TermInMonths = obj.TermInMonths;
        this.TermNotes = obj.TermNotes;
        this.RenewNotes = obj.RenewNotes;
        this.HasEscalationClause = obj.HasEscalationClause;

        if (obj.SalesOrder) {
            this.SalesOrder = new SalesOrderModel(obj.SalesOrder);
        }

        if (obj.InvoicingInstruction) {
            this.InvoicingInstruction = new InvoicingInstructionModel(obj.InvoicingInstruction);
        }

        if (obj.PreviousInvoicingInstruction) {
            this.PreviousInvoicingInstruction = new InvoicingInstructionModel(obj.PreviousInvoicingInstruction);
        }

        if (obj.Escalations) {
            this.Escalations = obj.Escalations.map(x => new SalesOrderEscalationModel(x)).sort((a, b): number => {
                return moment(a.EscalationDate).toDate().getTime() > moment(b.EscalationDate).toDate().getTime() ? 1 : -1;
            });
        }
    }
}
