import { Component, Input, SimpleChanges } from '@angular/core';

import { Globals } from '../../../shared/global';

import {
    BlobStorageSettingModel,
    SparkFileModel,
    SparkModuleTypeModel
} from '../../models';

import {
    Common,
    SecurityService,
    SparkFileService
} from '../../../core/services';

@Component({
    selector: 'spark-file',
    templateUrl: 'spark-file.component.html'
})
export class SparkFileComponent {
    @Input() model: SparkFileModel;
    @Input() previewOnly: boolean = false;
    @Input() showThumbnail: boolean = false;
    @Input() showDownload: boolean = true;
    @Input() status: string = 'NEW';
    @Input() sparkModuleType: SparkModuleTypeModel;

    downloading: boolean = false;
    path: string;
    accessToken: string = '';

    constructor(
        public common: Common,
        public securityService: SecurityService,
        public sparkFileService: SparkFileService
    ) {
    }

    ngOnInit() {
        this.setPath();
    }

    ngOnChanges(changes: SimpleChanges) {
        // detect status change and determine if MC url is needed instead of API
        if (changes.status && changes.status.currentValue) {
            this.setPath();
        }
    }

    setPath() {
        this.path = this.securityService.getAppSetting<string>('SparkFileWebPath');

        if (this.model.IsRemote) {
            this.path = this.securityService.getAppSetting<string>('SparkFileWebPathBlob');

            const blobStorage = JSON.parse(this.securityService.getAppSetting<any>('BlobStorage')) as BlobStorageSettingModel;

            if (blobStorage) {
                this.accessToken = blobStorage.Token;
            }
        }

        // list of modules that support attachments that are not sent to MC
        const excludedModules = ['so', 'ii', 'si', 'er'];
        const excludedStatuses = ['new', 'pending', 'deleted', 'denied'];
        const status = (this.status || '').toLowerCase();
        const moduleId = this.sparkModuleType ? this.sparkModuleType.Id.toLowerCase() : '';
        const submittedReport = ['sr', 'or'].indexOf(moduleId) >= 0 && ['new', 'deleted', 'denied'].indexOf(status) < 0;

        if (submittedReport || (excludedStatuses.indexOf(status) < 0 && excludedModules.indexOf(moduleId) < 0)) {
            this.path = this.securityService.getAppSetting<string>('OperatorReportWebPath');
        }
    }

    showPreview() {
        !this.previewOnly && this.common.showModal('spark-file' + this.model.Pk);
    }

    openFile() {
        this.downloading = true;

        this.sparkFileService.download(this.model.Pk)
            .subscribe(
                data => {
                    this.downloading = false;

                    if (data) {
                        this.common.downloadFile(this.model.Description, data);
                    }
                    else {
                        this.common.showError('Unexpected error', `We encountered an issue attempting to download ${this.model.Description}.`);
                    }
                },
                error => {
                    this.downloading = false;
                    this.common.showError('Unexpected error', `We encountered an issue attempting to download ${this.model.Description}.`);
                }
            );
    }

    isImage() {
        return this.model.isImage();
    }

    setModel(model: SparkFileModel) {
        this.model = new SparkFileModel(model);
    }
}
