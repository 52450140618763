import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    McPurchaseOrderModel
} from '../../shared/models';

@Injectable()
export class McPurchaseOrderService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.PURCHASE_ORDER}/api/mc-purchase-order`;

    constructor(private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get<McPurchaseOrderModel>(`${this.url}/get?modelPk=${modelPk}`, {})
            .pipe(
                map(res => new McPurchaseOrderModel(res))
            );
    }

    search() {
        return this.http.get<McPurchaseOrderModel[]>(`${this.url}/search`)
            .pipe(
                map(res => res ? res.map(x => new McPurchaseOrderModel(x)) : new Array() as McPurchaseOrderModel[])
            );
    }

    update(model: McPurchaseOrderModel, status: string) {
        return this.http.put<McPurchaseOrderModel>(`${this.url}/update?status=${status}`, model)
            .pipe(
                map(res => new McPurchaseOrderModel(res))
            );
    }
}
