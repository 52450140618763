import { McDetailModel } from './mc-detail.model';
import { WorkflowModuleModel } from './workflow.model';

import * as moment from 'moment';

export class McPurchaseOrderModel {
    Pk: number;
    OrderDate: Date;
    Comments: string;
    BuyerId: string;
    BuyerName: string;
    VendorId: string;
    VendorName: string;
    RequesterId: string;
    RequesterName: string;
    Total: number;
    Status: string;
    StatusDesc: string;
    AuthStatus: string;
    AuthStatusDesc: string;
    UDFChar2: string;
    Details: McPurchaseOrderDetailModel[] = new Array();
    // todo: is this needed anymore?
    WorkflowId: string = 'PO';
    Workflows: WorkflowModuleModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.OrderDate = moment(obj.OrderDate).toDate();
        this.Comments = obj.Comments;
        this.BuyerId = obj.BuyerId;
        this.BuyerName = obj.BuyerName;
        this.VendorId = obj.VendorId;
        this.VendorName = obj.VendorName;
        this.RequesterId = obj.RequesterId;
        this.RequesterName = obj.RequesterName;
        this.Total = obj.Total;
        this.Status = obj.Status;
        this.StatusDesc = obj.StatusDesc;
        this.AuthStatus = obj.AuthStatus;
        this.AuthStatusDesc = obj.AuthStatusDesc;
        this.UDFChar2 = obj.UDFChar2;

        if (obj.Details) {
            this.Details = obj.Details.map(x => new McPurchaseOrderDetailModel(x));
        }
    }
}

export class McPurchaseOrderDetailModel {
    Pk: number;
    POPK: number;
    LineItemNo: number;
    AreaId: string;
    AreaName: string;
    AssetId: string;
    AssetName: string;
    StockroomId: string;
    PartId: string;
    PartName: string;
    AccountId: string;
    AccountName: string;
    Comments: string;
    OrderUnitQty: number;
    OrderUnitQtyReceived: number;
    OrderUnitPrice: number;
    LineItemTotal: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.POPK = obj.POPK;
        this.LineItemNo = obj.LineItemNo;
        this.AreaId = obj.AreaId;
        this.AreaName = obj.AreaName;
        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.StockroomId = obj.StockroomId;
        this.PartId = obj.PartId;
        this.PartName = obj.PartName;
        this.AccountId = obj.AccountId;
        this.AccountName = obj.AccountName;
        this.Comments = obj.Comments;
        this.OrderUnitQty = obj.OrderUnitQty;
        this.OrderUnitQtyReceived = obj.OrderUnitQtyReceived;
        this.OrderUnitPrice = obj.OrderUnitPrice;
        this.LineItemTotal = obj.LineItemTotal;
    }
}
