import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { AppGlobalService } from '../../core/services';

import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private authService: MsalService,
        private global: AppGlobalService
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.authService.instance.getAllAccounts().length > 0 && this.global.sparkUser.ApplicationUserPk > 0;
    }
}
