export class SearchResultModel<T> {
    Results: T[];
    TotalResults: number = 0;

    constructor(obj?: any) {
        if (obj == null) {
            this.Results = new Array();
            return;
        }

        this.Results = obj.Results as T[];
        this.TotalResults = obj.TotalResults;
    }
}
