import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    McWorkOrderModel
} from '../../shared/models';

@Injectable()
export class McWorkOrderService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/work-order`;

    constructor(private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get<McWorkOrderModel>(`${this.url}/get?modelPk=${modelPk}`)
            .pipe(
                map(res => new McWorkOrderModel(res))
            );
    }
}
