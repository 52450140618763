import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { Common, SafetyService } from 'src/app/core/services';
import { NgForm } from '@angular/forms';
import { ApplicationUserModel, ConfirmModel, ModalFooterModel, SafetyCorrectiveActionModel } from 'src/app/shared/models';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { map } from 'rxjs/operators';

import { UserService } from '../../../core/services';

@Component({
    selector: 'safety-corrective-action',
    templateUrl: './safety-corrective-action.component.html'
})
export class SafetyCorrectiveActionComponent implements OnInit, AfterViewInit {
    @ViewChild('safetyCorrectiveActionForm', { static: true}) safetyCorrectiveActionForm: NgForm;

    @Input() model: SafetyCorrectiveActionModel;
    @Input() editable: boolean = true;

    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();

    modelOriginal: SafetyCorrectiveActionModel;
    users: ApplicationUserModel[] = new Array();
    selectedUser: string = '';

    confirmLoseChange: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to cancel and lose changes?',
        onConfirm: () => { this.onConfirmClose() }
    });

    modalFooter: ModalFooterModel = new ModalFooterModel({
        ConfirmText: 'Save',
        onConfirm: () => { this.onSaveCorrectiveAction() },
        onClose: () => { this.onClose() }
    });

    constructor(
        private common: Common,
        private safetyService: SafetyService,
        private userService: UserService
    ) {
        this.common.handleRequests([
            this._getUsers()
        ]);
    }

    private _getUsers() {
        return this.userService.getAllUsers().pipe(map(data => this.users = this.common.sort(data, 'DisplayName')));
    }

    ngOnInit() {
        this.modelOriginal = new SafetyCorrectiveActionModel(this.model);

        if (this.model.Pk) {
            this.selectedUser = this.model.ResponsibleParty.DisplayName;

            this.onValidateForm();
        }
    }

    ngAfterViewInit() {
        this.common.showModal('safety-corrective-action-form');
    }

    onSaveCorrectiveAction() {
        if (!this.hasChanges()) {
            this.onCancel.emit();
            return;
        }

        this._onSave();
    }

    onClose() {
        if (this.hasChanges()) {
            this.common.showConfirm(this.confirmLoseChange);
        }
        else {
            this.onConfirmClose();
        }
    }

    onConfirmClose() {
        this.onCancel.emit();
        this.common.hideConfirm();
    }

    onValidateForm() {
        this.modalFooter.DisableConfirm = !this.safetyCorrectiveActionForm.valid || !this.model.ResponsiblePartyPk;
    }

    onResponsiblePartyChange(value: any) {
        if (!value) {
            this.model.ResponsiblePartyPk = null;
        }

        this.onValidateForm();
    }

    onResponsiblePartySeleted(selected: TypeaheadMatch) {
        const selectedUser = selected.item as ApplicationUserModel;

        if (selectedUser) {
            this.model.ResponsiblePartyPk = selectedUser.Pk;
            this.model.ResponsibleParty = selectedUser;
        }

        this.onValidateForm();
    }

    private hasChanges() {
        return this.common.getChanges(this.model, this.modelOriginal, ['ResponsibleParty']).length > 0;
    }

    private _onSave() {
        this.modalFooter.disableAll();

        let method = this.safetyService.saveCorrectiveAction(this.model);

        method.subscribe((data: SafetyCorrectiveActionModel)=> {
            this.modalFooter.enableAll();
            this.common.hideModal('safety-corrective-action-form');
            this.onSave.emit(data);
        }, error => {
            this.common.showError('Error saving Corrective Action', error);
            this.modalFooter.enableAll();
        });
    }
}
