import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { map } from 'rxjs/operators';

import {
    AssetActivityReportModel,
    ConfirmModel,
    DataflowGroupModel,
    ModalFooterModel,
    SessionModel,
    UnitModel,
    UserModel
} from '../../shared/models';

import {
    AssetActivityReportService,
    Common,
    WorkflowService
} from '../../core/services';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { AssetActivityDataflowComponent } from '../../shared/components/dataflow/asset-activity-dataflow.component';

import * as moment from 'moment';

@Component({
    selector: 'asset-activity-report-detail',
    templateUrl: './asset-activity-report-detail.component.html'
})
export class AssetActivityReportDetailComponent implements OnInit {
    @ViewChild('dataflow', { static: true }) dataflow: AssetActivityDataflowComponent;

    @Input() public id: number = 0;
    @Input() public preview: boolean = false;

    access: any = {
        delete: false,
        print: false,
        save: false,
        submit: false,
        rollback: false
    };
    model: AssetActivityReportModel = new AssetActivityReportModel();
    modelOriginal: AssetActivityReportModel = new AssetActivityReportModel();
    user: UserModel = new UserModel();
    view: string;

    confirmSubmit: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to submit the Asset Activity Report?',
        onConfirm: () => { this.onConfirmSubmit() }
    });

    confirmDelete: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to delete the Asset Activity Report?',
        onConfirm: () => { this.onConfirmDelete() }
    });

    confirmLoseChanges: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to cancel and lose changes?',
        onConfirm: () => { this.goBack() }
    });

    constructor(
        public common: Common,
        private assetActivityReportService: AssetActivityReportService,
        private route: ActivatedRoute,
        private router: Router,
        private workflowService: WorkflowService
    ) {
        this.common.setTitle('Operations | Asset Activity Reports');

        this.route.queryParams.subscribe((params: Params) => {
            this.model.Pk = params['id'];
            this.view = params['view'];
        });

        if (this.model.Pk == null || this.model.Pk <= 0) {
            this.goBack();
            return;
        }

        this.user = this.common.getUser();


    }

    ngOnInit() {
        if (this.id) {
            this.model.Pk = this.id;
        }

        this.common.handleRequests([
            this._onLoad()
        ]);
    }

    private _onLoad(workflowStatus?: string) {
        return this.assetActivityReportService.get(this.model.Pk)
            .pipe(map(data => {
                this.model = data;
                this.modelOriginal = new AssetActivityReportModel(data);

                this.refreshAccess();

                if (workflowStatus != null) {
                    this.common.showMessage(`Successfully ${workflowStatus.toLowerCase()} Asset Activity Report.`);
                }
            }));
    }

    private _onUpdate(workflowStatus?: string) {
        return this.assetActivityReportService.update(this.model)
            .pipe(map(data => {
                this.model = data;
                this.modelOriginal = new AssetActivityReportModel(data);

                this.refreshAccess();

                this.common.showMessage('Successfully updated Asset Activity Report.');
            }));
    }

    onLoad(workflowStatus?: string) {
        this.common.handleRequests([this._onLoad(workflowStatus)]);
    }

    onUpdate() {
        if (this.dataflow.customValidation()) {
            this.onConfirmUpdate();
        }
    }

    onConfirmUpdate() {
        this.common.handleRequests([this._onUpdate()]);
    }

    canSave() {
        let hasMissingData = false;

        // todo: see if we handle this more efficiently so it's not a constant
        // loop anytime an event occurs to determine the state of the Save button
        if (this.dataflow.tabs.length) {
            for (let group of this.dataflow.tabs) {
                for (let field of group.Fields) {
                    const value = this.dataflow.model[field.Id];
                    const el = document.getElementById(field.Id) as HTMLInputElement;

                    if (field.Required && !value && value !== false && el && !el.disabled) {
                        hasMissingData = true;
                        break;
                    }
                }
            }
        }

        return !this.access.save || hasMissingData;
    }

    onBack() {
        if (this.dataflow.hasChanges()) {
            this.common.showConfirm(this.confirmLoseChanges);
        }
        else {
            this.goBack();
        }
    }

    goBack() {
        const params: any = {};

        if (this.view) {
            params.queryParams = { view: this.view };
        }

        this.router.navigate(['/operations/asset-activity/list'], params);
    }

    refreshAccess() {
        const status = this.model.Status.toLowerCase();
        const roles = this.common.getUserRoleAccess();

        this.access.delete = (status == 'reviewed' && roles.admin) || (this.model.CreatedBy == this.user.ApplicationUserPk && (status == 'new' || status == 'denied'));

        if (this.model.CreatedBy == this.user.ApplicationUserPk && (status == 'new' || status == 'denied')) {
            this.access.save = true;
            this.access.submit = true;
        }
        else if (status == 'pending' && this.model.CanApprove) {
            this.access.save = true;
            this.access.submit = false;
        }
        else {
            this.access.save = false;
            this.access.submit = false;
        }

        this.access.print = status == 'reviewed';
        this.access.rollback = status == 'reviewed' && roles.opsAdmin;
    }

    onSubmit() {
        this.common.showConfirm(this.confirmSubmit);
    }

    onConfirmSubmit() {
        this.confirmSubmit.disableAll();

        this.workflowService.createWorkflowModule(this.model.Pk, this.model.WorkflowId)
            .subscribe(
                data => {
                    this.common.hideConfirm();
                    this.confirmSubmit.enableAll();

                    this.model.Workflows.push(data);
                    this.common.showMessage('Successfully submitted Asset Activity Report.');
                    this.goBack();
                },
                error => {
                    this.common.showError('Error creating workflow for Asset Activity Report', error);
                    this.confirmSubmit.enableAll();
                });
    }

    onDelete() {
        this.common.showConfirm(this.confirmDelete);
    }

    onConfirmDelete() {
        this.confirmDelete.disableAll();

        this.assetActivityReportService.delete(this.model.Pk)
            .subscribe(
                () => {
                    this.common.hideConfirm();
                    this.confirmDelete.enableAll();

                    this.common.showMessage('Successfully deleted Asset Activity Report.');
                    this.goBack();
                },
                error => {
                    this.common.showError('Error deleting Asset Activity Report', error);
                    this.confirmDelete.enableAll();
                }
            );
    }

    onPrint() {
        this.common.showLoader();

        this.assetActivityReportService.print(this.model.Pk)
            .subscribe(
                data => {
                    if (data) {
                        this.common.downloadReport(`${this.model.AssetId}_${this.model.Type}_${moment(this.model.ActivityDate).format('MMDDYYYY')}`, data);
                    }
                    else {
                        this.common.showError('Error printing Expense Report', 'An error occurred printing the Expense Report. Please try again. If the problem persists, contact IT.');
                    }

                    this.common.hideLoader();
                },
                error => {
                    this.common.showError('Error printing Expense Report', error);
                    this.common.hideLoader();
                }
            );
    }
}