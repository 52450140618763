import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    GeneralFieldServiceModel,
    LaborModel,
    SearchCriteriaModel,
    SearchResultModel,
    TimeCardPeriod,
    TimeSheet
} from '../../shared/models';
import { Common } from './common.service';

import * as moment from 'moment';

@Injectable()
export class GeneralFieldService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.LABOR}/api/gfs`;

    data: any = {};

    constructor(private common: Common, private http: HttpClient) { }

    create(model: GeneralFieldServiceModel) {
        return this.http.post<GeneralFieldServiceModel>(this.url + '/create', JSON.stringify(model))
            .pipe(map(res => new GeneralFieldServiceModel(res)));
    }

    update(model: GeneralFieldServiceModel) {
        return this.http.put<GeneralFieldServiceModel>(this.url + '/update', JSON.stringify(model))
            .pipe(map(res => new GeneralFieldServiceModel(res)));
    }

    delete(id: number) {
        return this.http.delete<Boolean>(this.url + `/delete?gfspk=${id}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    get(modelPk: number) {
        return this.http.get<GeneralFieldServiceModel>(this.url + `/get?gfspk=${modelPk}`)
            .pipe(map(res => new GeneralFieldServiceModel(res)));
    }

    getByUserAndDate(userId: string, date: Date) {
        const newDate = moment(date).format('YYYY-MM-DD');
        return this.http.get<GeneralFieldServiceModel[]>(this.url + `/getByUserAndDate?userId=${userId}&date=${newDate}`)
            .pipe(map(res => res ? res.map(x => new GeneralFieldServiceModel(x)) : new Array() as GeneralFieldServiceModel[]));
    }

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<GeneralFieldServiceModel>>(`${this.url}/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new GeneralFieldServiceModel(x));
                }
                return res;
            }));
    }

    getGetTimesheetPeriods(cdate: string) {
        return this.http.get<TimeCardPeriod[]>(this.url + `/getperiods?startdate=${cdate}`)
            .pipe(map(res => res ? res.map(x => new TimeCardPeriod(x)) : new Array() as TimeCardPeriod[]));
    }

    getGetTimesheet(cdate: Date, userid: String, authtype: string) {
        var isodate = new Date(cdate);
        var ndate = new Date(isodate.toISOString().substring(0, 10) + "T22:00:00.000Z").toISOString();

        return this.http.get<TimeSheet[]>(this.url + `/gettimecard?start=${ndate}&requestorid=${userid}&t=${authtype}`);
    }

    sendTimesheet(type: string, reportDate: Date) {
        const date = moment(reportDate).toISOString();

        return this.http.get<Boolean>(this.url + `/sendtime?t=${type}&d=${date}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    getByPeriodAndArea(periodPk: number, area: string) {
        return this.http.get<LaborModel[]>(this.url + `/getbyperiodAndArea?periodPk=${periodPk}&area=${area}`)
            .pipe(map(res => res ? res.map(x => new LaborModel(x)) : new Array() as LaborModel[]));
    }

    getByPeriod(periodPk: number, directReportsOnly: boolean = false) {
        return this.http.get<LaborModel[]>(this.url + `/getbyperiod?periodPk=${periodPk}&directReportsOnly=${directReportsOnly}`)
            .pipe(map(res => res ? res.map(x => new LaborModel(x)) : new Array() as LaborModel[]));
    }

    getByPeriodAndLabor(periodPk: number, laborId: string) {
        return this.http.get<LaborModel>(this.url + `/getByPeriodAndLabor?periodPk=${periodPk}&laborId=${laborId}`)
            .pipe(map(res => new LaborModel(res)));
    }
}