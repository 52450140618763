import { ApplicationUserModel } from './application.model';
import { MacolaBatchModel } from './macola-batch.model';
import { WorkflowModuleModel } from './workflow.model';

import * as moment from 'moment';
import { SparkFileModel } from './spark-file.model';

export class ExpenseReportModel {
    Pk: number = 0;
    Name: string;
    Date: Date;
    Total: number;
    Status: string;
    CorporateCard: boolean;
    MacolaBatchPk: number;
    MacolaBatch: MacolaBatchModel;
    UserPk: number;
    Notes: string;
    AssignedTo: string;
    CreationDate: Date;
    CreatedBy: number;
    CreatedById: string;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    LineItems: ExpenseReportItemModel[] = new Array();
    Creator: ApplicationUserModel;
    ExpensedAs: ApplicationUserModel;
    CanApprove: boolean;
    WorkflowId: string = 'ER';
    Workflows: WorkflowModuleModel[] = new Array();
    Files: SparkFileModel[];

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Name = obj.Name;
        this.Total = obj.Total;
        this.Status = obj.Status;
        this.CorporateCard = obj.CorporateCard;
        this.MacolaBatchPk = obj.MacolaBatchPk;
        this.UserPk = obj.UserPk;
        this.Notes = obj.Notes;
        this.AssignedTo = obj.AssignedTo || '';
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.CreatedById = obj.CreatedById;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.CanApprove = obj.CanApprove;
        this.WorkflowId = obj.WorkflowId;

        if (obj.Date) {
            this.Date = moment(obj.Date).startOf('day').toDate();
        }

        if (obj.MacolaBatch) {
            this.MacolaBatch = new MacolaBatchModel(obj.MacolaBatch);
        }

        if (obj.LineItems) {
            this.LineItems = obj.LineItems.map(x => new ExpenseReportItemModel(x));
        }

        if (obj.Creator) {
            this.Creator = new ApplicationUserModel(obj.Creator);
        }

        if (obj.ExpensedAs) {
            this.ExpensedAs = new ApplicationUserModel(obj.ExpensedAs);
        }

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }

        if (obj.Files) {
            this.Files = obj.Files.map(x => new SparkFileModel(x));
        }
    }
}

export class ExpenseReportItemModel {
    Pk: number = 0;
    ExpenseReportPk: number;
    Date: Date;
    AccountId: string;
    AccountName: string;
    Vendor: string;
    City: string;
    State: string;
    Comments: string;
    AccountingComments: string;
    Amount: number;
    Files: SparkFileModel[];
    NoAttachment: boolean;
    ImageName: string;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    ItemType: string;
    IsOldItem: boolean = false;
    IsIncorrectCategory: boolean = false;
    RowStyleColor: string = "";
    RowStyleWeight: string = "";
    MessageText: string = "";
    Assets: ExpenseReportAssetModel[] = new Array();
    Mileage: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ExpenseReportPk = obj.ExpenseReportPk;
        this.AccountId = obj.AccountId;
        this.AccountName = obj.AccountName;
        this.Vendor = obj.Vendor;
        this.City = obj.City;
        this.State = obj.State;
        this.Comments = obj.Comments;
        this.AccountingComments = obj.AccountingComments;
        this.Amount = obj.Amount;
        this.ImageName = obj.ImageName;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.ItemType = obj.ItemType;
        this.Mileage = obj.Mileage;

        if (obj.Date) {
            this.Date = moment(obj.Date).startOf('day').toDate();

            var todayMinus90 = moment().add(-90, 'day').toDate().getTime();

            this.IsOldItem = this.Date.getTime() < todayMinus90;
            this.IsIncorrectCategory = this.ItemType.toLowerCase() == "personal_vehicle" && this.AccountName.toLowerCase().indexOf('employee expense') < 0;

            this.RowStyleColor = this.IsOldItem || this.IsIncorrectCategory ? "red" : "";
            this.RowStyleWeight = this.IsOldItem || this.IsIncorrectCategory ? "bold" : "";

            if (this.IsOldItem)
                this.MessageText += "This item is older than 90 days";

            if (this.IsIncorrectCategory)
                this.MessageText += this.IsOldItem ? " and the" : "The" + " category needs to be checked to make sure it is correct";
        }

        if (obj.Assets) {
            this.Assets = obj.Assets.map(x => new ExpenseReportAssetModel(x));
        }

        if (obj.Files) {
            this.Files = obj.Files.map(x => new SparkFileModel(x));
            this.NoAttachment = this.Files.length == 0;
        }
    }

    getAllComments() {
        if (this.AccountingComments) {
            return `${this.Comments}\n${this.AccountingComments}`;
        }

        return this.Comments;
    }
}

export class ExpenseReportAssetModel {
    Pk: number;
    ExpenseReportItemPk: number;
    AreaId: string;
    AssetId: string;
    Amount: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ExpenseReportItemPk = obj.ExpenseReportItemPk;
        this.AreaId = obj.AreaId;
        this.AssetId = obj.AssetId;
        this.Amount = obj.Amount;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
    }
}

export class ExpenseReportProcessedModel {
    Date: string;
    AccountCode: string;
    Description: string;
    DebitAmount: number;
    CreditAmount: number = 0;
    CostCenter: string;
    CostUnit: string;
    InvoiceNumber: string;
    CustomerSupplierAccount: string;
    AccountType: string;

    constructor(obj?: any) {
        if (obj = null) {
            return;
        }

        this.Date = obj.Date;
        this.AccountCode = obj.AccountCode;
        this.Description = obj.Description;
        this.DebitAmount = obj.DebitAmount;
        this.CreditAmount = obj.CreditAmount;
        this.CostCenter = obj.CostCenter;
        this.CostUnit = obj.CostUnit;
        this.InvoiceNumber = obj.InvoiceNumber;
        this.CustomerSupplierAccount = obj.CustomerSupplierAccount;
        this.AccountType = obj.AccountType;
    }
}
