import { MessageModel } from './message.model';
import { ModalFooterModel } from './modal-footer.model';

export class ConfirmModel {
    Content: MessageModel = new MessageModel();
    Footer: ModalFooterModel = new ModalFooterModel();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Content = new MessageModel({
            Text: obj.Text,
            Title: 'Confirm'
        });

        this.Footer = new ModalFooterModel({
            ConfirmText: 'Yes',
            CloseText: 'No',
            DisableConfirm: false,
            onConfirm: () => { obj.onConfirm() }
        });
    }

    disableAll(isLoading: boolean = true) {
        this.Footer.disableAll(isLoading);
    }

    enableAll(isLoading: boolean = false) {
        this.Footer.enableAll(isLoading);
    }
}
