import * as moment from 'moment';

export class LaborTypeModel {
    Pk: number;
    Id: string = null;
    Name: string = null;
    CategoryCode: string = null;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Id = obj.Id;
        this.Name = obj.Name;
        this.CategoryCode = obj.CategoryCode;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
    }
}
