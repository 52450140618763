<div id="dataflow-tabs" *ngIf="mode == 'tab'">
    <form #dataflowForm="ngForm" novalidate autocomplete="off">
        <div class="form-row" *ngIf="!model.Pk">
            <div class="form-group col-12" *ngIf="dataflowTypes && dataflowTypes.length">
                <label for="Type">{{dataflowTypes[0].Label}}:</label>

                <select [(ngModel)]="selectedType" class="form-control" id="Type" name="Type" required (ngModelChange)="onSelectedType()">
                    <option value="" *ngIf="!selectedType"></option>
                    <option *ngFor="let dataflow of dataflowTypes" [value]="dataflow.Pk">{{dataflow.Name}}</option>
                </select>
            </div>

            <div class="form-group col-12" *ngIf="dataflowTypes && dataflowTypes.length">
                <label for="SubType">{{dataflowTypes[0].Dataflows[0].Label}}:</label>

                <select [(ngModel)]="selectedSubType" class="form-control" id="SubType" name="SubType" required (ngModelChange)="onSelectedSubType()">
                    <option value="" *ngIf="!selectedSubType"></option>
                    <option *ngFor="let dataflow of dataflows" [value]="dataflow.Pk">{{dataflow.Name}}</option>
                </select>
            </div>
        </div>

        <tabset *ngIf="tabs.length">
            <tab *ngFor="let tab of tabs; let x = index" [heading]="tab.Title">
                <div class="form-row" *ngIf="x == 0 && model.Pk && dataflowTypes && dataflowTypes.length">
                    <div class="form-group col-6">
                        <label>{{dataflowTypes[0].Label}}:</label>
                        <div>{{getDataflowType()}}</div>
                    </div>

                    <div class="form-group col-6">
                        <label>{{dataflowTypes[0].Dataflows[0].Label}}:</label>
                        <div>{{getDataflow()}}</div>
                    </div>

                    <div class="form-group" [class.col-6]="model['WOID'] || model['WOPK']" [class.col-12]="!model['WOID'] && !model['WOPK']">
                        <label>Status:</label>
                        <div>{{common.getPendingStatus(model) | titlecase}}</div>
                    </div>

                    <div class="form-group col-6" *ngIf="model['WOID'] || model['WOPK']">
                        <label>Work Order #:</label>
                        <div>{{model['WOID'] || model['WOPK']}}</div>
                    </div>
                </div>

                <div class="form-row">
                    <ng-template ngFor let-field [ngForOf]="tab.Fields">
                        <div class="form-group" [class.col-sm-6]="tab.Fields.length > 2" [class.col-sm-12]="tab.Fields.length <= 2" [class.d-none]="field.Type == 'hidden'" *ngIf="!field.Options?.Hidden">
                            <label [htmlFor]="field.Id" *ngIf="field.Type != 'filelist' && field.Type != 'bitlist'">
                                {{field.Label}}<span *ngIf="field.Label.substr(-1) != '?'">:</span>
                            </label>

                            <input [(ngModel)]="model[field.Id]" [id]="field.Id" type="text" class="form-control" readonly [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'label'" [disabled]="field.Options?.Disabled">

                            <select [(ngModel)]="model[field.Id]" class="form-control" [id]="field.Id" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'select'" (ngModelChange)="onSelect(field)" [disabled]="checkReadonly(field) || field.Options?.Disabled">
                                <option value="" *ngIf="!model[field.Id]"></option>
                                <option *ngFor="let opt of selectOptions[field.Id]" [value]="opt.Value">{{opt.Text}}</option>
                            </select>

                            <input [(ngModel)]="model[field.Id]" [id]="field.Id" type="text" class="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'text'" (ngModelChange)="onModelChanged(field)" [readonly]="checkReadonly(field)" [disabled]="field.Options?.Disabled">

                            <input [(ngModel)]="model[field.Id]" [id]="field.Id" type="number" class="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'number'" (ngModelChange)="onModelChanged(field)" [readonly]="checkReadonly(field)" [disabled]="field.Options?.Disabled">

                            <input [(ngModel)]="selectedTypeahead[field.Id]" [id]="field.Id" type="text" class="form-control" [class.is-invalid]="checkCustomValue(field)" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'autocomplete'" (typeaheadOnSelect)="onTypeaheadSelect(field, $event)" [typeahead]="typeaheads[field.Options.DataSource]" [typeaheadOptionField]="field.Options.FieldName" [typeaheadScrollable]="true" (ngModelChange)="onTypeaheadChange(field)" [readonly]="checkReadonly(field)" [disabled]="field.Options?.Disabled" (blur)="validateTypeahead(field)">
                            <div class="form-text invalid-feedback" *ngIf="field.Type == 'autocomplete' && checkCustomValue(field)">{{field.Label}} not found.</div>

                            <textarea [(ngModel)]="model[field.Id]" [id]="field.Id" class="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" autoGrow *ngIf="field.Type == 'textarea'" [readonly]="checkReadonly(field)" [disabled]="field.Options?.Disabled" (ngModelChange)="onModelChanged(field)"></textarea>

                            <div *ngIf="field.Type == 'bit' || field.Type == 'checkbox'">
                                <label class="switch switch-success switch-lg" [class.switch-text]="field.Options.Checked" [class.switch-default]="!field.Options.Checked">
                                    <input [(ngModel)]="model[field.Id]" [id]="field.Id" class="switch-input" type="checkbox" [required]="field.Required" [ngModelOptions]="{standalone: true}" (ngModelChange)="onModelChanged(field)" [disabled]="checkReadonly(field) || field.Options?.Disabled">
                                    <span class="switch-label" [attr.data-on]="field.Options.Checked" [attr.data-off]="field.Options.Unchecked"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>

                            <p-calendar [(ngModel)]="model[field.Id]" [id]="field.Id" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'date'" (ngModelChange)="onModelChanged(field)" [disabled]="checkReadonly(field) || field.Options?.Disabled"></p-calendar>

                            <p-calendar [(ngModel)]="model[field.Id]" [id]="field.Id" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'datetime'" (ngModelChange)="onModelChanged(field)" [disabled]="checkReadonly(field) || field.Options?.Disabled" [defaultDate]="defaultDateTime" showTime="true" hourFormat="12" #dateTimeRef="ngModel" [hideOnDateTimeSelect]="false" [stepMinute]="field.Options?.StepMinute || 1">
                                <p-footer>
                                    <div class="p-datepicker-buttonbar text-right">
                                        <button class="btn btn-secondary" (click)="common.closeCalendar(dateTimeRef)">
                                            <i class="fas fa-times-circle"></i> <span class="ml-2">Close</span>
                                        </button>
                                    </div>
                                </p-footer>
                            </p-calendar>

                            <div class="form-row bitlist" *ngIf="field.Type == 'bitlist'">
                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="text" name="BitListSearch" placeholder="Search" class="form-control" [(ngModel)]="searchText[field.Id]" (ngModelChange)="onApplyFilter(field)" />
                                    </div>
                                </div>

                                <div class="col-12" *ngIf="!checkReadonly(field)">
                                    <div class="form-group">
                                        <label [htmlFor]="'SelectAll' + field.Id">Select All</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="selectAll[field.Id]" [id]="'SelectAll' + field.Id" class="switch-input" type="checkbox" [ngModelOptions]="{standalone: true}" (ngModelChange)="onBitListSelectAll(field)">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-striped stack">
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th *ngFor="let column of field.Options.Mapping">
                                                    {{column.Label}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of pagedResults[field.Id]; let i = index">
                                                <td>
                                                    <label class="switch switch-default switch-success switch-lg">
                                                        <input [(ngModel)]="item.Selected" [id]="field.Id + i" class="switch-input" type="checkbox" [ngModelOptions]="{standalone: true}" (ngModelChange)="onBitListChange(field, item, i)" [disabled]="checkReadonly(field)">
                                                        <span class="switch-label"></span>
                                                        <span class="switch-handle"></span>
                                                    </label>
                                                </td>
                                                <td *ngFor="let column of field.Options.Mapping">
                                                    {{item[column.FieldName]}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-sm-4">
                                    <div><strong>{{field.Label}} selected:</strong> {{selectedBitList[field.Id]}}</div>
                                    <div>{{field.Label}} available: {{filteredResults[field.Id].length}}</div>
                                </div>

                                <div class="col-sm-8 justify-content-center">
                                    <pagination [totalItems]="filteredResults[field.Id].length" (pageChanged)="onSetPage(field, $event.page)" [maxSize]="5"
                                        [rotate]="true" [(ngModel)]="pageStart" [boundaryLinks]="true" name="BitListPagination"
                                        [customFirstTemplate]="firstTemplate"
                                        [customLastTemplate]="lastTemplate"
                                        [customPreviousTemplate]="previousTemplate"
                                        [customNextTemplate]="nextTemplate"
                                    ></pagination>

                                    <ng-template #firstTemplate>
                                        <i class="fas fa-angle-double-left"></i>
                                    </ng-template>

                                    <ng-template #previousTemplate>
                                        <i class="fas fa-angle-left"></i> <span class="d-none d-md-inline ml-1">Previous</span>
                                    </ng-template>

                                    <ng-template #nextTemplate>
                                        <span class="d-none d-md-inline mr-2">Next</span> <i class="fas fa-angle-right"></i>
                                    </ng-template>

                                    <ng-template #lastTemplate>
                                        <i class="fas fa-angle-double-right"></i>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="form-row filelist" *ngIf="field.Type == 'filelist'">
                                <div class="form-group col-sm-7" *ngIf="!checkReadonly(field)">
                                    <div class="custom-file">
                                        <input [id]="field.Id" type="file" class="custom-file-input" (change)="onFileAttached(field.Id, $event)" [disabled]="uploading">
                                        <label class="custom-file-label" for="FileInput">
                                            <span>{{selectedFile[field.Id] ? selectedFile[field.Id].name : 'Choose file'}}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group col-sm-5 text-right" *ngIf="!checkReadonly(field)">
                                    <button class="btn btn-secondary" [disabled]="!selectedFile[field.Id] || uploading" (click)="onClearFile(field)">
                                        <i class="fas fa-times-circle"></i> <span class="ml-2">Clear</span>
                                    </button>
                                    <button class="btn btn-success" [disabled]="!selectedFile[field.Id] || uploading" (click)="onAddFile(field)">
                                        <i class="fas fa-plus-circle"></i> <span class="ml-2">Add</span>
                                    </button>
                                </div>

                                <div class="table-responsive" *ngIf="model[field.Id] && model[field.Id].length">
                                    <table class="table table-striped stack mb-0 mt-4" [class.mt-4]="!checkReadonly(field)">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th class="actions">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let file of model[field.Id]; let i = index">
                                                <td><span class="d-inline d-md-none">Name:</span> {{file.Description}}</td>
                                                <td class="actions">
                                                    <spark-file [model]="file" [status]="model.Status" *ngIf="file.Pk"></spark-file>

                                                    <button class="btn btn-sm btn-secondary" tooltip="Stop Upload" (click)="onCancelUpload(file, field, i)" *ngIf="!showFileActions(field, i) && !checkReadonly(field)">
                                                        <i class="fas fa-ban"></i> <span class="d-inline d-md-none ml-2">Stop Upload</span>
                                                    </button>
                                                    <button class="btn btn-sm btn-danger" tooltip="Delete" (click)="onDeleteFile(file, field, i)" *ngIf="showFileActions(field, i) && !checkReadonly(field)">
                                                        <i class="fas fa-times-circle"></i> <span class="d-inline d-md-none ml-2">Delete</span>
                                                    </button>
                                                </td>

                                                <progressbar [value]="uploadProgress[field.Id + i]" type="success" *ngIf="uploadProgress[field.Id + i] > 0 && uploadProgress[field.Id + i] < 100"></progressbar>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <validation-message [isValid]="invalid[field.Id]">
                                {{field.Label}} is required.
                            </validation-message>
                        </div>
                    </ng-template>
                </div>

            </tab>
        </tabset>
    </form>
</div>

<modal id="dataflow-modal" *ngIf="mode == 'wizard'">
    <header>{{stepTitle}}</header>

    <progressbar [value]="getProgress()"></progressbar>

    <form #dataflowForm="ngForm" novalidate autocomplete="off">
        <spark-carousel [activeItem]="currentStep - 1">
            <spark-carousel-item>
                <div class="form-row">
                    <div class="form-group col-12" *ngIf="dataflowTypes && dataflowTypes.length && showType">
                        <label for="Type">{{dataflowTypes[0].Label}}:</label>

                        <select [(ngModel)]="selectedType" class="form-control" id="Type" name="Type" required (ngModelChange)="onSelectedType()">
                            <option value="" *ngIf="!selectedType"></option>
                            <option *ngFor="let dataflow of dataflowTypes" [value]="dataflow.Pk">{{dataflow.Name}}</option>
                        </select>
                    </div>

                    <div class="form-group col-12" *ngIf="dataflowTypes && dataflowTypes.length">
                        <label for="SubType">{{dataflowTypes[0].Dataflows[0].Label}}:</label>

                        <select [(ngModel)]="selectedSubType" class="form-control" id="SubType" name="SubType" required (ngModelChange)="onSelectedSubType()">
                            <option value="" *ngIf="!selectedSubType"></option>
                            <option *ngFor="let dataflow of dataflows" [value]="dataflow.Pk">{{dataflow.Name}}</option>
                        </select>
                    </div>
                </div>
            </spark-carousel-item>

            <spark-carousel-item *ngFor="let step of steps">
                <ng-template ngFor let-field [ngForOf]="step.Fields">
                    <div class="form-row" [class.d-none]="field.Type == 'hidden'" *ngIf="!field.Options?.Hidden">
                        <div class="form-group col-12">
                            <label [htmlFor]="field.Id" *ngIf="field.Type != 'filelist' && field.Type != 'bitlist'">
                                {{field.Label}}<span *ngIf="field.Label.substr(-1) != '?'">:</span>
                            </label>

                            <input [(ngModel)]="model[field.Id]" [id]="field.Id" type="text" class="form-control-plaintext" readonly [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'label'" [disabled]="field.Options?.Disabled">

                            <select [(ngModel)]="model[field.Id]" class="form-control" [id]="field.Id" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'select'" (ngModelChange)="onSelect(field)" [disabled]="field.Options?.Disabled">
                                <option value="" *ngIf="!model[field.Id]"></option>
                                <option *ngFor="let opt of selectOptions[field.Id]" [value]="opt.Value">{{opt.Text}}</option>
                            </select>

                            <input [(ngModel)]="model[field.Id]" [id]="field.Id" type="text" class="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'text'" (ngModelChange)="onModelChanged(field)" [disabled]="field.Options?.Disabled">

                            <input [(ngModel)]="model[field.Id]" [id]="field.Id" type="number" class="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'number'" (ngModelChange)="onModelChanged(field)" [disabled]="field.Options?.Disabled">

                            <input [(ngModel)]="selectedTypeahead[field.Id]" [id]="field.Id" type="text" class="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'autocomplete'" (typeaheadOnSelect)="onTypeaheadSelect(field, $event)" [typeahead]="typeaheads[field.Options.DataSource]" [typeaheadOptionField]="field.Options.FieldName" [typeaheadScrollable]="true" (ngModelChange)="onTypeaheadChange(field)" [disabled]="field.Options?.Disabled" (blur)="validateTypeahead(field)">

                            <textarea [(ngModel)]="model[field.Id]" [id]="field.Id" class="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" autoGrow *ngIf="field.Type == 'textarea'" [disabled]="field.Options?.Disabled" (ngModelChange)="onModelChanged(field)"></textarea>

                            <div *ngIf="field.Type == 'bit' || field.Type == 'checkbox'">
                                <label class="switch switch-success switch-lg" [class.switch-text]="field.Options.Checked" [class.switch-default]="!field.Options.Checked">
                                    <input [(ngModel)]="model[field.Id]" [id]="field.Id" class="switch-input" type="checkbox" [required]="field.Required" [ngModelOptions]="{standalone: true}" (ngModelChange)="onModelChanged(field)" [disabled]="checkReadonly(field) || field.Options?.Disabled">
                                    <span class="switch-label" [attr.data-on]="field.Options.Checked" [attr.data-off]="field.Options.Unchecked"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>

                            <p-calendar [(ngModel)]="model[field.Id]" [id]="field.Id" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'date'" (ngModelChange)="onModelChanged(field)" [disabled]="field.Options?.Disabled"></p-calendar>

                            <p-calendar [(ngModel)]="model[field.Id]" [id]="field.Id" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="field.Required" [ngModelOptions]="{standalone: true}" *ngIf="field.Type == 'datetime'" (ngModelChange)="onModelChanged(field)" [disabled]="checkReadonly(field) || field.Options?.Disabled" [defaultDate]="defaultDateTime" showTime="true" hourFormat="12" #dateTimeRef="ngModel" [hideOnDateTimeSelect]="false" [stepMinute]="field.Options?.StepMinute || 1">
                                <p-footer>
                                    <div class="p-datepicker-buttonbar text-right">
                                        <button class="btn btn-secondary" (click)="common.closeCalendar(dateTimeRef)">
                                            <i class="fas fa-times-circle"></i> <span class="ml-2">Close</span>
                                        </button>
                                    </div>
                                </p-footer>
                            </p-calendar>

                            <div class="form-row bitlist" *ngIf="field.Type == 'bitlist'">
                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="text" name="BitListSearch" placeholder="Search" class="form-control" [(ngModel)]="searchText[field.Id]" (ngModelChange)="onApplyFilter(field)" />
                                    </div>
                                </div>

                                <div class="col-12" *ngIf="!checkReadonly(field)">
                                    <div class="form-group">
                                        <label [htmlFor]="'SelectAll' + field.Id">Select All</label>
                                        <div>
                                            <label class="switch switch-default switch-success switch-lg">
                                                <input [(ngModel)]="selectAll[field.Id]" [id]="'SelectAll' + field.Id" class="switch-input" type="checkbox" [ngModelOptions]="{standalone: true}" (ngModelChange)="onBitListSelectAll(field)">
                                                <span class="switch-label"></span>
                                                <span class="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-striped stack">
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th *ngFor="let column of field.Options.Mapping">
                                                    {{column.Label}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of pagedResults[field.Id]; let i = index">
                                                <td>
                                                    <label class="switch switch-default switch-success switch-lg">
                                                        <input [(ngModel)]="item.Selected" [id]="field.Id + i" class="switch-input" type="checkbox" [ngModelOptions]="{standalone: true}" (ngModelChange)="onBitListChange(field, item, i)">
                                                        <span class="switch-label"></span>
                                                        <span class="switch-handle"></span>
                                                    </label>
                                                </td>
                                                <td *ngFor="let column of field.Options.Mapping">
                                                    {{item[column.FieldName]}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-sm-4">
                                    <div><strong>{{field.Label}} selected:</strong> {{selectedBitList[field.Id]}}</div>
                                    <div>{{field.Label}} available: {{filteredResults[field.Id].length}}</div>
                                </div>

                                <div class="col-sm-8 justify-content-center">
                                    <pagination [totalItems]="filteredResults[field.Id].length" (pageChanged)="onSetPage(field, $event.page)" [maxSize]="5"
                                        [rotate]="true" [(ngModel)]="pageStart" [boundaryLinks]="true" name="BitListPagination"
                                        [customFirstTemplate]="firstTemplate"
                                        [customLastTemplate]="lastTemplate"
                                        [customPreviousTemplate]="previousTemplate"
                                        [customNextTemplate]="nextTemplate"
                                    ></pagination>

                                    <ng-template #firstTemplate>
                                        <i class="fas fa-angle-double-left"></i>
                                    </ng-template>

                                    <ng-template #previousTemplate>
                                        <i class="fas fa-angle-left"></i> <span class="d-none d-md-inline ml-1">Previous</span>
                                    </ng-template>

                                    <ng-template #nextTemplate>
                                        <span class="d-none d-md-inline mr-2">Next</span> <i class="fas fa-angle-right"></i>
                                    </ng-template>

                                    <ng-template #lastTemplate>
                                        <i class="fas fa-angle-double-right"></i>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="form-row filelist" *ngIf="field.Type == 'filelist'">
                                <div class="form-group col-sm-7">
                                    <div class="custom-file">
                                        <input [id]="field.Id" type="file" class="custom-file-input" (change)="onFileAttached(field.Id, $event)" [disabled]="uploading">
                                        <label class="custom-file-label" for="FileInput">
                                            <span>{{selectedFile[field.Id] ? selectedFile[field.Id].name : 'Choose file'}}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group col-sm-5 text-right">
                                    <button class="btn btn-secondary" [disabled]="!selectedFile[field.Id] || uploading" (click)="onClearFile(field)">
                                        <i class="fas fa-times-circle"></i> <span class="ml-2">Clear</span>
                                    </button>
                                    <button class="btn btn-success" [disabled]="!selectedFile[field.Id] || uploading" (click)="onAddFile(field)">
                                        <i class="fas fa-plus-circle"></i> <span class="ml-2">Add</span>
                                    </button>
                                </div>

                                <div class="table-responsive" *ngIf="model[field.Id] && model[field.Id].length">
                                    <table class="table table-striped stack mb-0 mt-4">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th class="actions">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let file of model[field.Id]; let i = index">
                                                <td><span class="d-inline d-md-none">Name:</span> {{file.Description}}</td>
                                                <td class="actions">
                                                    <spark-file [model]="file" [status]="model.Status" *ngIf="file.Pk"></spark-file>

                                                    <button class="btn btn-sm btn-secondary" tooltip="Stop Upload" (click)="onCancelUpload(file, field, i)" *ngIf="!showFileActions(field, i)">
                                                        <i class="fas fa-ban"></i> <span class="d-inline d-md-none ml-2">Stop Upload</span>
                                                    </button>
                                                    <button class="btn btn-sm btn-danger" tooltip="Delete" (click)="onDeleteFile(file, field, i)" *ngIf="showFileActions(field, i)">
                                                        <i class="fas fa-times-circle"></i> <span class="d-inline d-md-none ml-2">Delete</span>
                                                    </button>
                                                </td>

                                                <progressbar [value]="uploadProgress[field.Id + i]" type="success" *ngIf="uploadProgress[field.Id + i] > 0 && uploadProgress[field.Id + i] < 100"></progressbar>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <validation-message [isValid]="invalid[field.Id]">
                                {{field.Label}} is required.
                            </validation-message>
                        </div>
                    </div>
                </ng-template>
            </spark-carousel-item>
        </spark-carousel>
    </form>

    <modal-footer>
        <button class="btn btn-secondary" (click)="onPrevStep()" [disabled]="currentStep == 1 || (currentStep == totalSteps && formFooter.DisableClose) || (currentStep == 2 && !hasSelectionStep)">
            <i class="fas fa-angle-left mr-1"></i> Back
        </button>

        <button class="btn btn-secondary" (click)="onNextStep()" [disabled]="formFooter.DisableConfirm">
            {{currentStep == totalSteps ? 'Finish' : 'Next'}} <i class="fas fa-angle-right ml-1"></i>
        </button>

        <span class="ml-2">
            <loader [visible]="formFooter.IsLoading"></loader>
        </span>

        <button class="btn btn-secondary ml-auto" (click)="onResetForm()" [disabled]="formFooter.DisableClose">
            <i class="fas fa-ban mr-1"></i> Cancel
        </button>
    </modal-footer>
</modal>