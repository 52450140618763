import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../shared/global';
import { Common } from './common.service';
import { map } from 'rxjs/operators';
import { PayrollExportModel, PayrollExportUserModel } from '../../shared/models/payroll-export.model';
import { ReportModel } from '../../shared/models';

import * as moment from 'moment';

@Injectable()
export class PayrollExportService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/payroll-export`;

    constructor(private common: Common, private http: HttpClient) {}

    get(modelPk: number) {
        return this.http.get(`${this.url}/get/${modelPk}`)
            .pipe(
                map(res => new PayrollExportModel(res))
            );
    }

    search(view: string) {
        return this.http.get<PayrollExportModel[]>(`${this.url}/search/${view}`)
            .pipe(
                map(res => res ? res.map(x => new PayrollExportModel(x)) : new Array() as PayrollExportModel[])
            );
    }

    savePayrollExport(model: PayrollExportModel) {
        if (model.Pk) {
            return this.http.put<PayrollExportModel>(`${this.url}/update`, model)
                .pipe(
                    map(res => new PayrollExportModel(res))
                );
        }

        return this.http.post<PayrollExportModel>(`${this.url}/create`, model)
            .pipe(
                map(res => new PayrollExportModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete/${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    getPayrollSummary(timeCardPeriodPk: number, userId?: string) {
        return this.http.get<PayrollExportUserModel[]>(`${this.url}/getPayrollSummary/${timeCardPeriodPk}/${userId ? userId : "all"}`);
    }

    print(reportType: string, timeCardPeriodPk: number, startDate: Date, endDate: Date) {
        return this.http.get<ReportModel>(`${this.url}/print/${reportType}/${timeCardPeriodPk}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`);
    }
}
