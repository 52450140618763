<modal id="fluids-request-history" class="modal-lg">
    <header>Fluids Request History for Unit {{assetId}}</header>

    <pagination-container [results]="models" (onPaged)="filteredResults = $event" [storageKey]="storageKey">
        <div class="table-responsive" *ngIf="models.length && !modalFooter.IsLoading">
            <table class="table table-striped stack" sort>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>FR ID</th>
                        <th>Type</th>
                        <th>Vendor</th>
                        <th>Date Needed</th>
                        <th>Is Urgent?</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-item [ngForOf]="filteredResults" let-in="index" let-rowClass="index % 2 == 0 ? 'row-even' : 'row-odd'">
                        <tr class="pointer" [ngClass]="rowClass" (click)="item.Expanded = !item.Expanded;">
                            <td>
                                <span class="fas pointer" [class.fa-minus-square]="item.Expanded" [class.fa-plus-square]="!item.Expanded"></span>
                            </td>
                            <td>{{item.FluidsRequestPk}}</td>
                            <td>{{item.FluidsRequest.Type.Name}}</td>
                            <td>{{item.FluidsRequest.VendorName}}</td>
                            <td>
                                {{item.FluidsRequest.DeliveryDate | date: 'MM/dd/yyyy'}}
                                <span *ngIf="item.FluidsRequest.Type.Type != 'FillTanks'">{{item.FluidsRequest.DeliveryDate | date: 'h:mm a'}}</span>
                            </td>
                            <td>
                                <span *ngIf="item.FluidsRequest.Type.Type != 'FillTanks'">{{item.FluidsRequest.IsUrgent ? 'Yes' : 'No'}}</span>
                            </td>
                            <td>{{item.FluidsRequest.Status | titlecase}}</td>
                        </tr>
                        <tr *ngIf="item.Expanded" [ngClass]="rowClass">
                            <td colspan="8" class="p-0">
                                <div class="card p-3 m-0" *ngIf="item.FluidsRequest.Type.Type == 'NewTank'">
                                    <div class="form-row">
                                        <div class="form-group col-sm-4">
                                            <label>Vendor:</label>
                                            <div>{{item.FluidsRequest.VendorName}}</div>
                                        </div>

                                        <div class="form-group col-sm-8">
                                            <label>Date Needed:</label>
                                            <div>{{item.FluidsRequest.DeliveryDate | date: 'MM/dd/yyyy h:mm a'}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Latitude Coordinate:</label>
                                            <div>{{item.FluidsRequest.Latitude}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Longitude Coordinate:</label>
                                            <div>{{item.FluidsRequest.Longitude}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Directions:</label>
                                            <div>{{item.FluidsRequest.Directions}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Comments:</label>
                                            <div>{{item.FluidsRequest.Comments}}</div>
                                        </div>
                                    </div>

                                    <table class="table table-striped stack mb-0">
                                        <thead>
                                            <tr class="row-odd">
                                                <th>Fluid Type</th>
                                                <th>Tank Capacity</th>
                                                <th>Stand Height</th>
                                                <th>Order Quantity</th>
                                                <th>Product</th>
                                                <th>PO #</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let type of item.Types">
                                                <td>{{type.Type.Description}}</td>
                                                <td>{{type.Capacity}}</td>
                                                <td>{{type.StandHeight?.Description}}</td>
                                                <td>{{type.Quantity}}</td>
                                                <td>{{type.ProductName}}</td>
                                                <td>{{type.PurchaseOrderNumber}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="card p-3 m-0" *ngIf="item.FluidsRequest.Type.Type == 'FillTanks'">
                                    <div class="form-row">
                                        <div class="form-group col-sm-4">
                                            <label>Date Needed:</label>
                                            <div>{{item.FluidsRequest.DeliveryDate | date: 'MM/dd/yyyy'}}</div>
                                        </div>
                                    </div>

                                    <table class="table table-striped stack mb-0">
                                        <thead>
                                            <tr class="row-odd">
                                                <ng-template ngFor let-type [ngForOf]="item.Types">
                                                    <th>Gallons of {{type.Type.Description}}</th>
                                                    <th>PO #</th>
                                                </ng-template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="row-odd">
                                                <ng-template ngFor let-type [ngForOf]="item.Types">
                                                    <td>{{type.Quantity || 0}}</td>
                                                    <td>{{type.PurchaseOrderNumber}}</td>
                                                </ng-template>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="card p-3 m-0" *ngIf="item.FluidsRequest.Type.Type == 'MoveTank'">
                                    <div class="form-row">
                                        <div class="form-group col-sm-4">
                                            <label>Old Location:</label>
                                            <div>{{item.FromLocation}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>New Location:</label>
                                            <div>{{item.ToLocation}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Vendor:</label>
                                            <div>{{item.FluidsRequest.VendorName}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Latitude Coordinate:</label>
                                            <div>{{item.FluidsRequest.Latitude}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Longitude Coordinate:</label>
                                            <div>{{item.FluidsRequest.Longitude}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Date Needed:</label>
                                            <div>{{item.FluidsRequest.DeliveryDate | date: 'MM/dd/yyyy h:mm a'}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Directions:</label>
                                            <div>{{item.FluidsRequest.Directions}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Comments:</label>
                                            <div>{{item.FluidsRequest.Comments}}</div>
                                        </div>
                                    </div>

                                    <table class="table table-striped stack mb-0">
                                        <thead>
                                            <tr class="row-odd">
                                                <th>Fluid Type</th>
                                                <th>Tank Capacity</th>
                                                <th>Order Quantity</th>
                                                <th>PO #</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let type of item.Types">
                                                <td>{{type.Type.Description}}</td>
                                                <td>{{type.Capacity}}</td>
                                                <td>{{type.Quantity}}</td>
                                                <td>{{type.PurchaseOrderNumber}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="card p-3 m-0" *ngIf="item.FluidsRequest.Type.Type == 'ShutdownTank'">
                                    <div class="form-row">
                                        <div class="form-group col-sm-4">
                                            <label>Vendor:</label>
                                            <div>{{item.FluidsRequest.VendorName}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Date Needed:</label>
                                            <div>{{item.FluidsRequest.DeliveryDate | date: 'MM/dd/yyyy h:mm a'}}</div>
                                        </div>
                                    </div>

                                    <div class="form-row" *ngFor="let type of item.Types">
                                        <div class="form-group col-sm-4">
                                            <label>
                                                Are you shutting down {{['a','e','i','o','u'].indexOf(type.Type.Name[0].toLowerCase()) < 0 ? 'a' : 'an'}} {{type.Type.Description.toLowerCase()}} tank?
                                            </label>
                                            <div>
                                                <label class="switch switch-text switch-success switch-lg">
                                                    <input [(ngModel)]="item.FluidsRequest['Need' + type.Type.Name]" [name]="'Need' + type.Type.Name" class="switch-input" type="checkbox" [disabled]="true">
                                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                                    <span class="switch-handle"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Current Reading Level:</label>
                                            <div>{{type.CurrentLevel}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>PO #:</label>
                                            <div>{{type.PurchaseOrderNumber}}</div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-sm-12">
                                            <label>Directions:</label>
                                            <div>{{item.FluidsRequest.Directions}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Comments:</label>
                                            <div>{{item.FluidsRequest.Comments}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card p-3 m-0" *ngIf="item.FluidsRequest.Type.Type == 'EmergencyFill'">
                                    <div class="form-row">
                                        <div class="form-group col-sm-4">
                                            <label>Vendor:</label>
                                            <div>{{item.FluidsRequest.VendorName}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Date Needed:</label>
                                            <div>{{item.FluidsRequest.DeliveryDate | date: 'MM/dd/yyyy h:mm a'}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Directions:</label>
                                            <div>{{item.FluidsRequest.Directions}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Comments:</label>
                                            <div>{{item.FluidsRequest.Comments}}</div>
                                        </div>
                                    </div>

                                    <table class="table table-striped stack mb-0">
                                        <thead>
                                            <tr class="row-odd">
                                                <th>Fluid Type</th>
                                                <th>Tank Capacity</th>
                                                <th>Stand Height</th>
                                                <th>Order Quantity</th>
                                                <th>Product</th>
                                                <th>PO #</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let type of item.Types">
                                                <td>{{type.Type.Description}}</td>
                                                <td>{{type.Capacity}}</td>
                                                <td>{{type.StandHeight?.Description}}</td>
                                                <td>{{type.Quantity}}</td>
                                                <td>{{type.ProductName}}</td>
                                                <td>{{type.PurchaseOrderNumber}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="card p-3 m-0" *ngIf="item.FluidsRequest.Type.Type == 'OilOnly'">
                                    <div class="form-row">
                                        <div class="form-group col-sm-4">
                                            <label>Vendor:</label>
                                            <div>{{item.FluidsRequest.VendorName}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Date Needed:</label>
                                            <div>{{item.FluidsRequest.DeliveryDate | date: 'MM/dd/yyyy h:mm a'}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>PO #:</label>
                                            <div>{{item.Types[0].PurchaseOrderNumber}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Order Quantity:</label>
                                            <div>{{item.Types[0].Quantity}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Product:</label>
                                            <div>{{item.Types[0].ProductName}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Directions:</label>
                                            <div>{{item.FluidsRequest.Directions}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Comments:</label>
                                            <div>{{item.FluidsRequest.Comments}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card p-3 m-0" *ngIf="item.FluidsRequest.Type.Type == 'SwapUnit'">
                                    <div class="form-row">
                                        <div class="form-group col-sm-4">
                                            <label>Old Location:</label>
                                            <div>{{item.FromLocation}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>New Location:</label>
                                            <div>{{item.ToLocation}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Vendor:</label>
                                            <div>{{item.FluidsRequest.VendorName}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Latitude Coordinate:</label>
                                            <div>{{item.FluidsRequest.Latitude}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Longitude Coordinate:</label>
                                            <div>{{item.FluidsRequest.Longitude}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Date Needed:</label>
                                            <div>{{item.FluidsRequest.DeliveryDate | date: 'MM/dd/yyyy h:mm a'}}</div>
                                        </div>
                                    </div>

                                    <div class="form-row" *ngFor="let type of item.Types">
                                        <div class="form-group col-sm-4">
                                            <label>
                                                Are you swapping out {{['a','e','i','o','u'].indexOf(type.Type.Name[0].toLowerCase()) < 0 ? 'a' : 'an'}} {{type.Type.Description.toLowerCase()}} tank?
                                            </label>
                                            <div>
                                                <label class="switch switch-text switch-success switch-lg">
                                                    <input [(ngModel)]="item.FluidsRequest['Need' + type.Type.Name]" [name]="'Need' + type.Type.Name" class="switch-input" type="checkbox" [disabled]="true">
                                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                                    <span class="switch-handle"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>Product:</label>
                                            <div>{{type.ProductName}}</div>
                                        </div>

                                        <div class="form-group col-sm-4">
                                            <label>PO #:</label>
                                            <div>{{type.PurchaseOrderNumber}}</div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-sm-12">
                                            <label>Directions:</label>
                                            <div>{{item.FluidsRequest.Directions}}</div>
                                        </div>

                                        <div class="form-group col-sm-12">
                                            <label>Comments:</label>
                                            <div>{{item.FluidsRequest.Comments}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
    </pagination-container>

    <p *ngIf="!models.length && modalFooter.IsLoading">Loading...</p>

    <p *ngIf="!models.length && !modalFooter.IsLoading">No results</p>

    <modal-footer [model]="modalFooter">
        <button class="btn btn-secondary" (click)="onConfirmClose()" [disabled]="modalFooter.IsLoading">
            <i class="fas fa-ban mr-1"></i> Close
        </button>

        <span class="ml-2">
            <loader [visible]="modalFooter.IsLoading"></loader>
        </span>
    </modal-footer>
</modal>