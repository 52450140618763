import * as moment from 'moment';
import { ServiceReportPartWarrantyModel } from './service-report-part-warranty.model';

export class PartModel {
    Pk: number = 0;
    ParentPk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    ServiceReportPk: number = 0;
    LocationId: string;
    LocationName: string;
    PartId: string;
    PartName: string = '';
    PartDescription: string;
    Description: string;
    PartTypePk: number = 0;
    PartTypeName: string;
    PartTypeDescription: string;
    CategoryId: string;
    ClassificationId: string;
    ManufacturerId: string;
    AccountId: string;
    AccountName: string;
    Lot: string;
    Bin: string;
    Quantity: number = 0;
    IsPartFound: boolean = false;
    PONumber: string;
    Cause: string;
    Complaint: string;
    Corrections: string;
    IsOFR: boolean = false;
    FSNumber: string;
    IssueUnits: string;
    WasCatContacted: boolean;
    DealerName: string;
    DealerTimeframe: string;
    WasCoolantChanged: boolean;
    WasOilChanged: boolean;
    FieldTechsInvoled: number;
    RowVersionDate: Date;
    Area: string;
    Warranties: ServiceReportPartWarrantyModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ParentPk = obj.ParentPk;
        this.CreationDate = obj.CreationDate;
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = obj.LastUpdateDate;
        this.LastUpdateBy = obj.LastUpdateBy;
        this.ServiceReportPk = obj.ServiceReportPk;
        this.LocationId = obj.LocationId == null ? '' : obj.LocationId;
        this.LocationName = obj.LocationName;
        this.PartId = obj.PartId == null ? obj.Id : obj.PartId;
        this.PartName = obj.PartName == null ? obj.Name : obj.PartName;
        this.PartDescription = obj.PartDescription == null ? obj.Description : obj.PartDescription;
        this.Description = `${this.PartName} (${this.PartId})`;
        this.PartTypePk = obj.PartTypePk;
        this.PartTypeName = obj.PartTypeName;
        this.PartTypeDescription = obj.PartTypeDescription;
        this.CategoryId = obj.CategoryId;
        this.ClassificationId = obj.ClassificationId;
        this.ManufacturerId = obj.ManufacturerId;
        this.AccountId = obj.AccountId;
        this.AccountName = obj.AccountName;
        this.Lot = obj.Lot;
        this.Bin = obj.Bin;
        this.Quantity = obj.Quantity;
        this.IsPartFound = obj.IsPartFound;
        this.PONumber = obj.PONumber;
        this.Cause = obj.Cause;
        this.Complaint = obj.Complaint;
        this.Corrections = obj.Corrections;
        this.IsOFR = obj.OFR == null ? (obj.IsOFR == null ? false : obj.IsOFR) : obj.OFR;
        this.RowVersionDate = moment(obj.RowVersionDate).toDate();
        this.Area = obj.Area; 
        this.FSNumber = obj.FSNumber;
        this.IssueUnits = obj.IssueUnits;
        this.WasCatContacted = obj.WasCatContacted;
        this.DealerName = obj.DealerName;
        this.DealerTimeframe = obj.DealerTimeframe;
        this.WasCoolantChanged = obj.WasCoolantChanged;
        this.WasOilChanged = obj.WasOilChanged;
        this.FieldTechsInvoled = obj.FieldTechsInvoled;

        if (obj.Warranties) {
            this.Warranties = obj.Warranties.map(x => new ServiceReportPartWarrantyModel(x));
        }
    }
}
