import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ApplicationUserModel,
    AreaModel,
    ConfirmModel,
    InvoicingInstructionModel,
    LookupModel,
    LookupValueModel,
    MacolaContactModel,
    MacolaCustomerModel,
    ModalFooterModel,
    ModuleHistoryModel,
    SalesOrderModel,
    SparkFileModel,
    SparkModuleTypeModel,
    StateData,
    UnitModel,
    UserModel
} from '../../shared/models';

import {
    AreaService,
    Common,
    LookupService,
    LookupValueService,
    MacolaCustomerService,
    ModuleHistoryService,
    SalesOrderService,
    SecurityService,
    SparkFileService,
    SparkModuleTypeService,
    UnitService,
    UserService,
    WorkflowService,
    InvoicingInstructionService
} from '../../core/services';

import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import * as moment from 'moment';

@Component({
    selector: 'sales-order-detail',
    templateUrl: './sales-order-detail.component.html'
})
export class SalesOrderDetailComponent implements OnInit {
    @ViewChild('salesOrderForm', { static: true }) salesOrderForm: NgForm;
    @ViewChild('moduleTabs', { static: true }) tabs: TabsetComponent;
    @ViewChild('emailForm', { static: true }) emailForm: NgForm;

    @Input() id: number = 0;
    @Input() preview: boolean = false;

    access: any = {
        actionMenu: false,
        area: false,
        contractStatus: false,
        createContract: false,
        createInvoice: false,
        createNewInvoice: false,
        createRenewal: false,
        createRenewInvoice: false,
        customerEmail: true,
        installDate: true,
        unit: true,
        location: true,
        salesContact: true,
        salesContactNumber: true,
        save: true,
        delete: true,
        submit: true,
        email: false,
        print: false,
        performanceBasedNotes: false,
        monthlyRate: false,
        termInMonths: false
    };
    email:string;
    accountRepresentatives: ApplicationUserModel[] = new Array();
    areaManagers: ApplicationUserModel[] = new Array();
    areas: AreaModel[] = new Array();
    assets: UnitModel[] = new Array();
    availableAssets: UnitModel[] = new Array();
    contacts: MacolaContactModel[] = new Array();
    counties: LookupModel[] = new Array();
    customers: MacolaCustomerModel[] = new Array();
    latest: SalesOrderModel = new SalesOrderModel();
    locations: LookupModel[] = new Array();
    loaded: boolean = false;
    lookupValues: any = {};
    model: SalesOrderModel = new SalesOrderModel();
    modelOriginal: SalesOrderModel = new SalesOrderModel();
    sortedInvoicingInstructions: InvoicingInstructionModel[] = new Array();
    sourcePk: number;
    selectableAreaManagers: ApplicationUserModel[] = new Array();
    selectedAreaManagers: ApplicationUserModel[] = new Array();
    selectedAsset: UnitModel = new UnitModel();
    selectedFile: File;
    showReviewMessage: boolean = false;
    sparkModuleType: SparkModuleTypeModel;
    submittable: boolean = true;
    typeahead: any = {};
    uploading: boolean = false;
    uploadProgress: number;
    user: UserModel = new UserModel();
    users: ApplicationUserModel[] = new Array();
    view: string;
    notes: string;
    directions: string;
    emailAttachment: File;
    latestInvoicingInstruction: InvoicingInstructionModel;

    confirmAddContract: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to create a new contract?',
        onConfirm: () => { this.onConfirmAddContract() }
    });

    confirmSubmit: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to submit the Sales Information Sheet?',
        onConfirm: () => { this.onConfirmSubmit() }
    });

    confirmDelete: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to cancel the Sales Information Sheet?',
        onConfirm: () => { this.onConfirmDelete() }
    });

    confirmLoseChanges: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to cancel and lose changes?',
        onConfirm: () => { this.goBack() }
    });

    confirmDenySalesOrder: ConfirmModel = new ConfirmModel({
        Text: `There are changes in this Sales Information Sheet that will require resubmitting this form. Do you want to proceed?`,
        onConfirm: () => { this.onConfirmDenySalesOrder() }
    });

    emailFooter: ModalFooterModel = new ModalFooterModel({
        ConfirmIcon: 'fas fa-envelope',
        ConfirmText: 'Submit',
        onConfirm: () => { this.onSendEmail() },
        onClose: () => { this.onCloseEmail() }
    });

    signedContractFooter: ModalFooterModel = new ModalFooterModel({
        ConfirmText: 'Save',
        onConfirm: () => { this.onSaveSignedContract(); },
        onClose: () => { this.onCloseSignedContract() }
    });

    constructor(
        public common: Common,
        private areaService: AreaService,
        private lookupService: LookupService,
        private lookupValueService: LookupValueService,
        private macolaCustomerService: MacolaCustomerService,
        private moduleHistoryService: ModuleHistoryService,
        private route: ActivatedRoute,
        private router: Router,
        private salesOrderService: SalesOrderService,
        private securityService: SecurityService,
        private sparkFileService: SparkFileService,
        private sparkModuleTypeService: SparkModuleTypeService,
        private unitService: UnitService,
        private userService: UserService,
        private workflowService: WorkflowService,
        private invoicingInstructionService: InvoicingInstructionService
    ) {
        this.common.setTitle('Sales | Sales Information Sheet');

        this.route.queryParams.subscribe((params: Params) => {
            if (params['id']) {
                this.model.Pk = params['id'];

                if (this.loaded) {
                    this.tabs.tabs[0].active = true;
                    this.common.handleRequests([this._onLoad()]);
                }
            }

            if (params['sourcePk']) {
                this.sourcePk = Number(params['sourcePk']);

                if (this.loaded) {
                    this.tabs.tabs[0].active = true;
                    this.common.handleRequests([this._onLoadBySource()]);
                }
            }

            this.view = params['view'];
        });

        this.user = this.common.getUser();

        this.access = Object.assign(this.access, {
            create: this.common.hasAccess('OPS', 'SO', 'Create'),
            update: this.common.hasAccess('OPS', 'SO', 'Update'),
            delete: this.common.hasAccess('OPS', 'SO', 'Delete'),
            approve: this.common.hasAccess('OPS', 'SO', 'Approve'),
            review: this.common.hasAccess('OPS', 'SO', 'Review'),
            createInvoice: this.common.hasAccess('OPS', 'II', 'Create')
        }, this.common.getUserRoleAccess());

        let createSetting = this.securityService.getAppSetting('CreateSalesOrder');

        if (!this.model.Pk && !this.sourcePk && (!this.access.create || createSetting == "false")) {
            this.goBack();
            return;
        }

        this.common.handleRequests([
            this._getAreas(),
            this._getUsers(),
            this._getCustomers(),
            this._getLocations(),
            this._getLookupValues(),
            this._getModuleType()
        ]).then(() => {
            if (this.preview) {
                this.common.handleRequests([this._getAssets(false)]).then(() => {
                    this.common.handleRequests([this._onLoad()]).then(() => {
                        this.loaded = true;
                    });
                });
            }
            else {
                this.common.handleRequests([this._getAssets()]).then(() => {
                    if (this.model.Pk) {
                        this.common.handleRequests([this._onLoad()]).then(() => {
                            this.common.handleRequests([
                                this._getLatestByAsset(),
                                this._getLatestIIByAsset()
                            ]);
                        });
                    }
                    else if (this.sourcePk) {
                        this.common.handleRequests([this._onLoadBySource()]);
                    }
                    else {
                        if (this.access.accountManager) {
                            const manager = this.accountRepresentatives.find(x => x.UserId == this.user.AppUser.UserId);

                            if (manager) {
                                this.onAccountManagerChange(new TypeaheadMatch(manager));
                            }
                        }

                        this.refreshAccess();
                    }

                    this.loaded = true;
                });
            }
        });
    }

    ngOnInit() {
        if (this.id) {
            this.model.Pk = this.id;
            this.access.save = false;
            this.access.customerEmail = false;
            this.access.salesContact = false;
            this.access.salesContactNumber = false;
            this.access.unit = false;
        }
    }

    private _getLatestByAsset() {
        return this.salesOrderService.getLatestByAsset(this.model.AssetId).pipe(map(data => {
            this.latest = data;
            this.refreshAccess();
        }));
    }

    private _getLatestIIByAsset() {
        return this.invoicingInstructionService.getLatestByAsset(this.model.AssetId).pipe(map(data => {
            this.latestInvoicingInstruction = data;
        }));
    }

    private _onLoadBySource() {
        return this.salesOrderService.get(this.sourcePk)
            .pipe(map(data => {
                this.sourcePk = null;
                this.model = data.createRenewal();
                this.modelOriginal = new SalesOrderModel(this.model);

                this._onLoadDetails();
            }));
    }

    private _onLoad(workflowStatus?: string) {
        return this.salesOrderService.get(this.model.Pk)
            .pipe(map(data => {
                this.model = data;
                this.modelOriginal = new SalesOrderModel(data);

                this.sortedInvoicingInstructions = this.getInvoicingInstructions();

                if (workflowStatus != null) {
                    this.common.showMessage(`Successfully ${workflowStatus.toLowerCase()} Sales Information Sheet.`);

                    this.common.handleRequests([this._getAssets()]).then(() => {
                        this._onLoadDetails();
                    });
                }
                else {
                    this._onLoadDetails();
                }
            }));
    }

    private _onLoadDetails() {
        this.typeahead['AssetId'] = this.model.AssetName;

        const asset = this.assets.find(x => x.Unit == this.model.AssetId);

        if (asset) {
            this.selectedAsset = asset;
        }

        if (this.model.AreaId) {
            const area = this.areas.find(x => x.Id == this.model.AreaId);

            if (area) {
                this.typeahead['Area'] = area.Name;
            }
        }

        this.typeahead['State'] = this.model.State;
        this.typeahead['County'] = this.model.County;

        if (this.model.State) {
            const selectedState = (this.lookupValues['STATE'] as LookupValueModel[]).find(x => x.Description == this.model.State);

            if (selectedState) {
                this._getCounties(selectedState.Name).subscribe();
            }
        }

        this.typeahead['AccountManager'] = this.model.AccountManagerName;
        this.typeahead['OperationsManager'] = this.model.OperationsManagerName;
        this.typeahead['Customer'] = this.model.CustomerName;

        if (this.model.CustomerId) {
            const customer = this.customers.find(x => x.Code == this.model.CustomerId);

            if (customer) {
                this.contacts = this.common.sort(customer.Contacts.slice(), 'FullName');
            }
        }

        this.refreshAccess();
    }

    private _onSave(workflowStatus?: string): Observable<SalesOrderModel> {
        let method;

        if (this.model.Type.toLowerCase() == 'renew' && this.model.MonthlyRate && this.latestInvoicingInstruction && this.latestInvoicingInstruction.MonthlyRate) {
            this.model.Type = this.model.MonthlyRate <= this.latestInvoicingInstruction.MonthlyRate ? 'RenewRate' : 'Renew';
        }

        if (this.model.InstallTransportation != 'Shared Billing')
            this.model.InstallTransportationSplit = null;

        if (this.model.InstallTransportationOut != 'Shared Billing')
            this.model.InstallTransportationOutSplit = null;

        if (!this.model.HasEcoView) {
            this.model.EcoViewPackagePk = null;
            this.model.EcoViewPackageRate = null;
            this.model.EcoViewPackageDailyRate = null;
            this.model.HasEcoViewInstall = false;
            this.model.EcoViewInstallCharge = null;
            this.model.EcoViewInstallDate = null;
            this.model.EcoViewTermInMonths = null;
        }
        else if (!this.model.HasEcoViewInstall) {
            this.model.EcoViewInstallCharge = null;
            this.model.EcoViewInstallDate = null;
        }

        if (this.model.Pk) {
            method = this.salesOrderService.update(this.model);
        }
        else {
            method = this.salesOrderService.create(this.model);
        }

        return method.pipe(map((data: SalesOrderModel) => {
            this.common.showMessage(`Successfully ${!this.model.Pk ? 'created' : 'updated'} the Sales Information Sheet.`);

            if (!this.model.Pk) {
                const params = { queryParams: { id: data.Pk } };

                this.router.navigate(['/sales/sales-order/detail'], params);
            }
            else {
                this.model = data;
                this.modelOriginal = new SalesOrderModel(data);

                this.refreshAccess();
            }
        }));
    }

    private _getAssets(clearCache: boolean = true) {
        if (clearCache) {
            delete this.unitService.data['PortalUnits'];
        }

        return this.unitService.getPortalUnitList(true).pipe(map(data => {
            this.assets = data;
            this.availableAssets = this.assets.filter(x => x.Availability.toLowerCase() == 'available');
        }));
    }

    private _getAreas() {
        return this.areaService.get().pipe(map(data => this.areas = data));
    }

    private _getCounties(abbreviation: string) {
        return this.lookupService.getDataByState(abbreviation, StateData.County).pipe(map(data => this.counties = data));
    }

    private _getUsers() {
        return this.userService.getAllUsers().pipe(map(data => {
            this.users = data;
            // todo: Remove ApplicationPk check when permissions are consolidated
            this.accountRepresentatives = data.filter(x => x.ApplicationUserRoles.filter(y => ['accountmanager', 'salesmanager'].indexOf(y.ApplicationRole.Id.toLowerCase()) >= 0 && y.ApplicationPk == 2).length >= 1);
            this.areaManagers = data.filter(x => x.ApplicationUserRoles.filter(y => y.ApplicationRole.Id.toLowerCase() == 'areamanager').length >= 1);
        }));
    }

    private _getCustomers() {
        return this.macolaCustomerService.search().pipe(map(data => this.customers = this.common.sort(data, 'Name')));
    }

    private _getLocations() {
        return this.lookupService.getLookupList("location").pipe(map(data => this.locations = data));
    }

    private _getModuleType() {
        return this.sparkModuleTypeService.getById("SO").pipe(map(data => this.sparkModuleType = data));
    }

    private _getLookupValues() {
        const types = [
            'SALES_ECOVIEW_PACKAGE',
            'SALES_ECOVIEW_PACKAGE_INSTALL',
            'SALES_ECOVIEW_PACKAGE_RATE',
            'SALES_ORDER_COMPETITOR',
            'SALES_ORDER_PROBABILITY',
            'SALES_APPLICATION_TYPE',
            'SALES_REVENUE_TYPE',
            'STATE'
        ];

        return this.lookupValueService.getByTypes(types).pipe(map(data => {
            types.map(x => {
                if (!this.lookupValues[x]) {
                    this.lookupValues[x] = this.common.sort(data.filter(y => y.Type == x), 'Sort');
                }
            });
        }));
    }

    onLoad(workflowStatus?: string) {
        this.common.handleRequests([this._onLoad(workflowStatus)]);
    }

    onBack() {
        if (this.hasChanges()) {
            this.common.showConfirm(this.confirmLoseChanges);
        }
        else {
            this.goBack();
        }
    }

    goBack() {
        const params: any = {};

        if (this.view) {
            params.queryParams = { view: this.view };
        }

        this.router.navigate(['/sales/sales-order/list'], params);
    }

    refreshAccess() {
        const status = this.model.Status.toLowerCase();
        const type = this.model.Type.toLowerCase();
        const assetId = this.model.AssetId.toLowerCase();
        const availability = this.selectedAsset.Availability.toLowerCase();
        const creator = this.model.CreatedBy == this.user.ApplicationUserPk || this.model.AccountManagerId == this.user.AppUser.UserId;

        if (!this.model.Pk) {
            this.access.save = !!(this.access.create);
            this.access.area = this.access.save;
            this.access.monthlyRate = this.access.save;
            this.access.termInMonths = this.access.save;
            this.access.remove = false;
            this.access.submit = false;
            this.access.installDate = this.access.save;
            this.access.location = this.access.save;
        }
        // todo: consider refactoring this to be better optimized?
        else if (creator && ['new', 'denied'].indexOf(status) >= 0) {
            this.access.save = !!(this.access.update);
            this.access.area = this.access.save;
            this.access.monthlyRate = this.access.save;
            this.access.termInMonths = this.access.save;
            this.access.remove = !!(this.access.delete);
            this.access.submit = true;
        }
        else if (status == 'pending' && this.model.CanApprove) {
            this.access.save = false;
            this.access.area = false;
            this.access.monthlyRate = false;
            this.access.termInMonths = false;
            this.access.remove = false;
            this.access.submit = false;
            this.access.createContract = !!(this.access.contractAdmin);
            this.access.performanceBasedNotes = !!(this.access.contractAdmin);
            this.access.installDate = false;
            this.access.location = !!(this.access.contractAdmin);
            this.access.customerEmail = (type != 'renew');
            this.access.salesContact = (type != 'renew');
            this.access.salesContactNumber = (type != 'renew');
            this.access.unit = (type != 'renew');
        }
        else {
            const isEditable = ['cancelled', 'completed', 'closed'].indexOf(status) < 0;

            if (this.access.update && isEditable && (creator || this.access.salesManager || this.access.contractAdmin || this.access.salesAdmin)) {
                this.access.remove = !(this.access.contractAdmin);
                this.access.save = (creator);
                this.access.installDate = (creator);
                this.access.location = true;
                this.access.customerEmail = (type != 'renew');
                this.access.salesContact = (type != 'renew');
                this.access.salesContactNumber = (type != 'renew');
                this.access.unit = (type != 'renew');
                this.access.area = this.access.save;
                this.access.monthlyRate = this.access.save;
                this.access.termInMonths = this.access.save;
            }
            else {
                this.access.remove = false;
                this.access.save = false;
                this.access.installDate = false;
                this.access.location = false;
                this.access.customerEmail = false;
                this.access.salesContact = false;
                this.access.salesContactNumber = false;
                this.access.unit = false;
                this.access.area = false;
                this.access.termInMonths = false;
                this.access.monthlyRate = false;
            }

            this.access.submit = false;
            this.access.createContract = !!(this.access.contractAdmin);
        }

        // SIS records with TBD units are cancelled through Salesforce when the Opportunity is marked as lost
        if (type == 'new' && assetId.indexOf('tbd') >= 0) {
            this.access.remove = false;
        }

        if (['pending', 'reviewed'].indexOf(status) >= 0) {
            this.access.email = (this.access.accountManager || this.access.salesAdmin || this.access.admin);
        }
        else {
            this.access.email = false;
        }

        if (['pending', 'completed', 'reviewed', 'closed'].indexOf(status) >= 0) {
            this.access.print = true;
        }
        else {
            this.access.print = false;
        }

        if ((this.access.contractAdmin || this.access.salesAdmin) && status != 'cancelled') {
            this.access.contractStatus = true;
        }
        else {
            this.access.contractStatus = false;
        }

        const hasRenewal = (this.latest.Pk && this.latest.Pk != this.model.Pk && this.latest.Type == 'Renew');
        const isAsset = this.model.AssetId.toLowerCase().indexOf('tbd') < 0;

        this.access.createNewInvoice = this.access.createInvoice && status == 'reviewed' && type == 'new' && isAsset;
        this.access.createRenewInvoice = this.access.createInvoice && status == 'reviewed' && type == 'renew';

        const invoincingInstructions = this.getInvoicingInstructions();

        if (status == 'completed' && invoincingInstructions.length) {
            const validType = ['termination', 'renewterm'].indexOf(invoincingInstructions[0].Type.toLowerCase()) < 0;

            this.access.createRenewal = this.access.create && invoincingInstructions[0].Status.toLowerCase() == 'completed' && validType && !hasRenewal;
        }
        else {
            this.access.createRenewal = false;
        }

        if (this.access.submit && status == 'new' && type == 'new' && availability == 'unavailable') {
            this.submittable = false;
            this.access.submit = false;
        }
        else {
            this.submittable = true;
        }

        this.access.actionMenu = this.access.remove || this.access.submit || this.access.email || this.access.print || this.access.createContract ||
            this.access.createRenewal || this.access.createNewInvoice || this.access.createRenewInvoice ||
            (this.model.Workflows && this.model.Workflows.length);

        this.showReviewMessage = ['new', 'denied'].indexOf(status) >= 0 && type == 'renew';

        if (this.model.SalesMassRenewalPk) {
            this.access.remove = false;
            this.access.createNewInvoice = false;
            this.access.createRenewInvoice = false;

            if (this.model.SalesMassRenewal.Status.toLowerCase() == 'completed') {
                this.access.save = false;
            }
            else {
                this.access.createRenewal = false;
                this.access.location = false;
                this.access.installDate = false;
                this.access.area = false;
                this.access.monthlyRate = false;
                this.access.termInMonths = false;
            }
        }
    }

    checkFields(monitorType: string) {
        const changes = this.getChanges();
        const labels = Array.from(document.querySelectorAll<HTMLLabelElement>('[for]')) as HTMLLabelElement[];
        const data = Array.from(document.querySelectorAll<HTMLElement>(`[data-monitor-type=${monitorType}]`)) as HTMLElement[];
        const history = [];

        data.map(x => {
            if (changes.indexOf(x.id) >= 0) {
                const label = labels.find(l => l.htmlFor == x.id);

                history.push(new ModuleHistoryModel({
                    FieldName: x.id,
                    Description: label ? label.innerText.replace(/[^\w\s\/]/gi, '') : x.id,
                    OldValue: this.modelOriginal[x.id],
                    NewValue: this.model[x.id]
                }));
            }
        });

        return history;
    };

    onSave() {
        const changes = this.getChanges();
        const status = this.model.Status.toLowerCase();

        const requests = [];

        if (['new', 'completed', 'denied'].indexOf(status) < 0 && changes.length) {
            const workflowChanges = this.checkFields('workflow');

            if (workflowChanges.length && !this.access.performanceBasedNotes) {
                this.common.showConfirm(this.confirmDenySalesOrder);
                return;
            }

            const notifyChanges = this.checkFields('notify');

            if (notifyChanges.length && workflowChanges.length == 0) {
                requests.push(this.moduleHistoryService.notify(this.sparkModuleType.Pk, this.model.Pk, notifyChanges));
            }
        }

        requests.push(this._onSave());

        this.common.handleRequests(requests);
    }

    onConfirmDenySalesOrder() {
        this.confirmDenySalesOrder.disableAll();

        const lastWorkflow = this.model.Workflows.find(x => x.Active);

        const updateStatus = () => {
            this.common.hideConfirm();

            this.model.Status = 'DENIED';
            this.common.handleRequests([this._onSave()]);
        };

        if (lastWorkflow != null) {
            const workflowChanges = this.checkFields('workflow');

            this.common.handleRequests([this.workflowService.forceDeny(lastWorkflow.Pk, workflowChanges)]).then(() => updateStatus());
        }
        else {
            updateStatus();
        }
    }

    canSave() {
        return this.access.contractStatus || this.access.unit || (this.access.save && this.model.AssetId && this.model.Date);
    }

    getChanges() {
        const diff = [];
        const exclude = this.common.getExcludedFields().concat(['Children', 'Parent', 'Files']);

        Object.keys(this.model).map(x => {
            if (exclude.indexOf(x) < 0) {
                let originalValue = (this.modelOriginal[x] || '').toString();
                let modelValue = (this.model[x] || '').toString();

                if (x.toLowerCase().indexOf('date') >= 0) {
                    const now = new Date();
                    originalValue = moment(this.modelOriginal[x] || now).toDate().getTime();
                    modelValue = moment(this.model[x] || now).toDate().getTime();
                }

                if (originalValue != modelValue) {
                    diff.push(x);
                }
            }
        });

        return diff;
    }

    hasChanges() {
        return this.getChanges().length > 0;
    }

    onSubmit() {
        // Required typeahead fields are "valid" in that they have text, but not that a selection was made
        const validTypeaheads = (
            (this.model.AssetId && this.model.AssetId.length > 0) &&
            (this.model.AreaId && this.model.AreaId.length > 0) &&
            (this.model.CustomerId && this.model.CustomerId.length > 0) &&
            (this.model.AccountManagerId && this.model.AccountManagerId.length > 0) &&
            (this.model.OperationsManagerId && this.model.OperationsManagerId.length > 0) &&
            (this.model.State && this.model.State.length > 0) &&
            (this.model.County && this.model.County.length > 0)
        );

        if (!(this.access.save && this.salesOrderForm.valid && validTypeaheads)) {
            this.common.showError('Unable to submit', 'There are one or more required fields with missing information.');

            const controls = this.salesOrderForm.controls;

            Object.keys(controls).map(x => {
                if (controls[x].invalid) {
                    controls[x].setErrors({ 'invalid': true });
                    controls[x].markAsDirty();
                }
            });

            this.common.showTabWithError(this.salesOrderForm, this.tabs.tabs);

            return;
        }

        this.common.showConfirm(this.confirmSubmit);
    }

    onConfirmSubmit() {
        this.confirmSubmit.disableAll();

        const callback = () => {
            this.workflowService.createWorkflowModule(this.model.Pk, this.model.WorkflowId)
                .subscribe(
                    data => {
                        this.common.hideConfirm();
                        this.confirmSubmit.enableAll();

                        this.model.Workflows.push(data);
                        this.common.showMessage('Successfully submitted Sales Information Sheet.');
                        this.goBack();
                    },
                    error => {
                        this.common.showError('Error creating workflow for Sales Information Sheet', error);
                        this.confirmSubmit.enableAll();
                    });
        };

        if (this.canSave()) {
            this._onSave().subscribe(() => callback());
        }
        else {
            callback();
        }
    }

    onDelete() {
        this.common.showConfirm(this.confirmDelete);
    }

    onConfirmDelete() {
        this.confirmDelete.disableAll();

        this.salesOrderService.delete(this.model.Pk)
            .subscribe(
                () => {
                    this.common.hideConfirm();
                    this.confirmDelete.enableAll();

                    this.common.showMessage('Successfully deleted Sales Information Sheet.');
                    this.goBack();
                },
                error => {
                    this.common.showError('Error deleting Sales Information Sheet', error);
                    this.confirmDelete.enableAll();
                }
            );
    }

    onUnitChange(selected: TypeaheadMatch) {
        const unit = selected.item as UnitModel;

        this.typeahead['AssetId'] = unit.UnitName;
        this.model.AssetId = unit.Unit;
        this.model.AssetName = unit.UnitName;
        this.model.Engine = unit.Engine;
        this.model.Frame = unit.Frame;
        this.model.Cylinders = unit.Cylinders;

        this.selectedAsset = unit;

        // the original unit could become unavailable, so rerun the user's access
        this.refreshAccess();
    }

    onAreaChange(selected: TypeaheadMatch) {
        const area = selected.item as AreaModel;

        this.typeahead['Area'] = area.Name;
        this.model.AreaId = area.Id;
    }

    onStateChange(selected: TypeaheadMatch) {
        const state = selected.item as LookupModel;

        this.typeahead['State'] = state.Description;
        this.model.State = state.Description;

        this.typeahead['County'] = null;
        this.model.County = null;

        this.common.handleRequests([this._getCounties(state.Name)]);
    }

    onCountyChange(selected: TypeaheadMatch) {
        const county = selected.item as LookupModel;

        this.typeahead['County'] = county.Name;
        this.model.County = county.Name;
    }

    onAccountManagerChange(selected: TypeaheadMatch) {
        const manager = selected.item as ApplicationUserModel;

        this.typeahead['AccountManager'] = manager.DisplayName;
        this.model.AccountManagerId = manager.UserId;
        this.model.AccountManagerName = manager.DisplayName;
    }

    onOperationsManagerChange(selected: TypeaheadMatch) {
        const manager = selected.item as ApplicationUserModel;

        this.typeahead['OperationsManager'] = manager.DisplayName;
        this.model.OperationsManagerId = manager.UserId;
        this.model.OperationsManagerName = manager.DisplayName;
    }

    onAreaManagerChange(selected: TypeaheadMatch) {
        const manager = selected.item as ApplicationUserModel;

        this.typeahead['AreaManager'] = '';
        this.selectedAreaManagers.push(manager);
        this.selectableAreaManagers = this.selectableAreaManagers.filter(x => x.UserId != manager.UserId);
    }

    onDeleteAreaManager(manager: ApplicationUserModel) {
        this.selectedAreaManagers = this.selectedAreaManagers.filter(x => x.UserId != manager.UserId);

        this.selectableAreaManagers.push(manager);
        this.selectableAreaManagers = this.common.sort(this.selectableAreaManagers.slice(), 'DisplayName');
    }

    onCustomerChange(selected: TypeaheadMatch) {
        const customer = selected.item as MacolaCustomerModel;

        this.typeahead['Customer'] = customer.Name;
        this.model.AccountsPayableContactId = null;
        this.model.AccountsPayableContactName = null;

        this.model.CustomerId = customer.Code;
        this.model.CustomerName = customer.Name;

        this.contacts = this.common.sort(customer.Contacts.slice(), 'FullName');

        let contact: MacolaContactModel;

        const accountPayableContact = customer.Contacts.find(x => x.FullName == 'Accounts Payable');

        if (accountPayableContact) {
            contact = accountPayableContact;
        }
        else if (customer.ContactId) {
            contact = customer.Contacts.find(x => x.ContactId == customer.ContactId);
        }

        if (contact) {
            this.model.AccountsPayableContactId = contact.Pk.toString();
            this.model.AccountsPayableContactName = contact.FullName;
        }
    }

    onContactChange(contactPk: number) {
        const contact = this.contacts.find(x => x.Pk == contactPk)
        this.model.AccountsPayableContactName = contact.FullName;
    }

    onTypeaheadChange(typeaheadId: string) {
        switch (typeaheadId) {
            case 'AssetId':
                this.model.AssetId = null;
                this.model.AssetName = null;
                this.model.Engine = null;
                this.model.Frame = null;
                this.model.Cylinders = null;
                break;

            case 'Area':
                this.model.AreaId = null;
                break;

            case 'AccountManager':
                this.model.AccountManagerId = null;
                this.model.AccountManagerName = null;
                break;

            case 'OperationsManager':
                this.model.OperationsManagerId = null;
                this.model.OperationsManagerName = null;
                break;

            case 'Customer':
                this.model.CustomerId = null;
                this.model.CustomerName = null;
                this.model.AccountsPayableContactId = null;
                this.model.AccountsPayableContactName = null;
                break;

            case 'State':
                this.model.State = null;
                this.typeahead['County'] = null;
                this.model.County = null;
                this.counties = [];
                break;

            case 'County':
                this.model.County = null;
                break;

            default:
                break;
        }
    }

    validateTypeahead(typeaheadId: string) {
        let reset: boolean = false;

        if (typeaheadId == 'AssetId' && !this.model.AssetId) {
            reset = true;
        }

        if (typeaheadId == 'Area' && !this.model.AreaId) {
            reset = true;
        }

        if (typeaheadId == 'AccountManager' && !this.model.AccountManagerId) {
            reset = true;
        }

        if (typeaheadId == 'OperationsManager' && !this.model.OperationsManagerId) {
            reset = true;
        }

        if (typeaheadId == 'Customer' && !this.model.CustomerId) {
            reset = true;
        }

        if (typeaheadId == 'State' && !this.model.State) {
            reset = true;
        }

        if (typeaheadId == 'County' && !this.model.County) {
            reset = true;
        }

        if (reset) {
            this.typeahead[typeaheadId] = null;
            this.onTypeaheadChange(typeaheadId);
        }
    }

    calculateDailyRate() {
        if (!this.model.MonthlyRate) {
            this.model.DailyRate = 0;
        }
        else {
            this.model.DailyRate = this.common.round(this.model.MonthlyRate / 30.4, 2);
            this.salesOrderForm.controls['DailyRate'].setValue(this.model.DailyRate);
        }

        this.calculateStandbyRateAmount();
        this.calculatePackagingStandbyRateAmount();
    }

    calculateStandbyRateAmount() {
        if (!this.model.MonthlyRate || !this.model.StandbyRate) {
            this.model.StandbyRateAmount = 0;
            return;
        }

        this.model.StandbyRateAmount = this.common.round(this.model.MonthlyRate * (this.model.StandbyRate / 100), 2);
        this.salesOrderForm.controls['StandbyRateAmount'].setValue(this.model.StandbyRateAmount);
    }

    calculatePackagingStandbyRateAmount() {
        if (!this.model.MonthlyRate || !this.model.PackagingStandbyRate) {
            this.model.PackagingStandbyRateAmount = 0;
            return;
        }

        this.model.PackagingStandbyRateAmount = this.common.round(this.model.MonthlyRate * (this.model.PackagingStandbyRate / 100), 2);
        this.salesOrderForm.controls['PackagingStandbyRateAmount'].setValue(this.model.PackagingStandbyRateAmount);
    }

    onAddContract() {
        this.common.showConfirm(this.confirmAddContract);
    }

    onConfirmAddContract() {
        this.confirmAddContract.disableAll();

        this.salesOrderService.createContract(this.model.Pk)
            .subscribe(
                data => {
                    this.common.downloadFile(`${this.model.CustomerName} ${this.model.Location}.docx`, data);

                    this.confirmAddContract.enableAll();
                    this.common.hideConfirm();
                },
                error => {
                    this.confirmAddContract.enableAll();

                    this.common.showError('Error Creating Contract', error);
                }
            );
    }

    onAddSignedContract() {
        this.signedContractFooter.DisableConfirm = true;
        this.common.showModal('signed-contract');
    }

    onFileAttached(event: any) {
        if (event.target.files.length == 0) {
            this.selectedFile = null;
            return;
        }

        const file = event.target.files[0] as File;
        // todo: Move this to settings?
        const limit = 2090000000;

        if (file.size > limit) {
            const fileSize = (file.size / 1024) / 1024;
            this.selectedFile = null;
            this.common.showError('File Too Large', `The attached file <strong>${file.name}</strong> is too large at <strong>${fileSize.toFixed(2)} MB</strong>.
                The file size limit is <strong>${(limit / 1024 / 1024)} MB</strong>.`);
            return;
        }

        this.signedContractFooter.DisableConfirm = false;
        this.selectedFile = file;
    }

    onSaveSignedContract() {
        this.signedContractFooter.disableAll();

        this.sparkFileService.create(this.selectedFile, this.sparkModuleType.Pk, this.model.Pk)
            .subscribe(
                data => {
                    if (data.type == HttpEventType.UploadProgress) {
                        this.uploadProgress = Math.round(100 * data.loaded / data.total);
                    }

                    if (data.type == HttpEventType.Response) {
                        this.uploading = false;

                        const sparkFile = new SparkFileModel(data.body[0]);
                        this.model.Files.push(sparkFile);

                        this.selectedFile = null;

                        const e = document.getElementById('SignedContract') as HTMLInputElement;

                        if (e) {
                            e.value = '';
                        }

                        this.salesOrderService.createSignedContract(this.model.Pk, sparkFile.Pk)
                            .subscribe(
                                () => {
                                    this.common.showMessage('Successfully added signed contract.');

                                    this.uploadProgress = 0;
                                    this.signedContractFooter.enableAll();
                                    this.common.hideModal('signed-contract');
                                },
                                error => {
                                    this.signedContractFooter.enableAll();
                                    this.uploadProgress = 0;
                                    this.uploading = false;
                                    this.common.showError('Error Saving Signed Contract', error);
                                }
                            );
                    }
                },
                error => {
                    this.signedContractFooter.enableAll();
                    this.uploadProgress = 0;
                    this.uploading = false;

                    this.common.showError('Error Uploading Signed Contract', error);
                }
            );
    }

    onCloseSignedContract() {
        this.selectedFile = null;
        this.signedContractFooter.enableAll();
        this.common.hideModal('signed-contract');
    }

    onEmail() {
        this.emailForm.resetForm();
        this.selectedAreaManagers = [];
        this.selectableAreaManagers = this.common.sort(this.areaManagers.slice(), 'DisplayName');
        this.common.showModal('sales-order-email');
    }

    onEmailFileAttached(event: any) {
        if (event.target.files.length == 0) {
            this.emailAttachment = null;
            return;
        }

        const file = event.target.files[0] as File;
        const limit = 5242880;

        if (file.size > limit) {
            const fileSize = (file.size / 1024) / 1024;
            this.emailAttachment = null;
            this.common.showError('File Too Large', `The attached file <strong>${file.name}</strong> is too large at <strong>${fileSize.toFixed(2)}MB</strong>.
                The file size limit is <strong>${(limit / 1024 /1024)} MB</strong>.`)
            return;
        }

        this.emailAttachment = file;
    }

    onSendEmail() {
        this.emailFooter.disableAll();

        const emails = this.selectedAreaManagers.map(x => x.UserId + '@kodiakgas.com');

        this.salesOrderService.email(this.model.Pk, this.notes, this.directions, this.emailAttachment, emails)
            .subscribe(
                () => {
                    this.emailAttachment = null;
                    this.common.hideModal('sales-order-email');
                    this.emailFooter.enableAll();
                    this.common.showMessage(`Successfully sent installation email to the distribution list`);
                },
                error => {
                    this.common.showError('Error emailing Sales Information Sheet', error);
                    this.emailFooter.enableAll();
                }
           );
    }

    onCloseEmail() {
        this.emailAttachment = null;
        this.common.hideModal('sales-order-email');
    }

    onCreateInvoicingInstruction() {
        const params: any = {
            queryParams: {
                sopk: this.model.Pk
            }
        };

        this.router.navigate(['/sales/invoicing-instruction/detail'], params);
    }

    onSort(sortData: any) {
        this.sortedInvoicingInstructions = this.common.sort(this.model.InvoicingInstructions.slice(), sortData.active, sortData.order);
    }

    onOpenDetail(modulePk: number) {
        let params = { queryParams: { id: modulePk } };

        this.router.navigate(['/sales/invoicing-instruction/detail'], params);
    }

    getInvoicingInstructions() {
        return this.model.InvoicingInstructions ? this.model.InvoicingInstructions.filter(x => x.Status.toLowerCase() != 'cancelled') : [];
    }

    onPrint() {
        this.common.showLoader();

        this.salesOrderService.print(this.model.Pk)
            .subscribe(
                data => {
                    if (data) {
                        this.common.downloadReport(`${this.model.AssetId}_${moment(this.model.Date).format('MMDDYYYY')}`, data);
                    }
                    else {
                        this.common.showError('Error printing Sales Information Sheet', 'An error occurred printing the Sales Information Sheet. Please try again. If the problem persists, contact IT.');
                    }

                    this.common.hideLoader();
                },
                error => {
                    this.common.showError('Error printing Sales Information Sheet', error);
                    this.common.hideLoader();
                }
            );
    }

    onCreateRenewal() {
        const params: any = {
            queryParams: {
                sourcePk: this.model.Pk
            }
        };

        this.router.navigate(['/sales/sales-order/detail'], params);
    }

    validateEmailForm() {
        if (this.notes && this.directions) {
            this.emailFooter.enableAll();
        }
        else {
            this.emailFooter.resetAll();
        }
    }

    onReplacingCompetitorChange() {
        if (!this.model.IsReplacingCompetitor) {
            this.model.CompetitorPk = null;
        }
    }

    onEcoViewPackageSelect() {
        const ecoViewPackage = this.lookupValues['SALES_ECOVIEW_PACKAGE'].find(x => x.Pk == this.model.EcoViewPackagePk);

        if (ecoViewPackage) {
            const rate = this.lookupValues['SALES_ECOVIEW_PACKAGE_RATE'].find(x => x.Id == ecoViewPackage.Id);

            if (rate) {
                this.model.EcoViewPackageRate = rate.Description;
                this.calculateEcoViewDailyRate();
            }
        }

        this.onEcoViewInstallToggle();
    }

    onEcoViewInstallToggle() {
        if (!this.model.HasEcoViewInstall || !this.model.EcoViewPackagePk) {
            return;
        }

        const ecoViewPackage = this.lookupValues['SALES_ECOVIEW_PACKAGE'].find(x => x.Pk == this.model.EcoViewPackagePk);

        if (ecoViewPackage) {
            const install = this.lookupValues['SALES_ECOVIEW_PACKAGE_INSTALL'].find(x => x.Id == ecoViewPackage.Id);

            if (install) {
                this.model.EcoViewInstallCharge = install.Description;
            }
        }
    }

    calculateEcoViewDailyRate() {
        if (!this.model.EcoViewPackageRate) {
            this.model.EcoViewPackageDailyRate = 0;
        }
        else {
            this.model.EcoViewPackageDailyRate = this.common.round(this.model.EcoViewPackageRate / 30.4, 2);
            this.salesOrderForm.controls['EcoViewPackageDailyRate'].setValue(this.model.EcoViewPackageDailyRate);
        }
    }
}
