import { ApplicationUserModel } from '../../shared/models/application.model';

import * as moment from 'moment';

export class VehicleAllowanceExportModel {
    Pk: number = 0;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;
    ExportMonth: Date;
    TotalAmount: number;
    IsCompleted: boolean;
    VehicleAllowanceExportItems: VehicleAllowanceExportItemModel[] = new Array();

    constructor(obj?: any) {
        if (obj != null) {
            this.Pk = obj.Pk;
            this.CreatedBy = obj.CreatedBy;
            this.CreationDate = obj.CreationDate;
            this.LastUpdateBy = obj.LastUpdateBy;
            this.LastUpdateDate = obj.LastUpdateDate;
            this.TotalAmount = obj.TotalAmount;
            this.IsCompleted = obj.IsCompleted;

            if (obj.ExportMonth) {
                this.ExportMonth = moment(obj.ExportMonth).startOf('day').toDate();
            }

            if (obj.VehicleAllowanceExportItems) {
                this.VehicleAllowanceExportItems = obj.VehicleAllowanceExportItems.map(x => new VehicleAllowanceExportItemModel(x));
            }
        }
    }
}

export class VehicleAllowanceExportItemModel {
    Pk: number = 0;
    VehicleAllowanceExportPk: number;
    ApplicationUserPk: number;
    Amount: number;
    EarningsCode: string = "17";
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;
    ApplicationUser: ApplicationUserModel = new ApplicationUserModel();

    constructor(obj?: any) {

        if (obj != null) {
            this.Pk = obj.Pk;
            this.VehicleAllowanceExportPk = obj.VehicleAllowanceExportPk;
            this.ApplicationUserPk = obj.ApplicationUserPk;
            this.Amount = obj.Amount;

            this.CreatedBy = obj.CreatedBy;

            if (obj.CreationDate)
                this.CreationDate = moment(obj.CreationDate).toDate();

            this.LastUpdateBy = obj.LastUpdateBy;

            if (obj.LastUpdateDate)
                this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();

            this.ApplicationUser = new ApplicationUserModel(obj.ApplicationUser);
        }
    }
}
