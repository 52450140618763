import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Creating a SparkFile is the only call to send FormData, so let the browser set the header
        if (req.body instanceof FormData) {
            return next.handle(req);
        }

        const headers = { 'Content-Type': 'application/json' };
        const modified = req.clone({ setHeaders:  headers, body: req.serializeBody() });

        return next.handle(modified);
    }
}
