import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    ModuleHistoryModel,
    WorkflowModuleModel,
    WorkflowModuleDetailModel,
    WorkflowModel,
    WorkflowDetailTypeModel,
    WorkflowDetailModel
} from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class WorkflowService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.WORKFLOW}/api/workflow`;

    constructor(private common: Common, private http: HttpClient) { }

    create(model: WorkflowModel) {
        return this.http.post<WorkflowModel>(this.url + '/create', model)
            .pipe(
                map(res => new WorkflowModel(res))
            );
    }

    search() {
        return this.http.get<WorkflowModel[]>(this.url + '/search')
            .pipe(map(data => {
                if (data) {
                    const results = data.map(x => new WorkflowModel(x));

                    return results;
                }

                return new Array() as WorkflowModel[];
            }));
    }

    get(modelPk: number) {
        return this.http.get<WorkflowModel>(this.url + `/get?modelPk=${modelPk}`);
    }

    update(model: WorkflowModel) {
        return this.http.put<WorkflowModel>(this.url + '/update', model)
            .pipe(
                map(res => new WorkflowModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(this.url + `/delete?modelPk=${modelPk}`, { observe: 'response'})
            .pipe(map(this.common.httpBooleanHandler));
    }

    getDetailTypes() {
        return this.http.get<WorkflowDetailTypeModel[]>(this.url + `/get-detail-types`)
            .pipe(map(data => {
                if (data) {
                    const results = data.map(x => new WorkflowDetailTypeModel(x));

                    return results;
                }

                return new Array() as WorkflowDetailTypeModel[];
            }));
    }

    createWorkflowStep(model: WorkflowDetailModel) {
        return this.http.post<WorkflowDetailModel>(this.url + `/step/create`, model)
            .pipe(
                map(res => new WorkflowDetailModel(res))
            );
    }

    updateWorkflowStep(model: WorkflowDetailModel) {
        return this.http.put<WorkflowDetailModel>(this.url + `/step/update`, model)
            .pipe(
                map(res => new WorkflowDetailModel(res))
            );
    }

    deleteWorkflowStep(modelPk: number) {
        return this.http.delete<any>(this.url + `/step/delete?stepPk=${modelPk}`, { observe: 'response'})
            .pipe(map(res => {
                if (res.body != null) {
                    return res.body;
                }

                return res.status == 200;
            }));
    }

    reorderWorkflowSteps(steps: WorkflowDetailModel[]) {
        return this.http.put<WorkflowDetailModel[]>(this.url + `/step/update-order`, steps);
    }

    createWorkflowModule(modulePk: number, moduleType: string) {
        return this.http.post<WorkflowModuleModel>(this.url + `/submit?modulePk=${modulePk}&moduleType=${moduleType}`, {})
            .pipe(
                map(res => new WorkflowModuleModel(res))
            );
    }

    updateWorkflowModuleDetail(model: WorkflowModuleDetailModel) {
        return this.http.put<WorkflowModuleDetailModel>(this.url + '/module-detail/update', model)
            .pipe(
                map(res => new WorkflowModuleDetailModel(res))
            );
    }

    rollbackStep(model: WorkflowModuleDetailModel) {
        return this.http.put(this.url + '/step/rollback', model);
    }

    forceDeny(workflowModulePk: number, moduleHistory: ModuleHistoryModel[] = null) {
        return this.http.post(`${this.url}/force-deny?workflowModulePk=${workflowModulePk}`, moduleHistory).pipe(map(res => res));
    }
}