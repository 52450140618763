import * as moment from 'moment';

export class ServiceReportLaborModel {
    Pk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    ServiceReportPk: number = 0;
    BlowdownType: string;
    CategoryId: string = '';
    CategoryName: string = '';
    Comments: string;
    DownTimeBegin: string;
    DownTimeEnd: string;
    IsDowntimeKodiak: boolean = false;
    IsDowntimeKodiakValue: string;
    LaborHours: number = 0;
    LaborId: string;
    LaborName: string;
    NumberBlowdown: number = 0;
    EngineStartAttempts: number = 0;
    ServiceDate: Date = new Date();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.ServiceReportPk = obj.ServiceReportPk;
        this.BlowdownType = obj.BlowdownType;
        this.CategoryId = obj.CategoryId;
        this.CategoryName = obj.CategoryName;
        this.Comments = obj.Comments;
        this.DownTimeBegin = obj.DownTimeBegin == null ? '' : this.timeConvertAMPM(moment(obj.DownTimeBegin).toDate());
        this.DownTimeEnd = obj.DownTimeEnd == null ? '' : this.timeConvertAMPM(moment(obj.DownTimeEnd).toDate());

        if (obj.IsDowntimeKodiak != null) {
            this.IsDowntimeKodiak = obj.IsDowntimeKodiak;
            this.IsDowntimeKodiakValue = this.IsDowntimeKodiak ? "YES" : "NO";
        }

        this.LaborHours = obj.LaborHours;
        this.LaborId = obj.LaborId;
        this.LaborName = obj.LaborName;
        this.NumberBlowdown = obj.NumberBlowdown;
        this.EngineStartAttempts = obj.EngineStartAttempts;

        if (obj.ServiceDate) {
            this.ServiceDate = moment(obj.ServiceDate).startOf('day').toDate();
        }
    }

    initLaborList(list) {
        var laborArray: ServiceReportLaborModel[] = new Array();
        for (var i = 0; i < list.length; i++) {
            laborArray.push(new ServiceReportLaborModel(list[i]));
        }

        return laborArray;
    }

    private timeConvertAMPM(timeToConvert: Date) {
        var hours = timeToConvert.getHours();
        var minutes = timeToConvert.getMinutes();
        var minutesString = ""
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutesString = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
        var strTime = hours + ':' + minutesString + ' ' + ampm;
        return strTime;
    }
}
