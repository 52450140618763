import { LookupValueModel } from './lookup-value.model';

import * as moment from 'moment';

export class ContactModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    FirstName: string;
    LastName: string;
    Addresses: AddressModel[] = new Array();
    PhoneNumbers: PhoneNumberModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.FirstName = obj.FirstName;
        this.LastName = obj.LastName;

        if (obj.Addresses) {
            this.Addresses = obj.Addresses.map(x => new AddressModel(x));
        }

        if (obj.PhoneNumbers) {
            this.PhoneNumbers = obj.PhoneNumbers.map(x => new PhoneNumberModel(x));
        }
    }
}

export class AddressModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    ContactPk: number;
    TypePk: number;
    Address1: string;
    Address2: string;
    Address3: string;
    Address4: string;
    City: string;
    StatePk: number;
    ZipCode: string;
    Type: LookupValueModel;
    State: LookupValueModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.ContactPk = obj.ContactPk;
        this.TypePk = obj.TypePk;
        this.Address1 = obj.Address1;
        this.Address2 = obj.Address2;
        this.Address3 = obj.Address3;
        this.Address4 = obj.Address4;
        this.City = obj.City;
        this.StatePk = obj.StatePk;
        this.ZipCode = obj.ZipCode;

        if (obj.Type) {
            this.Type = new LookupValueModel(obj.Type);
        }

        if (obj.State) {
            this.State = new LookupValueModel(obj.State);
        }
    }
}

export class PhoneNumberModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    ContactPk: number;
    Number: string;
    TypePk: number;
    Type: LookupValueModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        this.ContactPk = obj.ContactPk;
        this.Number = obj.Number;
        this.TypePk = obj.TypePk;

        if (obj.Type) {
            this.Type = new LookupValueModel(obj.Type);
        }
    }
}