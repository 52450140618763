import { Injectable } from "@angular/core";
import { Globals } from "../../shared/global";
import { Common } from "./common.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import {
    SearchCriteriaModel,
    SearchResultModel,
    ReportModel,
    InventoryCountModel
} from "../../shared/models";

@Injectable()
export class InventoryService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.SUPPLY_CHAIN}/api/inventory`;

    constructor(private http: HttpClient, private common: Common) {}

    searchCount(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<InventoryCountModel>>(`${this.url}/count/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new InventoryCountModel(x))
                }

                return res;
            }));
    }

    getCount(modelPk: number) {
        return this.http.get<InventoryCountModel>(`${this.url}/count/get/${modelPk}`)
            .pipe(map(res => new InventoryCountModel(res)));
    }

    createCount(model: InventoryCountModel) {
        const newModel = this.common.formatDateFields(model, 'CountDate');

        return this.http.post<InventoryCountModel>(`${this.url}/count/create`, newModel)
            .pipe(map(res => new InventoryCountModel(res)));
    }

    updateCount(model: InventoryCountModel) {
        const newModel = this.common.formatDateFields(model, 'CountDate');

        return this.http.put<InventoryCountModel>(`${this.url}/count/update`, newModel)
            .pipe(map(res => new InventoryCountModel(res)));
    }

    deleteCount(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/count/delete/${modelPk}`, { observe : 'response'})
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    printCount(modelPk: number) {
        return this.http.get<ReportModel>(`${this.url}/count/print/${modelPk}`)
            .pipe(
                map(res => new ReportModel(res))
            );
    }
}