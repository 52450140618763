export class MacolaCategoryModel {
    Pk: string;
    ClassId: number;
    Code: string;
    Description: string;
    Accounts: MacolaAccountModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ClassId = obj.ClassId;
        this.Code = obj.Code;
        this.Description = obj.Description;

        if (obj.Accounts) {
            for (var i of obj.Accounts) {
                this.Accounts.push(new MacolaAccountModel(i));
            }
        }
    }
}

export class MacolaAccountModel {
    Pk: number;
    Id: string;
    Name: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Id = obj.Id;
        this.Name = obj.Name;
    }
}
