export class ValveTempValueModel {
    Key: number;
    Pk: number = 0;
    OperatorReportPk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Stage: string;
    Throw: string;
    IsSingleActed: boolean;
    SuctionHE: string;
    SuctionHE2: string;
    SuctionHE3: string;
    SuctionCE: string;
    SuctionCE2: string;
    SuctionCE3: string;
    DischargeHE: string;
    DischargeHE2: string;
    DischargeHE3: string;
    DischargeCE: string;
    DischargeCE2: string;
    DischargeCE3: string;
    ShowDelete: boolean = false;

    constructor(obj: any, key: number) {
        this.Key = key;

        if (obj != null) {
            this.Pk = obj.Pk;
            this.OperatorReportPk = obj.OperatorReportPk;
            this.CreationDate = obj.CreationDate;
            this.CreatedBy = obj.CreatedBy;
            this.LastUpdateDate = obj.LastUpdateDate;
            this.LastUpdateBy = obj.LastUpdateBy;
            this.Stage = this.numberToEmpty(obj.Stage);
            this.Throw = this.numberToEmpty(obj.Throw);
            this.IsSingleActed = obj.IsSingleActed;
            this.SuctionHE = this.numberToEmpty(obj.SuctionHE);
            this.SuctionHE2 = this.numberToEmpty(obj.SuctionHE2);
            this.SuctionHE3 = this.numberToEmpty(obj.SuctionHE3);
            this.SuctionCE = this.numberToEmpty(obj.SuctionCE);
            this.SuctionCE2 = this.numberToEmpty(obj.SuctionCE2);
            this.SuctionCE3 = this.numberToEmpty(obj.SuctionCE3);
            this.DischargeHE = this.numberToEmpty(obj.DischargeHE);
            this.DischargeHE2 = this.numberToEmpty(obj.DischargeHE2);
            this.DischargeHE3 = this.numberToEmpty(obj.DischargeHE3);
            this.DischargeCE = this.numberToEmpty(obj.DischargeCE);
            this.DischargeCE2 = this.numberToEmpty(obj.DischargeCE2);
            this.DischargeCE3 = this.numberToEmpty(obj.DischargeCE3);
            this.ShowDelete = obj.ShowDelete != undefined ? obj.ShowDelete : false;
        }
    }

    differentToObject(objectToCompare: ValveTempValueModel) {
        if (this.Stage != objectToCompare.Stage ||
            this.Throw != objectToCompare.Throw ||
            this.IsSingleActed != objectToCompare.IsSingleActed ||
            this.SuctionHE != objectToCompare.SuctionHE ||
            this.SuctionHE2 != objectToCompare.SuctionHE2 ||
            this.SuctionHE3 != objectToCompare.SuctionHE3 ||
            this.SuctionCE != objectToCompare.SuctionCE ||
            this.SuctionCE2 != objectToCompare.SuctionCE2 ||
            this.SuctionCE3 != objectToCompare.SuctionCE3 ||
            this.DischargeHE != objectToCompare.DischargeHE ||
            this.DischargeHE2 != objectToCompare.DischargeHE2 ||
            this.DischargeHE3 != objectToCompare.DischargeHE3 ||
            this.DischargeCE != objectToCompare.DischargeCE ||
            this.DischargeCE2 != objectToCompare.DischargeCE2 ||
            this.DischargeCE3 != objectToCompare.DischargeCE3) {
            return true;
        }

        return false;
    }

    private numberToEmpty(value: number) {
        return (value > 0 ? value.toString() : '');
    }
}
