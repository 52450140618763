import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    DataflowModel,
    DataflowFieldModel,
    DataflowGroupModel,
    DataflowTypeModel
} from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class DataflowService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/dataflow`;

    data: any = {};

    constructor(private common: Common, private http: HttpClient) {}

    // DataflowType
    get(modelPk: number) {
        const keys = Object.keys(this.data);

        if (keys.length) {
            for (let i of keys) {
                const result = (this.data[i] as DataflowTypeModel[]).find(x => x.Pk == modelPk);

                if (result) {
                    return result;
                }
            }
        }

        return this.http.get<DataflowTypeModel>(`${this.url}?modelPk=${modelPk}`).pipe(map(res => new DataflowTypeModel(res)));
    }

    search(sparkModuleTypePk: number) {
        const key = `Dataflows${sparkModuleTypePk}`;

        if (this.data[key]) {
            return of(this.data[key] as DataflowTypeModel[]);
        }

        return this.http.get<DataflowTypeModel[]>(`${this.url}/search?sparkModuleTypePk=${sparkModuleTypePk}`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new DataflowTypeModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as DataflowTypeModel[];
            }));
    }

    create(model: DataflowTypeModel) {
        const key = `Dataflows${model.SparkModuleTypePk}`;

        return this.http.post<DataflowTypeModel>(`${this.url}/create`, JSON.stringify(model))
            .pipe(map(res => {
                const result = new DataflowTypeModel(res);

                if (this.data[key]) {
                    this.data[key].push(result);
                }

                return result;
            }));
    }

    update(model: DataflowTypeModel) {
        const key = `Dataflows${model.SparkModuleTypePk}`;

        return this.http.put<DataflowTypeModel>(`${this.url}/update`, JSON.stringify(model))
            .pipe(map(res => {
                const result = new DataflowTypeModel(res);

                if (this.data[key]) {
                    let index = -1;

                    for (let i = 0; i < this.data[key].length; i++) {
                        if ((this.data[key][i] as DataflowTypeModel).Pk == model.Pk) {
                            index = i;
                            break;
                        }
                    }

                    if (index >= 0) {
                        this.data[key][index] = result;
                    }
                }

                return result;
            }));
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(res => {
                if (res.status == 200) {
                    Object.keys(this.data).map(x => {
                        this.data[x] = this.data[x].filter((y: DataflowTypeModel) => y.Pk != modelPk);
                    });
                }

                return res.status == 200;
            }));
    }

    // todo: manage dataflows in dataflow types in memory
    // Dataflow
    createDataflow(model: DataflowModel) {
        return this.http.post<DataflowModel>(`${this.url}/create`, JSON.stringify(model))
            .pipe(map(res => new DataflowModel(res)));
    }

    updateDataflow(model: DataflowModel) {
        return this.http.put<DataflowModel>(`${this.url}/update`, JSON.stringify(model))
            .pipe(map(res => new DataflowModel(res)));
    }

    deleteDataflow(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    // todo: manage groups in dataflows in memory
    // DataflowGroup
    createGroup(model: DataflowGroupModel) {
        return this.http.post<DataflowGroupModel>(`${this.url}/createGroup`, JSON.stringify(model)).pipe(
            map(res => new DataflowGroupModel(res))
        );
    }

    updateGroup(model: DataflowGroupModel) {
        return this.http.put<DataflowGroupModel>(`${this.url}/updateGroup`, JSON.stringify(model)).pipe(
            map(res => new DataflowGroupModel(res))
        );
    }

    deleteGroup(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/deleteGroup?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }

    // todo: manage fields in dataflows in memory
    // DataflowField
    createField(model: DataflowFieldModel) {
        return this.http.post<DataflowFieldModel>(`${this.url}/createField`, JSON.stringify(model)).pipe(
            map(res => new DataflowFieldModel(res))
        );
    }

    updateField(model: DataflowFieldModel) {
        return this.http.put<DataflowFieldModel>(`${this.url}/updateField`, JSON.stringify(model)).pipe(
            map(res => new DataflowFieldModel(res))
        );
    }

    deleteField(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/deleteField?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }
}