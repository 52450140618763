import * as moment from 'moment';

export class ServiceReportPartWarrantyModel {
    ServiceReportPartPk: number;
    PartId: string;
    PartName: string;
    PartDescription: string;
    WOPK: number;
    POPK: number;
    ServiceDate: Date;
    PreviousServiceReportPk: number;
    TotalHours: number;
    Quantity: number;
    AverageCost: number;
    CostTotal: number;
    LocationId: string;
    Reason: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.ServiceReportPartPk = obj.ServiceReportPartPk;
        this.PartId = obj.PartId;
        this.PartName = obj.PartName;
        this.PartDescription = obj.PartDescription;
        this.WOPK = obj.WOPK;
        this.POPK = obj.POPK;
        this.PreviousServiceReportPk = obj.PreviousServiceReportPk;
        this.TotalHours = obj.TotalHours;
        this.Quantity = obj.Quantity;
        this.AverageCost = obj.AverageCost;
        this.CostTotal = obj.CostTotal;
        this.LocationId = obj.LocationId;
        this.Reason = obj.Reason;

        if (obj.ServiceDate){
            this.ServiceDate = moment(obj.ServiceDate).toDate();
        }

    }
}