import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { ModuleHistoryModel } from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class ModuleHistoryService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/module-history`;

    constructor(private common: Common, private http: HttpClient) {}

    notify(moduleTypePk: number, modulePk: number, history: ModuleHistoryModel[]) {
        return this.http.post(`${this.url}/notify?moduleTypePk=${moduleTypePk}&modulePk=${modulePk}`, JSON.stringify(history)).pipe(map(res => res));
    }
}