import { EngineValueFieldsModel } from './engine.value.fields.model';
import { CylinderValueModel } from './cylinder.value.model';
import { ValveTempValueModel } from './valve.temp.value.model';
import { PressureValueModel } from './pressure.value.model';
import { PanelValueModel } from './panel.value.model';
import { LubeValueFieldsModel } from './lube.value.fields.model';
import { LevelValueFieldsModel } from './level.value.fields.model';
import { WorkflowModuleModel } from './workflow.model';
import { SparkFileModel } from './spark-file.model';
import * as moment from 'moment';

export class OperatorReportModel {
    Pk: number = 0;
    OperatorId: string;
    OperatorName: string;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    Date: Date = new Date(new Date().toDateString());
    AssetId: string;
    AssetName: string;
    AreaId: string;
    Customer: string;
    Location: string;
    Status: string = 'New';
    LaborHours: number;
    UnitHours: number;
    WOPK: number;
    Comments: string;
    IsStandby: boolean;
    AmbientTemp: number;
    ComponentValues: LubeValueFieldsModel = new LubeValueFieldsModel();
    CompressorValues: ValveTempValueModel[] = new Array();
    CylinderValues: CylinderValueModel[] = new Array();
    EngineValues: EngineValueFieldsModel = new EngineValueFieldsModel();
    FluidLevelsValues: LevelValueFieldsModel[] = new Array();
    PanelValues: PanelValueModel[] = new Array();
    PressuresValues: PressureValueModel[] = new Array();
    CanApprove: boolean;
    WorkflowId: string = 'OR';
    Workflows: WorkflowModuleModel[] = new Array();
    Files: SparkFileModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.OperatorId = obj.OperatorId;
        this.OperatorName = obj.OperatorName;
        this.CreatedBy = obj.CreatedBy;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.Date = moment(obj.Date).startOf('day').toDate();
        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.AreaId = obj.AreaId;
        this.Customer = obj.Customer;
        this.Location = obj.Location;
        this.Status = obj.Status;
        this.LaborHours = this.numberToUndefined(obj.LaborHours);
        this.UnitHours = obj.UnitHours;
        this.WOPK = obj.WOPK == 0 ? undefined : obj.WOPK;
        this.Comments = obj.Comments;
        this.IsStandby = obj.IsStandby;
        this.AmbientTemp = obj.AmbientTemp;
        this.ComponentValues = new LubeValueFieldsModel(obj.ComponentValues);
        this.CompressorValues = this.buildOperatorReportCompressorValues(obj.CompressorValues);
        this.EngineValues = new EngineValueFieldsModel(obj.EngineValues);
        this.CylinderValues = this.buildOperatorReportCylinderValues(obj.CylinderValues);
        this.PanelValues = this.buildOperatorReportPanelValues(obj.PanelValues);
        this.PressuresValues = this.buildOperatorReportPressuresValues(obj.PressuresValues);
        this.FluidLevelsValues.push(new LevelValueFieldsModel(obj.FluidLevelsValues));
        this.CanApprove = obj.CanApprove;
        this.WorkflowId = obj.WorkflowId;

        if (obj.Workflows) {
            for (var i of obj.Workflows) {
                this.Workflows.push(new WorkflowModuleModel(i));
            }
        }
        if (obj.Files) {
            this.Files = obj.Files.map(x => new SparkFileModel(x));
        }
    }

    private buildOperatorReportCompressorValues(compressorValues: any[]) {
        if (compressorValues == null)
            return new Array();

        let valveTempValueList: ValveTempValueModel[] = new Array();
        for (var i = 0; i < compressorValues.length; i++) {
            valveTempValueList.push(new ValveTempValueModel(compressorValues[i], i + 1));
        }

        return valveTempValueList;
    }

    private buildOperatorReportCylinderValues(cylinderValues: any[]) {
        if (cylinderValues == null)
            return new Array();

        let cylinderValueList: CylinderValueModel[] = new Array();
        for (var i = 0; i < cylinderValues.length; i++) {
            cylinderValueList.push(new CylinderValueModel(cylinderValues[i], i + 1));
        }

        return cylinderValueList;
    }

    private buildOperatorReportPanelValues(panelValues: any[]) {
        if (panelValues == null)
            return new Array();

        let panelValuesList: PanelValueModel[] = new Array();
        for (var i = 0; i < panelValues.length; i++) {
            panelValuesList.push(new PanelValueModel(panelValues[i], i + 1));
        }

        return panelValuesList;
    }

    private buildOperatorReportPressuresValues(pressuresValues: any[]) {
        if (pressuresValues == null)
            return new Array();

        let pressuresValuesList: PressureValueModel[] = new Array();
        for (var i = 0; i < pressuresValues.length; i++) {
            pressuresValuesList.push(new PressureValueModel(pressuresValues[i], i + 1));
        }

        return pressuresValuesList;
    }

    private numberToUndefined(value: number) {
        return (value > 0 ? value : undefined);
    }
}
