import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ConfirmModel,
    DataflowModel,
    DataflowGroupModel,
    DataflowFieldModel,
    DataflowFieldWorkflowDetailModel,
    DataflowTypeModel,
    ModalFooterModel,
    SparkFileModel,
    UserModel,
    WorkflowModuleModel
} from '../../models';

import {
    Common,
    DataflowService,
    SparkFileService,
    SparkModuleTypeService
} from '../../../core/services';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import * as moment from 'moment';

@Component({
    selector: 'dataflow',
    templateUrl: 'dataflow.component.html'
})
export class DataflowComponent {
    @ViewChild('dataflowForm') dataflowForm: NgForm;

    @Output() onSave: EventEmitter<any> = new EventEmitter();

    mode: string = 'wizard';
    sparkModuleTypePk: number = 0;

    currentStep: number = 1;
    defaultDateTime: Date = moment().hour(12).minute(0).toDate();
    dataflows: DataflowModel[] = new Array();
    dataflowTypes: DataflowTypeModel[];
    deletingFile: any = {};
    editableFields: DataflowFieldWorkflowDetailModel[] = new Array();
    filteredResults: any = {};
    formFooter: ModalFooterModel = new ModalFooterModel();
    hasSelectionStep: boolean = true;
    invalid: any = {};
    loaded: any = {
        data: false,
        model: false,
        dataflow: false
    };
    model: any = {};
    modelOriginal: any = {};
    pagedResults: any = {};
    pageSize: number = 10;
    pageStart: number = 1;
    results: any = {};
    searchText: any = {};
    selectAll: any = {};
    selectOptions: any = {};
    selectedBitList: any = {};
    selectedType: number;
    selectedSubType: number;
    showType: boolean = true;
    stepTitle: string;
    steps: DataflowGroupModel[] = [];
    selectedTypeahead: any = {};
    selectedFile: any = {};
    tabs: DataflowGroupModel[] = [];
    typeaheads: any = {};
    totalSteps: number = 1;
    uploadProgress: any = {};
    uploading: boolean = false;
    uploadSubscription: Subscription = new Subscription();
    user: UserModel = new UserModel();
    validation: any = {};

    confirmDeleteFile: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to delete the file?'
    });

    confirmLoseChanges: ConfirmModel = new ConfirmModel({
        Text: 'Are you sure you want to cancel and lose changes?',
        onConfirm: () => { this.onConfirmResetForm() }
    });

    constructor(
        public common: Common,
        public dataflowService: DataflowService,
        public sparkFileService: SparkFileService,
        public sparkModuleTypeService: SparkModuleTypeService
    ) {
        this.user = this.common.getUser();
    }

    private _getDataflows() {
        return this.dataflowService.search(this.sparkModuleTypePk)
            .pipe(map(data => {
                this.dataflowTypes = data;

                this.checkDataflowType();

                this.registerLoad("dataflow");
            }));
    }

    private _getModuleType(type: string) {
        return this.sparkModuleTypeService.getById(type).pipe(map(data => this.sparkModuleTypePk = data.Pk));
    }

    registerLoad(type: string) {
        this.loaded[type] = true;

        if (this.loaded.data && this.loaded.dataflow && this.loaded.model) {
            const dataflow = this.dataflows.find(x => x.Type.toLowerCase() == this.model.Type.toLowerCase());

            if (dataflow) {
                this.selectedSubType = dataflow.Pk;
                this.onSelectedSubType();
                this.loadTypeaheads();
            }
            else {
                this.common.showError('Dataflow Not Found', `Unable to locate dataflow based on <strong>${this.model.Type}</strong>.`);
            }
        }
    }

    setInputs(type: string, mode: string, model: any = {}) {
        this.mode = mode;

        if (model.Pk) {
            this.model = model;
            this.modelOriginal = JSON.parse(JSON.stringify(model));
        }

        this.common.handleRequests([
            this._getModuleType(type)
        ]).then(() => {
            this.common.handleRequests([this._getDataflows()])
        });
    }

    setupFields() {
        const groups = this.dataflows.find(x => x.Pk == this.selectedSubType).Groups;

        groups.map(x => {
            x.Fields.map(f => {
                if (f.Type == "select") {
                    const values = [];

                    if (f.Options.Values) {
                        values.concat(f.Options.Values);
                    }

                    if (f.Options.DataSource) {
                        const data = this.typeaheads[f.Options.DataSource];

                        if (data) {
                            data.map((x: any) => {
                                values.push({
                                    Text: x[f.Options.FieldName],
                                    Value: x[f.Options.FieldName]
                                });
                            });
                        }
                    }

                    this.selectOptions[f.Id] = values;
                }

                if (f.Type == "bit") {
                    // instead of duplicating logic, calling this
                    // establishes the correct values when loading
                    this.onModelChanged(f);
                }
            });
        });
    }

    loadTypeaheads() {
        const groups = this.dataflows.find(x => x.Pk == this.selectedSubType).Groups;

        groups.map(x => {
            x.Fields.map(f => {
                if (f.Type == "autocomplete") {
                    Object.keys(f.Options.Mapping).map(m => {
                        const key = f.Options.Mapping[m];

                        if (key == f.Id && this.model[f.Id]) {
                            const match = this.typeaheads[f.Options.DataSource].find(x => x[m] == this.model[f.Id]);

                            this.selectedTypeahead[f.Id] = match ? match[f.Options.FieldName] : this.model[f.Id];
                        }
                    });
                }

                if (f.Type == "bitlist") {
                    this.results[f.Id] = this.typeaheads[f.Options.DataSource];
                    this.filteredResults[f.Id] = this.results[f.Id].slice();
                    this.pagedResults[f.Id] = [];
                }
            });

            return x;
        });
    }

    saveModel() {
        this.common.showError("Missing Method", "SaveModel not implemented");
    }

    getDataflowType() {
        const dataflowType = this.dataflowTypes.find(x => x.Pk == this.selectedType);

        return dataflowType ? dataflowType.Name : '';
    }

    getDataflow() {
        this.common.showError("Missing Method", "GetDataflow not implemented");
    }

    show(model?: any) {
        this.common.showModal('dataflow-modal');

        this.getStepTitle();

        if (this.dataflows.length == 1) {
            this.hasSelectionStep = false;
            this.selectedSubType = this.dataflows[0].Pk;

            this.onSelectedSubType();

            const self = this;

            setTimeout(() => {
                self.onNextStep();
            }, 1);
        }
    }

    onSelectedType() {
        this.dataflows = [];

        if (!this.selectedType) {
            return;
        }

        this.dataflows = this.dataflowTypes.find(x => x.Pk == this.selectedType).Dataflows.slice();
        this.selectedSubType = 0;
    }

    onSelectedSubType() {
        this.steps = [];
        this.tabs = [];

        if (!this.selectedSubType) {
            return;
        }

        const groups = this.dataflows.find(x => x.Pk == this.selectedSubType).Groups;

        // Loop through fields to setup model values
        if (!this.model.Pk) {
            this.resetData();

            groups.map(x => {
                x.Fields.map(f => {
                    // set default values in model
                    if (f.Type == "bit") {
                        this.model[f.Id] = (f.DefaultValue == "1") as boolean;
                    }
                    else if ((f.DefaultValue||'').toString().length > 0) {
                        this.model[f.Id] = f.DefaultValue;
                    }
                });

                return x;
            });
        }

        this.setupFields();

        if (this.mode == "wizard") {
            this.steps = groups;
        }

        if (this.mode == "tab") {
            const tabs = {};

            groups.map(x => {
                if (!tabs[x.Title]) {
                    tabs[x.Title] = new DataflowGroupModel(x);
                }
                else {
                    x.Fields.map(f => tabs[x.Title].Fields.push(f));
                }
            });

            this.tabs = Object.keys(tabs).map(x => tabs[x]) as DataflowGroupModel[];
        }

        groups.map(x => {
            x.Fields.map(f => {
                if (f.Type == "bitlist") {
                    this.selectAll[f.Id] = false;
                    this.selectedBitList[f.Id] = 0;
                    this.results[f.Id] = this.typeaheads[f.Options.DataSource];
                    this.filteredResults[f.Id] = this.results[f.Id].slice();
                    this.pagedResults[f.Id] = [];
                }
            });
        });

        if (this.model.Workflows && this.model.Workflows.length) {
            groups.map(x => {
                x.Fields.map(y => {
                    y.WorkflowDetails.map(w => this.editableFields.push(w));
                });
            });
        }

        this.totalSteps = this.steps.length + 1;
        this.formFooter.DisableConfirm = false;
    }

    validateFields() {
        if (this.mode == "wizard" && this.currentStep <= 1) {
            this.formFooter.DisableConfirm = false;
            return;
        }

        const validate = (fields: DataflowFieldModel[]) => {
            for (let i of fields) {
                if (i.Required && !i.Options['Disabled'] && !i.Options['Hidden']) {
                    if ((i.Type == 'bit' && this.model[i.Id] == null) ||
                        (i.Type == 'select' && !this.model[i.Id]) ||
                        (i.Type != 'bit' && i.Type != 'bitlist' && !this.model[i.Id])
                    ) {
                        this.validation[i.Id] = false;
                        return false;
                        break;
                    }

                    if (i.Type == 'autocomplete' && i.Options.AllowCustomValue && !this.model[i.Id]) {
                        this.validation[i.Id] = false;
                        return false;
                        break;
                    }

                    if (i.Type == 'bitlist') {
                        const selected = this.typeaheads[i.Options.DataSource].filter(t => t.Selected).slice();

                        if (!selected.length) {
                            this.validation[i.Id] = false;
                            return false;
                            break;
                        }
                    }
                }
            }

            return true;
        };

        const fields = [];
        let isValid = true; // this.dataflowForm.valid;

        if (this.mode == "wizard") {
            this.steps[this.currentStep - 2].Fields.map(x => fields.push(x));

            if (isValid) {
                isValid = validate(fields);
            }

            this.formFooter.DisableConfirm = !isValid;
        }

        if (this.mode == "tab") {
            this.tabs.map(x => {
                x.Fields.map(f => fields.push(f));
            });

            if (isValid) {
                isValid = validate(fields);
            }
        }
    }

    getProgress() {
        let progress = 10;

        if (this.currentStep > 1 && this.totalSteps > 1) {
            const offset = this.hasSelectionStep ? 0 : 1;
            progress = (this.currentStep - offset) * (100 / (this.totalSteps - offset));
        }

        return progress;
    }

    hasChanges() {
        const diff = [];
        const exclude = this.common.getExcludedFields().concat(['Files']);

        Object.keys(this.model).map(x => {
            if (exclude.indexOf(x) < 0) {
                let originalValue = (this.modelOriginal[x] || '').toString();
                let modelValue = (this.model[x] || '').toString();

                if (x.toLowerCase().indexOf('date') >= 0) {
                    originalValue = moment(this.modelOriginal[x] || '').toDate().getTime();
                    modelValue = moment(this.model[x] || '').toDate().getTime();
                }

                if (originalValue != modelValue) {
                    diff.push(x);
                }
            }
        });

        return diff.length > 0;
    }

    resetData() {
        this.model = {
            Status: "NEW"
        };
        this.modelOriginal = {
            Status: "NEW"
        };
        this.selectedTypeahead = {};
        this.selectedFile = {};
    }

    onResetForm() {
        if (this.hasChanges()) {
            this.common.showConfirm(this.confirmLoseChanges);
        }
        else {
            this.onConfirmResetForm();
        }
    }

    onConfirmResetForm() {
        this.common.hideConfirm();
        this.common.hideModal('dataflow-modal');
        this.formFooter.resetAll();

        this.resetData();

        this.currentStep = 1;
        this.stepTitle = '';
        this.totalSteps = 1;

        this.dataflowForm.resetForm();

        const e = document.querySelector('#dataflow-modal .modal-dialog');

        if (e) {
            e.classList.remove('modal-md');
        }

        const self = this;
        setTimeout(() => self.checkDataflowType());
    }

    checkDataflowType() {
        if (this.dataflowTypes.length == 1) {
            this.selectedType = this.dataflowTypes[0].Pk;
            this.onSelectedType();
        }
    }

    onPrevStep() {
        this.currentStep--;
        this.getStepTitle();
        this.resizeWizard();
        this.validateFields();
    }

    onNextStep(bypass: boolean = false) {
        if (this.currentStep == this.totalSteps) {
            this.saveModel();
            return;
        }

        this.currentStep++;
        this.getStepTitle();
        this.resizeWizard();
        this.validateFields();
    }

    getStepTitle() {
        if (this.currentStep == 1) {
            this.stepTitle = 'New Type';
        }
        else {
            const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);

            this.stepTitle = `${dataflow.Name} - ${this.steps[this.currentStep - 2].Title}`;
        }
    }

    resizeWizard() {
        if (this.mode != "wizard" || this.currentStep == 1) {
            return;
        }

        const groups = this.dataflows.find(x => x.Pk == this.selectedSubType).Groups;
        const group = groups[this.currentStep - 2];

        const resizable = group.Fields.filter(x => x.Type == "bitlist").length > 0;
        const e = document.querySelector('#dataflow-modal .modal-dialog');

        if (e) {
            if (resizable) {
                e.classList.add('modal-md');
            }
            else {
                e.classList.remove('modal-md');
            }
        }
    }

    validateTypeahead(field: DataflowFieldModel) {
        if (!this.model[field.Id]) {
            this.selectedTypeahead[field.Id] = null;
            this.onTypeaheadChange(field);
        }
    }

    onTypeaheadSelect(field: DataflowFieldModel, selected: TypeaheadMatch) {
        if (field.Options && field.Options.Mapping) {
            Object.keys(field.Options.Mapping).map(x => {
                const key = field.Options.Mapping[x];

                this.model[key] = selected.item[x];
            });

            this.onModelChanged(field);
        }
        else {
            this.common.showError("Missing Options", `The autocomplete field ${field.Id} is missing options to define field mappings.`);
        }
    }

    onTypeaheadChange(field: DataflowFieldModel) {
        // Clear all related fields as the user is typing
        Object.keys(field.Options.Mapping).map(x => {
            const key = field.Options.Mapping[x];

            delete this.model[key];
        });

        // Set the custom value
        if (this.selectedTypeahead[field.Id] && field.Options.AllowCustomValue) {
            this.model[field.Id] = this.selectedTypeahead[field.Id];
        }

        this.onModelChanged(field);
    }

    onSelect(field: DataflowFieldModel) {
        if (!this.model[field.Id]) {
            return;
        }

        if (field.Options && field.Options.DataSource) {
            const selectedValue = this.typeaheads[field.Options.DataSource].find(x => x[field.Options.FieldName] == this.model[field.Id]);

            Object.keys(field.Options.Mapping).map(m => {
                const key = field.Options.Mapping[m];
                const value = selectedValue[m];

                if (value) {
                    this.model[key] = value;
                }
            });
        }

        this.onModelChanged(field);
    }

    checkCustomValue(field: DataflowFieldModel) {
        if (!field.Options.AllowCustomValue) {
            return false;
        }

        let isCustomValue = false;

        Object.keys(field.Options.Mapping).map(m => {
            const key = field.Options.Mapping[m];

            if (!isCustomValue && key == field.Id && this.model[field.Id]) {
                const match = this.typeaheads[field.Options.DataSource].find(x => x[m] == this.model[field.Id]);

                if (!match) {
                    isCustomValue = true;
                }
            }
        });

        return isCustomValue;
    }

    onFileAttached(fieldId: string, event: any) {
        if (event.target.files.length == 0) {
            this.selectedFile[fieldId] = '';
            return;
        }

        const file = event.target.files[0] as File;
        // todo: Move this to settings?
        const limit = 2090000000;

        if (file.size > limit) {
            const fileSize = (file.size / 1024) / 1024;
            this.selectedFile[fieldId] = '';
            this.common.showError('File Too Large', `The attached file <strong>${file.name}</strong> is too large at <strong>${fileSize.toFixed(2)} MB</strong>.
                The file size limit is <strong>${(limit / 1024 / 1024)} MB</strong>.`);
            return;
        }

        this.selectedFile[fieldId] = file;
        this.formFooter.DisableConfirm = true;
    }

    onAddFile(field: DataflowFieldModel) {
        if (!this.model[field.Id]) {
            this.model[field.Id] = [];
        }

        // Temporary model to display until the file is uploaded
        // to the server and the proper model is returned
        const file = new SparkFileModel();
        file.FileName = this.selectedFile[field.Id].name;
        file.Description = this.selectedFile[field.Id].name;

        this.model[field.Id].push(file);

        var index = this.model[field.Id].length - 1;
        this.uploading = true;

        this.uploadSubscription = this.sparkFileService.create(this.selectedFile[field.Id], this.sparkModuleTypePk, this.model.Pk)
            .pipe()
            .subscribe(
                data => {
                    if (data.type == HttpEventType.UploadProgress) {
                        this.uploadProgress[field.Id + index] = Math.round(100 * data.loaded / data.total);
                    }

                    if (data.type == HttpEventType.Response) {
                        this.formFooter.DisableConfirm = false;

                        this.uploading = false;

                        // this needs to change if we support attaching multiple files at once
                        this.model[field.Id][index] = new SparkFileModel(data.body[0]);

                        // this.uploadProgress[field.Id + index] = 0;
                        this.selectedFile[field.Id] = '';

                        const e = document.getElementById(field.Id) as HTMLInputElement;

                        if (e) {
                            e.value = '';
                        }
                    }
                },
                error => {
                    this.uploadProgress[field.Id + index] = 0;
                    this.uploading = false;

                    this.model[field.Id].slice(index, 1);

                    this.common.showError('Error Uploading File', error);
                }
            );

        delete this.selectedFile[field.Id];
    }

    onClearFile(field: DataflowFieldModel) {
        delete this.selectedFile[field.Id];
        this.formFooter.DisableConfirm = false;
    }

    showFileActions(field: DataflowFieldModel, index: number) {
        return !this.deletingFile[field.Id + index] && (this.uploadProgress[field.Id + index] >= 100 || this.model[field.Id][index].Pk);
    }

    onCancelUpload(file: SparkFileModel, field: DataflowFieldModel, index: number) {
        (document.getElementById(field.Id) as HTMLInputElement).value = '';

        this.uploadSubscription.unsubscribe();

        this.model[field.Id].splice(index, 1);

        this.uploading = false;
        this.uploadProgress[field.Id + index] = 0;
        delete this.selectedFile[field.Id];

        this.validateFields();

        this.common.showMessage(`Successfully cancelled upload.`);
    }

    onDeleteFile(file: SparkFileModel, field: DataflowFieldModel, index: number) {
        if (file.Pk) {
            const file = this.model[field.Id][index] as SparkFileModel;
            this.confirmDeleteFile.Content.Text = `Are you sure you want to delete the file <strong>${file.Description}</strong>?`;
            this.confirmDeleteFile.Footer.onConfirm = () => this.onConfirmDeleteFile(file, field, index);
            this.common.showConfirm(this.confirmDeleteFile);
        }
        else {
            this.onConfirmDeleteFile(file, field, index);
        }
    }

    onConfirmDeleteFile(file: SparkFileModel, field: DataflowFieldModel, index: number) {
        this.confirmDeleteFile.disableAll();

        this.deletingFile[field.Id + index] = 1;

        this.sparkFileService.delete(file.Pk, file.FileName)
            .subscribe(
                () => {
                    this.common.hideConfirm();
                    this.confirmDeleteFile.enableAll();

                    delete this.deletingFile[field.Id + index];
                    this.model[field.Id].splice(index, 1);

                    this.common.showMessage(`Successfully deleted file.`);
                },
                error => {
                    this.common.showError('Error deleting file', error);
                    this.confirmDeleteFile.enableAll();
                }
            );
    }

    onApplyFilter(field: DataflowFieldModel) {
        const fields = field.Options.Mapping.map(x => x.FieldName);
        const searchText = (this.searchText[field.Id] || '').toLowerCase();

        if (searchText.length == 0) {
            this.filteredResults[field.Id] = this.results[field.Id].slice();
        }
        else {
            this.filteredResults[field.Id] = [];

            this.results[field.Id].map(x => {
                for (let i of fields) {
                    if ((x[i]||'').toString().toLowerCase().indexOf(searchText) >= 0) {
                        this.filteredResults[field.Id].push(x);
                        break;
                    }
                }
            });
        }

        this.onSetPage(field, 1);
    }

    onBitListSelectAll(field: DataflowFieldModel) {
        const selected = this.selectAll[field.Id];
        this.filteredResults[field.Id].map(x => x.Selected = selected);
        this.selectedBitList[field.Id] = this.results[field.Id].filter(x => x.Selected).length;

        this.validateFields();
    }

    onBitListChange(field: DataflowFieldModel, value: any, index: number) {
        this.selectedBitList[field.Id] = this.results[field.Id].filter(x => x.Selected).length;
        this.selectAll[field.Id] = this.selectedBitList[field.Id].length == this.results[field.Id].length;

        this.validateFields();
    }

    onSetPage(field: DataflowFieldModel, page: number) {
        this.pagedResults[field.Id] = this.filteredResults[field.Id].slice((page - 1) * this.pageSize, page * this.pageSize);
    }

    onModelChanged(field: DataflowFieldModel) {
        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);

        if (!dataflow) {
            return;
        }

        let dependents: DataflowFieldModel[] = [];

        dataflow.Groups.map(x => {
            dependents = dependents.concat(x.Fields.filter(f => f.ParentPk == field.Pk));
        });

        if (field.Required && !this.model[field.Id]) {
            this.invalid[field.Id] = true;
        }
        else {
            delete this.invalid[field.Id];
        }

        if (!dependents.length) {
            this.validateFields();
            return;
        }

        let selectedValue;

        if (field.Type == "autocomplete") {
            selectedValue = this.typeaheads[field.Options.DataSource].find(x => x[field.Options.FieldName] == this.selectedTypeahead[field.Id]);
        }
        if (field.Type == "bit") {
            selectedValue = this.model[field.Id] as boolean;
        }

        for (let x of dependents) {
            if (field.Type == "bit") {
                const onParentToggle = x.Options.OnParentToggle;

                if (onParentToggle) {
                    x.Options[onParentToggle.State] = onParentToggle.OnValue ? this.model[field.Id] : !this.model[field.Id];
                }
            }

            if (!selectedValue) {
                continue;
            }

            const hasValue = (this.model[x.Id]||'').length > 0;

            // Always set the value for a label type of input since it's readonly
            if (x.Type == "label" || x.Type == "hidden") {
                this.model[x.Id] = selectedValue[x.Options.ParentSource];
            }
            else if (!hasValue) {
                if (x.Type == "autocomplete") {
                    Object.keys(x.Options.Mapping).map(m => {
                        const key = x.Options.Mapping[m];
                        const value = this.typeaheads[x.Options.DataSource].find(t => t[x.Options.FieldName] == selectedValue[x.Options.ParentSource]);

                        if (x.Options.Conditions) {
                            for (let key in x.Options.Conditions) {
                                this.results[x.Id] = this.results[x.Id].filter(t => t[key] == x.Options.Conditions[key]);
                            }
                        }

                        if (value) {
                            this.model[key] = value[m];
                            this.selectedTypeahead[x.Id] = value[x.Options.FieldName];
                            this.validation[key] = true;

                            let chilDependents: DataflowFieldModel[] = [];

                            for (let i of dataflow.Groups) {
                                if (i.Fields.filter(f => f.ParentPk == x.Pk).length) {
                                    this.onModelChanged(x);
                                    break;
                                }
                            }
                        }
                    });
                }

                if (x.Type == 'bitlist') {
                    this.results[x.Id] = this.typeaheads[x.Options.DataSource].filter(t => t[x.Options.ParentSource] == selectedValue[field.Options.FieldName]).slice();

                    if (x.Options.Conditions) {
                        for (let key in x.Options.Conditions) {
                            this.results[x.Id] = this.results[x.Id].filter(t => t[key] == x.Options.Conditions[key]);
                        }
                    }

                    this.filteredResults[x.Id] = this.results[x.Id].slice();
                    this.onSetPage(x, 1);
                }

                if (x.Type == "text") {
                    this.model[x.Id] = selectedValue[x.Options.ParentSource];
                    this.validation[x.Id] = true;
                }
            }
        }

        this.validateFields();
    }

    checkReadonly(field: DataflowFieldModel) {
        const status = this.model.Status.toLowerCase();

        if (!this.model.Pk || (this.model.Pk && (status == "new" || status == "denied") && this.model.CreatedBy == this.user.AppUser.ApplicationUserPk)) {
            return false;
        }

        if (status == "pending" && this.model.CanApprove && this.model.Workflows && this.model.Workflows.length) {
            const activeWorkflow = (this.model.Workflows as WorkflowModuleModel[]).find(x => x.Active);

            if (!activeWorkflow) {
                return true;
            }

            const activeWorkflowDetail = activeWorkflow.Details.find(x => x.Pk > 0 && x.Status.toLowerCase() == "pending");

            if (!activeWorkflowDetail) {
                return true;
            }

            return !this.editableFields.find(x => x.DataflowFieldPk == field.Pk && x.WorkflowDetailPk == activeWorkflowDetail.WorkflowDetailPk);
        }

        return true;
    }
}