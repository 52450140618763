<modal id="safety-corrective-action-form" class="modal-md">
    <header>{{model?.Pk ? 'Edit' : 'New'}} Corrective Action</header>

    <form #safetyCorrectiveActionForm="ngForm" novalidate autocomplete="off">
        <div class="form-row">
            <div class="form-group col-sm-6">
                <label for="ResponsiblePartyPk">Responsible Party:</label>
                <input name="ResponsiblePartyPk" type="search" class="form-control" [(ngModel)]="selectedUser" (ngModelChange)="onResponsiblePartyChange($event)" required (typeaheadOnSelect)="onResponsiblePartySeleted($event)" [typeahead]="users" [typeaheadScrollable]="true" typeaheadOptionField="DisplayName" [disabled]="!editable">
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-sm-6">
                <label for="TargetCloseDate">Target Close Date:</label>

                <p-calendar #targetCloseDateRef="ngModel" [(ngModel)]="model.TargetCloseDate" (ngModelChange)="onValidateForm()" required name="TargetCloseDate" readonlyInput="true" [disabled]="!editable" [inputStyleClass]="model.TargetCloseDate && !targetCloseDateRef.errors ? 'form-control ng-valid' : 'form-control ng-invalid'">
                </p-calendar>
            </div>

            <div class="form-group col-sm-6" *ngIf="model.Pk">
                <label for="CompletionDate">Completion Date:</label>

                <p-calendar #completionDateRef="ngModel" [(ngModel)]="model.CompletionDate" (ngModelChange)="onValidateForm()" name="CompletionDate" readonlyInput="true" [disabled]="!editable" [inputStyleClass]="model.CompletionDate && !completionDateRef.errors ? 'form-control ng-valid' : 'form-control ng-invalid'">
                </p-calendar>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-sm-12">
                <label for="Description">Description:</label>
                <textarea rows="3" id="Description" name="Description" type="text" class="form-control" required [(ngModel)]="model.Description" (ngModelChange)="onValidateForm()" [disabled]="!editable"></textarea>
            </div>
        </div>

        <div class="form-row" *ngIf="model.Pk">
            <div class="form-group col-sm-12">
                <label for="Feedback">Feedback:</label>
                <textarea rows="3" id="Feedback" name="Feedback" type="text" class="form-control" [(ngModel)]="model.Feedback" (ngModelChange)="onValidateForm()" [disabled]="!editable"></textarea>
            </div>
        </div>
    </form>

    <modal-footer [model]="modalFooter"></modal-footer>
</modal>