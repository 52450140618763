export class AreaModel {
    Pk: number;
    Id: string;
    Name: string;
    RegionPk: number;
    RegionId: string;
    RegionName: string;
    DivisionPk: number;
    DivisionId: string;
    DivisionName: string;
    AreaManagerId: string;
    AreaManagerName: string;
    RegionalManagerId: string;
    RegionalManagerName: string;
    DivisionManagerId: string;
    DivisionManagerName: string;
    DisplayName: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = Number(obj.Pk);
        this.Id = obj.Id;
        this.Name = obj.Name;
        this.RegionPk = Number(obj.RegionPk);
        this.RegionId = obj.RegionId;
        this.RegionName = obj.RegionName;
        this.DivisionPk = Number(obj.DivisionPk);
        this.DivisionId = obj.DivisionId;
        this.DivisionName = obj.DivisionName;
        this.AreaManagerId = obj.AreaManagerId;
        this.AreaManagerName = obj.AreaManagerName;
        this.RegionalManagerId = obj.RegionalManagerId;
        this.RegionalManagerName = obj.RegionalManagerName;
        this.DivisionManagerId = obj.DivisionManagerId;
        this.DivisionManagerName = obj.DivisionManagerName;
        this.DisplayName = `${this.Id} - ${this.Name}`;
    }
}