export class EngineValueFieldsModel {
    Pk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    OperatorReportPk: number;
    RpmOperating: number;
    RpmKill: number;
    BtuOperating: number;
    BtuKill: number;
    AirTempOperating: number;
    AirTempKill: number;
    EJWTempOperating: number;
    EJWTempKill: number;
    EJWPressureOperating: number;
    EJWPressureKill: number;
    FuelCorrectionOperating: number;
    FuelCorrectionKill: number;
    OilPressureOperating: number;
    OilPressureKill: number;
    OilTempOperating: number;
    OilTempKill: number;
    LoadPercentOperating: number;
    LoadPercentKill: number;
    OilFilterDiffOperating: number;
    OilFilterDiffKill: number;
    AirRestrictionOperating: number;
    AirRestrictionKill: number;
    CrankcasePressureOperating: number;
    CrankcasePressureKill: number;
    AuxWaterTempOperating: number;
    AuxWaterTempKill: number;
    AuxWaterPressureOperating: number;
    AuxWaterPressureKill: number;
    BatteryVoltsOperating: number;
    BatteryVoltsKill: number;
    CatalyticConvDiffPressureOperating: number;
    CatalyticConvDiffPressureKill: number;
    ManifoldVacPressLBOperating: number;
    ManifoldVacPressLBKill: number;
    ManifoldVacPresRBOperating: number;
    ManifoldVacPresRBKill: number;
    ConvTempPreOperating: number;
    ConvTempPreKill: number;
    ConvTempPostOperating: number;
    ConvTempPostKill: number;
    NoXPanelMeterOperating: number;
    NoXPanelMeterKill: number;
    FuelPressureOperating: number;
    FuelPressureKill: number;
    FuelPotPressureOperating: number;
    FuelPotPressureKill: number;
    FuelCommandOperating: number;
    FuelCommandKill: number;
    ThrottleCommandOperating: number;
    ThrottleCommandKill: number;
    TurboBypassOperating: number;
    TurboBypassKill: number;
    FuelTempOperating: number;
    FuelTempKill: number;
    TimingOperating: number;
    TimingKill: number;
    HydraxPsiOperating: number;
    HydraxPsiKill: number;
    RpmSpeedRefOperating: number;
    RpmSpeedRefKill: number;
    RpmMotorSpeedOperating: number;
    RpmMotorSpeedKill: number;
    MotorCurrentOperating: number;
    MotorCurrentKill: number;
    DriveEndOperating: number;
    DriveEndKill: number;
    NonDriveEndOperating: number;
    NonDriveEndKill: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.OperatorReportPk = obj.OperatorReportPk;
        this.Pk = obj.Pk;
        this.CreationDate = obj.CreationDate;
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = obj.LastUpdateDate;
        this.LastUpdateBy = obj.LastUpdateBy;
        this.OperatorReportPk = obj.OperatorReportPk;
        this.RpmOperating = this.numberToUndefined(obj.RpmOperating);
        this.RpmKill = this.numberToUndefined(obj.RpmKill);
        this.BtuOperating = this.numberToUndefined(obj.BtuOperating);
        this.BtuKill = this.numberToUndefined(obj.BtuKill);
        this.AirTempOperating = this.numberToUndefined(obj.AirTempOperating);
        this.AirTempKill = this.numberToUndefined(obj.AirTempKill);
        this.EJWTempOperating = this.numberToUndefined(obj.EJWTempOperating);
        this.EJWTempKill = this.numberToUndefined(obj.EJWTempKill);
        this.EJWPressureOperating = this.numberToUndefined(obj.EJWPressureOperating);
        this.EJWPressureKill = this.numberToUndefined(obj.EJWPressureKill);
        this.FuelCorrectionOperating = this.numberToUndefined(obj.FuelCorrectionOperating);
        this.FuelCorrectionKill = this.numberToUndefined(obj.FuelCorrectionKill);
        this.OilPressureOperating = this.numberToUndefined(obj.OilPressureOperating);
        this.OilPressureKill = this.numberToUndefined(obj.OilPressureKill);
        this.OilTempOperating = this.numberToUndefined(obj.OilTempOperating);
        this.OilTempKill = this.numberToUndefined(obj.OilTempKill);
        this.LoadPercentOperating = this.numberToUndefined(obj.LoadPercentOperating);
        this.LoadPercentKill = this.numberToUndefined(obj.LoadPercentKill);
        this.OilFilterDiffOperating = this.numberToUndefined(obj.OilFilterDiffOperating);
        this.OilFilterDiffKill = this.numberToUndefined(obj.OilFilterDiffKill);
        this.AirRestrictionOperating = this.numberToUndefined(obj.AirRestrictionOperating);
        this.AirRestrictionKill = this.numberToUndefined(obj.AirRestrictionKill);
        this.CrankcasePressureOperating = this.numberToUndefined(obj.CrankcasePressureOperating);
        this.CrankcasePressureKill = this.numberToUndefined(obj.CrankcasePressureKill);
        this.AuxWaterTempOperating = this.numberToUndefined(obj.AuxWaterTempOperating);
        this.AuxWaterTempKill = this.numberToUndefined(obj.AuxWaterTempKill);
        this.AuxWaterPressureOperating = this.numberToUndefined(obj.AuxWaterPressureOperating);
        this.AuxWaterPressureKill = this.numberToUndefined(obj.AuxWaterPressureKill);
        this.BatteryVoltsOperating = this.numberToUndefined(obj.BatteryVoltsOperating);
        this.BatteryVoltsKill = this.numberToUndefined(obj.BatteryVoltsKill);
        this.CatalyticConvDiffPressureOperating = this.numberToUndefined(obj.CatalyticConvDiffPressureOperating);
        this.CatalyticConvDiffPressureKill = this.numberToUndefined(obj.CatalyticConvDiffPressureKill);
        this.ManifoldVacPressLBOperating = this.numberToUndefined(obj.ManifoldVacPressLBOperating);
        this.ManifoldVacPressLBKill = this.numberToUndefined(obj.ManifoldVacPressLBKill);
        this.ManifoldVacPresRBOperating = this.numberToUndefined(obj.ManifoldVacPresRBOperating);
        this.ManifoldVacPresRBKill = this.numberToUndefined(obj.ManifoldVacPresRBKill);
        this.ConvTempPreOperating = this.numberToUndefined(obj.ConvTempPreOperating);
        this.ConvTempPreKill = this.numberToUndefined(obj.ConvTempPreKill);
        this.ConvTempPostOperating = this.numberToUndefined(obj.ConvTempPostOperating);
        this.ConvTempPostKill = this.numberToUndefined(obj.ConvTempPostKill);
        this.NoXPanelMeterOperating = this.numberToUndefined(obj.NoXPanelMeterOperating);
        this.NoXPanelMeterKill = this.numberToUndefined(obj.NoXPanelMeterKill);
        this.FuelPressureOperating = this.numberToUndefined(obj.FuelPressureOperating);
        this.FuelPressureKill = this.numberToUndefined(obj.FuelPressureKill);
        this.FuelPotPressureOperating = this.numberToUndefined(obj.FuelPotPressureOperating);
        this.FuelPotPressureKill = this.numberToUndefined(obj.FuelPotPressureKill);
        this.FuelCommandOperating = this.numberToUndefined(obj.FuelCommandOperating);
        this.FuelCommandKill = this.numberToUndefined(obj.FuelCommandKill);
        this.ThrottleCommandOperating = this.numberToUndefined(obj.ThrottleCommandOperating);
        this.ThrottleCommandKill = this.numberToUndefined(obj.ThrottleCommandKill);
        this.TurboBypassOperating = this.numberToUndefined(obj.TurboBypassOperating);
        this.TurboBypassKill = this.numberToUndefined(obj.TurboBypassKill);
        this.FuelTempOperating = this.numberToUndefined(obj.FuelTempOperating);
        this.FuelTempKill = this.numberToUndefined(obj.FuelTempKill);
        this.TimingOperating = this.numberToUndefined(obj.TimingOperating);
        this.TimingKill = this.numberToUndefined(obj.TimingKill);
        this.HydraxPsiOperating = this.numberToUndefined(obj.HydraxPsiOperating);
        this.HydraxPsiKill = this.numberToUndefined(obj.HydraxPsiKill);
        this.RpmSpeedRefOperating = this.numberToUndefined(obj.RpmSpeedRefOperating);
        this.RpmSpeedRefKill = this.numberToUndefined(obj.RpmSpeedRefKill);
        this.RpmMotorSpeedOperating = this.numberToUndefined(obj.RpmMotorSpeedOperating);
        this.RpmMotorSpeedKill = this.numberToUndefined(obj.RpmMotorSpeedKill);
        this.MotorCurrentOperating = this.numberToUndefined(obj.MotorCurrentOperating);
        this.MotorCurrentKill = this.numberToUndefined(obj.MotorCurrentKill);
        this.DriveEndOperating = this.numberToUndefined(obj.DriveEndOperating);
        this.DriveEndKill = this.numberToUndefined(obj.DriveEndKill);
        this.NonDriveEndOperating = this.numberToUndefined(obj.NonDriveEndOperating);
        this.NonDriveEndKill = this.numberToUndefined(obj.NonDriveEndKill);
    }

    differentToObject(objectToCompare: EngineValueFieldsModel) {
        var object1ToCompare: EngineValueFieldsModel = new EngineValueFieldsModel(this);
        var object2ToCompare: EngineValueFieldsModel = new EngineValueFieldsModel(objectToCompare);

        if (object1ToCompare.RpmOperating != object2ToCompare.RpmOperating ||
            object1ToCompare.RpmKill != object2ToCompare.RpmKill ||
            object1ToCompare.BtuOperating != object2ToCompare.BtuOperating ||
            object1ToCompare.BtuKill != object2ToCompare.BtuKill ||
            object1ToCompare.AirTempOperating != object2ToCompare.AirTempOperating ||
            object1ToCompare.AirTempKill != object2ToCompare.AirTempKill ||
            object1ToCompare.EJWTempOperating != object2ToCompare.EJWTempOperating ||
            object1ToCompare.EJWTempKill != object2ToCompare.EJWTempKill ||
            object1ToCompare.EJWPressureOperating != object2ToCompare.EJWPressureOperating ||
            object1ToCompare.EJWPressureKill != object2ToCompare.EJWPressureKill ||
            object1ToCompare.FuelCorrectionOperating != object2ToCompare.FuelCorrectionOperating ||
            object1ToCompare.FuelCorrectionKill != object2ToCompare.FuelCorrectionKill ||
            object1ToCompare.OilPressureOperating != object2ToCompare.OilPressureOperating ||
            object1ToCompare.OilPressureKill != object2ToCompare.OilPressureKill ||
            object1ToCompare.OilTempOperating != object2ToCompare.OilTempOperating ||
            object1ToCompare.OilTempKill != object2ToCompare.OilTempKill ||
            object1ToCompare.LoadPercentOperating != object2ToCompare.LoadPercentOperating ||
            object1ToCompare.LoadPercentKill != object2ToCompare.LoadPercentKill ||
            object1ToCompare.OilFilterDiffOperating != object2ToCompare.OilFilterDiffOperating ||
            object1ToCompare.OilFilterDiffKill != object2ToCompare.OilFilterDiffKill ||
            object1ToCompare.AirRestrictionOperating != object2ToCompare.AirRestrictionOperating ||
            object1ToCompare.AirRestrictionKill != object2ToCompare.AirRestrictionKill ||
            object1ToCompare.CrankcasePressureOperating != object2ToCompare.CrankcasePressureOperating ||
            object1ToCompare.CrankcasePressureKill != object2ToCompare.CrankcasePressureKill ||
            object1ToCompare.AuxWaterTempOperating != object2ToCompare.AuxWaterTempOperating ||
            object1ToCompare.AuxWaterTempKill != object2ToCompare.AuxWaterTempKill ||
            object1ToCompare.AuxWaterPressureOperating != object2ToCompare.AuxWaterPressureOperating ||
            object1ToCompare.AuxWaterPressureKill != object2ToCompare.AuxWaterPressureKill ||
            object1ToCompare.BatteryVoltsOperating != object2ToCompare.BatteryVoltsOperating ||
            object1ToCompare.BatteryVoltsKill != object2ToCompare.BatteryVoltsKill ||
            object1ToCompare.CatalyticConvDiffPressureOperating != object2ToCompare.CatalyticConvDiffPressureOperating ||
            object1ToCompare.CatalyticConvDiffPressureKill != object2ToCompare.CatalyticConvDiffPressureKill ||
            object1ToCompare.ManifoldVacPressLBOperating != object2ToCompare.ManifoldVacPressLBOperating ||
            object1ToCompare.ManifoldVacPressLBKill != object2ToCompare.ManifoldVacPressLBKill ||
            object1ToCompare.ManifoldVacPresRBOperating != object2ToCompare.ManifoldVacPresRBOperating ||
            object1ToCompare.ManifoldVacPresRBKill != object2ToCompare.ManifoldVacPresRBKill ||
            object1ToCompare.ConvTempPreOperating != object2ToCompare.ConvTempPreOperating ||
            object1ToCompare.ConvTempPreKill != object2ToCompare.ConvTempPreKill ||
            object1ToCompare.ConvTempPostOperating != object2ToCompare.ConvTempPostOperating ||
            object1ToCompare.ConvTempPostKill != object2ToCompare.ConvTempPostKill ||
            object1ToCompare.NoXPanelMeterOperating != object2ToCompare.NoXPanelMeterOperating ||
            object1ToCompare.NoXPanelMeterKill != object2ToCompare.NoXPanelMeterKill ||
            object1ToCompare.FuelPressureOperating != object2ToCompare.FuelPressureOperating ||
            object1ToCompare.FuelPressureKill != object2ToCompare.FuelPressureKill ||
            object1ToCompare.FuelPotPressureOperating != object2ToCompare.FuelPotPressureOperating ||
            object1ToCompare.FuelPotPressureKill != object2ToCompare.FuelPotPressureKill ||
            object1ToCompare.FuelCommandOperating != object2ToCompare.FuelCommandOperating ||
            object1ToCompare.FuelCommandKill != object2ToCompare.FuelCommandKill ||
            object1ToCompare.ThrottleCommandOperating != object2ToCompare.ThrottleCommandOperating ||
            object1ToCompare.ThrottleCommandKill != object2ToCompare.ThrottleCommandKill ||
            object1ToCompare.TurboBypassOperating != object2ToCompare.TurboBypassOperating ||
            object1ToCompare.TurboBypassKill != object2ToCompare.TurboBypassKill ||
            object1ToCompare.FuelTempOperating != object2ToCompare.FuelTempOperating ||
            object1ToCompare.FuelTempKill != object2ToCompare.FuelTempKill ||
            object1ToCompare.TimingOperating != object2ToCompare.TimingOperating ||
            object1ToCompare.TimingKill != object2ToCompare.TimingKill ||
            object1ToCompare.HydraxPsiOperating != object2ToCompare.HydraxPsiOperating ||
            object1ToCompare.HydraxPsiKill != object2ToCompare.HydraxPsiKill ||
            object1ToCompare.RpmSpeedRefOperating != object2ToCompare.RpmSpeedRefOperating ||
            object1ToCompare.RpmSpeedRefKill != object2ToCompare.RpmSpeedRefKill ||
            object1ToCompare.RpmMotorSpeedOperating != object2ToCompare.RpmMotorSpeedOperating ||
            object1ToCompare.RpmMotorSpeedKill != object2ToCompare.RpmMotorSpeedKill ||
            object1ToCompare.MotorCurrentOperating != object2ToCompare.MotorCurrentOperating ||
            object1ToCompare.MotorCurrentKill != object2ToCompare.MotorCurrentKill ||
            object1ToCompare.DriveEndOperating != object2ToCompare.DriveEndOperating ||
            object1ToCompare.DriveEndKill != object2ToCompare.DriveEndKill ||
            object1ToCompare.NonDriveEndOperating != object2ToCompare.NonDriveEndOperating ||
            object1ToCompare.NonDriveEndKill != object2ToCompare.NonDriveEndKill) {
            return true;
        }

        return false;
    }

    private numberToUndefined(value: number) {
        return (value > 0 ? value : undefined);
    }
}
