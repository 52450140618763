import { Component, Input } from '@angular/core';

@Component({
    selector: 'validation-message',
    template: `<div *ngIf="isValid" [class.d-block]="!isValid && standalone" class="error invalid-feedback"><ng-content></ng-content></div>`
})
export class ValidationMessageComponent {
    @Input() isValid: boolean = true;
    // use standalone when using this component and it is not tied to a validated field
    @Input() standalone: boolean = false;
}
