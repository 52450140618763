export class McReorderPointModel {
    LocationId: string = '';
    PartId: string = '';
    MinimumIssueQuantity: number = 0;
    ReorderQuantity: number = 0;
    MaximumIssueQuantity: number = 0;
    InStock: boolean = true;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.LocationId = obj.LocationId;
        this.PartId = obj.PartId;
        this.MinimumIssueQuantity = obj.MinimumIssueQuantity;
        this.ReorderQuantity = obj.ReorderQuantity;
        this.MaximumIssueQuantity = obj.MaximumIssueQuantity;
        this.InStock = obj.InStock;
    }
}

export class McPartModel {
    LocationId: string = '';
    PartId: string = '';
    Bin: string = '';

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.LocationId = obj.LocationId;
        this.PartId = obj.PartId;
        this.Bin = obj.Bin;
    }
}

export class McNewPartModel {
    PartId: string;
    PartName: string;
    PartDescription: string;
    CategoryId: string;
    ClassificationId: string;
    ManufacturerId: string;
    CostRule: string;
    AverageCost: number;
    ApplyToAllStockRooms: boolean;
    DirectIssuePart: boolean;
    OutForRebuild: boolean;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.PartId = obj.PartId;
        this.PartName = obj.PartName;
        this.PartDescription = obj.PartDescription;
        this.CategoryId = obj.CategoryId;
        this.ClassificationId = obj.ClassificationId;
        this.ManufacturerId = obj.ManufacturerId;
        this.CostRule = obj.CostRule;
        this.AverageCost = obj.AverageCost;
        this.ApplyToAllStockRooms = obj.ApplyToAllStockRooms;
        this.DirectIssuePart = obj.DirectIssuePart;
        this.OutForRebuild = obj.OutForRebuild;
    }
}

export class McPOModel {
    PurchaseOrderId: string = '';
    LocationId: string = ''; //'DI' for Direct Issue
    PartId: string = '';
    AccountId: string = '';
    SubAccountId: string = ''; // 'NA' for none
    Cost: number = 0;
    Quantity: number = 0;
    WorkOrder: string = ''; // 'NA' for none
    AssetId: string = ''; // 'NA' for none

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.PurchaseOrderId = obj.PurchaseOrderId;
        this.LocationId = obj.LocationId;
        this.AccountId = obj.AccountId;
        this.SubAccountId = obj.SubAccountId;
        this.Cost = obj.Cost;
        this.Quantity = obj.Quantity;
        this.WorkOrder = obj.WorkOrder;
        this.AssetId = obj.AssetId;
    }
}

export class McTransferPartModel {
    OriginalLocationId: string = '';
    NewLocationId: string = '';
    PartId: string = '';
    Quantity: number = 0;
    Comments: string = '';

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.OriginalLocationId = obj.OriginalLocationId;
        this.NewLocationId = obj.NewLocationId;
        this.PartId = obj.PartId;
        this.Quantity = obj.Quantity;
        this.Comments = obj.Comments;
    }
}

export class McNewUnitModel {
    AssetId: string;
    StockroomId: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.AssetId = obj.AssetId;
        this.StockroomId = obj.StockroomId;
    }
}

export class McUploadDetailModel {
    FailedLineData: string[] = new Array();
    UploadCount: number = 0;
    UploadTotal: number = 0;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.FailedLineData = obj.FailedLineData;
        this.UploadCount = obj.UploadCount;
        this.UploadTotal = obj.UploadTotal;
    }
}