import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    ApprovalCountModel,
    ApplicationUserModel,
    UserModel,
    DashboardModuleCountModel
} from '../../shared/models';

import { AppGlobalService } from './app-global.service';
import { Common } from './common.service';

@Injectable()
export class UserService {
    private applicationUrl = `${Globals.BASE_API_URL}:${Globals.API_PORTS.APPLICATION}/api`;
    private url: string = `${this.applicationUrl}/application-user`;

    data: any = {};
    userCounts$: Observable<any> = new Observable();
    user: UserModel = new UserModel();
    constructor(
        private global: AppGlobalService,
        private common: Common,
        private http: HttpClient,

    ) {
        this.user = this.common.getUser();
        if (this.user == null) {
            this.user = new UserModel();
        }

        this.common.changeEmitted$.subscribe(
            (text) => {
                if (text == "new_user" || text == "refresh_count") {
                    this.user = this.common.getUser();
                    this.userCounts$ = this.getUsersApprovalCounts(this.user.ApplicationUserPk).pipe(shareReplay());
                }
            }
        );
    }

    postAuthorize() {
        return this.http.post<UserModel>(this.applicationUrl + "/Authorize", {}, { observe: 'response' })
            .pipe(
                map(res => {
                    this.global.userObject = res.headers.get('User-Object');
                    return new UserModel(res.body);
                })
            )
    }

    getUserToLogin(username: string) {
        return this.http.get<UserModel>(this.url + "/get-user-to-login?username=" + username, { observe: 'response' })
            .pipe(
                map(res => {
                    this.common.setSparkStorage('LoggedInAs', {
                        userId: username,
                        userObject: this.global.userObject
                    });

                    this.global.userObject = res.headers.get('User-Object');

                    return new UserModel(res.body);
                })
            );
    }

    getAllUsers() {
        const key = 'ApplicationUsers';

        if (this.data[key]) {
            return of(this.data[key] as ApplicationUserModel[]);
        }

        return this.http.get<ApplicationUserModel[]>(this.url + `/get-users`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new ApplicationUserModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as ApplicationUserModel[];
            }));
    }

    getUsersByRole(rolePk: number) {
        return this.http.get<ApplicationUserModel[]>(this.url + `/get-users?rolePk=${rolePk}`)
            .pipe(map(res => res ? res.map(x => new ApplicationUserModel(x)) : new Array() as ApplicationUserModel[]));
    }

    getUsersApprovalCounts(userPk: number): Observable<any> {
        return this.http.get(`${this.url}/get-user-approval-counts?userPk=${userPk}`)
            .pipe(map((res: ApprovalCountModel[]) => {
                const result = {};

                res.map(x => result[x.ModuleType] = x.ApprovalCount);

                return result;
            }));
    }

    hasAppAccess(appId: string) {
        var user = this.common.getUser();

        if (user == null)
            return false;

        return user.UserActions.find(x => x.ApplicationId == appId) != null;
    }

    removeCachedData(key: string) {
        this.data[key] = null;
    }

    getDashboardModuleCount(userPk: number) {
        return this.http.get<DashboardModuleCountModel[]>(`${this.url}/get-dashboard-module-counts?userPk=${userPk}`)
            .pipe(
                map(res => res ? res.map(x => new DashboardModuleCountModel(x)) : new Array() as DashboardModuleCountModel[])
            );
    }
}