import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { Globals } from '../../shared/global';

import {
    ApplicationCriteria,
    ApplicationModel,
    ApplicationActionModel,
    ApplicationModuleModel,
    ApplicationRoleModel,
    ApplicationRoleModuleActionModel,
    ApplicationSettingModel,
    ApplicationUserModel,
    ApplicationUserRoleModel,
    ApplicationUserSearchModel,
    UserActionModel
} from '../../shared/models';

import { Common } from './common.service';

@Injectable()
export class SecurityService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.APPLICATION}/api/`;
    private key: string = 'appSettings';

    data: any = {};

    constructor(private common: Common, private http: HttpClient) { }

    // Application
    getApplications() {
        return this.http.get<ApplicationModel[]>(this.url + 'application/search')
            .pipe(map(res => res ? res.map(x => new ApplicationModel(x)) : new Array() as ApplicationModel[]));
    }

    createApplication(model: ApplicationModel) {
        return this.http.post<ApplicationModel>(this.url + 'application/create', model)
            .pipe(map(res => new ApplicationModel(res)));
    }

    updateApplication(model: ApplicationModel) {
        return this.http.put<ApplicationModel>(this.url + 'application/update', model)
            .pipe(map(res => new ApplicationModel(res)));
    }

    deleteApplication(modelPk: number) {
        return this.http.delete<Boolean>(this.url + `application/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    // ApplicationModule
    getApplicationModules(applicationPk: number) {
        return this.http.get<ApplicationModuleModel[]>(this.url + `application-module/get-by-application?applicationPk=${applicationPk}`)
            .pipe(map(res => res ? res.map(x => new ApplicationModuleModel(x)) : new Array() as ApplicationModuleModel[]));
    }

    createModule(model: ApplicationModuleModel) {
        return this.http.post<ApplicationModuleModel>(this.url + 'application-module/create', model)
            .pipe(map(res => new ApplicationModuleModel(res)));
    }

    updateModule(model: ApplicationModuleModel) {
        return this.http.put<ApplicationModuleModel>(this.url + 'application-module/update', model)
            .pipe(map(res => new ApplicationModuleModel(res)));
    }

    deleteModule(modelPk: number) {
        return this.http.delete<Boolean>(this.url + `application-module/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    // ApplicationRole
    getApplicationRoles(applicationPk?: number) {
        return this.http.get<ApplicationRoleModel[]>(this.url + `application-role/search?applicationPk=${applicationPk ? applicationPk : 0}`)
            .pipe(map(res => res ? res.map(x => new ApplicationRoleModel(x)) : new Array() as ApplicationRoleModel[]));
    }

    createRole(model: ApplicationRoleModel) {
        return this.http.post<ApplicationRoleModel>(this.url + 'application-role/create', model)
            .pipe(map(res => new ApplicationRoleModel(res)));
    }

    updateRole(model: ApplicationRoleModel) {
        return this.http.put<ApplicationRoleModel>(this.url + 'application-role/update', model)
            .pipe(map(res => new ApplicationRoleModel(res)));
    }

    deleteRole(modelPk: number) {
        return this.http.delete<Boolean>(this.url + `application-role/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    // ApplicationAction
    getApplicationActions(applicationPk: number) {
        return this.http.get<ApplicationActionModel[]>(this.url + `application-action/get-by-application?applicationPk=${applicationPk}`)
            .pipe(map(res => res ? res.map(x => new ApplicationActionModel(x)) : new Array() as ApplicationActionModel[]));
    }

    createAction(model: ApplicationActionModel) {
        return this.http.post<ApplicationActionModel>(this.url + 'application-action/create', model)
            .pipe(map(res => new ApplicationActionModel(res)));
    }

    updateAction(model: ApplicationActionModel) {
        return this.http.put<ApplicationActionModel>(this.url + 'application-action/update', model)
            .pipe(map(res => new ApplicationActionModel(res)));
    }

    deleteAction(modelPk: number) {
        return this.http.delete<Boolean>(this.url + `application-action/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    // ApplicationRoleModuleAction
    getRoleModuleActions(applicationCriteria: ApplicationCriteria) {
        return this.http.get<ApplicationRoleModuleActionModel[]>(this.url + 'application-role-module-action/search' +
            `?applicationPk=${applicationCriteria.ApplicationPk}` +
            `&applicationModulePk=${applicationCriteria.ApplicationModulePk}` +
            `&applicationRolePk=${applicationCriteria.ApplicationRolePk}` +
            `&applicationActionPk=${applicationCriteria.ApplicationActionPk}` +
            `&applicationUserPk=${applicationCriteria.ApplicationUserPk}`
        ).pipe(map(res => res ? res.map(x => new ApplicationRoleModuleActionModel(x)) : new Array() as ApplicationRoleModuleActionModel[]));
    }

    createRoleModuleAction(model: ApplicationRoleModuleActionModel) {
        return this.http.post<ApplicationRoleModuleActionModel>(this.url + 'application-role-module-action/create', model)
            .pipe(map(res => new ApplicationRoleModuleActionModel(res)));
    }

    deleteRoleModuleAction(modelPk: number) {
        return this.http.delete<Boolean>(this.url + `application-role-module-action/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    // ApplicationUser
    getUser(modelPk: number) {
        return this.http.get<ApplicationUserModel>(`${this.url}application-user/get?modelPk=${modelPk}`)
            .pipe(map(res => new ApplicationUserModel(res)));
    }

    createUser(model: ApplicationUserModel) {
        return this.http.post<ApplicationUserModel>(this.url + 'application-user/create', model)
            .pipe(map(res => new ApplicationUserModel(res)));
    }

    updateUser(model: ApplicationUserModel) {
        return this.http.put<ApplicationUserModel>(this.url + 'application-user/update', model)
            .pipe(map(res => new ApplicationUserModel(res)));
    }

    // ApplicationUserRole
    getUserRoles(userCriteria: ApplicationUserSearchModel) {
        const params = [];

        if (userCriteria.UserId) params.push(`userId=${userCriteria.UserId}`);
        if (userCriteria.FirstName) params.push(`firstName=${userCriteria.FirstName}`);
        if (userCriteria.LastName) params.push(`lastName=${userCriteria.LastName}`);
        if (userCriteria.IsActive != null) params.push(`isActive=${userCriteria.IsActive}`);
        if (userCriteria.ApplicationRolePk) params.push(`applicationRolePk=${userCriteria.ApplicationRolePk}`);

        return this.http.get<ApplicationUserRoleModel[]>(this.url + 'application-user-role/search?' + params.join('&'))
            .pipe(map(res => res ? res.map(x => new ApplicationUserRoleModel(x)) : new Array() as ApplicationUserRoleModel[]));
    }

    createUserRole(model: ApplicationUserRoleModel) {
        return this.http.post<ApplicationUserRoleModel>(this.url + 'application-user-role/create', model)
            .pipe(map(res => new ApplicationUserRoleModel(res)));
    }

    deleteUserRole(applicationUserPk: number, applicationRolePk: number) {
        return this.http.delete<Boolean>(this.url + `application-user-role/delete?applicationUserPk=${applicationUserPk}&applicationRolePk=${applicationRolePk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }

    // ApplicationSetting
    getApplicationSettings() {
        // if (this.data[this.key]) {
        //     return of(this.data[this.key] as ApplicationSettingModel[]);
        // }

        return this.http.get<ApplicationSettingModel[]>(this.url + 'application-setting/search')
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new ApplicationSettingModel(x));

                    this.data[this.key] = results;

                    return results;
                }

                return new Array() as ApplicationSettingModel[];
            }));
    }

    getAppSetting<T>(searchKey: string): T {
        if (!this.data || !this.data[this.key]) {
            return null;
        }

        var settingsVal = this.data[this.key].find(x => x.Name == searchKey);

        if (settingsVal) {
            return settingsVal.Value as T;
        }

        return null;
    }

    createSetting(model: ApplicationSettingModel) {
        return this.http.post<ApplicationSettingModel>(this.url + 'application-setting/create', model)
            .pipe(map(res => new ApplicationSettingModel(res)));
    }

    updateSetting(model: ApplicationSettingModel) {
        return this.http.put<ApplicationSettingModel>(this.url + 'application-setting/update', model)
            .pipe(map(res => new ApplicationSettingModel(res)));
    }

    deleteSetting(modelPk: number) {
        return this.http.delete<Boolean>(this.url + `application-setting/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(map(this.common.httpBooleanHandler));
    }
}
