import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { SortHeaderComponent } from '../components/sort/sort-header.component';

@Directive({
    selector: '[sort]'
})
export class SortDirective {
    @Input('sort-active') active: string;
    @Input('sort-order') order: string = 'asc';

    @Output('sortChange') sortChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    sort(header: SortHeaderComponent) {
        if (this.active != header.id) {
            this.active = header.id;
        }

        if (this.order != header.order) {
            this.order = header.order;
        }

        this.sortChange.emit({
            active: this.active,
            order: this.order
        });
    }
}