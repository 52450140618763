import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'text-preview',
    templateUrl: 'text-preview.component.html'
})
export class TextPreviewComponent implements OnInit {
    @Input() value: string = '';
    @Input() limit: number = 125;

    expanded: boolean = false;
    previewText: string = '';

    private _checkLength() {
        if (this.value == null) {
            this.value = '';
        }

        let index = this.value.substr(0, this.limit).lastIndexOf(' ');

        if (index < 0) {
            index = this.value.substr(0, this.limit).lastIndexOf("\n");
        }

        if (index <= 0) {
            index = this.limit;
        }

        this.previewText = this.value.substr(0, index);

        this.expanded = (this.value.trim().length < this.limit);
    }

    ngOnInit() {
        this._checkLength();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value.currentValue != changes.value.previousValue) {
            this._checkLength();
        }
    }

    toggle() {
        if (this.value && this.value.trim().length > this.limit && this.previewText != this.value) {
            this.expanded = !this.expanded;
        }
    }
}
