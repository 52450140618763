import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ModalFooterModel } from '../../models';

@Component({
    selector: 'modal-footer',
    templateUrl: 'modal-footer.component.html'
})
export class ModalFooterComponent implements OnInit {
    @Input() model: ModalFooterModel = new ModalFooterModel();

    @ViewChild('button', { static: true }) button: ElementRef;

    hasButton: boolean = false;

    ngOnInit() {
        this.hasButton = this.button.nativeElement.childNodes.length > 1;
    }
}
