import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';

import {
    DataflowGroupModel,
    FluidsRequestItemModel,
    FluidsRequestItemTypeModel,
    FluidsRequestModel,
    LookupModel,
    LookupValueModel,
    PartModel,
    UnitModel
} from '../../models';

import {
    AreaService,
    Common,
    DataflowService,
    FluidsRequestService,
    LookupService,
    LookupValueService,
    SparkFileService,
    SparkModuleTypeService,
    UnitService
} from '../../../core/services';

import { DataflowComponent } from './dataflow.component';

@Component({
    selector: 'fluids-request-dataflow',
    templateUrl: 'dataflow.component.html'
})
export class FluidsRequestDataflowComponent extends DataflowComponent {
    @Input() mode: string;
    @Input() model: FluidsRequestModel = new FluidsRequestModel();

    modelOriginal: FluidsRequestModel = new FluidsRequestModel();
    showType: boolean = false;

    constructor(
        public common: Common,
        public dataflowService: DataflowService,
        public sparkFileService: SparkFileService,
        public sparkModuleTypeService: SparkModuleTypeService,
        private areaService: AreaService,
        private fluidsRequestService: FluidsRequestService,
        private lookupService: LookupService,
        private lookupValueService: LookupValueService,
        private unitService: UnitService
    ) {
        super(common, dataflowService, sparkFileService, sparkModuleTypeService);
    }

    ngOnInit() {
        this.common.handleRequests([
            this._getAreas(),
            this._getAssets(),
            this._getLookupValues(),
            this._getProducts(),
            this._getVendors()
        ]).then(() => {
            this.typeaheads['FLUIDS_REQUEST_ITEM_TYPE'].map((x: LookupValueModel) => {
                this.typeaheads[x.Name + "Products"] = this.typeaheads["Products"].filter((y: PartModel) => y.LocationId.toUpperCase() == 'FR-' + x.Name.toUpperCase());
            });

            this.registerLoad("data");

            // PK is not a valid number, so force dataflow fetch
            if (!this.model.Pk) {
                super.setInputs("FR", this.mode, {});
            }
        });

        this.getStepTitle();
    }

    private _getAssets() {
        return this.unitService.getPortalUnitList().pipe(map(data => this.typeaheads["Asset"] = data));
    }

    private _getAreas() {
        return this.areaService.get().pipe(map(data => this.typeaheads["Area"] = data));
    }

    private _getLookupValues() {
        const types = [
            'FLUIDS_REQUEST_ITEM_TYPE',
            'TANK_STAND_HEIGHT'
        ];

        return this.lookupValueService.getByTypes(types).pipe(map(data => {
            types.map(x => {
                if (!this.typeaheads[x]) {
                    this.typeaheads[x] = this.common.sort(data.filter(y => y.Type == x), 'Sort');
                }
            });
        }));
    }

    private _getProducts() {
        return this.fluidsRequestService.getProducts().pipe(map(data => this.typeaheads["Products"] = this.common.sort(data, 'Name')));
    }

    private _getVendors() {
        return this.lookupService.getLookupList('fluids-request-vendor').pipe(map(data => this.typeaheads["Vendor"] = this.common.sort(data, 'Name')));
    }

    getDataflow() {
        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);

        return dataflow ? dataflow.Name : '';
    }

    getStepTitle() {
        if (this.currentStep == 1) {
            this.stepTitle = 'New Fluids Request';
        }
        else {
            super.getStepTitle();
        }
    }

    onNextStep(bypass: boolean = false) {
        if (this.currentStep == this.totalSteps) {
            this.saveModel();
            return;
        }

        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);
        const group = dataflow.Groups[this.currentStep - 2];

        if (this.currentStep > 1 && group.CustomValidation && !bypass) {
            this.customValidation(group);
        }
        else {
            super.onNextStep();
        }
    }

    customValidation(selectedGroup?: DataflowGroupModel) {
        let messages = [];

        if (!this.model.NeedOil && !this.model.NeedCoolant && !this.model.NeedCompoundOil) {
            messages.push("At least one tank is needed before proceeding.");
        }

        if (messages.length) {
            this.common.showError('Unable to Proceed', `<ul><li>${messages.join("</li><li>")}</li></ul>`);
        }
        else {
            this.onNextStep(true);
        }
    }

    saveModel() {
        this.formFooter.disableAll();

        const dataflow = this.dataflows.find(x => x.Pk == this.selectedSubType);

        this.model.Items = [];
        this.model.TypePk = this.selectedSubType;

        const addAsset = (asset: UnitModel) => {
            const item = new FluidsRequestItemModel({
                AssetId: asset.Unit,
                Operator: asset.Operator,
                FromLocation: asset.Location,
                ToLocation: this.model[name + 'ToLocation'] || null,
                Horsepower: asset.Horsepower,
                EngineMake: asset.EngineManufacturer,
                EngineModel: asset.Engine,
                CompressorMake: asset.FrameManufacturer,
                CompressorModel: asset.Frame
            });

            this.typeaheads['FLUIDS_REQUEST_ITEM_TYPE'].map((x: LookupValueModel) => {
                const name = x.Name.replace(/\s/g, '');

                if (this.model['Need' + name]) {
                    item.Types.push(new FluidsRequestItemTypeModel({
                        TypePk: x.Pk,
                        PurchaseOrderNumber: this.common.getFluidsRequestPurchaseOrder(this.model.DeliveryDate, asset.Unit, x.Name),
                        DeliveryDate: this.model.DeliveryDate,
                        ProductId: this.model[name + 'ProductId'],
                        ProductName: this.model[name + 'ProductName'],
                        StandHeightPk: this.model[name + 'StandHeightPk'],
                        Capacity: this.model[name + 'Capacity'],
                        Quantity: this.model[name + 'Quantity'],
                        CurrentLevel: this.model[name + 'CurrentLevel']
                    }));
                }
                else {
                    item.Types = item.Types.filter(y => y.TypePk != x.Pk);
                }
            });

            this.model.Items.push(new FluidsRequestItemModel(item));
        };

        // If the AssetId is set, it is not a bulk order
        // so we have to setup the item list for each fluid item type
        if (this.model['AssetId']) {
            const asset: UnitModel = this.typeaheads['Asset'].find((x: UnitModel) => x.Unit == this.model['AssetId']);
            addAsset(asset);
        }
        else {
            const assets: UnitModel[] = this.typeaheads['Asset'].filter((x: UnitModel) => x.Area == this.model.AreaId && x.Unit.length <= 4);
            assets.map(x => addAsset(x));
        }

        // try to think of a better way to do this
        this.model.UseContainments = (dataflow.Name != 'Oil Only');

        this.fluidsRequestService.save(this.model)
            .subscribe(
                data => {
                    this.formFooter.enableAll();

                    data.Type = dataflow;
                    this.onSave.emit(data);
                },
                error => {
                    this.formFooter.enableAll();
                    this.common.showError(`Error creating ${dataflow.Name} ${dataflow.Label}`, error);
                }
            );
    }
}
