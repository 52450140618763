export class DashboardModuleCountModel {
    ModuleId: string;
    Status: string;
    Count: number = 0;

    constructor(obj? : any) {
        if (obj == null) {
            return;
        }

        this.ModuleId = obj.ModuleId;
        this.Status = obj.Status;
        this.Count = obj.Count;
    }
}