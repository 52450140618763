import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppGlobalService } from '../services';

import { MsalService } from '@azure/msal-angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private global: AppGlobalService,
        private authService: MsalService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.instance.getAllAccounts().length > 0) {
            var headers = {
                'Access-Control-Allow-Origin': '*'
            };

            if (this.global.userObject) {
                headers['User-Object'] = this.global.userObject;
            }

            const modified = req.clone({ setHeaders: headers });
            return next.handle(modified);
        }

        return next.handle(req);
    }
}
