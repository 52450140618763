import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[phoneNumberMask]'
})

export class PhoneNumberMask {
    constructor(public ngControl: NgControl) { }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event, false);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
        this.onInputChange(event.target.value, true);
    }

    onInputChange(event, backspace) {
        let phoneNumber = event.replace(/\D/g, '');
        if (backspace) {
            if (phoneNumber.length <= 3) {
                phoneNumber = phoneNumber.substring(0, phoneNumber.length - 1);
            } else if (phoneNumber.length <= 6) {
                phoneNumber = phoneNumber.substring(0, phoneNumber.length);
            }
        }
        if (phoneNumber.length === 0) {
            phoneNumber = '';
        } else if (phoneNumber.length <= 3) {
            phoneNumber = phoneNumber.replace(/^(\d{0,3})/, '($1)');
        } else if (phoneNumber.length <= 6) {
            phoneNumber = phoneNumber.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (phoneNumber.length <= 10) {
            phoneNumber = phoneNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        } else {
            phoneNumber = phoneNumber.substring(0, 10);
            phoneNumber = phoneNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }
        this.ngControl.valueAccessor.writeValue(phoneNumber);
    }
} 