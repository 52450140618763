<div id="loader" *ngIf="isLoading">
    <i class="fas fa-spinner fa-pulse"></i>
</div>

<modal id="message-modal">
    <header>{{messageModal.Title}}</header>

    <div class="row">
        <div class="col-sm-12">
            <p [innerHTML]="messageModal.Text"></p>
        </div>
    </div>
</modal>

<router-outlet></router-outlet>
