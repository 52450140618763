export class TimeSheet {
    RequesterID: string;
    targetdate: Date;
    sr: number;
    op: number;
    gfs: number;
    nw: number;
    total: number;
    period: number;
    iscurrent: boolean;
    date: Date;
}

export class TimeSheetTotal {
    sr: number = 0;
    op: number = 0;
    gfs: number = 0;
    nw: number = 0;
    total: number = 0;
}
