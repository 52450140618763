import * as moment from 'moment';

export class TimeCardPeriod {
    Pk: number;
    IsCurrent: boolean;
    PayPeriodFirstDate: Date;
    PayPeriodLastDate: Date;
    IsOpen: boolean;
    CreationDate: Date;
    CreatedBy: Number;
    LastUpdateDate: Date;
    LastUpdateBy: Number;
    Areas: string = '';
    ConvertedAreas: any[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.IsCurrent = obj.IsCurrent;
        this.PayPeriodFirstDate = moment(obj.PayPeriodFirstDate).toDate();
        this.PayPeriodLastDate = moment(obj.PayPeriodLastDate).toDate();
        this.IsOpen = obj.IsOpen;
        this.Areas = obj.Areas || '';

        if (this.Areas) {
            this.ConvertedAreas = this.Areas.split(",");
        }

        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
    }

    getAreas() {
        return (this.Areas || '').replace(/,/g, ', ');
    }
}
