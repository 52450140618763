import { Directive, ElementRef, OnInit, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[autoGrow]'
})
export class AutoGrowDirective implements OnInit {
    @Input() minRows: number = 2;
    @Input() maxRows: number = 15;

    constructor(public element: ElementRef) {}

    private _getLineHeight() {
        let lineHeight = parseInt(this.element.nativeElement.style.lineHeight, 10);
        const styles = window.getComputedStyle(this.element.nativeElement);
        return (parseInt(styles.lineHeight, 10) + 7);
    }

    @HostListener('input', ['$event.target'])
    ngOnInit() {
        this.adjust();
    }

    @HostListener('focus', ['$event.target'])
    onFocus(e) {
        this.adjust();
    }

    adjust() {
        const e = this.element.nativeElement;
        const clone = e.cloneNode(true);
        const parent = e.parentNode;

        clone.style.visibility = 'hidden';
        parent.appendChild(clone);

        clone.style.overflow = 'auto';
        clone.style.height = 'auto';

        let height = clone.scrollHeight;
        const willGrow = height > e.offsetHeight;
        const lineHeight = this._getLineHeight();
        const rowsCount = height / lineHeight;

        let styleAttribute = '';

        if (this.minRows && this.minRows >= rowsCount) {
            height = this.minRows * lineHeight;
        }
        else if (this.maxRows && this.maxRows <= rowsCount) {
            height = this.maxRows * lineHeight;
            styleAttribute += 'overflow: auto;';
        }
        else {
            styleAttribute += 'overflow: hidden;';
        }

        styleAttribute += `height: ${height}px`;

        e.setAttribute('style', styleAttribute);

        parent.removeChild(clone);
    }
}