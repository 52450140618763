import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import * as services from './services';

import {
    AuthInterceptor,
    ContentTypeInterceptor,
    HttpErrorInterceptor,
    SearchInterceptor
} from './interceptors';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
    MsalBroadcastService,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalRedirectComponent,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';

import { Globals } from 'src/app/shared/global';

export function MSALInstanceFactory(): IPublicClientApplication {
    const app = new PublicClientApplication(Globals.MSAL_CONFIG);

    app.handleRedirectPromise().then(() => {});

    return app;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set('/api/', Globals.MSAL_PROTECTED_RESOURCES.api);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: []
        }
    };
}

const APP_SERVICES = [
    services.AreaService,
    services.AssetActivityReportService,
    services.Common,
    services.DataflowService,
    services.ExpenseReportService,
    services.FluidsRequestService,
    services.GeneralFieldService,
    services.InvoicingInstructionService,
    services.LaborTypeService,
    services.LookupService,
    services.LookupValueService,
    services.MacolaCustomerService,
    services.McPurchaseOrderService,
    services.McUploadService,
    services.McWorkOrderService,
    services.ModalService,
    services.ModuleHistoryService,
    services.OilSampleService,
    services.OperatorReportService,
    services.PartService,
    services.PayrollExportService,
    services.RuntimeReportService,
    services.SafetyService,
    services.SalesOrderService,
    services.SecurityService,
    services.ServiceReportService,
    services.SparkFileService,
    services.SparkMessageService,
    services.SparkModuleTypeService,
    services.TimeCardPeriodService,
    services.UnitService,
    services.UserService,
    services.VehicleAllowanceService,
    services.WorkflowService,
    services.ActiveDirectorySyncService,
    services.InventoryService,
    services.SalesMassRenewalService,
    services.UtilitiesUploadService,
    services.DollarPerHorsepowerService,
    services.ProducerPriceIndexService
];

@NgModule({
    imports: [ CommonModule, RouterModule, HttpClientModule ],
    exports: [ RouterModule, HttpClientModule ],
    declarations: [],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ContentTypeInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SearchInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalBroadcastService,
        ...APP_SERVICES
    ],
    bootstrap: [ MsalRedirectComponent ]
})
export class CoreModule {    // Ensure that CoreModule is only loaded into AppModule
    // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
    constructor(@Optional() @SkipSelf() targetModule: CoreModule) {
        if (targetModule) {
            throw new Error(`${targetModule.constructor.name} has already been loaded. Import this module in the AppModule only.`);
        }
    }
}
