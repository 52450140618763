import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import { SparkModuleTypeModel } from '../../shared/models';
import { Common } from './common.service';
import { of } from 'rxjs';

@Injectable()
export class SparkModuleTypeService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.COMMON}/api/spark-module-type`;

    data: any = {};

    constructor(
        private common: Common,
        private http: HttpClient
    ) { }

    get(moduleTypePk: number) {
        const key = 'SparkModules';

        if (this.data[key]) {
            const result = (this.data[key] as SparkModuleTypeModel[]).find(x => x.Pk == moduleTypePk);

            if (result) {
                return of(result);
            }
        }

        return this.http.get<SparkModuleTypeModel>(`${this.url}?moduleTypePk=${moduleTypePk}`)
            .pipe(map(res => {
                if (res) {
                    const result = new SparkModuleTypeModel(res);

                    if (!this.data[key]) {
                        this.data[key] = [];
                    }

                    this.data[key].push(result);

                    return result;
                }

                throw new Error(`Unable to locate Spark Module Type by pk ${moduleTypePk}. Contact IT if this problem continues.`);
            }));
    }

    getById(id: string) {
        const key = 'SparkModules';

        if (this.data[key]) {
            const result = (this.data[key] as SparkModuleTypeModel[]).find(x => x.Id == id);

            if (result) {
               return of(result);
            }
        }

        return this.http.get<SparkModuleTypeModel[]>(`${this.url}/getById?id=${id}`)
            .pipe(map(res => {
                if (res) {
                    const result = new SparkModuleTypeModel(res);

                    if (!this.data[key]) {
                        this.data[key] = [result];
                    }
                    else {
                        // if refreshing a page, the search and individual call are made, so double check
                        const existing = (this.data[key] as SparkModuleTypeModel[]).find(x => x.Id == id);

                        if (!existing) {
                            this.data[key].push(result);
                        }
                    }

                    return result;
                }

                throw new Error(`Unable to locate Spark Module Type by id ${id}. Contact IT if this problem continues.`);
            }));
    }

    search() {
        const key = 'SparkModules';

        if (this.data[key]) {
            return of(this.data[key] as SparkModuleTypeModel[]);
        }

        return this.http.get<SparkModuleTypeModel[]>(this.url + '/search')
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new SparkModuleTypeModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as SparkModuleTypeModel[];
            }));
    }

    create(model: SparkModuleTypeModel) {
        return this.http.post<SparkModuleTypeModel>(this.url + '/create', model)
            .pipe(map(res => new SparkModuleTypeModel(res)));
    }

    update(model: SparkModuleTypeModel) {
        return this.http.put<SparkModuleTypeModel>(this.url + '/update', model)
            .pipe(map(res => new SparkModuleTypeModel(res)));
    }
}
