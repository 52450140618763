import { PartModel } from './part.model';
import { ServiceReportLaborModel } from './service-report-labor.model';
import { FileModel } from './file.model';
import { WorkflowModuleModel } from './workflow.model';

import * as moment from 'moment';
import { SparkFileModel } from './spark-file.model';

export class ServiceReportModel {
    Pk: number = 0;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    OperatorId: string;
    OperatorName: string;
    Date: Date;
    AssetId: string;
    AssetName: string;
    AreaId: string;
    Customer: string;
    Location: string;
    FaultCodeId: string;
    FaultCodeName: string;
    UnitHours: number = 0;
    // IsSubmittedForApproval: boolean;
    WOID: string = null;
    WoStatus: string;
    Reason: string;
    TypeId: string;
    TypeName: string;
    Status: string = 'New';
    ProcedurePk: number;
    IsProcedureFromTracker: boolean;
    Parts: PartModel[] = new Array();
    Labors: ServiceReportLaborModel[] = new Array();
    Tasks: ServiceReportTaskModel[] = new Array();
    Files: SparkFileModel[] = new Array();
    CanApprove: boolean;
    WorkflowId: string = 'SR';
    Workflows: WorkflowModuleModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.OperatorId = obj.OperatorId;
        this.OperatorName = obj.OperatorName;

        if (obj.Date) {
            this.Date = moment(obj.Date).startOf('day').toDate();
        }

        this.AssetId = obj.AssetId;
        this.AssetName = obj.AssetName;
        this.AreaId = obj.AreaId;
        this.Customer = obj.Customer;
        this.Location = obj.Location;
        this.FaultCodeId = obj.FaultCodeId;
        this.FaultCodeName = obj.FaultCodeName;
        this.UnitHours = obj.UnitHours;
        this.WOID = obj.WOID;
        this.WoStatus = obj.WoStatus || '';
        this.Reason = obj.Reason;
        this.TypeId = obj.TypeId;
        this.TypeName = obj.TypeName;
        this.Status = obj.Status ? obj.Status : obj.WOID ? 'Submitted' : 'New';
        this.ProcedurePk = obj.ProcedurePk;
        this.IsProcedureFromTracker = obj.IsProcedureFromTracker;
        this.CanApprove = obj.CanApprove;
        this.WorkflowId = obj.WorkflowId;

        if (obj.Parts) {
            this.Parts = obj.Parts.map(x => new PartModel(x));
        }

        if (obj.Labors) {
            this.Labors = obj.Labors.map(x => new ServiceReportLaborModel(x));
        }

        if (obj.Tasks) {
            this.Tasks = obj.Tasks.map(x => new ServiceReportTaskModel(x));
        }

        if (obj.Files) {
            this.Files = obj.Files.map(x => new SparkFileModel(x));
        }

        if (obj.Workflows) {
            this.Workflows = obj.Workflows.map(x => new WorkflowModuleModel(x));
        }
    }
}

export class ServiceReportTaskModel {
    Pk: number;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;
    ServiceReportPk: number;
    Id: string;
    ProcedureTaskPk: number;
    TaskNo: number;
    TaskAction: string;
    TaskHeader: boolean;
    TaskHasValue: boolean;
    TaskValueType: string;
    Value: string;
    IsCompleted: boolean;
    Initials: string;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.CreationDate = moment(obj.CreationDate).toDate();
        this.CreatedBy = obj.CreatedBy;
        this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        this.LastUpdateBy = obj.LastUpdateBy;
        this.ServiceReportPk = obj.ServiceReportPk;
        this.ProcedureTaskPk = obj.ProcedureTaskPk;
        this.Id = obj.TaskAction.replace(/[^a-zA-Z0-9]/g, '');
        this.TaskNo = obj.TaskNo;
        this.TaskAction = obj.TaskAction;
        this.TaskHeader = obj.TaskHeader;
        this.TaskHasValue = obj.TaskHasValue;
        this.TaskValueType = obj.TaskValueType;
        this.Value = obj.Value;
        this.IsCompleted = obj.IsCompleted;
        this.Initials = obj.Initials;
    }
}
