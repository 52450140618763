import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Globals } from '../../shared/global';
import {
    MacolaAssetModel,
    UnitModel,
    SearchCriteriaModel,
    SearchResultModel
} from '../../shared/models';
import { Common } from './common.service';

@Injectable()
export class UnitService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.ASSET}/api`;

    data: any = {};

    constructor(private common: Common, private http: HttpClient) {}

    search(criteria: SearchCriteriaModel) {
        return this.http.post<SearchResultModel<UnitModel>>(`${this.url}/unit/search`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new UnitModel(x));
                }
                return res;
            }));
    }

    getPortalUnitList(includeToBeDetermined: boolean = false) {
        const key = 'PortalUnits';

        let results: UnitModel[] = [];

        if (this.data[key]) {
            return of(this.data[key] as UnitModel[]);
        }

        return this.http.get<UnitModel[]>(`${this.url}/unit/get-all?includeToBeDetermined=${includeToBeDetermined}`)
            .pipe(map(res => {
                if (res) {
                    results = res.map(x => new UnitModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as UnitModel[];
            }));
    }

    get(id: string) {
        return this.http.get<UnitModel>(`${this.url}/unit/get?id=${id}`).pipe(map(res => new UnitModel(res)));
    }

    getMacolaAssets(type: string) {
        const key = 'MacolaUnits';

        if (this.data[key]) {
            return of(this.data[key] as MacolaAssetModel[]);
        }

        return this.http.get<MacolaAssetModel[]>(`${this.url}/macola-asset/get?type=${type}`)
            .pipe(map(res => {
                if (res) {
                    const results = res.map(x => new MacolaAssetModel(x));

                    this.data[key] = results;

                    return results;
                }

                return new Array() as MacolaAssetModel[];
            }));
    }

    getExcel() {
        return this.http.post(`${this.url}/unit/create-excel-report`, {}, { responseType: 'arraybuffer' }).pipe(map(res => res));
    }

    syncAllUnits() {
        return this.http.post<Boolean>(`${this.url}/unit/sync`, {}, { observe: 'response' }).pipe(map(this.common.httpBooleanHandler));
    }
}
