<fluids-request-history [assetId]="selectedAsset" (onClose)="onCloseHistory()"></fluids-request-history>

<div class="row" id="fluids-request-detail">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <i class="far fa-file-alt"></i>
                <strong>Fluids Request #{{model.Pk}}</strong>
            </div>

            <div class="card-body">
                <form #fluidRequestFillTankForm="ngForm" novalidate autocomplete="off">
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label>Status:</label>
                            <div>{{model.Status | titlecase}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label>Type:</label>
                            <div>{{model.Type?.Name}}</div>
                        </div>

                        <div class="form-group col-sm-6 col-md-4" *ngIf="model.Workflows.length">
                            <label>Submitted on:</label>
                            <div>{{model.Workflows[0].CreationDate | date: 'MM/dd/yyyy h:mm a'}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label for="NeedOil">Show oil?</label>
                            <div>
                                <label class="switch switch-text switch-success switch-lg">
                                    <input [(ngModel)]="model.NeedOil" name="NeedOil" id="NeedOil" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onToggle('Oil')">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                        </div>

                        <div class="form-group col-sm-6 col-md-4">
                            <label for="NeedCoolant">Show coolant?</label>
                            <div>
                                <label class="switch switch-text switch-success switch-lg">
                                    <input [(ngModel)]="model.NeedCoolant" name="NeedCoolant" id="NeedCoolant" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onToggle('Coolant')">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label for="NeedCompoundOil">Show compound oil?</label>
                            <div>
                                <label class="switch switch-text switch-success switch-lg">
                                    <input [(ngModel)]="model.NeedCompoundOil" name="NeedCompoundOil" id="NeedCompoundOil" class="switch-input" type="checkbox" [disabled]="!access.save" (ngModelChange)="onToggle('CompoundOil')">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4">
                            <label for="VendorId">Vendor:</label>
                            <select #vendorRef="ngModel" name="VendorId" id="VendorId" class="form-control" [(ngModel)]="model.VendorId" required [disabled]="!access.save" (ngModelChange)="onVendorUpdate()">
                                <option *ngIf="!model.VendorId"></option>
                                <option *ngFor="let item of lookupValues['Vendor']" [value]="item.Id">{{item.Name}}</option>
                            </select>

                            <validation-message [isValid]="vendorRef.invalid && (vendorRef.dirty || vendorRef.touched)">
                                Please select a Vendor
                            </validation-message>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-striped stack">
                            <thead>
                                <tr>
                                    <th *ngIf="model.Status == 'PENDING' && (access.process || access.admin)"></th>
                                    <th>Unit</th>
                                    <th>Location</th>
                                    <th>Operator</th>
                                    <th>Area</th>
                                    <th>HP</th>
                                    <th>Engine</th>
                                    <th>Date Needed</th>
                                    <th *ngIf="model.NeedOil">Gallons of Oil</th>
                                    <th *ngIf="model.NeedCoolant">Gallons of Coolant</th>
                                    <th *ngIf="model.NeedCompoundOil">Gallons of Compound Oil</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-template ngFor let-item [ngForOf]="items" let-i="index">
                                    <tr [ngClass]="i % 2 == 0 ? 'row-even' : 'row-odd'">
                                        <td *ngIf="model.Status == 'PENDING' && (access.process || access.admin)">
                                            <span class="fas pointer" [ngClass]="{ 'fa-minus-square': item.Expanded, 'fa-plus-square': !item.Expanded}" (click)="item.Expanded = !item.Expanded"></span>
                                        </td>
                                        <td [attr.colspan]="(item.AssetId || '').length <= 5 ? 1 : 2">
                                            <span class="d-inline d-md-none">Unit:</span> {{item.AssetId}}
                                        </td>
                                        <td *ngIf="(item.AssetId || '').length <= 5"><span class="d-inline d-md-none">Location:</span> {{item.FromLocation}}</td>
                                        <td><span class="d-inline d-md-none">Operator:</span> {{item.Operator}}</td>
                                        <td><span class="d-inline d-md-none">Area:</span> {{model.AreaId}}</td>
                                        <td><span class="d-inline d-md-none">HP:</span> {{item.Horsepower}}</td>
                                        <td><span class="d-inline d-md-none">Engine:</span> {{item.EngineModel}}</td>
                                        <td>
                                            <p-calendar [name]="'DeliveryDate' + i" [id]="'DeliveryDate' + i" [(ngModel)]="item.Types[0].DeliveryDate" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true" [disabled]="!access.save" *ngIf="item.Types.length"></p-calendar>
                                        </td>
                                        <ng-template ngFor let-type [ngForOf]="item.Types" let-itemTypeIndex="index">
                                            <td>
                                                <span class="d-inline d-md-none">Gallons of {{type.Type.Description}}:</span>
                                                <input type="number" [(ngModel)]="type.Quantity" (ngModelChange)="calculateTotal(type.Type.Id)" [name]="'Quantity' + i + type.TypePk" class="form-control" numbersOnly [allowDecimals]="false" [disabled]="!access.save" />
                                            </td>
                                        </ng-template>
                                        <td class="actions">
                                            <button class="btn btn-sm btn-success" (click)="onShowHistory(item)" tooltip="History" *ngIf="item.AssetId && (item.AssetId || '').length <= 5">
                                                <i class="fas fa-history"></i> <span class="d-inline d-md-none ml-2">History</span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="item.Expanded" [ngClass]="i % 2 == 0 ? 'row-even' : 'row-odd'">
                                        <td [attr.colspan]="columnCount + 1">
                                            <table class="table">
                                                <thead>
                                                    <th></th>
                                                    <th>Invoice Number</th>
                                                    <th>Invoice Date</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                    <th>Amount</th>
                                                    <th>Tax</th>
                                                    <th>Total</th>
                                                </thead>
                                                <tbody>
                                                    <ng-template ngFor let-t [ngForOf]="item.Types" let-index="index">
                                                        <tr>
                                                            <td><strong>{{t.Type.Description}}</strong></td>
                                                            <td>
                                                                <span></span>
                                                                <input class="form-control" type="text" [(ngModel)]="t.InvoiceNumber" [name]="'InvoiceNumber' + i + t.TypePk" [id]="'InvoiceNumber' + i + t.TypePk" required />
                                                            </td>
                                                            <td>
                                                                <p-calendar [(ngModel)]="t.InvoiceDate" [name]="'InvoiceDate' + i + t.TypePk" [id]="'InvoiceDate' + i + t.TypePk" dateFormat="mm/dd/yy" readonlyInput="true" inputStyleClass="form-control" [required]="true"></p-calendar>
                                                            </td>
                                                            <td>
                                                                <input class="form-control" type="number" [(ngModel)]="t.InvoiceQuantity" [name]="'InvoiceQuantity' + i + t.TypePk" [id]="'InvoiceQuantity' + i + t.TypePk" required numbersOnly [allowDecimals]="false" />
                                                            </td>
                                                            <td>
                                                                <input class="form-control" type="number" [(ngModel)]="t.InvoicePrice" [name]="'InvoicePrice' + i + t.TypePk" [id]="'InvoicePrice' + i + t.TypePk" required numbersOnly />
                                                            </td>
                                                            <td>
                                                                <input class="form-control" type="number" [(ngModel)]="t.InvoiceAmount" [name]="'InvoiceAmount' + i + t.TypePk" [id]="'InvoiceAmount' + i + t.TypePk" required numbersOnly />
                                                            </td>
                                                            <td>
                                                                <input class="form-control" type="number" [(ngModel)]="t.InvoiceTax" [name]="'InvoiceTax' + i + t.TypePk" [id]="'InvoiceTax' + i + t.TypePk" required numbersOnly />
                                                            </td>
                                                            <td>
                                                                <input class="form-control" type="number" [(ngModel)]="t.InvoiceTotal" [name]="'InvoiceTotal' + i + t.TypePk" [id]="'InvoiceTotal' + i + t.TypePk" required numbersOnly />
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                </ng-template>
                                <tr *ngIf="items.length">
                                    <td *ngIf="model.Status == 'PENDING' && (access.process || access.admin)"></td>
                                    <td colspan="7"><strong>Total Gallons Ordered:</strong></td>
                                    <td *ngIf="model.NeedOil"><span class="d-inline d-md-none">Oil:</span><strong> {{totalGallonsOrdered['OIL']}}</strong></td>
                                    <td *ngIf="model.NeedCoolant"><span class="d-inline d-md-none">Coolant:</span><strong> {{totalGallonsOrdered['COOLANT']}}</strong></td>
                                    <td *ngIf="model.NeedCompoundOil"><span class="d-inline d-md-none">Compound Oil:</span><strong> {{totalGallonsOrdered['COMPOUND_OIL']}}</strong></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>