import { GeneralFieldServiceModel } from './general-field-service.model';

export class LaborModel {
    LaborPk: number;
    LaborId: string;
    LaborName: string;
    RegularHours: number;
    OvertimeHours: number;
    OtherHours: number;
    PayPeriodFirstDate: Date;
    PayPeriodLastDate: Date;
    Week1RegularHours: number;
    Week1OvertimeHours: number;
    Week1OtherHours: number;
    Week1Labors: GeneralFieldServiceModel[] = new Array();
    Week2RegularHours: number;
    Week2OvertimeHours: number;
    Week2OtherHours: number;
    Week2Labors: GeneralFieldServiceModel[] = new Array();
    Labors: GeneralFieldServiceModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.LaborPk = obj.LaborPk;
        this.LaborId = obj.LaborId;
        this.LaborName = obj.LaborName;
        this.RegularHours = obj.RegularHours;
        this.OvertimeHours = obj.OvertimeHours;
        this.OtherHours = obj.OtherHours;
        this.PayPeriodFirstDate = new Date(obj.PayPeriodFirstDate);
        this.PayPeriodLastDate = new Date(obj.PayPeriodLastDate);
        this.Week1RegularHours = obj.Week1RegularHours;
        this.Week1OvertimeHours = obj.Week1OvertimeHours;
        this.Week1OtherHours = obj.Week1OtherHours;
        this.Week2RegularHours = obj.Week2RegularHours;
        this.Week2OvertimeHours = obj.Week2OvertimeHours;
        this.Week2OtherHours = obj.Week2OtherHours;

        if (obj.Labors) {
            this.Labors = obj.Labors.map(x => new GeneralFieldServiceModel(x));
        }

        if (obj.Week1Labors) {
            for (var i of obj.Week1Labors) {
                this.Week1Labors.push(new GeneralFieldServiceModel(i));
            }
        }

        if (obj.Week2Labors) {
            for (var i of obj.Week2Labors) {
                this.Week2Labors.push(new GeneralFieldServiceModel(i));
            }
        }
   }
}
