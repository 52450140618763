import { Injectable } from "@angular/core";
import { Globals } from "../../shared/global";
import { SearchCriteriaModel, SearchResultModel } from "../../shared/models";
import { Common } from "./common.service";
import { HttpClient } from "@angular/common/http";
import { SalesMassRenewalModel } from "../../shared/models/mass-renewal.model";
import { map } from "rxjs/operators";

@Injectable()
export class SalesMassRenewalService {
    private url: string = `${Globals.BASE_API_URL}:${Globals.API_PORTS.SALES}/api/sales-mass-renewal`;

    constructor(private common: Common, private http: HttpClient) {}

    search(criteria: SearchCriteriaModel, type: string) {
        return this.http.post<SearchResultModel<SalesMassRenewalModel>>(`${this.url}/search?type=${type}`, criteria)
            .pipe(map(res => {
                if (res) {
                    res.Results = res.Results.map(x => new SalesMassRenewalModel(x));
                }
                return res;
            }));
    }

    get(modelPk: number)  {
        return this.http.get<SalesMassRenewalModel>(`${this.url}/get?modelPk=${modelPk}`)
            .pipe(
                map(res => new SalesMassRenewalModel(res))
            );
    }

    save(model: SalesMassRenewalModel) {
        const newModel: SalesMassRenewalModel = this.common.formatDateFields(model, 'RenewalDate');

        for (let x in newModel.Items) {
            newModel.Items[x] = this.common.formatDateFields(newModel.Items[x], 'RenewalDate');

            if (newModel.Items[x].Pk < 0) {
                newModel.Items[x].Pk = 0;
            }
        }

        if (model.Pk) {
            return this.http.put<SalesMassRenewalModel>(`${this.url}/update`, newModel)
                .pipe(
                    map(res => new SalesMassRenewalModel(res))
                );
        }

        return this.http.post<SalesMassRenewalModel>(`${this.url}/create`, newModel)
            .pipe(
                map(res => new SalesMassRenewalModel(res))
            );
    }

    delete(modelPk: number) {
        return this.http.delete<Boolean>(`${this.url}/delete?modelPk=${modelPk}`, { observe: 'response' })
            .pipe(
                map(this.common.httpBooleanHandler)
            );
    }
}