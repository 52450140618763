import * as moment from 'moment';

export class ApplicationModel {
    Pk: number;
    Id: string;
    Description: string;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;
    // Actions: ApplicationActionModel[] = new Array();
    // Modules: ApplicationModuleModel[] = new Array();
    // Roles: ApplicationRoleModel[] = new Array();
    // UserRoles: ApplicationUserRoleModel[] = new Array();
    /*
    ApplicationRoleModuleActions: []
    */

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Id = obj.Id;
        this.Description = obj.Description;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        // if (obj.Actions) {
        //     this.Actions = obj.Actions.map(x => new ApplicationActionModel(x));
        // }

        // if (obj.Modules) {
        //     this.Modules = obj.Modules.map(x => new ApplicationModuleModel(x));
        // }

        // if (obj.Roles) {
        //     this.Roles = obj.Roles.map(x => new ApplicationRoleModel(x));
        // }

        // if (obj.UserRoles) {
        //     this.UserRoles = obj.UserRoles.map(x => new ApplicationUserRoleModel(x));
        // }
    }
}

export class ApplicationActionModel {
    Pk: number;
    ApplicationPk: number;
    Id: string;
    Description: string;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ApplicationPk = obj.ApplicationPk;
        this.Id = obj.Id;
        this.Description = obj.Description;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }
}

export class ApplicationModuleModel {
    Pk: number;
    ApplicationPk: number;
    Id: string;
    Description: string;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;
    // ApplicationRoleModuleActionModel

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ApplicationPk = obj.ApplicationPk;
        this.Id = obj.Id;
        this.Description = obj.Description;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;
    }
}

export class ApplicationRoleModuleActionModel {
    Pk: number;
    ApplicationPk: number;
    ApplicationRolePk: number;
    ApplicationModulePk: number;
    ApplicationActionPk: number;
    IsEnabled: boolean;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;

    Application: ApplicationModel;
    ApplicationAction: ApplicationActionModel;
    ApplicationModule: ApplicationModuleModel;
    ApplicationRole: ApplicationRoleModel;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ApplicationPk = obj.ApplicationPk;
        this.ApplicationRolePk = obj.ApplicationRolePk;
        this.ApplicationModulePk = obj.ApplicationModulePk;
        this.ApplicationActionPk = obj.ApplicationActionPk;
        this.IsEnabled = obj.IsEnabled;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        if (obj.Application) {
            this.Application = new ApplicationModel(obj.Application);
        }

        if (obj.ApplicationAction) {
            this.ApplicationAction = new ApplicationActionModel(obj.ApplicationAction);
        }

        if (obj.ApplicationModule) {
            this.ApplicationModule = new ApplicationModuleModel(obj.ApplicationModule);
        }

        if (obj.ApplicationRole) {
            this.ApplicationRole = new ApplicationRoleModel(obj.ApplicationRole);
        }
    }
}

export class ApplicationRoleModel {
    Pk: number;
    Application: ApplicationModel;
    ApplicationPk: number;
    Id: string;
    Description: string;
    CreationDate: Date;
    CreatedBy: number;
    LastUpdateDate: Date;
    LastUpdateBy: number;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ApplicationPk = obj.ApplicationPk;
        this.Id = obj.Id;
        this.Description = obj.Description;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        if (obj.Application) {
            this.Application = new ApplicationModel(obj.Application);
        }
    }
}

export class ApplicationUserRoleModel {
    Pk: number;
    Application: ApplicationModel;
    ApplicationPk: number;
    ApplicationUserPk: number
    ApplicationRolePk: number;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;
    ApplicationRole: ApplicationRoleModel;
    ApplicationUser: ApplicationUserModel;
    // todo: remove
    ApplicationDescription: string; // "Operations"
    ApplicationId: string; // "OPS"
    ApplicationRoleDescription: string; // "Administrator"
    ApplicationRoleId: string; // "ADMIN"
    ApplicationUserRolePk: number; //2296

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.ApplicationPk = obj.ApplicationPk;
        this.ApplicationUserPk = obj.ApplicationUserPk;
        this.ApplicationRolePk = obj.ApplicationRolePk;

        if (obj.Application) {
            this.Application = new ApplicationModel(obj.Application);
        }

        if (obj.ApplicationRole) {
            this.ApplicationRole = new ApplicationRoleModel(obj.ApplicationRole);
        }

        if (obj.ApplicationUser) {
            this.ApplicationUser = new ApplicationUserModel(obj.ApplicationUser);
        }

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        // todo: remove
        this.ApplicationDescription = obj.ApplicationDescription;
        this.ApplicationId = obj.ApplicationId;
        this.ApplicationRoleDescription = obj.ApplicationRoleDescription;
        this.ApplicationRoleId = obj.ApplicationRoleId;
        this.ApplicationUserRolePk = obj.ApplicationUserRolePk;
    }
}

export class ApplicationUserModel {
    Pk: number;
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;
    IsEnabled: boolean;
    UserId: string;
    AreaId: string;
    FirstName: string;
    LastName: string;
    Nickname: string;
    AssetId: string;
    FullName: string;
    DisplayName: string;
    HireDate: Date;
    TruckAllowance: boolean;
    IsGuaranteed40: boolean;
    PayrollId: string;
    TSheetsId: string;
    ApplicationUserRoles: ApplicationUserRoleModel[] = new Array();

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;

        if (obj.ApplicationUserPk) {
            this.Pk = obj.ApplicationUserPk;
        }

        this.IsEnabled = obj.IsEnabled;
        this.UserId = obj.UserId;
        this.AreaId = obj.AreaId;
        this.FirstName = obj.FirstName;
        this.LastName = obj.LastName;
        this.Nickname = obj.Nickname;
        this.FullName = `${this.FirstName} ${this.LastName}`;
        this.DisplayName = `${this.LastName}, ${this.FirstName}`;

        if (this.Nickname) {
            this.DisplayName += ` (${this.Nickname})`
        }

        this.HireDate = obj.HireDate;
        this.TruckAllowance = obj.TruckAllowance;
        this.IsGuaranteed40 = obj.IsGuaranteed40;
        this.AssetId = obj.AssetId;
        this.PayrollId = obj.PayrollId;
        this.TSheetsId = obj.TSheetsId;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        if (obj.ApplicationUserRoles) {
            this.ApplicationUserRoles = obj.ApplicationUserRoles.map(x => new ApplicationUserRoleModel(x));
        }
    }

    getFirstNicknameLast() {
        var nickName = this.Nickname ? "(" + this.Nickname + ") " : "";

        if (this.FirstName && this.LastName) {
            return `${this.FirstName} ${nickName}${this.LastName}`;
        }

        return this.FirstName ? this.FirstName : this.LastName;
    }
}

export class ApplicationSettingModel {
    Pk: number;
    Name: string;
    Value: string;
    Type: string = 'text';
    CreatedBy: number;
    CreationDate: Date;
    LastUpdateBy: number;
    LastUpdateDate: Date;
    ConvertedValue: any;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }

        this.Pk = obj.Pk;
        this.Name = obj.Name;
        this.Value = obj.Value;
        this.Type = obj.Type;

        if (obj.CreationDate) {
            this.CreationDate = moment(obj.CreationDate).toDate();
        }

        this.CreatedBy = obj.CreatedBy;

        if (obj.LastUpdateDate) {
            this.LastUpdateDate = moment(obj.LastUpdateDate).toDate();
        }

        this.LastUpdateBy = obj.LastUpdateBy;

        try {
            if (this.Type == 'boolean') {
                this.ConvertedValue = this.Value.toLowerCase() == 'true';
            }
            else if (this.Type == 'list') {
                this.ConvertedValue = this.Value.split(',');
            }
            else if (this.Type == 'json') {
                this.ConvertedValue = JSON.parse(this.Value);
            }
            else {
                this.ConvertedValue = this.Value;
            }
        } catch (error) {
            console.log(`Application Setting #${this.Pk}: ${error.message}`);
        }
    }
}

export interface BlobStorageSettingModel {
    ConnectionString: string;
    Container: string;
    Token: string;
    TokenExpiration: string;
}
